import React from "react";
import { useState } from "react";
import "../../../../../css/games/funTarget/rightButtonPanel.css";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import { useSelector } from "react-redux";
function RightButtonPanel(props) {
  const language = useSelector((state) => state.languageObjs.languageObj)
  return (
    <div className="sorat_btnPanel">
      <div className="fl_1">
        <button
            className={props.actBtn ? "fd ar_bt_1 active z_350" : "fd ar_bt_1 z_10"}
            name={props.betBtnText}
            disabled={props.disBetBtn == 1}
            onClick={(e) => {
            e.preventDefault();
            console.log(e.target.name +"   ---   "+props.betButtonValue );
            if (props.betButtonValue === 1) {
              let betSounds;
            betSounds = new Audio(betSound);
            betSounds.play();
              props.action("SPIN");
            } else if (props.betButtonValue === 0) {
              props.action("PREV");
            } else {
               
                props.action("TAKE");
               
            }
            // if (e.target.name === "SPIN") {
            //   let betSounds;
            // betSounds = new Audio(betSound);
            // betSounds.play();
            //   props.action("SPIN");
            // } else if (e.target.name === "PREV") {
            //   props.action("PREV");
            // } else {
               
            //     props.action("TAKE");
               
            // }
          }}
        >
          {props.betBtnText}
        </button>
      </div>
      <div className="fl_1">
        <button
          className="fd ar_bt_1"
          onClick={(e) => {
            e.preventDefault();
            props.action("clear");
          }}
          disabled={props.disClearBtn == 1}
        >
          {language.Clear}
        </button>
      </div>
      <div className="fl_1">
        <button
          className="fd ar_bt_1"
          onClick={(e) => {
            e.preventDefault();
            props.action("double");
          }}
          disabled={props.disDoubleBtn == 1}
        >
          {language.Double}
        </button>
      </div>
    </div>
  );
}
export default  RightButtonPanel