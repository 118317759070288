import React from "react";
import "../../../../../css/games/americanRoulette/title.css";
import american_roulette_timer from "../../../../../../assets/titles/indie/americanRouletteTimer.svg";

export default function Title(props) {
    return (
        <div className="fd text_center m_t_30">
            <img className='ht_100' src={american_roulette_timer} alt={american_roulette_timer}></img>
        </div>
    )
}
