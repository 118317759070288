import React, { Component } from "react";

import "./bingo.css";


import resultCoin from "../../../../../../assets/games/bingo/resBg.png";

import takeStart from "../../../../../../assets/games/bingo/bet_buttons.png";

import bingo_clearbtn from "../../../../../../assets/games/bingo/bngo_clear.png";
import bingo_betglow from "../../../../../../assets/games/bingo/Button-Glow.png";
import { connect } from "react-redux";
class RightButtonPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      result: "",
      bingo_dupcomponet: false,
      animation_bingo1: false,
      animation_bingo2: false,
      animation_bingo3: false,
      animation_bingo4: false,
      animation_bingo5: false,
      animation_bingo6: false,
      betglow: false,

      lotteryNumbers: [],
      currentNumberIndex: 0,

      bngrsltdisplay: false,
      bingoresulnum: [],
      bingobonusval: null,
    };
    this.isbnDupActive = false;
    this.spinchifst = "";
    this.spinchsecn = "";
    this.spinchitrd = "";
    this.spinchifrth = "";
    this.spinchifivth = "";
    this.spinchisxth = "";
  }

  spinCoinAnimation0 = "";
  spinCoinAnimation1 = "";
  spinCoinAnimation2 = "";
  spinCoinAnimation3 = "";
  spinCoinAnimation4 = "";
  spinCoinAnimation6 = "";

  componentDidMount() {

    this.preloadBingoImgRight();
    this.setState({ bingo_dupcomponet: true });
    // this.bngoanim_snd = new Audio(bngoanim_snd);

    if (this.props.betButtonValue == 1) {
      document.getElementById("bngo_betglow").src = bingo_betglow;
      document.getElementById("bngo_betglow").classList.add("betbutton_glow");
    }

    const { childRef } = this.props;
    childRef(this);
    this.rightbn1 = null;
    this.rightbn2 = null;
    this.rightbn3 = null;
    this.rightbn4 = null;
    this.rightbn5 = null;
    this.rightbn6 = null;
    this.rightbn7 = null;
    this.rightbn8 = null;
  }

  preloadBingoImgRight = () => {
    let bngoImagesLoadRight = [
      resultCoin,
      takeStart,
      bingo_betglow,
      bingo_betglow,
    ];

    for (let i = 0; i < bngoImagesLoadRight.length; i++) {
      let bngoImagesRight = new Image();
      bngoImagesRight.src = bngoImagesLoadRight[i];
    }
  };

  showResultInBetHouses = (event) => {
    console.log("RBP: ResultBtn")
    console.log(event)
    if (event) {
      // if (document.getElementById("fstchip_spin")) {
      //   document.getElementById("fstchip_spin").src = brownanim;
      //   document.getElementById("fstchip_spin").classList.add("bingorollinggifs");
      // }
      // if (document.getElementById("secondchip_spin")) {
      //   document.getElementById("secondchip_spin").src = pinkanim;
      //   document.getElementById("secondchip_spin").classList.add("bingorollinggise");
      // }
      // if (document.getElementById("trdchip_spin")) {
      //   document.getElementById("trdchip_spin").src = bluenanim;
      //   document.getElementById("trdchip_spin").classList.add("bingorollinggitrd");
      // }
      // if (document.getElementById("frthchip_spin")) {
      //   document.getElementById("frthchip_spin").src = greeenanim;
      //   document.getElementById("frthchip_spin").classList.add("bingorollinggifrth");
      // }
      // if (document.getElementById("fithchip_spin")) {
      //   document.getElementById("fithchip_spin").src = thickbluenanim;
      //   document.getElementById("fithchip_spin").classList.add("bingorollinggifive");
      // }
      // if (document.getElementById("sixthchip_spin")) {
      //   document.getElementById("sixthchip_spin").src = redanim;
      //   document.getElementById("sixthchip_spin").classList.add("bingorollinggisix");
      // }
      // this.bngoanim_snd = new Audio(bngoanim_snd);
      // this.bngoanim_snd.currentTime = 0;
      // this.bngoanim_snd.play();

    clearTimeout(this.rightbn2)
    clearTimeout(this.rightbn3)
    clearTimeout(this.rightbn4)
    clearTimeout(this.rightbn5)
    clearTimeout(this.rightbn6)
    clearTimeout(this.rightbn7)


      this.rightbn2 = null;
      this.rightbn3 = null;
      this.rightbn4 = null;
      this.rightbn5 = null;
      this.rightbn6 = null;
      this.rightbn7 = null;

      // this.clearanimonebyone();
    }
  };

  clearanimonebyone = () => {
    this.rightbn2 = setTimeout(() => {
      // if (document.getElementById("fstchip_spin")) {
      //   document.getElementById("fstchip_spin").src = "";
      //   document.getElementById("fstchip_spin").classList.remove("bingorollinggifs");
      // }
    }, 1000);

    this.rightbn3 = setTimeout(() => {
      // if (document.getElementById("secondchip_spin")) {
      //   document.getElementById("secondchip_spin").src = "";
      //   document.getElementById("secondchip_spin").classList.remove("bingorollinggise");
      // }
    }, 2000);

    this.rightbn4 = setTimeout(() => {
      // if (document.getElementById("trdchip_spin")) {
      //   document.getElementById("trdchip_spin").src = "";
      //   document.getElementById("trdchip_spin").classList.remove("bingorollinggitrd");
      // }
    }, 3000);

    this.rightbn5 = setTimeout(() => {
      // if (document.getElementById("frthchip_spin")) {
      //   document.getElementById("frthchip_spin").src = "";
      //   document.getElementById("frthchip_spin").classList.remove("bingorollinggifrth");
      // }
    }, 4000);

    this.rightbn6 = setTimeout(() => {
      // if (document.getElementById("fithchip_spin")) {
      //   document.getElementById("fithchip_spin").src = "";
      //   document.getElementById("fithchip_spin").classList.remove("bingorollinggifive");
      // }
    }, 5000);

    this.rightbn7 = setTimeout(() => {
      // if (document.getElementById("sixthchip_spin")) {
      //   document.getElementById("sixthchip_spin").src = "";
      //   document.getElementById("sixthchip_spin").classList.remove("bingorollinggisix");
      // }
    }, 6000);
  };

  resultList = [
    { id: 0, src: resultCoin, resultNo: "1" },
    { id: 1, src: resultCoin, resultNo: "2" },
    { id: 2, src: resultCoin, resultNo: "3" },
    { id: 3, src: resultCoin, resultNo: "4" },
    { id: 4, src: resultCoin, resultNo: "5" },
    { id: 5, src: resultCoin, resultNo: "NORMAL" },
  ];

  clearintervalsright = () => {
    clearTimeout(this.rightbn1);
    clearTimeout(this.rightbn2);
    clearTimeout(this.rightbn3);
    clearTimeout(this.rightbn4);
    clearTimeout(this.rightbn5);
    clearTimeout(this.rightbn6);
    clearTimeout(this.rightbn7);
    clearTimeout(this.rightbn8);
  };

  bingoResultAnimation = (bingoResult) => {
    // console.log(bingoResult);

    if (bingoResult) {
      let bngrstlnums = bingoResult;
      let resultnum = JSON.parse(bngrstlnums[0]);
      let bonusval = bngrstlnums[1];
      this.setState({ bingoresulnum: resultnum, bingobonusval: bonusval });
      this.setState({ bngrsltdisplay: true });
    }

    this.rightbn8 = setTimeout(() => {
      // this.afterBingoResultAnimation(resNo)
      this.setState({ bngrsltdisplay: false });
    }, 7200);
  };


  render() {
    return (
      <React.Fragment>
        <div className="bet-result-container row">
          <div className="col-6 resBg">
            <div className="res1">
              <span className="bingo-chip-result0" id="resultimages0"></span>
              <img id="resultspinchip0" src={resultCoin} />
              <img id="fstchip_spin" className="bingo_animation" src="" />
              <img id="bngoresult_anim1" className="bngoresult_anim1" src="" />
            </div>
            <div className="res1">
              <span className="bingo-chip-result1" id="resultimages1"></span>
              <img id="resultspinchip1" src={resultCoin} />
              <img id="secondchip_spin" className="bingo_animationschip" src="" />
              <img id="bngoresult_anim2" className="bngoresult_anim2" src="" />
            </div>
            <div className="res1">
              <span className="bingo-chip-result2" id="resultimages2"></span>
              <img id="resultspinchip2" src={resultCoin} />
              <img id="trdchip_spin" className="bingo_animationthrchip" src="" />
              <img id="bngoresult_anim3" className="bngoresult_anim3" src="" />
            </div>
            <div className="res1">
              <span className="bingo-chip-result3" id="resultimages3"></span>
              <img id="resultspinchip3" src={resultCoin} />
              <img id="frthchip_spin" className="bingo_animationfrthchip" src="" />
              <img id="bngoresult_anim4" className="bngoresult_anim4" src="" />
            </div>
            <div className="res1">
              <span className="bingo-chip-result4" id="resultimages4"></span>
              <img id="resultspinchip4" src={resultCoin} />
              <img id="fithchip_spin" className="bingo_animationfive" src="" />
              <img id="bngoresult_anim5" className="bngoresult_anim5" src="" />
            </div>
            <div className="res1">
              <span className="bingo-chip-result5" id="resultimages5"></span>
              <img id="resultspinchip5" src={resultCoin} />
              <img id="sixthchip_spin" className="bingo_animationsix" src="" />
              <img id="bngoresult_anim6" className="bngoresult_anim6" src="" />
            </div>
          </div>

          <div className="col-6 resBg ">
            <button
              className={this.props.isMobile ? "doublbngo_btn emptyPointer_bngo" : "doublbngo_btn addCursorPointer_bngo"}
              id="doubleButton"
              disabled={this.props.disdouble == 1}
              onClick={(e) => {
                e.preventDefault();
                if (this.props.disdouble == 0) {
                  this.props.action("double");
                }
              }}
              onPointerDown={(e) => {
                e.preventDefault();
                if (this.props.disdouble == 0) {
                  document.getElementById("doubleButton").style.transform = "scale(0.85)";
                }
              }}
              onPointerUp={(e) => {
                e.preventDefault();
                if (this.props.disdouble == 0) {
                  document.getElementById("doubleButton").style.transform = "scale(1)";
                }
              }}
              onPointerOut={(e) => {
                e.preventDefault();
                if (this.props.disdouble == 0) {
                  document.getElementById("doubleButton").style.transform = "scale(1)";
                }
              }}
              onPointerCancel={(e) => {
                e.preventDefault();
                if (this.props.disdouble == 0) {
                  document.getElementById("doubleButton").style.transform = "scale(1)";
                }
              }}>
              <img
                id="double_bngoimg"
                className={this.props.isMobile ? "double_button_bingo emptyPointer_bngo" : "double_button_bingo addCursorPointer_bngo"}
                src={takeStart}
                alt=""
              />
              <span id="double_heading" className="double_heading">{this.props.language.Double}</span>
            </button>

            <button
              id="bngo_clear"
              className={this.props.isMobile ? "bngo_clear emptyPointer_bngo" : "bngo_clear addCursorPointer_bngo"}
              disabled={this.props.disClearBtn == 1}
              onClick={(e) => {
                console.log(this.props.disClearBtn);
                e.preventDefault();
                if (this.props.disClearBtn == 0) {
                  this.props.action("clear");
                }
              }}
              onPointerDown={(e) => {
                e.preventDefault();
                if (this.props.disClearBtn == 0) {
                  //this.props.action("clear");
                  document.getElementById("bngo_clear").style.transform = "scale(0.85)";
                }
              }}
              onPointerUp={(e) => {
                document.getElementById("bngo_clear").style.transform = "scale(1)";
                e.preventDefault();
                if (this.props.disClearBtn == 0) {
                  document.getElementById("bngo_clear").style.transform = "scale(1)";
                }
              }}
              onPointerOut={(e) => {
                e.preventDefault();
                if (this.props.disClearBtn == 0) {
                  document.getElementById("bngo_clear").style.transform = "scale(1)";
                }
              }}
            >
              <img
                id="clerbngo_dupimg"
                className={
                  this.props.isMobile
                    ? "bingoclearbtn emptyPointer_bngo"
                    : "bingoclearbtn addCursorPointer_bngo"
                }
                src={bingo_clearbtn}
              />
              <span id="bngclear_text" className="bngclear_text">{this.props.language.Clear}</span>
            </button>

            <button
              className={this.props.isMobile ? "bngo_betbtn emptyPointer_bngo" : "bngo_betbtn addCursorPointer_bngo"}
              id="betbngoButton"
              disabled={this.props.disbetBt == 1}
              name={this.props.betButtonText}
              onClick={(e) => {
                e.preventDefault();
                console.log("________" + this.props.betButtonValue + " " + this.props.disbetBt);
                if (this.props.betButtonValue == 1 && this.props.disbetBt == 0) {
                  this.props.action("Roll");
                } else if (this.props.betButtonValue == 0) {
                  this.props.action("PREV");
                } else if (this.props.betButtonValue == 2) {
                  this.setState({ betglow: true });
                  this.props.action("TAKE");
                }
              }}
              onPointerDown={(e) => {
                e.preventDefault();

                if (this.props.disbetBt == 0) {
                  // action("Roll");
                  document.getElementById("betbngoButton").style.transform = "scale(0.85)";
                }
              }}
              onPointerUp={(e) => {
                // alert("'.....")
                e.preventDefault();
                if (this.props.disbetBt == 0) {
                  document.getElementById("betbngoButton").style.transform = "scale(1)";
                }
                // document.getElementById("map1").style.transform = "scale(1)";
              }}
              onPointerOut={(e) => {
                e.preventDefault();
                if (this.props.disbetBt == 0) {
                  document.getElementById("betbngoButton").style.transform = "scale(1)";
                }
              }}
            >
              <img
                className={
                  this.props.isMobile
                    ? "bngo_betglow emptyPointer_bngo"
                    : "bngo_betglow  "
                }
                id="bngo_betglow"
                src=""
              />
              <img
                className={
                  this.props.isMobile
                    ? "bingobetbtn emptyPointer_bngo"
                    : "bingobetbtn addCursorPointer_bngo"
                }
                id="bngo_betbtn"
                src={takeStart}
              />

              <span className="bngo_betprevtake">
                {this.props.betButtonText}
              </span>
            </button>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj,
  };
};
export default connect(mapStatesToProps)(RightButtonPanel);
