import React from 'react';

const tableJson = [
    { tr:'tr1', visibleVal:'0', btmVal:'', cntrVal:'', leftVal:'0', mainVal:'0', bg:'', uqId:'1'},
    { tr:'tr1', visibleVal:'3', btmVal:'40', cntrVal:'114', leftVal:'99', mainVal:'4', bg:'red', uqId:'2'},
    { tr:'tr1', visibleVal:'6', btmVal:'42', cntrVal:'116', leftVal:'65', mainVal:'7', bg:'black', uqId:'3'},
    { tr:'tr1', visibleVal:'9', btmVal:'44', cntrVal:'118', leftVal:'68', mainVal:'10', bg:'red', uqId:'4'},
    { tr:'tr1', visibleVal:'12', btmVal:'46', cntrVal:'120', leftVal:'71', mainVal:'13', bg:'red', uqId:'5'},
    { tr:'tr1', visibleVal:'15', btmVal:'48', cntrVal:'122', leftVal:'74', mainVal:'16', bg:'black', uqId:'6'},
    { tr:'tr1', visibleVal:'18', btmVal:'50', cntrVal:'124', leftVal:'77', mainVal:'19', bg:'red', uqId:'7'},
    { tr:'tr1', visibleVal:'21', btmVal:'52', cntrVal:'126', leftVal:'80', mainVal:'22', bg:'red', uqId:'8'},
    { tr:'tr1', visibleVal:'24', btmVal:'54', cntrVal:'128', leftVal:'83', mainVal:'25', bg:'black', uqId:'9'},
    { tr:'tr1', visibleVal:'27', btmVal:'56', cntrVal:'130', leftVal:'86', mainVal:'28', bg:'red', uqId:'10'},
    { tr:'tr1', visibleVal:'30', btmVal:'58', cntrVal:'132', leftVal:'89', mainVal:'31', bg:'red', uqId:'11'},
    { tr:'tr1', visibleVal:'33', btmVal:'60', cntrVal:'134', leftVal:'92', mainVal:'34', bg:'black', uqId:'12'},
    { tr:'tr1', visibleVal:'36', btmVal:'62', cntrVal:'136', leftVal:'95', mainVal:'37', bg:'red', uqId:'13'},
    { tr:'tr1', visibleVal:'2 to 1', btmVal:'35,36', cntrVal:'', leftVal:'33,36', mainVal:'156', bg:'thirdRow', uqId:'14'},

    { tr:'tr2', visibleVal:'', btmVal:'98', cntrVal:'113', leftVal:'149', mainVal:'150', bg:'hidden', uqId:'28'},
    { tr:'tr2', visibleVal:'2', btmVal:'39', cntrVal:'112', leftVal:'97', mainVal:'3', bg:'black', uqId:'15'},
    { tr:'tr2', visibleVal:'5', btmVal:'41', cntrVal:'115', leftVal:'64', mainVal:'6', bg:'red', uqId:'16'},
    { tr:'tr2', visibleVal:'8', btmVal:'43', cntrVal:'117', leftVal:'67', mainVal:'9', bg:'black', uqId:'17'},
    { tr:'tr2', visibleVal:'11', btmVal:'45', cntrVal:'119', leftVal:'70', mainVal:'12', bg:'black', uqId:'18'},
    { tr:'tr2', visibleVal:'14', btmVal:'47', cntrVal:'121', leftVal:'73', mainVal:'15', bg:'red', uqId:'19'},
    { tr:'tr2', visibleVal:'17', btmVal:'49', cntrVal:'123', leftVal:'76', mainVal:'18', bg:'black', uqId:'20'},
    { tr:'tr2', visibleVal:'20', btmVal:'51', cntrVal:'125', leftVal:'79', mainVal:'21', bg:'black', uqId:'21'},
    { tr:'tr2', visibleVal:'23', btmVal:'53', cntrVal:'127', leftVal:'82', mainVal:'24', bg:'red', uqId:'22'},
    { tr:'tr2', visibleVal:'26', btmVal:'55', cntrVal:'129', leftVal:'85', mainVal:'27', bg:'black', uqId:'23'},
    { tr:'tr2', visibleVal:'29', btmVal:'57', cntrVal:'131', leftVal:'88', mainVal:'30', bg:'black', uqId:'24'},
    { tr:'tr2', visibleVal:'32', btmVal:'59', cntrVal:'133', leftVal:'91', mainVal:'33', bg:'red', uqId:'25'},
    { tr:'tr2', visibleVal:'35', btmVal:'61', cntrVal:'135', leftVal:'94', mainVal:'36', bg:'black', uqId:'26'},
    { tr:'tr2', visibleVal:'2 to 1', btmVal:'', cntrVal:'', leftVal:'', mainVal:'155', bg:'secondRow', uqId:'27'},

    { tr:'tr3', visibleVal:'', btmVal:'151', cntrVal:'152', leftVal:'153', mainVal:'', bg:'hidden', uqId:'29'},
    { tr:'tr3', visibleVal:'1', btmVal:'100', cntrVal:'137', leftVal:'96', mainVal:'2', bg:'red', uqId:'30'},
    { tr:'tr3', visibleVal:'4', btmVal:'101', cntrVal:'138', leftVal:'63', mainVal:'5', bg:'black', uqId:'31'},
    { tr:'tr3', visibleVal:'7', btmVal:'102', cntrVal:'139', leftVal:'66', mainVal:'8', bg:'red', uqId:'32'},
    { tr:'tr3', visibleVal:'10', btmVal:'103', cntrVal:'140', leftVal:'69', mainVal:'11', bg:'black', uqId:'33'},
    { tr:'tr3', visibleVal:'13', btmVal:'104', cntrVal:'141', leftVal:'72', mainVal:'14', bg:'black', uqId:'34'},
    { tr:'tr3', visibleVal:'16', btmVal:'105', cntrVal:'142', leftVal:'75', mainVal:'17', bg:'red', uqId:'35'},
    { tr:'tr3', visibleVal:'19', btmVal:'106', cntrVal:'143', leftVal:'78', mainVal:'20', bg:'red', uqId:'36'},
    { tr:'tr3', visibleVal:'22', btmVal:'107', cntrVal:'144', leftVal:'81', mainVal:'23', bg:'black', uqId:'37'},
    { tr:'tr3', visibleVal:'25', btmVal:'108', cntrVal:'145', leftVal:'84', mainVal:'26', bg:'red', uqId:'38'},
    { tr:'tr3', visibleVal:'28', btmVal:'109', cntrVal:'146', leftVal:'87', mainVal:'29', bg:'black', uqId:'39'},
    { tr:'tr3', visibleVal:'31', btmVal:'110', cntrVal:'147', leftVal:'90', mainVal:'32', bg:'black', uqId:'40'},
    { tr:'tr3', visibleVal:'34', btmVal:'111', cntrVal:'148', leftVal:'93', mainVal:'35', bg:'red', uqId:'41'},
    { tr:'tr3', visibleVal:'2 to 1', btmVal:'', cntrVal:'', leftVal:'', mainVal:'154', bg:'firstRow', uqId:'42'},

    { tr:'tr4', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'43'},
    { tr:'tr4', visibleVal:'1st 12', tdBg:'sector', mainVal:'157', divBg:'mainVal first_twelve_Section', colSpn:'4', uqId:'44'},
    { tr:'tr4', visibleVal:'2nd 12', tdBg:'sector', mainVal:'158', divBg:'mainVal second_twelve_Section', colSpn:'4', uqId:'45'},
    { tr:'tr4', visibleVal:'3rd 12', tdBg:'sector', mainVal:'159', divBg:'mainVal third_twelve_Section', colSpn:'4', uqId:'46'},
    { tr:'tr4', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'47'},

    { tr:'tr5', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'48'},
    { tr:'tr5', visibleVal:'1 TO 18', tdBg:'sector', mainVal:'169', divBg:'mainVal oneToEighteen', colSpn:'2', uqId:'49'},
    { tr:'tr5', visibleVal:'EVEN', tdBg:'sector', mainVal:'167', divBg:'mainVal evenNos', colSpn:'2', uqId:'50'},
    { tr:'tr5', visibleVal:' ', tdBg:'sector red_box', mainVal:'165', divBg:'mainVal redBox', colSpn:'2', uqId:'51'},
    { tr:'tr5', visibleVal:' ', tdBg:'sector black_box', mainVal:'166', divBg:'mainVal blackBox', colSpn:'2', uqId:'52'},
    { tr:'tr5', visibleVal:'ODD', tdBg:'sector', mainVal:'168', divBg:'mainVal oddNos', colSpn:'2', uqId:'53'},
    { tr:'tr5', visibleVal:'19 TO 36', tdBg:'sector', mainVal:'170', divBg:'mainVal nineteenToThirtysix', colSpn:'2', uqId:'54'},
    { tr:'tr5', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'55'},


    { tr:'tr2', visibleVal:'', btmVal:'38', cntrVal:'156', leftVal:'160', mainVal:'161', bg:'hidden', uqId:'55'},
    { tr:'tr2', visibleVal:'', btmVal:'162', cntrVal:'163', leftVal:'164', mainVal:'', bg:'hidden', uqId:'56'},
]

export default tableJson;