
import React from "react";
import img_1 from "../../../../../../assets/games/funSoratTimer/x_images/ima-1.png";
import img_2 from "../../../../../../assets/games/funSoratTimer/x_images/ima-2.png";
import img_3 from "../../../../../../assets/games/funSoratTimer/x_images/ima-3.png";
import img_4 from "../../../../../../assets/games/funSoratTimer/x_images/ima-4.png";
import img_5 from "../../../../../../assets/games/funSoratTimer/x_images/ima-5.png";
import img_6 from "../../../../../../assets/games/funSoratTimer/x_images/ima-6.png";
import img_7 from "../../../../../../assets/games/funSoratTimer/x_images/ima-7.png";
import img_8 from "../../../../../../assets/games/funSoratTimer/x_images/ima-8.png";
import img_9 from "../../../../../../assets/games/funSoratTimer/x_images/ima-9.png";
import img_10 from "../../../../../../assets/games/funSoratTimer/x_images/ima-10.png";
import img_11 from "../../../../../../assets/games/funSoratTimer/x_images/ima-11.png";
import x2 from "../../../../../../assets/games/funSoratTimer/x_images/2x.png";
import x4 from "../../../../../../assets/games/funSoratTimer/x_images/4x.png";

export default class Ximage extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      currentImg: 0,
      X_list: [img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11],
      marque_list: [img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11, img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10, img_11],
      resultId: 0,
    };

    //console.log("this.props.jockIndex: "+ this.props.jockIndex)
  }
  componentDidMount() {
    console.log("Component Didmount");
    this.combine(this.state.resultId);
  }
 
  startImageChange = () => {
    let jackpot_img = document.getElementById("jackpot_img");
    jackpot_img.style.display = 'none';
  };
 
  setJackpotNumber(value){
    let jackpot_img2 = document.getElementById("jackpot_img");
    jackpot_img2.style.display = 'block';
    
   if (Number(value) === 2) {    
      jackpot_img2.innerHTML = `<img src=${x2} name="xImgs" className="act m_l_0"></img>`;
    } 
    if (Number(value) == 4) {     
      jackpot_img2.innerHTML = `<img src=${x4} name="xImgs" className="act m_l_0"></img>`;      
    }
  }

  stopImageChange = () => {   
    let jackpot_img = document.getElementById("jackpot_img");
    jackpot_img.style.display = 'block';
    console.log("Bonus Value: "+this.props.bonusVal);
    if (this.props.bonusVal == undefined || this.props.bonusVal == 0 || this.props.bonusVal == 1) {
      let bonusValue = Math.floor(Math.random() * 11);
      jackpot_img.innerHTML = `<img src=${this.state.X_list[bonusValue]} name="xImgs" className="act m_l_0"></img>`;
    } else if (this.props.bonusVal == 2) {
      jackpot_img.innerHTML = `<img src=${x2} name="xImgs" className="act m_l_0"></img>`;
    } else if (this.props.bonusVal == 4) {
      jackpot_img.innerHTML = `<img src=${x4} name="xImgs" className="act m_l_0"></img>`;
    }
  };


  combine = (imge) => {
    this.setState({ resultId: Number(imge) });
     console.log("Component Didmount combine  "+imge);
    if (Number(imge) == 1) { 
      this.startImageChange();
    } else {
      this.stopImageChange();
    }
  };

  render() { 
    const pStyle = {
      width: `${this.props.X_wid}px`,
      height: `${this.props.X_wid}px`, 
    };
    return (
      <React.Fragment>
        <div style={pStyle} className="betXiMG" id="betXiMG"></div>
        <div className="soratMarqueJack"> 
        <div style={pStyle} id="marqueeTag" className="betXiMG_Marq"> 
            <marquee direction="right" scrollamount="20" className="soratMarque">
                {this.state.marque_list.map((data, i) => (
                  <img key={i} src={data} name="xImgs" className="act"></img>
                  )) } 
            </marquee> 
        </div> 
        <div style={pStyle} id="jackpot_img" className="betXiMG">
          <img src={this.state.X_list[0]} name="xImgs" className="act m_l_0"></img>
        </div> 
        </div> 
      </React.Fragment>
    );
  }
}
