import React from "react";
import WheelInner from "./funTargetTimerWheel";
// import WheelBorder from "./funTargetTimerWheelBorder";
import Jackpot from './jackpot';

export default class Wheel extends React.Component {
    constructor(props) {
        super(props);
        this.fttWheelRef = React.createRef();
        this.jackpotXimg = React.createRef();
        this.winImage = React.createRef();
        this.state = {
            targetResult:props.targetResult
        }
        this.inWid = window.innerWidth
        this.inHt = window.innerHeight
  
        this.wheelWidth = 850;
        this.wheelHeight = 850;

        this.wheelY = this.wheelWidth * 0.46;
        this.wheelX = this.wheelWidth * 0.5;

        this.fontSize = 100;
        this.jackWidth = 350;
        this.jackTop = this.jackWidth * 0.6;
        this.wheelBoxHeight = 660;
        this.y = 40;
        
        this.resultNum = 0;
    }

    componentStateHandler() {}

    spinTheWheel = (s) => {
        this.resultNum = s;
        console.log("s "+s);
        console.log("Res Num: "+this.resultNum);
        this.fttWheelRef.current.spinTheWheel(s);
        // this.jackpotXimg.current.startJockpot()
       // this.props.jackpotHandler();
    };

    stopWheelSound =() =>{
        //this.fttWheelRef.current.stopWheel();
        console.log("Res Num: 2 "+this.resultNum);
       // this.Jackpot.current.stopImageChange();
       if(this.WheelInner && this.WheelInner.current){
           this.WheelInner.current.stopWheel();
       }
       // this.winImage.current.showWinImage(this.resultNum);
        
    }
    wheelHandler(position) {
        this.props.action(position);
        console.log("wheel: targetResult   ");
        console.log(this.props.targetResult);
       
    }
    setJackpotNumber =(bonusNum) =>{
        this.jackpotXimg.current.setJackpotNumber(bonusNum);
    }

    sendimg=(img)=>{  
        this.jackpotXimg.current.combine();              
    }

    
    stopWheelAnim(){
        console.log("Wheel --> Stop wheel animation.")       
         this.fttWheelRef.current.stopWheelAnim();       
    }
    render() {
        return (
            <div className="wheelBox_1 m_t_50" style={{height:`${this.wheelBoxHeight}px`}}> 
                <Jackpot jackTop={this.jackTop} targetResult={this.props.targetResult} ref={this.jackpotXimg} width={this.jackWidth} height={this.jackWidth}/>
                <WheelInner wheelX={this.wheelX} wheelY={this.wheelY} yVal={this.y} fontSize={this.fontSize}  onFocus={this.props.onFocus} gameState={this.props.gameState} targetResult={this.props.targetResult} ref={this.fttWheelRef} height={this.wheelHeight} width={this.wheelWidth} action={this.wheelHandler.bind(this)} isMobile={this.props.isMobile}></WheelInner>
                {/* <WheelBorder trgtTmLoded={this.props.trgtTmLoded} targetResult={this.props.targetResult} ref={this.winImage} width={this.wheelWidth} height={this.wheelHeight} x={0} y={0}></WheelBorder> */}
            </div>
        );
    }
}
