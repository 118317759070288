import React from "react";
import betSound from "../../../../../../assets/sounds/bet.mp3"; 
import { connect } from "react-redux";
 class ButtonsList extends React.Component{
    constructor(props){
        super(props)
    }

    
    componentDidMount(){
        const { childRef } = this.props;
        childRef(this);
      }


    render(){
        return(
            <React.Fragment>
                 
                <button
                           id= "bet_take"
                           className="fd zin_360"
                           name={this.props.betBtnText}
                           disabled={this.props.disBetBtn == 1 && this.props.betBtnText !== "TAKE"}
                           onClick={(e) => {
                               console.log("Button name: "+e.target.name)
                               e.preventDefault();

                               if (this.props.betButtonValue=== 2) {
                                   console.log("TAKE Button ");
                                   this.props.action("TAKE");
                               }else if(this.props.betButtonValue === 0){
                                   console.log("Prev Button Name: "+e.target.name)
                                   this.props.action("PREV");
                               }else if(this.props.betButtonValue === 1){
                                   console.log("Send Bet Request");
                                   this.betSound = new Audio(betSound);
                                   this.betSound.play();
                                   this.props.action("BET OK");
                               }
                               // this.props.action("ZoomON");
                               // this.props.action("Zindex");
                               // this.props.action("betOk");
                           }}
                           // disabled={this.props.betBtn}
                       >
                           {this.props.betBtnText}
                       </button>

                        <button className="fd " onClick={(e) => { e.preventDefault(); this.props.action("double") }} disabled={this.props.disDoubleBtn == 1}>{this.props.language.Double}</button>
                        <button className="fd" onClick={(e) => { e.preventDefault(); this.props.actionClear("clear") }} disabled={this.props.disClearBtn == 1}>{this.props.language.Clear} </button> 
                        {/* <button className="fd ar_bt_1 z_1024"  onClick={(e) => { e.preventDefault(); this.props.action("exit") }}>{this.props.language.Exit}</button> */}
                        {/* { this.props.gameEntry == 'in' ?<button className="fd ar_bt_1" onClick={(e) => { e.preventDefault(); this.props.action("exit") }}>{this.props.language.Exit}</button> : null } */}
                      
            </React.Fragment>
        )
    }
}

const mapStatesToProps = (state)=>{
    return {
      language : state.languageObjs.languageObj
    }
  }
  export default connect(mapStatesToProps)(ButtonsList) 
  