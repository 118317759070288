
import chip_1 from '../../../assets/epmty_chips/chip_1.svg';
// import chip_5 from '../../../assets/epmty_chips/chip_5.svg';
import chip_10 from '../../../assets/epmty_chips/chip_10.svg';
import chip_25 from '../../../assets/epmty_chips/chip_25.svg';
import chip_50 from '../../../assets/epmty_chips/chip_50.svg';
import chip_100 from '../../../assets/epmty_chips/chip_100.svg';
import chip_500 from '../../../assets/epmty_chips/chip_500.svg';
import chip_1000 from '../../../assets/epmty_chips/chip_1000.svg';
import chip_5000 from '../../../assets/epmty_chips/chip_5000.svg';
import chip_delete from '../../../assets/epmty_chips/chip_delete.png';

const chips = [
    { src: chip_1, val: 1 },
    // { src: chip_5, val: 5 },
    { src: chip_10, val:10 },
    { src: chip_25, val: 25 },
    { src: chip_50, val:50 },
    { src: chip_100, val:100 },
    { src: chip_500, val:500 },
    { src: chip_1000, val:1000 },
    { src: chip_5000, val:5000 },
    { src: chip_delete, val:0 }
]

export default chips;