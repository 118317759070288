import React from "react";
import "../../../../../css/games/funTargetTimer/balanceLabel.css"; 

export default function BalanceLabel(props) {
    return (
        <div className="fd">
                <div className="targetTimerUser">
                   {props.name}
                </div>
            </div>
    );
}
