import React from 'react';

const tableJson = [ 
    { tr:'tr1', visibleVal:'0', btmVal:'', cntrVal:'', leftVal:'0', mainVal:'0', bg:'', uqId:'1'}, 
    { tr:'tr1', visibleVal:'3', btmVal:'38', cntrVal:'110', leftVal:'96', mainVal:'3', bg:'red', uqId:'2'},
    { tr:'tr1', visibleVal:'6', btmVal:'40', cntrVal:'112', leftVal:'63', mainVal:'6', bg:'black', uqId:'3'},
    { tr:'tr1', visibleVal:'9', btmVal:'42', cntrVal:'114', leftVal:'66', mainVal:'9', bg:'red', uqId:'4'},
    { tr:'tr1', visibleVal:'12', btmVal:'44', cntrVal:'116', leftVal:'69', mainVal:'12', bg:'red', uqId:'5'},
    { tr:'tr1', visibleVal:'15', btmVal:'46', cntrVal:'118', leftVal:'72', mainVal:'15', bg:'black', uqId:'6'},
    { tr:'tr1', visibleVal:'18', btmVal:'48', cntrVal:'120', leftVal:'75', mainVal:'18', bg:'red', uqId:'7'},
    { tr:'tr1', visibleVal:'21', btmVal:'50', cntrVal:'122', leftVal:'78', mainVal:'21', bg:'red', uqId:'8'},
    { tr:'tr1', visibleVal:'24', btmVal:'52', cntrVal:'124', leftVal:'81', mainVal:'24', bg:'black', uqId:'9'},
    { tr:'tr1', visibleVal:'27', btmVal:'54', cntrVal:'126', leftVal:'84', mainVal:'27', bg:'red', uqId:'10'},
    { tr:'tr1', visibleVal:'30', btmVal:'56', cntrVal:'128', leftVal:'87', mainVal:'30', bg:'red', uqId:'11'},
    { tr:'tr1', visibleVal:'33', btmVal:'58', cntrVal:'130', leftVal:'90', mainVal:'33', bg:'black', uqId:'12'},
    { tr:'tr1', visibleVal:'36', btmVal:'60', cntrVal:'132', leftVal:'93', mainVal:'36', bg:'red', uqId:'13'},
    { tr:'tr1', visibleVal:'2 to 1', btmVal:'35,36', cntrVal:'', leftVal:'33,36', mainVal:'153', bg:'thirdRow', uqId:'14'},

    { tr:'tr2', visibleVal:'', btmVal:'', cntrVal:'112', leftVal:'', mainVal:'', bg:'hidden', uqId:'28'},
    { tr:'tr2', visibleVal:'2', btmVal:'37', cntrVal:'109', leftVal:'95', mainVal:'2', bg:'black', uqId:'15'},
    { tr:'tr2', visibleVal:'5', btmVal:'39', cntrVal:'111', leftVal:'62', mainVal:'5', bg:'red', uqId:'16'},
    { tr:'tr2', visibleVal:'8', btmVal:'41', cntrVal:'113', leftVal:'65', mainVal:'8', bg:'black', uqId:'17'},
    { tr:'tr2', visibleVal:'11', btmVal:'43', cntrVal:'115', leftVal:'68', mainVal:'11', bg:'black', uqId:'18'},
    { tr:'tr2', visibleVal:'14', btmVal:'45', cntrVal:'117', leftVal:'71', mainVal:'14', bg:'red', uqId:'19'},
    { tr:'tr2', visibleVal:'17', btmVal:'47', cntrVal:'119', leftVal:'74', mainVal:'17', bg:'black', uqId:'20'},
    { tr:'tr2', visibleVal:'20', btmVal:'49', cntrVal:'121', leftVal:'77', mainVal:'20', bg:'black', uqId:'21'},
    { tr:'tr2', visibleVal:'23', btmVal:'51', cntrVal:'123', leftVal:'80', mainVal:'23', bg:'red', uqId:'22'},
    { tr:'tr2', visibleVal:'26', btmVal:'53', cntrVal:'125', leftVal:'83', mainVal:'26', bg:'black', uqId:'23'},
    { tr:'tr2', visibleVal:'29', btmVal:'55', cntrVal:'127', leftVal:'86', mainVal:'29', bg:'black', uqId:'24'},
    { tr:'tr2', visibleVal:'32', btmVal:'57', cntrVal:'129', leftVal:'89', mainVal:'32', bg:'red', uqId:'25'},
    { tr:'tr2', visibleVal:'35', btmVal:'59', cntrVal:'131', leftVal:'92', mainVal:'35', bg:'black', uqId:'26'},
    { tr:'tr2', visibleVal:'2 to 1', btmVal:'', cntrVal:'', leftVal:'', mainVal:'152', bg:'secondRow', uqId:'27'},

    { tr:'tr3', visibleVal:'', btmVal:'', cntrVal:'', leftVal:'', mainVal:'', bg:'hidden', uqId:'29'},
    { tr:'tr3', visibleVal:'1', btmVal:'97', cntrVal:'133', leftVal:'94', mainVal:'1', bg:'red', uqId:'30'},
    { tr:'tr3', visibleVal:'4', btmVal:'98', cntrVal:'135', leftVal:'61', mainVal:'4', bg:'black', uqId:'31'},
    { tr:'tr3', visibleVal:'7', btmVal:'99', cntrVal:'136', leftVal:'64', mainVal:'7', bg:'red', uqId:'32'},
    { tr:'tr3', visibleVal:'10', btmVal:'100', cntrVal:'137', leftVal:'67', mainVal:'10', bg:'black', uqId:'33'}, 
    { tr:'tr3', visibleVal:'13', btmVal:'101', cntrVal:'138', leftVal:'70', mainVal:'13', bg:'black', uqId:'34'},
    { tr:'tr3', visibleVal:'16', btmVal:'102', cntrVal:'139', leftVal:'73', mainVal:'16', bg:'red', uqId:'35'},
    { tr:'tr3', visibleVal:'19', btmVal:'103', cntrVal:'140', leftVal:'76', mainVal:'19', bg:'red', uqId:'36'},
    { tr:'tr3', visibleVal:'22', btmVal:'104', cntrVal:'141', leftVal:'79', mainVal:'22', bg:'black', uqId:'37'}, 
    { tr:'tr3', visibleVal:'25', btmVal:'105', cntrVal:'142', leftVal:'82', mainVal:'25', bg:'red', uqId:'38'},
    { tr:'tr3', visibleVal:'28', btmVal:'106', cntrVal:'143', leftVal:'85', mainVal:'28', bg:'black', uqId:'39'},
    { tr:'tr3', visibleVal:'31', btmVal:'107', cntrVal:'144', leftVal:'88', mainVal:'31', bg:'black', uqId:'40'},
    { tr:'tr3', visibleVal:'34', btmVal:'108', cntrVal:'145', leftVal:'91', mainVal:'34', bg:'red', uqId:'41'},
    { tr:'tr3', visibleVal:'2 to 1', btmVal:'', cntrVal:'', leftVal:'', mainVal:'151', bg:'firstRow', uqId:'42'},

    { tr:'tr4', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'43'},
    { tr:'tr4', visibleVal:'1st 12', tdBg:'sector', mainVal:'154', divBg:'mainVal first_twelve_Section', colSpn:'4', uqId:'44'},
    { tr:'tr4', visibleVal:'2nd 12', tdBg:'sector', mainVal:'155', divBg:'mainVal second_twelve_Section', colSpn:'4', uqId:'45'},
    { tr:'tr4', visibleVal:'3rd 12', tdBg:'sector', mainVal:'156', divBg:'mainVal third_twelve_Section', colSpn:'4', uqId:'46'},
    { tr:'tr4', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'47'},

    { tr:'tr5', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'48'},
    { tr:'tr5', visibleVal:'1 TO 18', tdBg:'sector', mainVal:'166', divBg:'mainVal oneToEighteen', colSpn:'2', uqId:'49'},
    { tr:'tr5', visibleVal:'EVEN', tdBg:'sector', mainVal:'164', divBg:'mainVal evenNos', colSpn:'2', uqId:'50'},
    { tr:'tr5', visibleVal:'', tdBg:'sector red_box', mainVal:'162', divBg:'mainVal redBox', colSpn:'2', uqId:'51'},
    { tr:'tr5', visibleVal:'', tdBg:'sector black_box', mainVal:'163', divBg:'mainVal blackBox', colSpn:'2', uqId:'52'},
    { tr:'tr5', visibleVal:'ODD', tdBg:'sector', mainVal:'165', divBg:'mainVal oddNos', colSpn:'2', uqId:'53'},
    { tr:'tr5', visibleVal:'19 TO 36', tdBg:'sector', mainVal:'167', divBg:'mainVal nineteenToThirtysix', colSpn:'2', uqId:'54'},
    { tr:'tr5', visibleVal:'', tdBg:'empty', colSpn:'0', uqId:'55'},


    { tr:'tr2', visibleVal:'', btmVal:'134', cntrVal:'146', leftVal:'147', mainVal:'148', bg:'hidden', uqId:'55'},
    { tr:'tr2', visibleVal:'', btmVal:'149', cntrVal:'150', leftVal:'157', mainVal:'158', bg:'hidden', uqId:'56'},
    { tr:'tr2', visibleVal:'', btmVal:'159', cntrVal:'160', leftVal:'161', mainVal:'168', bg:'hidden', uqId:'57'},
    { tr:'tr2', visibleVal:'', btmVal:'169', cntrVal:'170', leftVal:'171', mainVal:'', bg:'hidden', uqId:'58'},
]

export default tableJson;