import "../../../../../css/games/funTarget/leftButtonPanel.css";
import user from "../../../../../../assets/login/user.svg";
import React from "react";
import { connect } from "react-redux";
 class BottomPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const { childRef } = this.props;
    childRef(this);
  }

  render() {
    // console.log(this.props.language.Clear)
    return (
      <div className="fd cardBtmPanel"> 
        <div className="fd"> 

          <div className="F_card_btnPanel">
            
            <div className="funCardCover" id="funCardCover"></div>
              <button
                // className={this.props.btnActive ? "mabBtn_1 ar_bt_1 active z_320" : "mabBtn_1 ar_bt_1 z_0"}
                className="mabBtn_1 ar_bt_1"
                onClick={(e) => {
                  e.preventDefault();
                  // this.props.dealBtn(this.props.betBtnTxt);
                  this.props.dealBtn(this.props.betButtonValue);

                }}
                disabled={this.props.disableDealBtn}
              >
                {this.props.betBtnTxt}
              </button> 
              <button
                className="mabBtn_2 ar_bt_1"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.cleareBtn();
                }}
                disabled={this.props.disableClearBtn}
              >
                {this.props.language.Clear}
               
              </button>
             
            
              <button
                className="mabBtn_3 ar_bt_1"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.doubleAmount();
                }}
                disabled={this.props.disable}
              >
                 {this.props.language.Double}
              </button>
            </div>
           
          <div className="F_Card_ins">
             {this.props.message} 
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps=(state)=>{
  return{
    language:state.languageObjs.languageObj

  }
}
  export default connect(mapStatesToProps)(BottomPanel)