import React from "react"; 
import "../../../../../css/games/funTarget/infoBoard.css";

class InfoBoard extends React.Component{
  constructor(props){
    super(props)
    this.historySet = []
  }
  componentDidMount(){
    this.historyDistract();
  }

  historyDistract (){ 
    this.historySet = [];
    // console.log(this.props.history);
      
    // console.log(this.historySet);
    let resHist;
    for (let i = 0; i < 10; i++) {
      console.log( this.props.history == undefined )
      if (this.props.history !== undefined ) { 
        if (this.props.history[i] !== undefined ) {
          if(this.props.history.length > 0){
          resHist = {
            resultNo: this.props.history[i].resultNo,
            extraInfo: this.props.history[i].extraInfo,
            index: i,
          };

         } 
        } 
        else {
          resHist = {
            resultNo: "",
            extraInfo: "",
            index: i,
          };
        } 
      }else {
        resHist = {
          resultNo: "",
          extraInfo: "",
          index: i,
        };
      }
      this.historySet.push(resHist);
  }
    console.log(this.historySet); 
  };
  render(){
    return(
      <div className="fd m_t_10vh">
      <div className="funTrgtBoard">
        <div>{this.props.text}</div>
        <div></div>
        <div style={{ color: "#ff0000" }}> {this.props.text2}</div>
        <div style={{ color: "#ff0000" }}>{this.props.text1}</div>
      </div>
      <div className="fd m_t_5">
        <table className="HistoryTblTargetNonTimer" cellPadding="0" cellSpacing="0">
          <tbody> 
            <tr>
                {this.historySet.map((value, index) => {
                  return index >= 0 ? <td key={index}>{value.resultNo ? value.resultNo :  <span> &nbsp; </span> } </td> : null ;
                })}
              </tr>
              <tr>
                {this.historySet.map((value, index) => {
                  return index >= 0 ? <td key={index}>{value.extraInfo ? value.extraInfo + "X" : <span> &nbsp; </span>} </td> : null ;
                })}
              </tr> 
          </tbody>
        </table>
      </div>
    </div>
    )

  } 
}
 
export default InfoBoard;