import React, { useState } from "react";
import "../../../../../css/games/funTarget/betAmount.css";
import chips from '../../../chips';

export default class BetAmount extends React.Component{
    constructor(props){
        super(props);
        this.state={selectedBtn:1}

        this.chipVal = [ 
            { label: "1k" , val: 1000 }, 
            { label: "5k" , val: 5000 }, 
            { label: "10k" , val: 10000 }, 
            { label: "10k" , val: 10000 }, 
            { label: "50k" , val: 50000 }, 
            { label: "100k" , val: 100000 }, 
            { label: "5M" , val: 5000000 }, 
            { label: "1B" , val: 100000000 },
            { label: "" , val: 0 }
        ];
    }
    
    componentDidMount(){
        if(this.props.ft_chipValues){
            let newChips = this.props.ft_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt ="";
            for(let i =0; i<newChips.length; i++){                   
                if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
                    lblTxt = "K"
                }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
                    lblTxt = "M"                        
                }else if(Number(newChips[i]) > 999999999){            
                    lblTxt = "B"
                }                     
                this.chipVal[i].val = newChips[i]         
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);
            }
        }
    }
    checkChipValue(chipValue, chpTxt, i) {       
        if(chipValue >= 1000){
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        }else{
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }

    showResultChipValue(chVal, chipTxt, index){
        console.log(chVal);
        console.log(chVal+""+chipTxt)
        this.chipVal[index].label = chVal+""+chipTxt;
        console.log(this.chipVal)
    }

    clearBets(){
        this.setState({selectedBtn:0});
    }
    render(){
    return(
        <React.Fragment>
            <div className="fd chips funTarget_chips">
                <div className="row">
                    {chips.map((chips, i)=> (
                        <div key={i} className= {this.state.selectedBtn === chips.val ? "col-4 chp_img_over text-center Select":"col-4 text-center chp_img_over UnSelect"}
                            onClick={(e)=>{e.preventDefault();
                            this.props.bet(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);
                            this.setState({selectedBtn:chips.val})}}> 
                            <img className="chp_img" src={chips.src} ></img> 
                            <div class="overlay-text">
                                {this.chipVal[i].label}
                                    {/* <span className={`ft_chip${i}`}>{this.chipVal[i].label}</span> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
    }
}