import styles from '../../css/login/forcelogin.module.css'

function ForceLogin(prop) {
    
			console.log(prop.forceData.log);
    return (
        <div className={styles.pop}>
            <div className={styles.popOutWid}>
                <div className="bg_00 clr_ff fd p_25 rds_10 text_center">
                    <div className="p_t_10">
                        {prop.forceData.log}
                        <div className="p_t_10">
                            <button className={styles.popBtn} onClick={() => prop.forceData.forcedLoginCheck('entered')} > Yes </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForceLogin;