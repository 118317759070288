import react from "react";
import da from '../../../../../../assets/games/andarBaharTimer/drawCards/ad.png';
import ca from '../../../../../../assets/games/andarBaharTimer/drawCards/ac.png';
import ha from '../../../../../../assets/games/andarBaharTimer/drawCards/ah.png';
import sa from '../../../../../../assets/games/andarBaharTimer/drawCards/as.png';
import d2 from '../../../../../../assets/games/andarBaharTimer/drawCards/2d.png';
import c2 from '../../../../../../assets/games/andarBaharTimer/drawCards/2c.png';
import h2 from '../../../../../../assets/games/andarBaharTimer/drawCards/2h.png';
import s2 from '../../../../../../assets/games/andarBaharTimer/drawCards/2s.png';
import d3 from '../../../../../../assets/games/andarBaharTimer/drawCards/3d.png';
import c3 from '../../../../../../assets/games/andarBaharTimer/drawCards/3c.png';
import h3 from '../../../../../../assets/games/andarBaharTimer/drawCards/3h.png';
import s3 from '../../../../../../assets/games/andarBaharTimer/drawCards/3s.png';
import d4 from '../../../../../../assets/games/andarBaharTimer/drawCards/4d.png';
import c4 from '../../../../../../assets/games/andarBaharTimer/drawCards/4c.png';
import h4 from '../../../../../../assets/games/andarBaharTimer/drawCards/4h.png';
import s4 from '../../../../../../assets/games/andarBaharTimer/drawCards/4s.png';
import d5 from '../../../../../../assets/games/andarBaharTimer/drawCards/5d.png';
import c5 from '../../../../../../assets/games/andarBaharTimer/drawCards/5c.png';
import h5 from '../../../../../../assets/games/andarBaharTimer/drawCards/5h.png';
import s5 from '../../../../../../assets/games/andarBaharTimer/drawCards/5s.png';
import d6 from '../../../../../../assets/games/andarBaharTimer/drawCards/6d.png';
import c6 from '../../../../../../assets/games/andarBaharTimer/drawCards/6c.png';
import h6 from '../../../../../../assets/games/andarBaharTimer/drawCards/6h.png';
import s6 from '../../../../../../assets/games/andarBaharTimer/drawCards/6s.png';
import d7 from '../../../../../../assets/games/andarBaharTimer/drawCards/7d.png';
import c7 from '../../../../../../assets/games/andarBaharTimer/drawCards/7c.png';
import h7 from '../../../../../../assets/games/andarBaharTimer/drawCards/7h.png';
import s7 from '../../../../../../assets/games/andarBaharTimer/drawCards/7s.png';
import d8 from '../../../../../../assets/games/andarBaharTimer/drawCards/8d.png';
import c8 from '../../../../../../assets/games/andarBaharTimer/drawCards/8c.png';
import h8 from '../../../../../../assets/games/andarBaharTimer/drawCards/8h.png';
import s8 from '../../../../../../assets/games/andarBaharTimer/drawCards/8s.png';
import d9 from '../../../../../../assets/games/andarBaharTimer/drawCards/9d.png';
import c9 from '../../../../../../assets/games/andarBaharTimer/drawCards/9c.png';
import h9 from '../../../../../../assets/games/andarBaharTimer/drawCards/9h.png';
import s9 from '../../../../../../assets/games/andarBaharTimer/drawCards/9s.png';
import dt from '../../../../../../assets/games/andarBaharTimer/drawCards/td.png';
import ct from '../../../../../../assets/games/andarBaharTimer/drawCards/tc.png';
import ht from '../../../../../../assets/games/andarBaharTimer/drawCards/th.png';
import st from '../../../../../../assets/games/andarBaharTimer/drawCards/ts.png';
import dj from '../../../../../../assets/games/andarBaharTimer/drawCards/jd.png';
import cj from '../../../../../../assets/games/andarBaharTimer/drawCards/jc.png';
import hj from '../../../../../../assets/games/andarBaharTimer/drawCards/jh.png';
import sj from '../../../../../../assets/games/andarBaharTimer/drawCards/js.png';
import dq from '../../../../../../assets/games/andarBaharTimer/drawCards/qd.png';
import cq from '../../../../../../assets/games/andarBaharTimer/drawCards/qc.png';
import hq from '../../../../../../assets/games/andarBaharTimer/drawCards/qh.png';
import sq from '../../../../../../assets/games/andarBaharTimer/drawCards/qs.png';
import dk from '../../../../../../assets/games/andarBaharTimer/drawCards/kd.png';
import ck from '../../../../../../assets/games/andarBaharTimer/drawCards/kc.png';
import hk from '../../../../../../assets/games/andarBaharTimer/drawCards/kh.png';
import sk from '../../../../../../assets/games/andarBaharTimer/drawCards/ks.png';

const resultCards = [
    {src: da, rsc_val : 'AD' },
    {src: ca, rsc_val : 'AC' },
    {src: ha, rsc_val : 'AH' },
    {src: sa, rsc_val : 'AS' },
    {src: d2, rsc_val : '2D' },
    {src: c2, rsc_val : '2C' },
    {src: h2, rsc_val : '2H' },
    {src: s2, rsc_val : '2S' },
    {src: d3, rsc_val : '3D' },
    {src: c3, rsc_val : '3C' },
    {src: h3, rsc_val : '3H' },
    {src: s3, rsc_val : '3S' },
    {src: d4, rsc_val : '4D' },
    {src: c4, rsc_val : '4C' },
    {src: h4, rsc_val : '4H' },
    {src: s4, rsc_val : '4S' },
    {src: d5, rsc_val : '5D' },
    {src: c5, rsc_val : '5C' },
    {src: h5, rsc_val : '5H' },
    {src: s5, rsc_val : '5S' },
    {src: d6, rsc_val : '6D' },
    {src: c6, rsc_val : '6C' },
    {src: h6, rsc_val : '6H' },
    {src: s6, rsc_val : '6S' },
    {src: d7, rsc_val : '7D' },
    {src: c7, rsc_val : '7C' },
    {src: h7, rsc_val : '7H' },
    {src: s7, rsc_val : '7S' },
    {src: d8, rsc_val : '8D' },
    {src: c8, rsc_val : '8C' },
    {src: h8, rsc_val : '8H' },
    {src: s8, rsc_val : '8S' },
    {src: d9, rsc_val : '9D' },
    {src: c9, rsc_val : '9C' },
    {src: h9, rsc_val : '9H' },
    {src: s9, rsc_val : '9S' },
    {src: dt, rsc_val : 'TD' },
    {src: ct, rsc_val : 'TC' },
    {src: ht, rsc_val : 'TH' },
    {src: st, rsc_val : 'TS' },
    {src: dj, rsc_val : 'JD' },
    {src: cj, rsc_val : 'JC' },
    {src: hj, rsc_val : 'JH' },
    {src: sj, rsc_val : 'JS' },
    {src: dq, rsc_val : 'QD' },
    {src: cq, rsc_val : 'QC' },
    {src: hq, rsc_val : 'QH' },
    {src: sq, rsc_val : 'QS' },
    {src: dk, rsc_val : 'KD' },
    {src: ck, rsc_val : 'KC' },
    {src: hk, rsc_val : 'KH' },
    {src: sk, rsc_val : 'KS' },
     
]

export default resultCards;