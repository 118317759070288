import React from "react";
import WheelInner from "./europeanRouletteWheel";

export default class Wheel extends React.Component{
    constructor(props){
        super(props)
        this.artWheelRef = React.createRef();
        this.inWid = window.innerWidth
        this.inHt = window.innerHeight 
        this.widthMain = 1250;
        this.heightMain = 560;
        this.ScaleX = 1; 
        this.posX = 0.500;
        this.ScaleY = 0.47;
        this.posY = 0.95;
        this.fontSize = 35;
        this.ballSize = 13;
        this.textYpos = 0.13; 
        this.ballPosAfterRotate = 1299
        this.ballPosOnload = -2
         
    }
    componentStateHandler(){

    }
    stopWheelSound =() =>{
       // this.artWheelRef.current.stopWheel();
    }
	spinTheWheel = (s) => {
        console.log("Spin Number: "+s)
        this.artWheelRef.current.spinTheWheel(s);
    }
    wheelHandler(position){
        this.props.action(position);
    }
    render(){
        return(
            <React.Fragment>
            <div id="erRoutTimerWheel" 
            style={{
                width:this.widthMain,      
                height:this.heightMain,                 
                transform: `scale(${this.props.zoom})`,
                transformOrigin: 'top',
                zIndex:this.props.zindex,
                position:'relative',}} >
                <WheelInner 
                    ballPosOnload={this.ballPosOnload} 
                    ballPosAfterRotate={this.ballPosAfterRotate}
                    textYpos={this.textYpos} 
                    ballSize={this.ballSize} 
                    fontSize={this.fontSize} 
                    width={this.widthMain} 
                    height={this.heightMain} 
                    ScaleX={this.ScaleX} 
                    ScaleY={this.ScaleY}
                    posX={this.posX} 
                    posY={this.posY}
                    gameState={this.props.gameState} 
                    ref={this.artWheelRef} 
                    zindex={this.props.zindex} 
                    zoom={this.props.zoom} 
                    action={this.wheelHandler.bind(this)} 
                    isMobile={this.props.isMobile}  
                    onFocus={this.props.onFocus}
                ></WheelInner>
                </div>
            </React.Fragment>
        )
    }
}
