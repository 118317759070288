const gamesList = [
    {
        "gameId": 19,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 500000,
        "timerGame": true,
        "gameName": "SORAT_TIMER",
        "displayName": "Sorat Timer",
        "timerValue": 60,
        "className": "soratTimer",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
        "orderId": 1,
        "shortName": "FST"
    },
    {
        "gameId": 2,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 50000,
        "timerGame": true,
        "gameName": "TARGET_TIMER",
        "displayName": "Fun Target Timer ",
        "timerValue": 60,
        "className": "funTarget",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000",
        "orderId": 2,
        "shortName": "FTT"
    },
    {
        "gameId": 3,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 855000,
        "timerGame": true,
        "gameName": "FUN_ROULETTE",
        "displayName": "American Roulette Timer",
        "timerValue": 60,
        "className": "funRoulette",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
        "orderId": 3,
        "shortName": "ART"
    },
    {
        "gameId": 12,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 840000,
        "timerGame": true,
        "gameName": "EUROPEAN_ROULETTE_TIMER",
        "displayName": "European Roulette Timer",
        "timerValue": 60,
        "className": "europeanRouletteTimer",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
        "orderId": 4,
        "shortName": "ERT"
    },
    {
        "gameId": 7,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 200000,
        "timerGame": true,
        "gameName": "MATKA1",
        "displayName": "Fun Matka Timer",
        "timerValue": 120,
        "className": "matka1",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-1000|1-600|1-300|1-100",
        "orderId": 5,
        "shortName": "FMT"
    },
    {
        "gameId": 1,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 5000,
        "timerGame": false,
        "gameName": "FUN_CARD",
        "displayName": "Fun Card",
        "timerValue": 0,
        "className": "funCard",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "",
        "orderId": 6,
        "shortName": "FCNT"
    },
    {
        "gameId": 5,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 50000,
        "timerGame": false,
        "gameName": "TARGET",
        "displayName": "Fun Target Non Timer ",
        "timerValue": 60,
        "className": "target",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000",
        "orderId": 7,
        "shortName": "FTNT"
    },
    {
        "gameId": 6,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 855000,
        "timerGame": false,
        "gameName": "ROULETTE",
        "displayName": "American Roulette Non Timer",
        "timerValue": 60,
        "className": "roulette",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
        "orderId": 8,
        "shortName": "ARNT"
    },
    {
        "gameId": 13,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 840000,
        "timerGame": false,
        "gameName": "EUROPEAN_ROULETTE",
        "displayName": "European Roulette",
        "timerValue": 0,
        "className": "europeanRoulette",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000|1-5000",
        "orderId": 9,
        "shortName": "ERNT"
    },
    {
        "gameId": 14,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 200000,
        "timerGame": false,
        "gameName": "MATKA_NON",
        "displayName": "Matka Non",
        "timerValue": 0,
        "className": "matkaNon",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-1000|1-600|1-300|1-100",
        "orderId": 10,
        "shortName": "FMNT"
    },
    {
        "gameId": 20,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "DRAGON_TIGER",
        "displayName": "Dragon Tiger",
        "timerValue": 0,
        "className": "dragonTiger",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 11,
        "shortName": "DT"
    },
    {
        "gameId": 25,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "KENO80",
        "displayName": "Keno 80",
        "timerValue": 0,
        "className": "keno80",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 15,
        "shortName": "KN"
    },
    {
        "gameId": 24,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "ANDAR_BAHAR",
        "displayName": "Andar Bahar",
        "timerValue": 0,
        "className": "andarBahar",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 15,
        "shortName": "AB"
    },
    {
        "gameId": 21,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "DOUBLE_CHANCE",
        "displayName": "Double Tiger",
        "timerValue": 0,
        "className": "doubleChance",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 21,
        "shortName": "DC"
    },
    {
        "gameId": 22,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "TRIPLE_CHANCE",
        "displayName": "Triple Chance",
        "timerValue": 0,
        "className": "tripleChance",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 22,
        "shortName": "TC"
    },
    {
        "gameId": 23,
        "gameType": "CASINO",
        "minBet": 1,
        "maxBet": 13750,
        "timerGame": false,
        "gameName": "MONEY_WHEEL",
        "displayName": "Money Tiger",
        "timerValue": 0,
        "className": "moneyWheel",
        "active": true,
        "isRiskCheck": true,
        "innerLimits": "1-10000|1-1250|1-2500",
        "orderId": 23,
        "shortName": "MW"
    }
]

export default gamesList;