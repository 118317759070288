import react from "react";
import b1 from '../../../../../../assets/games/keno/ball_1.png';
import b2 from '../../../../../../assets/games/keno/ball_2.png';
import b3 from '../../../../../../assets/games/keno/ball_3.png';
import b4 from '../../../../../../assets/games/keno/ball_4.png';
import b5 from '../../../../../../assets/games/keno/ball_5.png';
import b6 from '../../../../../../assets/games/keno/ball_6.png';
import b7 from '../../../../../../assets/games/keno/ball_7.png';
import b8 from '../../../../../../assets/games/keno/ball_8.png';
import b9 from '../../../../../../assets/games/keno/ball_9.png';
import b10 from '../../../../../../assets/games/keno/ball_10.png';
import b11 from '../../../../../../assets/games/keno/ball_11.png';
import b12 from '../../../../../../assets/games/keno/ball_12.png';
import b13 from '../../../../../../assets/games/keno/ball_13.png';
import b14 from '../../../../../../assets/games/keno/ball_14.png';
import b15 from '../../../../../../assets/games/keno/ball_15.png';
import b16 from '../../../../../../assets/games/keno/ball_16.png';
import b17 from '../../../../../../assets/games/keno/ball_17.png';
import b18 from '../../../../../../assets/games/keno/ball_18.png';
import b19 from '../../../../../../assets/games/keno/ball_19.png';
import b20 from '../../../../../../assets/games/keno/ball_20.png';
import b21 from '../../../../../../assets/games/keno/ball_21.png';
import b22 from '../../../../../../assets/games/keno/ball_22.png';
import b23 from '../../../../../../assets/games/keno/ball_23.png';
import b24 from '../../../../../../assets/games/keno/ball_24.png';
import b25 from '../../../../../../assets/games/keno/ball_25.png';
import b26 from '../../../../../../assets/games/keno/ball_26.png';
import b27 from '../../../../../../assets/games/keno/ball_27.png';
import b28 from '../../../../../../assets/games/keno/ball_28.png';
import b29 from '../../../../../../assets/games/keno/ball_29.png';
import b30 from '../../../../../../assets/games/keno/ball_30.png';
import b31 from '../../../../../../assets/games/keno/ball_31.png';
import b32 from '../../../../../../assets/games/keno/ball_32.png';
import b33 from '../../../../../../assets/games/keno/ball_33.png';
import b34 from '../../../../../../assets/games/keno/ball_34.png';
import b35 from '../../../../../../assets/games/keno/ball_35.png';
import b36 from '../../../../../../assets/games/keno/ball_36.png';
import b37 from '../../../../../../assets/games/keno/ball_37.png';
import b38 from '../../../../../../assets/games/keno/ball_38.png';
import b39 from '../../../../../../assets/games/keno/ball_39.png';
import b40 from '../../../../../../assets/games/keno/ball_40.png';
import b41 from '../../../../../../assets/games/keno/ball_41.png';
import b42 from '../../../../../../assets/games/keno/ball_42.png';
import b43 from '../../../../../../assets/games/keno/ball_43.png';
import b44 from '../../../../../../assets/games/keno/ball_44.png';
import b45 from '../../../../../../assets/games/keno/ball_45.png';
import b46 from '../../../../../../assets/games/keno/ball_46.png';
import b47 from '../../../../../../assets/games/keno/ball_47.png';
import b48 from '../../../../../../assets/games/keno/ball_48.png';
import b49 from '../../../../../../assets/games/keno/ball_49.png';
import b50 from '../../../../../../assets/games/keno/ball_50.png';
import b51 from '../../../../../../assets/games/keno/ball_51.png';
import b52 from '../../../../../../assets/games/keno/ball_52.png';
import b53 from '../../../../../../assets/games/keno/ball_53.png';
import b54 from '../../../../../../assets/games/keno/ball_54.png';
import b55 from '../../../../../../assets/games/keno/ball_55.png';
import b56 from '../../../../../../assets/games/keno/ball_56.png';
import b57 from '../../../../../../assets/games/keno/ball_57.png';
import b58 from '../../../../../../assets/games/keno/ball_58.png';
import b59 from '../../../../../../assets/games/keno/ball_59.png';
import b60 from '../../../../../../assets/games/keno/ball_60.png';
import b61 from '../../../../../../assets/games/keno/ball_61.png';
import b62 from '../../../../../../assets/games/keno/ball_62.png';
import b63 from '../../../../../../assets/games/keno/ball_63.png';
import b64 from '../../../../../../assets/games/keno/ball_64.png';
import b65 from '../../../../../../assets/games/keno/ball_65.png';
import b66 from '../../../../../../assets/games/keno/ball_66.png';
import b67 from '../../../../../../assets/games/keno/ball_67.png';
import b68 from '../../../../../../assets/games/keno/ball_68.png';
import b69 from '../../../../../../assets/games/keno/ball_69.png';
import b70 from '../../../../../../assets/games/keno/ball_70.png';
import b71 from '../../../../../../assets/games/keno/ball_71.png';
import b72 from '../../../../../../assets/games/keno/ball_72.png';
import b73 from '../../../../../../assets/games/keno/ball_73.png';
import b74 from '../../../../../../assets/games/keno/ball_74.png';
import b75 from '../../../../../../assets/games/keno/ball_75.png';
import b76 from '../../../../../../assets/games/keno/ball_76.png';
import b77 from '../../../../../../assets/games/keno/ball_77.png';
import b78 from '../../../../../../assets/games/keno/ball_78.png';
import b79 from '../../../../../../assets/games/keno/ball_79.png';
import b80 from '../../../../../../assets/games/keno/ball_80.png';


const resultBalls = [
    {src: b1, bsc_val : 1 },
    {src: b2, bsc_val : 2 },
    {src: b3, bsc_val : 3 },
    {src: b4, bsc_val : 4 },
    {src: b5, bsc_val : 5 },
    {src: b6, bsc_val : 6 },
    {src: b7, bsc_val : 7 },
    {src: b8, bsc_val : 8 },
    {src: b9, bsc_val : 9 },
    {src: b10, bsc_val : 10 },
    {src: b11, bsc_val : 11 },
    {src: b12, bsc_val : 12 },
    {src: b13, bsc_val : 13 },
    {src: b14, bsc_val : 14 },
    {src: b15, bsc_val : 15 },
    {src: b16, bsc_val : 16 },
    {src: b17, bsc_val : 17},
    {src: b18, bsc_val : 18 },
    {src: b19, bsc_val : 19 },
    {src: b20, bsc_val : 20 },
    {src: b21, bsc_val : 21 },
    {src: b22, bsc_val : 22 },
    {src: b23, bsc_val : 23 },
    {src: b24, bsc_val : 24 },
    {src: b25, bsc_val : 25 },
    {src: b26, bsc_val : 26 },
    {src: b27, bsc_val : 27},
    {src: b28, bsc_val : 28},
    {src: b29, bsc_val : 29},
    {src: b30, bsc_val : 30},
    {src: b31, bsc_val : 31},
    {src: b32, bsc_val : 32},
    {src: b33, bsc_val : 33},
    {src: b34, bsc_val : 34},
    {src: b35, bsc_val : 35},
    {src: b36, bsc_val : 36},
    {src: b37, bsc_val : 37},
    {src: b38, bsc_val : 38},
    {src: b39, bsc_val : 39 },
    {src: b40, bsc_val : 40},
    {src: b41, bsc_val : 41},
    {src: b42, bsc_val : 42},
    {src: b43, bsc_val : 43},
    {src: b44, bsc_val : 44},
    {src: b45, bsc_val : 45 },
    {src: b46, bsc_val : 46 },
    {src: b47, bsc_val : 47 },
    {src: b48, bsc_val : 48 },
    {src: b49, bsc_val : 49 },
    {src: b50, bsc_val : 50 },
    {src: b51, bsc_val : 51 },
    {src: b52, bsc_val : 52 },
    {src: b53, bsc_val : 53 },
    {src: b54, bsc_val : 54 },
    {src: b55, bsc_val : 55 },
    {src: b56, bsc_val : 56 },
    {src: b57, bsc_val : 57 },
    {src: b58, bsc_val : 58 },
    {src: b59, bsc_val : 59},
    {src: b60, bsc_val : 60},
    {src: b61, bsc_val : 61},
    {src: b62, bsc_val : 62 },
    {src: b63, bsc_val : 63 },
    {src: b64, bsc_val : 64 },
    {src: b65, bsc_val : 65},
    {src: b66, bsc_val : 66},
    {src: b67, bsc_val : 67},
    {src: b68, bsc_val : 68},
    {src: b69, bsc_val : 69},
    {src: b70, bsc_val : 70},
    {src: b71, bsc_val : 71},
    {src: b72, bsc_val : 72},
    {src: b73, bsc_val : 73},
    {src: b74, bsc_val : 74},
    {src: b75, bsc_val : 75},
    {src: b76, bsc_val : 76},
    {src: b77, bsc_val : 77},
    {src: b78, bsc_val : 78},
    {src: b79, bsc_val : 79},
    {src: b80, bsc_val : 80 },
   
     
]

export default resultBalls;