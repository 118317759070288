import React from "react";
import './home.css'
import styles from './home.module.css'
import gamesList from './beforeLoginGames';
import icon from '../../../assets/icon.svg'
import indie from '../../../assets/indie.svg'
import ic from '../../../assets/ic.svg'
import game_1 from '../../../assets/gameImages/dragonTiger_1.jpg'

import SwiperImgs from "./swiperImgs";
import avtar from '../../../assets/avtar_1.jpg';
import down from '../../../assets/donw_aro.svg'; 
import { BrowserRouter as Link } from 'react-router-dom';

 
export default class HomePage extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
        loginpop : false,
        gameLoader : true,
        gamesNames : ''
    } 
  }
  updateGames = () => { 
      this.setState({gameLoader : false }) 
  }
  actionHandler = (action, value) => {  
    this.props.action(action, value);
    }
    closeLogPop = () => {
        this.setState({ loginpop : false })
    }
  render() {
    return (
      <div className="fd bg_gray">
        <div className={`${styles.tpLogoMenu} fd`}>
          <div className="maxWid_1120 ">
            <div className="fd">
              <div className="row">
                <div className="col-6">
                  <div className="logoBox">
                    <div className="box_1Logo">
                      <img src={icon} />
                      <img src={ic} />
                    </div>
                    <div className="box_2Logo">
                      <img src={indie} />
                    </div>
                  </div>
                </div>
                {this.props.isPlayerLogin ?
                <div className="col-6 d_flex j_end al_cntr">
                  <div className="userBlnc"> 
                    <div className="ub">
                    <span> {this.props.user} </span>
                      <span> {this.props.balance} </span> 
                    </div>
                    <div className="user"> 
                      <img className="user" src={avtar} alt='avtar' /> 
                      </div>
                      <div className="down">
                        <img src={down} alt='down' /> 
                        <div className="dropDn">
                            <ul> 
                                <li onClick={(e) => { e.preventDefault(); this.props.action('myaccount', {}) } }> My Account  </li>
                                <li onClick={(e) => { e.preventDefault(); this.props.action('logOutSuccess', {}) }}> Logout </li>
                            </ul>
                        </div>
                      </div>
                  </div>
                  {/* <div className="fd"> <button type="button" className="btn_1 btn_logout" onClick={(e) => { e.preventDefault(); this.props.logoutAction('logOutSuccess', {}) }}> Logout </button> </div>
                <div className="fd "> <button type="button" className="btn_1 btn_myAcount" onClick={(e) => { e.preventDefault(); this.props.popUpAction("Open") }}> My Account </button> </div> */}
                </div>
                  : 
                <div className={`${ styles.loginButtons} col-6`}> 
                  <button
                    type="submit" 
                    value="Login"
                    onClick={(e) => { e.preventDefault(); this.props.action('showLog') }}
                    > 
                    Login 
                    </button> 
                    <button
                    type="submit"
                    className=""
                    value="Login" 
                    onClick={(e) => { e.preventDefault(); this.props.action('registration')}}
                  > Register </button>
                </div>
              }
              </div>
            </div>
          </div>
        </div> 
        <div className="fd z_5">
            <SwiperImgs></SwiperImgs>
        </div>
          <div className="fd">
            <div className="maxWid_1120">
              <div className="fd">
                <div className="row">
                  <div className="col-12 m_t_20">
                    <h2 style={{ color: "#cdd6d9" }}> Most Popular Games in India </h2>
                  </div>
                    <div className="row"> 
                    {gamesList.map((data, i) => 
                        <div className="col_custm_5 m_t_10" key={i}>
                            <div className="indie_game">
                                <img src={game_1} alt={data.displayName} className="fd" /> 
                                <div className="play_cover"></div>
                                {this.props.isPlayerLogin ? 
                                  <button className="play_bt1" onClick={(e) => { e.preventDefault(); this.props.gameOpenHandler(data.gameId) }}> Play </button>
                                  : 
                                  <button className="play_bt1" onClick={(e) => { e.preventDefault(); this.props.action('showLog') }}> Play </button>
                              }
                            </div>
                            <div className="imgTitle"> {data.displayName} </div>
                        </div>
                        )}
                    </div>
                </div> 
              </div>
            </div>
          </div> 
            <div className="fd clr_3a text_center p_tb_10 bg_1a">
              <div className="fd clr_3a text_center clr_cd" style={{color:'rgb(106 123 129)'}}>
                  &#169; Copyright 2022 - All rights reserved.
              </div>
            </div>
 
          </div>
    );
  }
}
