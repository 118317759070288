import React from "react";

//import tickSound from "../../../../../../assets/sounds/tick.mp3";
export default class EuropeanTimerInSeconds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeInSec: 60,
      getTime: 60,
      callsec: true,
      isBetSent: false,
      isDisableSent: false,
    };
    this.timerv = this.props.timesend;
  }

  sendTimer(tt) { 
    document.getElementById("timeSpan").style.display = "block";
    this.setState({ getTime: tt });
    this.state.getTime = tt;
    this.myTimer();
  }
  clearTimer() {
    clearInterval(this.timerclear);
  }
  componentDidMount() {
    document.getElementById("timeSpan").style.display = "none";
    this.timerclear = setInterval(this.myTimer, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerclear);
  }

  myTimer = () => {
    if(this.state.getTime != null && this.state.getTime != NaN && this.state.getTime >=0){

      var d = this.state.getTime;
  
      var timer = document.getElementById("timer");
      var eurpRltCover = document.getElementById("eurpRltCover");

      var n = --d;
      var sec = parseInt(n % 60);
      var secS = sec.toString();
      if (String(sec).length < 2) {
          secS = "0" + sec.toString();
      } 
      this.setState({ getTime: secS });
      this.setState({ timeInSec: secS });
      if (n != null && n != NaN) {
        if(n == 10){
         
        }
        if (n <= 0) {
          this.setState({ getTime: 60 });
        }
        
        if (n == 0) {
          if (!this.state.isBetSent) {
            this.props.action("ZoomON");
          }
          if(this.state.isDisableSent){
            (this.setState({isDisableSent:false}))
          }
        }
       
        if (n <= 5 && n > 0) {
          if(!this.state.isBetSent){
            this.getTimer();
            this.setState({isBetSent: true});     
            this.state.isBetSent = true;
            this.props.action("BET OK"); 
          }
          eurpRltCover.style.display = 'block';
          eurpRltCover.style.zIndex = "1200"  
          if (!this.state.isDisableSent) {
            this.setState({ isDisableSent: true });
            this.props.action("disableBtns");
            
          }                              
        }else{
          this.setState({isBetSent: false}); 
        }

        // if (n == 4) {
        //   if (!this.state.isDisableSent) {
        //     this.setState({ disableBtns: true });
        //   }
        //   this.props.action("disableBtns");
        //   eurpRltCover.style.zIndex = "1200"
          
        // }
        // if(n <= 4){
        //   eurpRltCover.style.display = 'block';
        //   eurpRltCover.style.zIndex = "1200"
        // }
        
        if (n <= 10 && n > 5 ) {
          timer.classList.add("timerYelloBorder");
          // this.tickSound = new Audio(tickSound);
          // this.tickSound.play();          
          this.props.action("PlayTickSound");
          
          this.setState({isBetSent: false});
        } else {
          if (timer && timer.classList.contains("timerYelloBorder")) {
            timer.classList.remove("timerYelloBorder");
            // this.tickSound = {};          
            this.props.action("StopTickSound"); 
          
          }
        }
      }
    }
  };

  getTimer() {
    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "GET_GAME_TIMER",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    this.props.network.sendof(body);
    this.body={};
  }
  render() {
    return (
      <div className="fd m_b_15">
        <div id="eurpRltCover" className="eurpRltCover"></div>
        <div className="timer_Sec" id="timer">
          {" "}
          <span className="" id="timeSpan">
            {" "}
            {this.state.timeInSec}{" "}
          </span>{" "}
        </div>
      </div>
    );
  }
}
