
import React from "react";
// import wheelSound from "../../../../../../assets/sounds/wheelStart.mp3"; 
import er_num_img from "../../../../../../assets/games/europeanRoulette/er_num_bg.png";
import er_ball_img from "../../../../../../assets/games/europeanRoulette/er_rollBall.png";
import erWheelBg from "../../../../../../assets/games/erupeanRouletteTimer/ert_wheel_bg.svg";
import wheel_poll from "../../../../../../assets/games/erupeanRouletteTimer/wheel_poll.svg"; 

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props); 

        this.firstload = true;
       this.reward = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26];
       this.rewards = { 0: 0, 32: 1, 15: 2, 19: 3, 4: 4, 21: 5, 2: 6, 25: 7, 17: 8, 34: 9, 6: 10, 27: 11, 13: 12, 36: 13, 11: 14, 30: 15, 8: 16, 23: 17, 10: 18, 5: 19, 24: 20, 16: 21, 33: 22, 1: 23, 20: 24, 14: 25, 31: 26, 9: 27, 22: 28, 18: 29, 29: 30, 7: 31, 28: 32, 12: 33, 35: 34, 3: 35, 26: 36 };
    //    this.wheelSound = new Audio(wheelSound);
       this.resultNumber = 0;         
        this.er_ballBg = "";
        this.er_wheelAnim = "";
    }

    componentDidMount() { 

        this.er_ballBg = document.getElementById("er_ballBg");
        this.er_wheelAnim = document.getElementById("er_wheelAnim")
        this.er_rollBallBg = document.getElementById("er_rollBall");
 
        if(this.props.gameState.history && this.firstload && this.props.gameState.history.length > 1){
            this.firstload = false; 
            let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            this.spinTheWheelOnload(resNo)   
          } else {
            let defaultNo = 0
            this.spinTheWheelOnload(defaultNo)
        }  
    }  
    spinTheWheelOnload = (s) => {     
        console.log(s)
        this.resultNumber = Number(s);

        let index = this.reward.indexOf(this.resultNumber);
        console.log(index);
        console.log(this.rewards);
        console.log(this.reward[index]);

        let stopValueFirst = this.reward[index];
        console.log(stopValueFirst);

        const er_ball_style = {
            transition: 'all 0s',
            transform: `rotate(${((index) * 9.73)}deg)`,
        };
        Object.assign(this.er_ballBg.style, er_ball_style);

        const er_styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.er_wheelAnim.style, er_styles);
    };
 
    spinTheWheel = (s) => {
        console.log("Spin Wheel Result: "+s)
        this.resultNumber = s;  
        // this.wheelSound = new Audio(wheelSound);    
        // this.wheelSound.currentTime = 1;
        // this.wheelSound.play();
        console.log("ER Result Numeber: "+this.resultNumber)
        let index = this.reward.indexOf(this.resultNumber);
        console.log(index);
        const er_ball_style = {
          transition: 'all 7s',
          transform: `rotate(${(2160 + (index * 9.73))}deg)`,
        };
        Object.assign(this.er_ballBg.style, er_ball_style);
    
        const er_styles = {
          transition: 'all 7s',
          transform: `rotate(${-1080}deg)`,
    
        };
        Object.assign(this.er_wheelAnim.style, er_styles);
    
        setTimeout(() => {
          this.er_rollBallBg.style.transform = 'translateY(-165px)'
        }, 5000)
    
        this.er_rollBallBg.style.transform = 'translateY(-240px)'
    
        setTimeout(() => {
          this.afterRotate(index);
        }, 7200)
    };

    afterRotate(resNumVal) {
        console.log('hi');
        console.log(resNumVal);        
        console.log(resNumVal * 9.73);
    
        const ball_style = {
            transition: 'all 0s',
            transform: `rotate(${(resNumVal * 9.73)}deg)`,
        };
        Object.assign(this.er_ballBg.style, ball_style);
    
        const styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.er_wheelAnim.style, styles);
        this.announceWinner(this.resultNumber);
        // this.wheelSound = {};
    }
 
    announceWinner(value) {               
        setTimeout(() => {
            this.props.action(value);  
        }, 100);
    }

    render() {
        return ( 
            <div className="er_WheelWraper">
            <div className="er_wheelBorder"> <img src={erWheelBg} /> </div>
            <div class="er_wheelAnimOne">
                <div className="er_wheelAnimParent">
                    <div class="er_wheelAnim" id="er_wheelAnim">
                        <img src={er_num_img} />
                        <div class="er_bollWrap" id="er_ballBg">
                            <img class="er_rollBall" id="er_rollBall" src={er_ball_img} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="er_wheel_poll"> <img src={wheel_poll} /> </div>
            </div>
        );
    }
}
