import React from "react" 
import banner_1 from '../../../assets/banners/banner_1.jpg';
import banner_2 from '../../../assets/banners/banner_2.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.min.css"; 
export default class SwiperImgs extends React.Component{
    constructor(){
        super()
    }
    render(){
        return( 
            <div className="fd">
                    <Swiper
                    spaceBetween={0}
                    slidesPerView={1}  
                    pagination={{ clickable: true }}
                    autoplay={true}
                    >
                    <SwiperSlide> <img src={banner_2} /> </SwiperSlide>
                    <SwiperSlide> <img src={banner_1} /> </SwiperSlide>
                </Swiper>
            </div>
        )
    }
}