import React from "react";
import img_1 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot2.png";
import img_2 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot1.png";
import img_3 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot3.png";
import img_4 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot4.png";
import img_5 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot5.png";
import img_6 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot6.png";
import img_7 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot7.png";
import img_8 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot8.png";
import img_9 from "../../../../../../assets/games/funTargetTimer/x_images/jackpot9.png"; 

class Jackpot extends React.Component {
  constructor() {
    super();
    this.state = {     
      marque_list: [img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9],     
    };
  }
  componentDidMount(){
    this.setJackpotNumber(0) 
  }

  stopImageChange = () => {    
      this.setJackpotNumber(this.props.targetResult.bonusX)
  };

  setJackpotNumber = (bonusX) =>{  
 
    let  imageMarquee = document.getElementById('imageMarquee');
    if(imageMarquee && imageMarquee.classList.contains("ftt_scrollImages")){
      imageMarquee.classList.remove('ftt_scrollImages') 
    }

    if(imageMarquee){
      if (bonusX == undefined || bonusX == 0 || bonusX == 1) {
          let max = 8;
          let min = 2;
          let bonusValue = Math.floor(Math.random() * (max - min)) + min; 
          console.log("Bonus Value   "+bonusValue);
          imageMarquee.style.transform = `translateX(-${bonusValue*220}px)`
        } else if (bonusX == 2) {
          imageMarquee.style.transform = `translateX(-${0*220}px)`
        } else if (bonusX == 4) {
        imageMarquee.style.transform = `translateX(-${1*220}px)`
      }
    }
  }

  combine(val) {
   
    let  imageMarquee = document.getElementById('imageMarquee')
    imageMarquee.classList.add('ftt_scrollImages');
    setTimeout(() => { 
      this.stopImageChange(); 
    }, 6000);
  } 

  render() {
    const pStyle = {
      width: `${this.props.X_wid}px`,
      height: `${this.props.X_ht}px`,

    };
    return (
      <React.Fragment> 
        <div className="ftt_overallBox" id="ftt_marqueeTag">           
            <div className="ftt_mainTrans" id="imageMarquee" >
              {this.state.marque_list.map((data, i) => (
                <img key={i} src={data} name="xImgs" className="act"></img>
              ))}
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Jackpot;
