import React from "react";
import { Component } from "react";
import "../../../../../css/games/funMatka/rightButtonPanel.css";
import matkanums from "./matkaNumbers";
import user from "../../../../../../assets/login/user.svg";
import dp_1 from "../../../../../../assets/dp_1.svg";
import sp_1 from "../../../../../../assets/sp_1.svg";
import akda from "../../../../../../assets/games/funMatka/akda.svg";
import triple from "../../../../../../assets/games/funMatka/triple.svg";

import betSound from "../../../../../../assets/sounds/bet.mp3";

import { connect } from "react-redux";

class RightButtonPanel extends Component {
  constructor(props) {
    super(props);
    const{childRef} = this.props 
    childRef(this)

    this.state = {
      valNumber: '000',
    };
  } 
  scale(val){ 
    var secList = document.getElementsByClassName('secList');
    for (var i = 0; i < secList.length; i++) {
      secList[i].classList.remove('matkaScale')
    }
    document.getElementById(val).classList.add('matkaScale'); 
  }
  removeScale(){
    var secList = document.getElementsByClassName('secList');
    for (var i = 0; i < secList.length; i++) {
      secList[i].classList.remove('matkaScale')
    }
  }

  mouseDownSelectCard(data){ 
    this.props.action(data, "rightClick") 
    this.startInt = setInterval( () => { 
      this.props.action(data, "rightClick")
    }, 150); 
  } 
  mouseUpStopCards(){
    clearInterval(this.startInt);
  }


  render() {
    return (
      <div className="fd p_r_5">
        <div className="matkaUserLbl fd">
          <img className="user" src={user} alt={user} /> {this.props.name}
        </div>
        <div className="fd d-flex">
          <div className="topBox numberSet secList" id="rightOne" onClick={(e)=> this.scale('rightOne')}>
            {matkanums[4].top_bottom.filter(data => data.tpBtm === 'topRight').map((data) => (
              <div className="in_2" key={data.betId} betnumber={data.betNum} id={data.betId} 
              onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
              onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
          </div>
          <div className="spDp font_10 text-center lh_12 d-none" id="230"> This is hidden </div>
          <div className="akda_tm font_10 text-center lh_12" id="231" 
          onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(231)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}>
              <img src={akda} alt="AKDA X9"/>
          </div>
        </div>
        <div className="fd  d-flex">
          <div className="numberSet secList inact" id="rightTwo" onClick={(e)=> this.scale('rightTwo')}>
            {matkanums[2].sectionThree.map((data) => (
              <div className="in_2" key={data.betId} betnumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
          </div>
          <div className="spDp" id="232" 
          onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(232)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> <img className="sp_1" src={sp_1} alt={sp_1} /> </div>
        </div>
        <div className="fd matka_m_t_vh d-flex">
          <div className="fd numberSet secList inact"
          id="rightThree" onClick={(e)=> this.scale('rightThree')}>
            {matkanums[3].sectionFour.map((data) => (
              <div className="in_2" key={data.betId} betnumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
          </div>
          <div className="spDp" id="233" 
          onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(233)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> <img className="dp_1" src={dp_1} alt={dp_1} /> </div>
        </div>
        <div className="fd matka_m_t_vh d-flex">
          <div className="topBox numberSet secList"
          id="rightFour" onClick={(e)=> this.scale('rightFour')}>
            {matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmRight').map((data) => (
              <div className="in_2" key={data.betId} betnumber={data.betNum} id={data.betId} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.betId}`)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> {data.betNum} <span></span> </div>
            ))}
          </div>
          <div className="akda_tm font_10 text-center lh_12" id="234" 
          onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(234)}}
            onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}>
          <img src={triple} alt="Triple X1000"/>
          </div>
        </div>
        <div className="fd matka_m_t_vh f_matka_btns d-flex">
          <div className="fl_1">
              <button className={this.props.btnActive === true ? "mtkBt_2 z_120 active button fd ar_bt_1" : "mtkBt_2 z_0 button fd ar_bt_1"}
              name={this.props.betBtnText} disabled={this.props.disBetBtn == 1} onClick={(e) => {
                e.preventDefault();
                console.log(e.target.name);
                // if (e.target.name === "BET OK") {
                if (this.props.betButtonValue === 1) {

                    this.betSound = new Audio(betSound);
                    this.betSound.play();
                  this.props.btntext("betOk");
                // } else if (e.target.name ===  "PREV") {
                } else if (this.props.betButtonValue === 0) {

                  this.props.btntext("PreviousBet");
                } else {
                  this.props.btntext("takeWinAmount");
                }
              }} >{this.props.betBtnText}
              </button> 
          </div>
          <div className="fl_1" onClick={(e) => { e.preventDefault(); this.props.btntext("clear") }} >
              <button className="mtkBt_2 button fd ar_bt_1" disabled={this.props.disClearBtn == 1}>{this.props.language.Clear} </button>
          </div>
          <div className="fl_1" onClick={(e) => { e.preventDefault(); this.props.btntext("double") }} >
              <button className="mtkBt_2 button fd ar_bt_1" disabled={this.props.disDoubleBtn == 1}>{this.props.language.Double} </button>
          </div>
        </div>
        {/*<label htmlFor="stopTheWheelAt"> Stop At:</label>
        <input id="stopTheWheelAt" defaultValue="000"></input> */}
      </div>
    );
  }
}



const mapStatesToProps = (state)=>{
  return {
      language : state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(RightButtonPanel) 

