import { Component, React } from 'react';
import '../../../../../css/table/roulette_number_table.css';
import coin from '../../../../../../assets/gold_coin.svg'
import tableJson from './tableJson';
import chips from '../../../chips';
class Table extends Component {
    constructor() {
        super()
        //console.log(tableJson);
        this.state = {  }

    }
    componentDidMount() {
        var divContain = document.getElementById('roulette');
        var divs = divContain.getElementsByTagName('div');
        var tds = divContain.getElementsByTagName('td');
        var mainVal = divContain.getElementsByClassName('mainVal');
        var evenNos = divContain.getElementsByClassName('evenNos');
        var oddNos = divContain.getElementsByClassName('oddNos');

        /************** Third row START ***********/

        var thirdRow = document.getElementsByClassName('thirdRow');
        var len = 14
        for (var a = 0; a < thirdRow.length; a++) {
            thirdRow[a].addEventListener('mouseenter', function () {
                thirdRow[0].classList.add('shadow');
                for (let b = 1; b < len; b++) {
                    tds[b].classList.add('shadow');
                }
            })
            thirdRow[a].addEventListener('mouseleave', function () {
                thirdRow[0].classList.remove('shadow');
                for (let c = 1; c < len; c++) {
                    tds[c].classList.remove('shadow');
                }
            })
        }
        /************** Third row END ***********/
        /************** Second row START ***********/
        var secondRow = document.getElementsByClassName('secondRow');
        let s = 27
        for (var d = 0; d < secondRow.length; d++) {
            secondRow[d].addEventListener('mouseenter', function () {
                secondRow[0].classList.add('shadow');
                for (let e = 14; e < s; e++) {
                    tds[e].classList.add('shadow');
                }
            })
            secondRow[d].addEventListener('mouseleave', function () {
                secondRow[0].classList.remove('shadow');
                for (let ac = 14; ac < s; ac++) {
                    tds[ac].classList.remove('shadow');
                }
            })
        }
        /************** Second row END ***********/
        /************** First row START ***********/

        var firstRow = document.getElementsByClassName('firstRow');
        let f_val = 44;
        for (var ad = 0; ad < firstRow.length; ad++) {
            firstRow[ad].addEventListener('mouseenter', function () {
                firstRow[0].classList.add('shadow');
                for (let k = 30; k < f_val; k++) {
                    tds[k].classList.add('shadow');
                }
            })
            firstRow[ad].addEventListener('mouseleave', function () {
                firstRow[0].classList.remove('shadow');
                for (let k = 30; k < f_val; k++) {
                    tds[k].classList.remove('shadow');
                }
            })
        }
        /************** First row END ***********/

        var trs = divContain.getElementsByTagName('tr');
        var tds_tr_0 = trs[0].getElementsByTagName('td');
        var tds_tr_1 = trs[1].getElementsByTagName('td');
       // console.log(tds_tr_1)
        var tds_tr_2 = trs[2].getElementsByTagName('td');
        /************** one_to_twelve_Section ROW START ***********/
        var first_twelve_Section = document.getElementsByClassName('first_twelve_Section');

        for (var ae = 0; ae < first_twelve_Section.length; ae++) {
            first_twelve_Section[ae].addEventListener('mouseenter', function () {
                var f_fr = 5;
                for (var af = 1; af < f_fr; af++) {
                    tds_tr_0[af].classList.add('shadow');
                    tds_tr_1[af].classList.add('shadow');
                    tds_tr_2[af].classList.add('shadow');
                }
            });
            first_twelve_Section[ae].addEventListener('mouseleave', function () {
                var f_fr = 5;
                for (var ag = 1; ag < f_fr; ag++) {
                    tds_tr_0[ag].classList.remove('shadow');
                    tds_tr_1[ag].classList.remove('shadow');
                    tds_tr_2[ag].classList.remove('shadow');
                }
            });
        }
        /************** one_to_twelve_Section ROW END ***********/


        /************** second_twelve_Section ROW START ***********/
        var second_twelve_Section = document.getElementsByClassName('second_twelve_Section');

        for (var ah = 0; ah < second_twelve_Section.length; ah++) {
            second_twelve_Section[ah].addEventListener('mouseenter', function () {
                var f_fr = 9;
                for (var ai = 5; ai < f_fr; ai++) {
                    tds_tr_0[ai].classList.add('shadow');
                    tds_tr_1[ai].classList.add('shadow');
                    tds_tr_2[ai].classList.add('shadow');
                }
            });
            second_twelve_Section[ah].addEventListener('mouseleave', function () {
                var f_fr = 9;
                for (var aj = 5; aj < f_fr; aj++) {
                    tds_tr_0[aj].classList.remove('shadow');
                    tds_tr_1[aj].classList.remove('shadow');
                    tds_tr_2[aj].classList.remove('shadow');
                }
            });
        }
        /************** second_twelve_Section ROW END ***********/

        /************** third_twelve_Section ROW START ***********/
        var third_twelve_Section = document.getElementsByClassName('third_twelve_Section');

        for (var ak = 0; ak < third_twelve_Section.length; ak++) {
            third_twelve_Section[ak].addEventListener('mouseenter', function () {
                var f_fr = 13;
                for (var al = 9; al < f_fr; al++) {
                    tds_tr_0[al].classList.add('shadow');
                    tds_tr_1[al].classList.add('shadow');
                    tds_tr_2[al].classList.add('shadow');
                }
            });
            third_twelve_Section[ak].addEventListener('mouseleave', function () {
                var f_fr = 13;
                for (var am = 9; am < f_fr; am++) {
                    tds_tr_0[am].classList.remove('shadow');
                    tds_tr_1[am].classList.remove('shadow');
                    tds_tr_2[am].classList.remove('shadow');
                }
            });
        }
        /************** third_twelve_Section ROW END ***********/

        /************** oneToEighteen ROW START ***********/
        var oneToEighteen = document.getElementsByClassName('oneToEighteen');

        for (var an = 0; an < oneToEighteen.length; an++) {
            oneToEighteen[an].addEventListener('mouseenter', function () {
                var f_fr = 7;
                for (var ao = 1; ao < f_fr; ao++) {
                    tds_tr_0[ao].classList.add('shadow');
                    tds_tr_1[ao].classList.add('shadow');
                    tds_tr_2[ao].classList.add('shadow');
                }
            });
            oneToEighteen[an].addEventListener('mouseleave', function () {
                var f_fr = 7;
                for (var ap = 1; ap < f_fr; ap++) {
                    tds_tr_0[ap].classList.remove('shadow');
                    tds_tr_1[ap].classList.remove('shadow');
                    tds_tr_2[ap].classList.remove('shadow');
                }
            });
        }
        /************** oneToEighteen ROW END ***********/

        /************** nineteenToThirtysix ROW START ***********/
        var nineteenToThirtysix = document.getElementsByClassName('nineteenToThirtysix');

        for (var aq = 0; aq < nineteenToThirtysix.length; aq++) {
            nineteenToThirtysix[aq].addEventListener('mouseenter', function () {
                var f_fr = 13;
                for (var ar = 7; ar < f_fr; ar++) {
                    tds_tr_0[ar].classList.add('shadow');
                    tds_tr_1[ar].classList.add('shadow');
                    tds_tr_2[ar].classList.add('shadow');
                }
            });
            nineteenToThirtysix[aq].addEventListener('mouseleave', function () {
                var f_fr = 13;
                for (var as = 7; as < f_fr; as++) {
                    tds_tr_0[as].classList.remove('shadow');
                    tds_tr_1[as].classList.remove('shadow');
                    tds_tr_2[as].classList.remove('shadow');
                }
            });
        }
        /************** nineteenToThirtysix ROW END ***********/

        var red = divContain.getElementsByClassName('red');
        var redBox = divContain.getElementsByClassName('redBox');
        var black = divContain.getElementsByClassName('black');
        var blackBox = divContain.getElementsByClassName('blackBox');

        /************** RED BOX START ***********/
        for (var at = 0; at < redBox.length; at++) {
            redBox[at].addEventListener('mouseenter', function () {
                for (var au = 0; au < red.length; au++) {
                    red[au].classList.add('shadow');
                }
            })
            redBox[at].addEventListener('mouseleave', function () {
                for (var av = 0; av < red.length; av++) {
                    red[av].classList.remove('shadow');
                }
            })
        }
        /************** RED BOX END ***********/

        /************** BLACK BOX START ***********/
        for (var aw = 0; aw < blackBox.length; aw++) {
            blackBox[aw].addEventListener('mouseenter', function () {
                for (var ax = 0; ax < black.length; ax++) {
                    black[ax].classList.add('shadow');
                }
            })
            blackBox[aw].addEventListener('mouseleave', function () {
                for (var ay = 0; ay < black.length; ay++) {
                    black[ay].classList.remove('shadow');
                }
            })
        }
        /************** BLACK BOX END ***********/



        /************************* EVEN NUMBERS JS START ****************************/
        for (var az = 0; az < evenNos.length; az++) {
            evenNos[az].addEventListener('mouseenter', function () {
                for (var ba = 0; ba < mainVal.length; ba++) {
                    var splAttr = mainVal[ba].getAttribute('splAttr');
                    if (splAttr > 1) {
                        if (splAttr && splAttr % 2 === 0) {
                            mainVal[ba].classList.add('shadow')
                        }
                    }
                }
            })
            evenNos[az].addEventListener('mouseleave', function () {
                for (var bb = 0; bb < mainVal.length; bb++) {
                    var splAttr = mainVal[bb].getAttribute('splAttr');
                    if (splAttr && splAttr % 2 === 0) {
                        mainVal[bb].classList.remove('shadow')
                    }
                }
            })
        }
        /************************* EVEN NUMBERS JS END ****************************/
        /************************* ODD NUMBERS JS START ****************************/
        for (var bc = 0; bc < oddNos.length; bc++) {
            oddNos[bc].addEventListener('mouseenter', function () {
                for (var bd = 0; bd < mainVal.length; bd++) {
                    var splAttr = mainVal[bd].getAttribute('splAttr');
                    if (splAttr && splAttr % 2 !== 0) {
                        mainVal[bd].classList.add('shadow')
                    }
                }
            })
            oddNos[bc].addEventListener('mouseleave', function () {
                for (var be = 0; be < mainVal.length; be++) {
                    var splAttr = mainVal[be].getAttribute('splAttr');
                    if (splAttr && splAttr % 2 !== 0) {
                        mainVal[be].classList.remove('shadow')
                    }
                }
            })
        }
        /************************* ODD NUMBERS JS END ****************************/
    } 
    tableScale( ){ 
        var divContain = document.getElementById('roulette');
        divContain.style.transform = "scale(1.1)";
    }
    mouseDownSelectCard(data){ 
        this.props.action(data) 
        this.startInt = setInterval( () => { 
          this.props.action(data)
        }, 150); 
      } 
      mouseUpStopCards(){
        clearInterval(this.startInt);
      }
    render() {  
        return (
            <div> 
                <div className='fd'>
                <div className="roulette p_lr_15" id="roulette" onClick={(e)=> this.tableScale()}>
                    <table className='eurpRouletteTimerTable'>
                        <tbody>
                            <tr>
                            {tableJson.filter(data => data.tr === 'tr1' && data.bg === '').map((data, i) => (
                                <td className={data.bg + " green num "} key={data.uqId} rowSpan="3">
                                    <h3> 0 </h3>
                                    <div className="mainVal er_0"
                                        id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(0)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr="0"></div>
                                </td>
                            ))}

                                {tableJson.filter(data => data.tr === 'tr1' && data.bg !== '' && data.bg !== 'thirdRow').map((data, i) => (
                                    <td className={data.bg + " num"} key={data.uqId}>
                                    {data.visibleVal}
                                    <div className="mainVal " id={"id_"+data.mainVal} 
                                    onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} 
                                    splattr={data.visibleVal}>  </div>
                                    <div className="btmVal" id={"id_"+data.btmVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.btmVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    <div className="leftVal" id={"id_"+data.leftVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.leftVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    <div className="cntrVal" id={"id_"+data.cntrVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.cntrVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                        </td>
                                ))}
                                {tableJson.filter(data => data.tr === 'tr1' && data.bg === 'thirdRow').map((data, i) => (
                                    <td className="sector" key={data.uqId}>
                                    {data.visibleVal}
                                        <div className={data.bg + " mainVal"} id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr={data.visibleVal}>  </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                {tableJson.filter(data => data.tr === 'tr2' && data.bg !== '' && data.bg !== 'secondRow').map((data, i) => (
                                    <td className={data.bg + " num"} key={data.uqId}>
                                    {data.visibleVal}
                                        <div className="mainVal " id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr={data.visibleVal}> </div>
                                        <div className="btmVal" id={"id_"+data.btmVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.btmVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                        <div className="leftVal" id={"id_"+data.leftVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.leftVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                        <div className="cntrVal" id={"id_"+data.cntrVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.cntrVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    </td>
                                ))}
                                {tableJson.filter(data => data.tr === 'tr2' && data.bg === 'secondRow').map((data, i) => (
                                    <td className="sector" key={data.uqId}>
                                    {data.visibleVal} 
                                        <div className={data.bg + " mainVal"} id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr={data.visibleVal}> </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                {tableJson.filter(data => data.tr === 'tr3' && data.bg !== '' && data.bg !== 'firstRow').map((data, i) => (
                                    <td className={data.bg + " num"} key={data.uqId}>
                                    {data.visibleVal} 
                                        <div className="mainVal" id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr={data.visibleVal}> </div>
                                        <div className="btmVal" id={"id_"+data.btmVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.btmVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                        <div className="leftVal" id={"id_"+data.leftVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.leftVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                        <div className="cntrVal" id={"id_"+data.cntrVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.cntrVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    </td>
                                ))}
                                {tableJson.filter(data => data.tr === 'tr3' && data.bg === 'firstRow').map((data, i) => (
                                    <td className="sector" key={data.uqId}>
                                    {data.visibleVal} 
                                        <div className={data.bg + " mainVal"} id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}} splattr={data.visibleVal}> </div>
                                    </td>
                                ))}
                            </tr>


                            <tr>
                                {tableJson.filter(data => data.tr === 'tr4').map((data, i) => (
                                    <td colSpan={data.colSpn} className={data.tdBg} key={data.uqId}>
                                    {data.visibleVal} 
                                        <div className={data.divBg} id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    </td>
                                ))}

                            </tr>
                            <tr>
                                {tableJson.filter(data => data.tr === 'tr5').map((data, i) => (
                                    <td colSpan={data.colSpn} className={data.tdBg} key={data.uqId}>
                                    {data.visibleVal} 
                                        <div className={data.divBg} id={"id_"+data.mainVal} onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${data.mainVal}`)}}
                                    onMouseUp={(e)=>{this.mouseUpStopCards()}}
onMouseOut={(e)=>{this.mouseUpStopCards()}}> </div>
                                    </td>
                                ))}

                            </tr>
                        </tbody>
                    </table> 
                </div>
                </div>
            </div>
        )
    }
}

export default Table;
