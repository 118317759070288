export const FETCH_ENGLISH_REQUEST = "FETCH_ENGLISH_REQUEST";
export const FETCH_ENGLISH_SUCCESS = "FETCH_ENGLISH_SUCCESS";
export const FETCH_ENGLISH_FAIL = "FETCH_ENGLISH_FAIL";

export const FETCH_TELUGU_REQUEST = "FETCH_TELUGU_REQUEST";
export const FETCH_TELUGU_SUCCESS = "FETCH_TELUGU_SUCCESS";
export const FETCH_TELUGU_FAIL = "FETCH_TELUGU_FAIL";

export const FETCH_BENGALI_REQUEST = "FETCH_BENGALI_REQUEST";
export const FETCH_BENGALI_SUCCESS = "FETCH_BENGALI_SUCCESS";
export const FETCH_BENGALI_FAIL = "FETCH_BENGALI_FAIL";

export const FETCH_GUJARATHI_REQUEST = "FETCH_GUJARATI_REQUEST";
export const FETCH_GUJARATHI_FAIL = "FETCH_GUJARATI_FAIL";
export const FETCH_GUJARATHI_SUCCESS = "FETCH_GUJARATI_SUCCESS";

export const FETCH_MARATHI_REQUEST = "FETCH_MARATI_REQUEST";
export const FETCH_MARATHI_FAIL = "FETCH_MARATI_FAIL";
export const FETCH_MARATHI_SUCCESS = "FETCH_MARATI_SUCCESS";

export const FETCH_TAMIL_REQUEST = "FETCH_TAMIL_REQUEST";
export const FETCH_TAMIL_SUCCESS = "FETCH_TAMIL_SUCCESS";
export const FETCH_TAMIL_FAIL = "FETCH_TAMIL_FAIL";

export const FETCH_KANNADA_REQUEST = "FETCH_KANNADA_REQUEST";
export const FETCH_KANNADA_SUCCESS = "FETCH_KANNADA_SUCCESS";
export const FETCH_KANNADA_FAIL = "FETCH_KANNADA_FAIL";

export const FETCH_MALAYALAM_REQUEST = "FETCH_MALAYALAM_REQUEST";
export const FETCH_MALAYALAM_SUCCESS = "FETCH_MALAYALAM_SUCCESS";
export const FETCH_MALAYALAM_FAIL = "FETCH_MALAYALAM_FAIL";

export const FETCH_PORTUGUESE_REQUEST = "FETCH_PORTUGUESE_REQUEST";
export const FETCH_PORTUGUESE_SUCCESS = "FETCH_PORTUGUESE_SUCCESS";
export const FETCH_PORTUGUESE_FAIL = "FETCH_PORTUGUESE_FAIL";


 