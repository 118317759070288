
import sparkanimation1 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_1.png"
import sparkanimation2 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_2.png"
import sparkanimation3 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_3.png"
import sparkanimation4 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_4.png"
import sparkanimation5 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_5.png"
import sparkanimation6 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_6.png"
import sparkanimation7 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_7.png"
import sparkanimation8 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_8.png"
import sparkanimation9 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_9.png"
import sparkanimation10 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_10.png"
import sparkanimation11 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_11.png"
import sparkanimation12 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_12.png"
import sparkanimation13 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_13.png"
import sparkanimation14 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_14.png"
import sparkanimation15 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_15.png"
import sparkanimation16 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_16.png"
import sparkanimation17 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_17.png"
import sparkanimation18 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_18.png"
import sparkanimation19 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_19.png"
import sparkanimation20 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_20.png"
import sparkanimation21 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_21.png"
import sparkanimation22 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_22.png"
import sparkanimation23 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_23.png"
import sparkanimation24 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_24.png"
import sparkanimation25 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_25.png"
import sparkanimation26 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_26.png"
import sparkanimation27 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_27.png"
import sparkanimation28 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_28.png"
import sparkanimation29 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_29.png"
import sparkanimation30 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_30.png"
import sparkanimation31 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_31.png"
import sparkanimation32 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_32.png"
import sparkanimation33 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_33.png"
import sparkanimation34 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_34.png"
import sparkanimation35 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_35.png"
import sparkanimation36 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_36.png"
import sparkanimation37 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_37.png"
import sparkanimation38 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_38.png"
import sparkanimation39 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_39.png"
import sparkanimation40 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_40.png"
import sparkanimation41 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_41.png"
import sparkanimation42 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_42.png"
import sparkanimation43 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_43.png"
import sparkanimation44 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_44.png"
import sparkanimation45 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_45.png"
import sparkanimation46 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_46.png"
import sparkanimation47 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_47.png"
import sparkanimation48 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_48.png"
import sparkanimation49 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_49.png"
import sparkanimation50 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_50.png"

import sparkanimation51 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_51.png"
import sparkanimation52 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_52.png"
import sparkanimation53 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_53.png"
import sparkanimation54 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_54.png"
import sparkanimation55 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_55.png"
import sparkanimation56 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_56.png"
import sparkanimation57 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_57.png"
import sparkanimation58 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_58.png"
import sparkanimation59 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_59.png"
import sparkanimation60 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_60.png"

import sparkanimation61 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_61.png"
import sparkanimation62 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_62.png"
import sparkanimation63 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_63.png"
import sparkanimation64 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_64.png"
import sparkanimation65 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_65.png"
import sparkanimation66 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_66.png"
import sparkanimation67 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_67.png"
import sparkanimation68 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_68.png"
import sparkanimation69 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_69.png"
import sparkanimation70 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_70.png"

import sparkanimation71 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_71.png"
import sparkanimation72 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_72.png"
import sparkanimation73 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_73.png"
import sparkanimation74 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_74.png"
import sparkanimation75 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_75.png"
import sparkanimation76 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_76.png"
import sparkanimation77 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_77.png"
import sparkanimation78 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_78.png"
import sparkanimation79 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_79.png"
import sparkanimation80 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_80.png"

import sparkanimation81 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_81.png"
import sparkanimation82 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_82.png"
import sparkanimation83 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_83.png"
import sparkanimation84 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_84.png"
import sparkanimation85 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_85.png"
import sparkanimation86 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_86.png"
import sparkanimation87 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_87.png"
import sparkanimation88 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_88.png"
import sparkanimation89 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_89.png"
import sparkanimation90 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_90.png"

import sparkanimation91 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_91.png"
import sparkanimation92 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_92.png"
import sparkanimation93 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_93.png"
import sparkanimation94 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_94.png"
import sparkanimation95 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_95.png"
import sparkanimation96 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_96.png"
import sparkanimation97 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_97.png"
import sparkanimation98 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_98.png"
import sparkanimation99 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_99.png"
import sparkanimation100 from "../../../assets/games/sparkleanimation/Spark_Anim/spark_100.png"

const sparkanimation = {

    sparkanimation1,
    sparkanimation2,
    sparkanimation3,
    sparkanimation4,
    sparkanimation5,
    sparkanimation6,
    sparkanimation7,
    sparkanimation8,
    sparkanimation9,
    sparkanimation10,
    sparkanimation11,
    sparkanimation12,
    sparkanimation13,
    sparkanimation14,
    sparkanimation15,
    sparkanimation16,
    sparkanimation17,
    sparkanimation18,
    sparkanimation19,
    sparkanimation20,
    sparkanimation21,
    sparkanimation22,
    sparkanimation23,
    sparkanimation24,
    sparkanimation25,
    sparkanimation26,
    sparkanimation27,
    sparkanimation28,
    sparkanimation29,
    sparkanimation30,
    sparkanimation31,
    sparkanimation32,
    sparkanimation33,
    sparkanimation34,
    sparkanimation35,
    sparkanimation36,
    sparkanimation37,
    sparkanimation38,
    sparkanimation39,
    sparkanimation40,
    sparkanimation41,
    sparkanimation42,
    sparkanimation43,
    sparkanimation44,
    sparkanimation45,
    sparkanimation46,
    sparkanimation47,
    sparkanimation48,
    sparkanimation49,
    sparkanimation50,
    sparkanimation51,
    sparkanimation52,
    sparkanimation53,
    sparkanimation54,
    sparkanimation55,
    sparkanimation56,
    sparkanimation57,
    sparkanimation58,
    sparkanimation59,
    sparkanimation60,
    sparkanimation61,
    sparkanimation62,
    sparkanimation63,
    sparkanimation64,
    sparkanimation65,
    sparkanimation66,
    sparkanimation67,
    sparkanimation68,
    sparkanimation69,
    sparkanimation70,
    sparkanimation71,
    sparkanimation72,
    sparkanimation73,
    sparkanimation74,
    sparkanimation75,
    sparkanimation76,
    sparkanimation77,
    sparkanimation78,
    sparkanimation79,
    sparkanimation80,
    sparkanimation81,
    sparkanimation82,
    sparkanimation83,
    sparkanimation84,
    sparkanimation85,
    sparkanimation86,
    sparkanimation87,
    sparkanimation88,
    sparkanimation89,
    sparkanimation90,
    sparkanimation91,
    sparkanimation92,
    sparkanimation93,
    sparkanimation94,
    sparkanimation95,
    sparkanimation96,
    sparkanimation97,
    sparkanimation98,
    sparkanimation99,
    sparkanimation100,



}
export default sparkanimation;