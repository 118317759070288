import React from "react";
import american_ring from "../../../../../../assets/games/americanRouletteTimer/american_ring.svg";
import ring_outside from "../../../../../../assets/games/americanRouletteTimer/ring_outside.svg";
import art_num_img from "../../../../../../assets/games/americanRouletteTimer/art_num_bg.svg";
import art_ball_img from "../../../../../../assets/games/americanRouletteTimer/art_rollBall.png";
import artWheelBg from "../../../../../../assets/games/americanRouletteTimer/art_wheel_bg.svg";
import wheel_poll from "../../../../../../assets/games/americanRouletteTimer/art_wheel_poll.svg";
import art_wheelBorder_bottom from "../../../../../../assets/games/americanRouletteTimer/art_wheel_border.svg";

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props);    
        this.firstload = true;
        this.reward = [0, 28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2];
        this.rewards = { 0: 0, 28: 1, 9: 2, 26: 3, 30: 4, 11: 5, 7: 6, 20: 7, 32: 8, 17: 9, 5: 10, 22: 11, 34: 12, 15: 13, 3: 14, 24: 15, 36: 16, 13: 17, 1: 18, 37: 19, 27: 20, 10: 21, 25: 22, 29: 23, 12: 24, 8: 25, 19: 26, 31: 27, 18: 28, 6: 29, 21: 30, 33: 31, 16: 32, 4: 33, 23: 34, 35: 35, 14: 36, 2: 37 };        
        this.resultNumber = 0;
        this.playAudio= false;
        this.art_ballBg = "";
        this.art_wheelAnim = "";
    }

    componentDidMount() {  
        this.preloadImgs();
        
        console.log( this.props.gameState.history );
        this.art_ballBg = document.getElementById("art_ballBg");
        this.art_wheelAnim = document.getElementById("art_wheelAnim")
        this.art_rollBallBg = document.getElementById("art_rollBall");
        if(this.props.gameState.history && this.firstload && this.props.gameState.history.length > 0){
            this.firstload = false; 
            let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            this.spinTheWheelOnload(resNo)   
          } else {
            let defaultNo = 0
            this.spinTheWheelOnload(defaultNo)
        }  
    }

    preloadImgs() {  
        let imgsLoad = [american_ring, ring_outside, art_num_img, art_ball_img, artWheelBg, wheel_poll, art_wheelBorder_bottom];
        for (let t = 0; t < imgsLoad.length; t++) {
          let art_img = new Image();
          const artImageName = imgsLoad[t];
          art_img.src = artImageName;
          // console.log(spark_img.src);      
        }
      }
      
    addImage(image) {
        let img = new window.Image();
        img.src = american_ring;
        return img;
    }
    addImageRing(){
        let img = new window.Image();
        img.src = ring_outside;
        return img;
    }     
    spinTheWheelOnload (s){         
        console.log(s)
        this.resultNumber = Number(s);
      
        let index = this.reward.indexOf(this.resultNumber);
        console.log(index);  
        console.log(this.rewards);
        console.log(this.reward[index]);
 
        var stopValueFirst = this.reward[index];
        console.log(stopValueFirst);
          
        const art_ball_style = {
            transition: 'all 0s',
            transform: `rotate(${((index) * 9.478)}deg)`,
        };
        Object.assign(this.art_ballBg.style, art_ball_style);
  
        const art_styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.art_wheelAnim.style, art_styles);
    }

    spinTheWheel = (s) => {
        this.resultNumber = s;     
        console.log("ART Result Numeber: "+this.resultNumber)
        let index = this.reward.indexOf(this.resultNumber);
        console.log(index);
        const art_ball_style = {
            transition: 'all 7s',
            transform: `rotate(${(2160 + (index * 9.478))}deg)`,
        };
        Object.assign(this.art_ballBg.style, art_ball_style);

        const art_styles = {
            transition: 'all 7s',
            transform: `rotate(${-1080}deg)`,
        };
        Object.assign(this.art_wheelAnim.style, art_styles);

        setTimeout(() => {
            this.art_rollBallBg.style.transform = 'translateY(-165px)'
        }, 5000)

        this.art_rollBallBg.style.transform = 'translateY(-240px)'

        setTimeout(() => {
            this.afterRotate(index);
        }, 7200)
    };

    afterRotate(resNumVal) {
        // console.log('hi');
        // console.log(resNumVal);        
        // console.log(resNumVal * 9.478);
    
        const ball_style = {
            transition: 'all 0s',
            transform: `rotate(${(resNumVal * 9.478)}deg)`,
        };
        Object.assign(this.art_ballBg.style, ball_style);
    
        const styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.art_wheelAnim.style, styles);
        this.announceWinner(this.resultNumber);

        // this.wheelSound = {};
    }

    announceWinner(value) {
        this.props.action(value);
        this.playAudio = false;
    }

    render() {
        return (
            <div className="art_WheelWraper">
                <div className="art_wheelBorder"> <img src={artWheelBg} /> </div>
                <div className="art_wheelBorder_out zin_9"> <img src={art_wheelBorder_bottom} /> </div>
                <div className="art_wheelAnimOne">
                    <div className="art_wheelAnimParent">
                        <div className="art_wheelAnim" id="art_wheelAnim">
                            <img src={art_num_img} />
                            <div className="art_bollWrap" id="art_ballBg">
                                <img className="art_rollBall" id="art_rollBall" src={art_ball_img} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="art_wheelPoll"> <img src={wheel_poll} />  </div>
            </div>
        );
    }
}
