import React, { Component, Fragment } from "react";
import { Stage, Layer, Group, Rect, Arc, Text } from "react-konva";
import betchipsnd from "././../../../../../assets/sounds/betoncard.mp3";
import betrussiansnd from "././../../../../../assets/sounds/bet.mp3";
import pokerchipsnd from "././../../../../../assets/sounds/pokerchip_snd.mp3";
import plyerwin_snd from "././../../../../../assets/sounds/playerwin.mp3";
import cardsdropping_snd from "././../../../../../assets/sounds/carddroping.mp3";
import cardsshuffle from "././../../../../../assets/sounds/shuffle-cards.mp3";
import reaveldealer_cards from "././../../../../../assets/sounds/dealercards_reavel.mp3";
import clear_snd from "././../../../../../assets/sounds/clear.mp3";
import selectCard from "../../../../../assets/sounds/selectcard.mp3";
import unselectCard from "../../../../../assets/sounds/unselectcard.mp3";
import playerWin from "../../../../../assets/sounds/win.mp3"
import playerLose from "../../../../../assets/sounds/duplose.mp3"
import Cards from "../RussianPoker/UI/cards";
import chip_10 from "../../../../../assets/games/russianPoker/Whitechip.png";
import chip_25 from "../../../../../assets/games/russianPoker/black.png";
import chip_50 from "../../../../../assets/games/russianPoker/blue.png";
import chip_100 from "../../../../../assets/games/russianPoker/red.png";
import chip_500 from "../../../../../assets/games/russianPoker/yellow.png";
import "./Russianpoker.css";
import russianbg from "../../../../../assets/games/russianPoker/BG.png";
import mutesnds from "../../../../../assets/games/russianPoker/mute_snds.png"
import pokertableruss from "../../../../../assets/games/russianPoker/AssetTable.png";
import pokertablelandsp from "../../../../../assets/games/russianPoker/landscapetable.png";
import landscapebg from "../../../../../assets/games/russianPoker/landspbg.png";
import porttraitejson from "../portraite&landscape.json";
import balanceicon from "../../../../../assets/games/russianPoker/Balance icon.png";
import balanceiconwindows from "../../../../../assets/games/russianPoker/balancewindows.png";
import exitrussian_popup from "../../../../../assets/games/russianPoker/logOut.png";
// import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import exitrussian from "../../../../../assets/games/russianPoker/logOut.png";
import popupExit from "../../../../../assets/common_imgs/exit_btn.png"
// import exitrussian from "../../../../../assets/games/russianPoker/Exit.png";
import beticon from "../../../../../assets/games/russianPoker/Bet icon.png";
import beticonwindows from "../../../../../assets/games/russianPoker/betwindows.png";
import winnericn from "../../../../../assets/games/russianPoker/winnericon.png";
import winnericnwindows from "../../../../../assets/games/russianPoker/winnericonwindows.png";
import informicon from "../../../../../assets/games/russianPoker/i.png";
import russiantitle from "../../../../../assets/games/russianPoker/russiantitle.png";
import optionbtn from "../../../../../assets/games/russianPoker/Option.png";
import optionbtnwindows from "../../../../../assets/games/russianPoker/optionbtnwindows.png";
import volumeimg from "../../../../../assets/games/russianPoker/Valume.png";
import russianbetbtnup from "../../../../../assets/games/russianPoker/up.png";
import russianbetbtright from "../../../../../assets/games/russianPoker/right.png";
import russianbetbtnleft from "../../../../../assets/games/russianPoker/left.png";
import russianbetbtndown from "../../../../../assets/games/russianPoker/down.png";
import russianbetcle from "../../../../../assets/games/russianPoker/betcircle.png";
import betincerementdecrement from "../../../../../assets/games/russianPoker/betincreament&decreament.png";
// import betincerementdecrement1 from "../../../../../assets/games/russianPoker/Balance icon.png"
import betchipsRussian from "../../../../../assets/games/russianPoker/Whitechip.png";
import Russianbgwindows from "../../../../../assets/games/russianPoker/russianbgwindows.png";
import Rutablewindows from "../../../../../assets/games/russianPoker/russiantblewindows.png";
import Drawbtn from "../../../../../assets/games/russianPoker/drawbtn.png";
import Drawbtnwindows from "../../../../../assets/games/russianPoker/drawbtnwindows.png";
import bnsactive from "../../../../../assets/games/russianPoker/bnsactive.png";
import Buybtn from "../../../../../assets/games/russianPoker/buybtn.png";
import Buybtnwindows from "../../../../../assets/games/russianPoker/buybtnwindows.png";
import Betbtn from "../../../../../assets/games/russianPoker/betbtn.png";
// import Betbtnwindows from "../../../../../assets/games/russianPoker/betbtnwindows.png"
import Foldbn from "../../../../../assets/games/russianPoker/foldbtn.png";
import Foldbnwindows from "../../../../../assets/games/russianPoker/foldbtnwindows.png";
import useridlmg from "../../../../../assets/games/russianPoker/userid.png";
import gsap from "gsap";
import DealerCardsContainer from "./UI/DealerCardsContainer";
import resultCards from "../RussianPoker/UI/resultCards";
import { connect } from "react-redux";


// npm i react-konva@17.0.1-3
class RussianPoker extends Component {
  constructor(props) {
    super(props);
    this.cardsRef = React.createRef();
    this.dealerCardsContainer = React.createRef();
    // antebetresult and shuffled result : ---------
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    // replaceCards (userSelectedCards) : ---------
    this.replaceCards = [];
    // serverResponse (after draw cards) : ---------
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    // clickOnBuyCard : ---------
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    // DEALER CARDS
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    // CONFIRM_DEALER_CARD : -----------------
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];
    this.insuranceActive = false;
    this.balnce = this.props.balance;
    this.antebet = 0;
    this.betsamount = 0;
    this.roundNumber = 0;
    this.betAmount = 0;

    this.state = {
      myOrient: "land",
      betshigh_balnce: null,
      keyboardhandle: false,
      dealenable: false,
      disClearBtn: 1,
      disDrawbtn: 1,
      disBuybtn: 1,
      cmfrmdealeraction: 0,
      cmfrmdealeractionNO: 0,
      Foldbtn: 1,
      handId: 0,
      Yesbtn: 1,
      Nobtn: 1,
      betBtnValue: 0,
      disBetBtn: 1,
      playerhand_status: "",
      isoptionmenu: false,
      buttontext: "",
      drawbuttonhandle: false,
      isbonuspattable: false,
      ShowLimits:false,
      isactiveoptionsmenu: false,
      ishistorytable: false,
      balance_rsp: 0,
      updateBet: 0,
      betdeductions: 0,
      antebetcircle: false,
      betchipincrementbtn: false,
      multibuttonsebledsble: false,
      mutesounds: false,
      totalbet: 0,
      bonusbetamount: 0,
      previousBonusbetamount: 0,
      count: 1,
      insurencetbet: 0,
      pokerranking: "",
      pokerrankingdeler: "",
      storebetchips: [],
      storeantebetchips: [],
      storebonuschips: [],
      storeinsurancechips:[],
      prevStatestoreantebetchips:[],
      prevStatestorebonuschips:[],
      totalwinRussian: 0,
      betdecreamentbtn: false,
      idincrement: 0,
      bsbtn: true,
      anteBetAmount: 0,
      previousAnteBetAmount: 0,
      disablemultibutton: false,
      insurencebtn: false,
      isMobilePortraitLndsp: null,
      betbutton: "DEAL",
      activeScreen: null,
      // //GroupProperties
      heightOfLayer: null,
      widthOfLayer: null,
      dealerDistanceFromX: null,
      playerDistanceFromX: null,
      dealerDistanceFromY: null,
      PlayerDistanceFromY: null,
      // SpriteSheetProps
      revealCard: false,
      infoText: this.props.infoText,
      buydrawCheck: false,
      insurencetbet: 0,
      lowBalaceErrorInfo:"",
      ShowHistory:false,

      bnsactvglwcss: null,
      betactvglwcss:null,
      betbuttoncss: null,
      betbtntextcss: null,
      foldbuttoncss: null,
      foldbtntextcss: null,
      buybtncss:null,
      buybtntxtcss: null,
      drawbtncss: null,
      drawbtntxtcss: null,
      betchipstoantebetcss: null,
      betchipstobonusbetcss: null,
      chipsstoredcontainercss: null,
      antebetpdvcss: null,
      antechpimgcss: null,
      antespntxtcss: null,
      bonusdivcss:null,
      bonusimgcss:null,
      bonuspntxtcss:null,
      betbtntxtcss:null,
      betchiptobetbtncss:null,
      betchiptobetbtncss:null,
      betbtntxtcss:null,
      buydrawcontainer:null,
      drawchipcss:null,
      drawchiptextcss:null ,
      insurancedivcss:null,
      insurancechipcss: null,
      insurancetxtcss:null,
      dealerhandcss:null,
      playerhandcss:null,
      mobileAntiMaskCovercss:null,
      betpannelrussiancovercss:null,
      insurenceactivecss:null,
      rankinginfodelercss:null,

      inforussiancss:null
    };

    this.chipref = React.createRef();
    this.chiprefee = React.createRef();
    this.chipdrawref = React.createRef();
    this.chipbnsref = React.createRef();
    this.chipvalue = React.createRef();
    this.chipsarray = React.createRef();
    this.chipbetref = React.createRef();
    this.chipinsurenceref = React.createRef();
    this.no = React.createRef();
    this.cardsRef = React.createRef();
    // this.dealerCardsContainer = React.createRef();
    this.buyDealerCardDrawn = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.isMobile = false;
    this.dealenable = false;
    this.resetAnimationChips = false;
    this.playerWinBonus = false;
    this.wanttobuydelercrd = null;

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;

    this.clearTimingLeft = [
      this.clertime1, this.clertime2, this.clertime3, this.clertime4, this.clertime5, this.clertime6, this.clertime7, this.clertime8, this.clertime9,
      this.clertime11, this.clertime12, this.clertime13, this.clertime14, this.clertime15, this.clertime16, this.clertime17, this.clertime18, this.clertime19,
      this.clertime21, this.clertime22, this.clertime23, this.clertime24, this.clertime25, this.clertime26, this.clertime27, this.clertime28, this.clertime29,
      this.clertime31, this.clertime32, this.clertime33, this.clertime34, this.clertime35, this.clertime36, this.clertime37, this.clertime38, this.clertime39,
      this.clertime41, this.clertime42, this.clertime43, this.clertime44, this.clertime45, this.clertime46, this.clertime47, this.clertime48, this.clertime49,
      this.clertime50, this.clertime51, this.clertime52, this.clertime53, this.clertime54, this.clertime55, this.clertime56
    ]


    this.bonuspaytable = [
      { k: "Royal Flush", val: "1500 to 1" },
      { k: "Straight Flush", val: "800 to 1" },
      { k: "Four of a Kind", val: "250 to 1" },
      { k: "Full House", val: "150 to 1" },
      { k: "Flush", val: "100 to 1" },
      { k: "Straight", val: "50 to 1" },
      { k: "Three of a kind", val: "5 to 1" },
    ];
    this.chipVal = [
      { src: chip_10, key: 1, label: "10", val: 10 },
      { src: chip_25, key: 1, label: "20", val: 20 },
      { src: chip_50, key: 1, label: "50", val: 50 },
      { src: chip_100, key: 1, label: "100", val: 100 },
      { src: chip_500, key: 1, label: "500", val: 500 },
      // { src: chip_25, key: 1, val: 1000 },
      // { src: chip_25, key: 1, val: 5000 },
    ];
    this.updatePlayerInfo = this.updatePlayerInfo.bind(this);
    // this.handleOrientationChange = this.handleOrientationChange.bind(this)

  }
  clearallsettimeouts = () => {
    for (let i = 1; i <= this.clearTimingLeft.length; i++) {
      clearTimeout(this.clearTimingLeft[i]);
      console.log(this.clearTimingLeft[i]);
    }
  }
  componentDidMount() {
    setTimeout(()=>{
      // this.FoldCards()
    },5000)
    
    
    document.addEventListener("keydown", this.Russian_handleKeyboardEvent);
    this.setState({ buttontext: "clear", insurencebtn: true });

    console.log(this.props.rp_chipValues)
    this.setState({ buttontext: "clear", insurencebtn: true })
    if (this.props.rp_chipValues) {
      let newChips = this.props.rp_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt = "";
      for (let i = 0; i < newChips.slice(0,5).length; i++) {
        if (Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999) {
          lblTxt = "K"
        } else if (Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999) {
          lblTxt = "M"
        } else if (Number(newChips[i]) > 999999999) {
          lblTxt = "B"
        }
        this.chipVal[i].val = newChips[i]
        console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValuerussian(Number(this.chipVal[i].val), lblTxt, i);
      }

    }

    this.betchipsnd = new Audio(betchipsnd);
    this.betrussiansnd = new Audio(betrussiansnd);
    this.reaveldealer_cards = new Audio(reaveldealer_cards);

    this.selectCard = new Audio(selectCard);
    this.unselectCard = new Audio(unselectCard);
    this.playerWin = new Audio(playerWin);
    this.playerLose = new Audio(playerLose);

    const { childRef } = this.props;
    childRef(this);
    document.getElementById("russiangamecover").style.display = "none";
    // document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")
    document.getElementById("infrmicon").classList.add("addpointer")


    console.log(porttraitejson.pokertable.height);
    window.addEventListener("resize", this.handleResize);

    // window.addEventListener("resize", this.handleOrientationChange);

    this.handleOrientationChange();

    console.log("Width: " + window.screen.width);
    console.log("height: " + window.screen.height);
    const betchipsRussianpoker = document.getElementById("betchips");
    this.setState({ infoText: this.props.language.Place_antebet })
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
    document.getElementById("optionrussian").classList.add("addpointer")
      const buybutton = document.getElementById("buybtn");
      buybutton.classList.add("addpointer")

      document.getElementById("minmaxbets").classList.add("minmaxbets_windows");
      this.setState({
        activeScreen: 0
      })

      document.getElementById("container_playerdealer").classList.add("container_playerdealer");
      document.getElementById("Dealer").classList.add("Dealer")
      document.getElementById("Player").classList.add("Player")
      // document.getElementById("optionrussian").style.display = "none"

      document.getElementById("insurenceRussian").classList.add("insurencenodrop")
      document.getElementById("buydrawRussian").classList.add("insurencenodrop")
      document.getElementById("betRussian").classList.add("insurencenodrop")
      document.getElementById('volumeRussian').classList.add("addpointer")
      document.getElementById("drawtext").classList.add("drawtextwindows");
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");

      document.getElementById("betcontainer").classList.add("betpannelrussianwindows");
      document.getElementById("inforussian").classList.add("windowsinfo");



     
      // document.getElementById("anitecircleactive").classList.add("antecircleactivewindows")
      document.getElementById("betcircle").classList.add("addpointer");
      document.getElementById("betbtn").classList.add("addpointer");
      document.getElementById("Foldbtn").classList.add("addpointer");

      document.getElementById("bonusRussian").classList.add("bnspoiner");
      // document.getElementById("bonusRussian").classList.add("addpointer")

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("valtext_windows");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10", "chip_10text");

          
          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;
        default:
          break;
      }
      Object.assign(betchipsRussianpoker.style, porttraitejson.betchpwindows);


    } else {
      this.setState({
        activeScreen: 1
      })
      const confirmbuydealerElement = document.getElementById("confirmbuydealer_popup");
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
      const containerPlayerdealerElement =  document.getElementById("container_playerdealer");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const drawbutton = document.getElementById("drawbtn");
      drawbutton.classList.add("drwnodrop")
      const buybutton = document.getElementById("buybtn");
      // buybutton.classList.add("addpointer")


      containerPlayerdealerElement.className = "";
      containerPlayerdealerElement.style.cssText = "";




      drawbutton.src = "";
      drawbutton.style.cssText = "";
      buybutton.src = "";
      buybutton.style.cssText = "";
      betbutton.src = "";
      betbutton.style.cssText = "";
      foldbutton.src = "";
      foldbutton.style.cssText = "";

      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;
          
          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          break;

        default:
          break;

      }





      document.getElementById("val0").style.cssText = "";
      document.getElementById("inforussian").className = "";
      if (window.innerWidth < window.innerHeight) {

        document.getElementById("val0").classList.add("valtextPortrait", "valtextPortrait1");


        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverPortrait");
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("mobileAntiMaskCover").classList.add("mobileAntiMaskCover");
        document.getElementById("val0").classList.remove('valtext0');
        confirmbuydealerElement.classList.add('confirmbuy-dealerpopup');

        document.getElementById("inforussian").classList.add("portraitinfo");
        document.getElementById('volumeRussian').classList.add("addpointer")
        // betChipText.style.cssText = "";

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prt");
        document.getElementById("Player").style.fontSize = "80px";
       
      }else{

        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverLndsp");

        document.getElementById("val0").classList.add("valtextLandscape", "valtextPortrait1");
        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnsp");
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("mobileAntiMaskCover").classList.add("mobileLndspAntiMaskCover");

        document.getElementById("inforussian").classList.add("lndspinfo");


      }
    }
    this.showPreviousBetsStatus();
  }
  

    Russian_handleKeyboardEvent=(evt)=>{
    console.log(evt.code)
    switch(evt.code){
      case "Enter":
      case "NumpadEnter":
        console.log(this.state.betBtnValue)
        console.log(this.state.keyboardhandle)
        console.log(this.state.disBetBtn)
        if (this.state.betBtnValue == 0 && this.state.disBetBtn == 0 && this.state.keyboardhandle) {
        this.anteBet();
          
        }else if (this.state.betBtnValue== 1 && this.state.disBetBtn == 0 && this.state.keyboardhandle) {
          this.RusssianpokerBet();
        }
        else if(this.state.betbutton === "REBET"&& this.state.disBetBtn == 0 && this.state.keyboardhandle){
          this.anteclick(this.state.betbutton);

        }
        break;
        case "Digit2":
          console.log(this.state.betbutton)
          console.log(this.state.disBetBtn)
          if(this.state.buttontext === "REBETX2"&& this.state.disBetBtn == 0 && this.state.keyboardhandle){
            this.anteclick(this.state.buttontext);
  
          }
          break;   

      case "KeyC": 
      // console.log(this.state.disClearBtn)     
        if(this.state.disClearBtn == 0 && this.state.keyboardhandle){
          this.clearbets();
          
         
        }
        break;   
        case "KeyD": 
        // console.log(this.state.disClearBtn)     
          if(this.state.disDrawbtn == 0 && this.state.keyboardhandle) {
            this.drawbuybets();
           
          }
          break;   
          case "KeyB": 
          // console.log(this.state.disClearBtn)     
            if(this.state.disBuybtn == 0 && this.state.keyboardhandle){
              this.drawbuybets();
             
            }
            break;   
            case "KeyF": 
              if(this.state.Foldbtn == 0){
                this.FoldCards();
               
              }
          break;   
          case "KeyY":  
          console.log(this.state.betbutton +""+this.state.keyboardhandle)
            if(this.state.Yesbtn == 0 && this.state.keyboardhandle){
              this.buyDealerCard(this.state.betbutton);
             
            }
            else if(this.state.betbutton=="YES" && this.state.keyboardhandle){
              this.Confirmbuydealercard(true);

            }
            else if(this.state.buttontext=="insurence"){
              this.InsurenceClaim(this.state.buttontext);

            }
             
        break;   
        case "KeyN": 
          if(this.state.Nobtn == 0 && this.state.keyboardhandle){
            this.buyDealerCard(this.state.buttontext);
           
          }
          else if(this.state.betbutton=="NO" && this.state.keyboardhandle){
            this.Confirmbuydealercard(false);

          }
          else if( this.state.betbutton=="noinsurence"){
            this.InsurenceClaim(this.state.betbutton);

          }
      break;   
        default:
          break;   
    }


  }
  
  
  checkChipValuerussian(chipValue, chpTxt, i) {

    if (chipValue >= 1000) {
      chipValue = chipValue / 1000;
      this.checkChipValuerussian(chipValue, chpTxt, i)
    } else {
      console.log(chpTxt);
      this.showResultChipValuerussian(chipValue, chpTxt, i);
      return chipValue
    }
  }
  showResultChipValuerussian(chVal, chipTxt, index) {
    console.log(chVal);
    console.log(chVal + "" + chipTxt)
    this.chipVal[index].label = chVal + "" + chipTxt;
    console.log(this.chipVal)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.Russian_handleKeyboardEvent);
    window.removeEventListener("resize", this.handleResize);
    this.betchipsnd.pause();
    this.betrussiansnd.pause();
    this.reaveldealer_cards.pause();

    this.selectCard.pause();
    this.unselectCard.pause();
    this.playerWin.pause();
    this.playerLose.pause();
  }
 

  clearActivesWeDid = () => {

    this.setState({
      // bsbtn: true,
      pokerranking:"",
      pokerrankingdeler:"",
    });

    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];

    document.getElementById('rankinginfodeler').className = "";
    document.getElementById('rankinginfo').className = "";
    document.getElementById('rankinginfodeler').textContent = "";
    document.getElementById('rankinginfo').textContent = "";

    this.cardsRef.current.ReBet();
    this.dealerCardsContainer.current.ReBet();

    this.betchipactivated = false;
    this.buydrawchipactivated = false;
    this.ConfirmbuydealercardactivePopup = false;
    this.resetPyrWnCpsAmn = false;


  }

  handleResize() {
    console.log("calling resize");
    // document.getElementById("bacaratbgim");
    this.handleOrientationChange();
  }
 
  handleOrientationChange = () => {
    
    // if (this.state.isactiveoptionsmenu) {
    //   this.Showoptionsmenu();

    // }
    // if(this.state.ShowLimits){
    //   this.ShowLimits();
    // }
    // if (this.state.ishistorytable) {
    //   // document.getElementById("prevhistory")
    //   this.ShowHistory();

    // }

    if(document.getElementById("optionsmenubutton")){
      gsap.to('#optionsmenubutton',{autoAlpha:0})
    }
    this.setState({ isoptionmenu: false,ShowLimits:false });

    if (document.getElementById("histry_russian")) {
      document.getElementById("histry_russian").className = "";

    }
    if (document.getElementById("exithistory")) {
      document.getElementById("exithistory").className = "";

    }
    if (document.getElementById("prevhistory")) {
      document.getElementById("prevhistory").className = "";

    }
    if (document.getElementById("histry_body")) {
      document.getElementById("histry_body").className = "";
    }
    if (document.getElementById("heading_hstiry")) {
      document.getElementById("heading_hstiry").className ="";

    }

    
 


    if(this.state.isbonuspattable){
      this.ShowBonuspayouttable();


    }
    console.log(window.innerHeight);
    console.log(window.innerWidth);
    const isPortrait = window.innerHeight > window.innerWidth;
    if (isPortrait) {
      console.log(" -----------  PORT     -------------------");
      this.setState({
        myOrient: "port",
      });
    } else {
      console.log(" -----------  LAND     -------------------");
      this.setState({
        myOrient: "land",
      });
    }


    const betactiveElement = document.getElementById("betactive");
    const bacaratbgim = document.getElementById("bacaratbgim");
    const pokertable = document.getElementById("pokertableim");
    const pokertablelnspe = document.getElementById("pokertablelandscape");
    const useriddetl = document.getElementById("userid");
    const russiantiname = document.getElementById("russiantitle");
    const russianbncicon = document.getElementById("balnceicon");
    const exitbtn = document.getElementById("exitbtn");
    const beticonrussian = document.getElementById("beticon");
    const winnericonrussian = document.getElementById("winnericon");
    const informi = document.getElementById("infrmicon");
    const optionrussianbtn = document.getElementById("optionrussian");
    const volumeRussianimg = document.getElementById("volumeRussian");
    const bonusRussiangme = document.getElementById("bonusRussian");
    const insurenceRussiangme = document.getElementById("insurenceRussian");
    const buydrawRussiangme = document.getElementById("buydrawRussian");
    const betRussiangme = document.getElementById("betRussian");
    const betcirclerussian = document.getElementById("betcircle");
    const betchipincrementbtn = document.getElementById("betincrenet");
    const betchipdecrementbtn = document.getElementById("betdecrement");
    const betchipsRussianpoker = document.getElementById("betchips");
    const pokerrankingtwo = document.getElementById("Straightflush");
    const pokerrankingone = document.getElementById("royalflush");
    const pokerrankingthree = document.getElementById("frofkind");
    const pokerrankingfour = document.getElementById("fullhse");
    const pokerrankingfive = document.getElementById("flush");
    const pokerrankingsix = document.getElementById("stright");
    const pokerrankingseven = document.getElementById("Threekind");
    const pokerrankingeight = document.getElementById("twopair");
    const pokerrankingnine = document.getElementById("onepair");
    const pokerrankingten = document.getElementById("aceking");
    const insurencepays = document.getElementById("insurencepays");
    const Russianbgwindowsimg = document.getElementById("Russianbgwindows");
    const Russiantblewindows = document.getElementById("rsntblewindows");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const drwtext = document.getElementById("drawtext");
    const buybtntext = document.getElementById("buytext");
    const betbtntext = document.getElementById("betbtntext");
    const foldbtntext = document.getElementById("foldtext");
    const dealerBuyCardElement = document.getElementById("dealerBuyCard");
    const dealerChipsAddToBuyElement = document.getElementById("dealerChipsAddToBuyButton");

    const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
    const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
    
    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const playerDetailsElement = document.getElementById("playerDetails");
    const containerPlayerdealerElement =  document.getElementById("container_playerdealer");
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');
    const minmaxbetsElement = document.getElementById("minmaxbets")

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
  


    containerPlayerdealerElement.className = "";
    playerDetailsElement.className = "";
    minmaxbetsElement.className = "";
    


    bacaratbgim.style.cssText = "";
   
    optionrussianbtn.src = "";
    optionrussianbtn.style.cssText = "";
   

    bonusRussiangme.src = "";
    bonusRussiangme.style.cssText="";
    

    betcirclerussian.src = "";
    betcirclerussian.style.cssText="";
   
    russianbncicon.src = "";
    russianbncicon.style.cssText="";
    
    beticonrussian.src = "";
    beticonrussian.style.cssText="";
    
    exitbtn.src = "";
    exitbtn.style.cssText="";
   

    winnericonrussian.src = "";
    winnericonrussian.style.cssText="";
  
    informi.src = "";
    informi.style.cssText="";
    

    volumeRussianimg.src = "";
    volumeRussianimg.style.cssText="";
  
    betchipincrementbtn.src = "";
    betchipincrementbtn.style.cssText="";
   
    betchipsRussianpoker.src = "";
    betchipsRussianpoker.style.cssText="";
   
    betchipdecrementbtn.src = "";
    betchipdecrementbtn.style.cssText="";
    

    useriddetl.src = "";
    useriddetl.style.cssText="";
    // betactiveElement.className = ""
   
    dealerBuyCardElement.className = "";
    dealerChipsAddToBuyElement.className = "";
    winpopuprspelement.className = "";
    if(chipsstoredcontainerElement !== null){
    chipsstoredcontainerElement.className = "";
    }
   

    if (bonusInfoTheadElement && bonusInfoTbodyElement) {
      bonusInfoTheadElement.className = "";
      bonusInfoTbodyElement.className = "";

    }





    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
     
      this.setState({
        heightOfLayer: 600,
        widthOfLayer: 2350,
        // dealerDistanceFromX: 250,
        // playerDistanceFromX: 1020,    
        dealerDistanceFromX: 225,
        playerDistanceFromX: 1060,
        dealerDistanceFromY: 0,
        PlayerDistanceFromY: 18,
        isMobilePortraitLndsp: false,
        scaleX: 1.8,
        scaleY: 1.8,
        activeScreen: 0
      });

      this.isMobile = false;
      this.systemOS = "Windows";
      document.getElementById("topcardscontainer").classList.remove("topcards");
      document.getElementById("betcontainer").classList.remove("betpannelrussian");
      document.getElementById("betcontainer").classList.add("betpannelrussianwindows");
      playerDetailsElement.classList.add("playerIdDetailswindows");

      containerPlayerdealerElement.classList.add("container_playerdealer");


      if (russiantiname) {
        russiantiname.src = russiantitle;
        russiantiname.style.cssText="";
      
      }



      drwtext.classList.remove("drawtextportraite");
      buybtntext.classList.remove("buytextportraite");
      betbtntext.classList.remove("betportraite");
      foldbtntext.classList.remove("foldtextportraite");

      drwtext.classList.add("drawtextwindows");
      buybtntext.classList.add("buytextwindows");
      betbtntext.classList.add("betwindows");
      foldbtntext.classList.add("foldtextwindows");
      document.getElementById("antetext").classList.remove("antetextlnspe,antetextportraite");
      // document.getElementById("antetext").classList.remove("antetextportraite");
      document.getElementById("antetext").classList.add("antetextwindows");
      drwtext.classList.remove("drawtextlandspe");
      buybtntext.classList.remove("buytextlndspe");
      betbtntext.classList.remove("betLandscape");
      foldbtntext.classList.remove("foldtextLandscape"); 

      Russianbgwindowsimg.style.background = `url(${Russianbgwindows})`;
      //  alert(".............."+ Russianbgwindowsimg.style.background)

    Object.assign(Russianbgwindowsimg.style,porttraitejson.russianbgwindows)



      Russiantblewindows.src = Rutablewindows;
      Object.assign(Russiantblewindows.style,porttraitejson.russtablewindows)


      optionrussianbtn.src = optionbtnwindows;
      Object.assign(optionrussianbtn.style,porttraitejson.optionbtnwindows)
     
      russianbncicon.src = balanceiconwindows;
      Object.assign(russianbncicon.style,porttraitejson.balanceiconwindows)
    
      document.getElementById("balance").classList.remove("balanceportraite,balancelndscape");
      document.getElementById("balance").classList.add("balancewindows");

      useriddetl.src = useridlmg;
      Object.assign(useriddetl.style,porttraitejson.useridwindows)
  
      

      document.getElementById("useridtext").classList.remove("useridportraite,useridlandscape");
      document.getElementById("useridtext").classList.add("useridwindows");

      beticonrussian.src = beticonwindows;
  
      Object.assign(beticonrussian.style, porttraitejson.beticonwindows);
      document.getElementById("bets").classList.remove("betslandspe,betsportraite");
      document.getElementById("bets").classList.add("betswindows");

   

      if (russiantiname) {
        russiantiname.src = russiantitle;
        Object.assign(russiantiname.style, porttraitejson.russiantitlewindows);
       
      }

      winnericonrussian.src = winnericnwindows;
      Object.assign(winnericonrussian.style, porttraitejson.winnericonwindows);
     
      document.getElementById("wins").classList.remove("winslandspe,winsportraite");
      document.getElementById("wins").classList.add("winswindows");

      informi.src = informicon;
      Object.assign(informi.style, porttraitejson.infrmicnlnspewindows);
     
      volumeRussianimg.src = volumeimg;
      Object.assign(volumeRussianimg.style, porttraitejson.volumewindows);
   

      betchipincrementbtn.src = betincerementdecrement;
      Object.assign(betchipincrementbtn.style, porttraitejson.beincrementwindows);
   

      betchipdecrementbtn.src = betincerementdecrement;
      Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementwindows);
    
      betcirclerussian.src = russianbetcle;
      Object.assign(betcirclerussian.style, porttraitejson.becirclewindows);
    

      bonusRussiangme.src = russianbetbtright;
      Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnwindows);
   
      document.getElementById("bonustext").classList.remove("bonustextportraite,bonustextlndspe");
      document.getElementById("bonustext").classList.add("bonustext");

      insurenceRussiangme.src = russianbetbtnup;
      Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtnwindows);
      document.getElementById("insurencetext").classList.remove("insurencetextportraite,insurencetextlndspe");
      document.getElementById("insurencetext").classList.add("insurencetext");

      buydrawRussiangme.src = russianbetbtnleft;
      Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnwindows);
  
      document.getElementById("buydrawtext").classList.add("buydrawtext");

      betRussiangme.src = russianbetbtndown;
      Object.assign(betRussiangme.style, porttraitejson.betbtnwindows);
     
      document.getElementById("bettext").classList.remove("bettextlndspe,bettextportraite");
      document.getElementById("bettext").classList.add("bettext");

      pokerrankingone.classList.remove("royalflshportraite");
      document.getElementById("royalto");

      pokerrankingtwo.classList.remove("strightportraite");
      document.getElementById("strghtto").classList.remove("royalflshportraiteto");

      pokerrankingthree.classList.remove("fourofkindportraite");
      document.getElementById("frkndto").classList.remove("royalflshportraiteto");

      pokerrankingfour.classList.remove("fullhouseportraite");
      document.getElementById("fullhseto").classList.remove("royalflshportraiteto");

      pokerrankingfive.classList.remove("flushportraite");
      document
        .getElementById("flushto")
        .classList.remove("royalflshportraiteto");

      pokerrankingsix.classList.remove("stright");
      document
        .getElementById("strightto")
        .classList.remove("royalflshportraiteto");

      pokerrankingseven.classList.remove("threekindportraite");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairportraite");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairportraite");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekingportraite");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepaysportraite");
      document
        .getElementById("insurencepaysto")
        .classList.remove("insurenceratio");

      pokerrankingone.classList.remove("royalflshlndspe");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushlndspe");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindlndspe");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouselndspe");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flushlndspe");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightlndspe");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekindlndspe");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairlndspe");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairlndspe");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekinglndspe");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepayslndspe");
      document
        .getElementById("insurencepaysto")
        .classList.remove("insurenceratio");

      pokerrankingone.classList.add("royalflshwindows");
      document.getElementById("royalto").classList.add("royaltocolor");

      pokerrankingtwo.classList.add("strightflushwindows");
      document.getElementById("strghtto").classList.add("royaltocolor");

      pokerrankingthree.classList.add("fourofkindwindows");
      document.getElementById("frkndto").classList.add("royaltocolor");

      pokerrankingfour.classList.add("fullhouse");
      document.getElementById("fullhseto").classList.add("royaltocolor");

      pokerrankingfive.classList.add("flush");
      document.getElementById("flushto").classList.add("royaltocolor");

      pokerrankingsix.classList.add("strightwindows");
      document.getElementById("strightto").classList.add("royaltocolor");

      pokerrankingseven.classList.add("threekind");
      document.getElementById("Threekindto").classList.add("royaltocolor");

      pokerrankingeight.classList.add("twopair");
      document.getElementById("twopairto").classList.add("royaltocolor");

      pokerrankingnine.classList.add("onepair");
      document.getElementById("onepairto").classList.add("royaltocolor");

      pokerrankingten.classList.add("aceking");
      document.getElementById("acekingto").classList.add("royaltocolor");

      insurencepays.classList.add("insurencepays");
      document
        .getElementById("insurencepaysto").classList.add("insurenceratio");
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-portrait");
    } else {
      document.getElementById("inforussian").className = "";
      pokerrankingone.classList.remove("royalflshwindows");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushwindows");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindwindows");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouse");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flush");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightwindows");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekind");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopair");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepair");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("aceking");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepays");
      document.getElementById("insurencepaysto").classList.remove("insurenceratio");

      pokerrankingone.classList.remove("royalflshlndspe");
      document.getElementById("royalto").classList.remove("royaltocolor");

      pokerrankingtwo.classList.remove("strightflushlndspe");
      document.getElementById("strghtto").classList.remove("royaltocolor");

      pokerrankingthree.classList.remove("fourofkindlndspe");
      document.getElementById("frkndto").classList.remove("royaltocolor");

      pokerrankingfour.classList.remove("fullhouselndspe");
      document.getElementById("fullhseto").classList.remove("royaltocolor");

      pokerrankingfive.classList.remove("flushlndspe");
      document.getElementById("flushto").classList.remove("royaltocolor");

      pokerrankingsix.classList.remove("strightlndspe");
      document.getElementById("strightto").classList.remove("royaltocolor");

      pokerrankingseven.classList.remove("threekindlndspe");
      document.getElementById("Threekindto").classList.remove("royaltocolor");

      pokerrankingeight.classList.remove("twopairlndspe");
      document.getElementById("twopairto").classList.remove("royaltocolor");

      pokerrankingnine.classList.remove("onepairlndspe");
      document.getElementById("onepairto").classList.remove("royaltocolor");

      pokerrankingten.classList.remove("acekinglndspe");
      document.getElementById("acekingto").classList.remove("royaltocolor");

      insurencepays.classList.remove("insurencepayslndspe");
      document.getElementById("insurencepaysto").classList.remove("insurenceratio");
      document.getElementById("crdscover").className = "";
      document.getElementById("crdscover").cssText = "";

      pokerrankingone.className = "";
      pokerrankingtwo.className = "";
      pokerrankingthree.className = "";
      pokerrankingfour.className = "";
      pokerrankingfive.className = "";
      pokerrankingsix.className = "";
      pokerrankingseven.className = "";
      pokerrankingeight.className = "";
      pokerrankingnine.className = "";
      pokerrankingten.className = "";
      insurencepays.className = "";
      popupbetGreaterthanBalnceElement.className = "";

      document.getElementById("royalto").classList.add("royalflshportraiteto");
      document.getElementById("strghtto").classList.add("royalflshportraiteto");
      document.getElementById("frkndto").classList.add("royalflshportraiteto");
      document.getElementById("fullhseto").classList.add("royalflshportraiteto");
      document.getElementById("flushto").classList.add("royalflshportraiteto");
      document.getElementById("strightto").classList.add("royalflshportraiteto");
      document.getElementById("Threekindto").classList.add("royaltocolor");
      document.getElementById("twopairto").classList.add("royaltocolor");
      document.getElementById("onepairto").classList.add("royaltocolor");
      document.getElementById("acekingto").classList.add("royaltocolor");
      document.getElementById("insurencepaysto").classList.add("insurenceratio");

      console.log(isPortrait);
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-portrait");
      document.getElementById("playerDealerCardContainer").classList.remove("stage-top-mobile-landscape");
      let betcontainerElement = document.getElementById("betcontainer");

      Russianbgwindowsimg.style.cssText = "";



      useriddetl.src = "";
      useriddetl.style.cssText = "";


      Russiantblewindows.src = "";
      Russiantblewindows.style.cssText = "";

      bacaratbgim.style.cssText = "";


      optionrussianbtn.src = "";
      optionrussianbtn.style.cssText = "";


      russianbncicon.src = "";
      russianbncicon.style.cssText = "";

      beticonrussian.src = "";
      beticonrussian.style.cssText = "";


      exitbtn.src = "";
      exitbtn.style.cssText = "";

      russiantiname.className = "";
      if (russiantiname) {
        russiantiname.src = russiantitle;
        russiantiname.style.cssText = "";

      }


      winnericonrussian.src = "";
      winnericonrussian.style.cssText = "";


      informi.src = "";
      informi.style.cssText = "";




      volumeRussianimg.src = "";
      volumeRussianimg.style.cssText = "";




      betchipsRussianpoker.src = "";
      betchipsRussianpoker.style.cssText = "";




      betchipincrementbtn.src = "";
      betchipincrementbtn.style.cssText = "";



      betchipdecrementbtn.src = "";
      betchipdecrementbtn.style.cssText = "";




      let chipsElementText = document.getElementById("val0");
      betcontainerElement.style.cssText = "";
      chipsElementText.className = "";
      let chipsElmtextclass = "";

      const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");


      if(this.playerWinBonus === true){
        if(isPortrait){
          if(this.state.bonusbetamount){
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.00001, opacity: 1
                
              });
            }
  
          }

        }else{

          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
            
              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.00001, autoAlpha: 1,
              });
           
            }
  
          }

        }
      }else{
        this.playerWinBonus = false
      }
      


      if(this.betchipactivated === true){
        if(isPortrait){
          gsap.to(this.chipbetref.current, {
            x: -27,
            y: -925,
            duration:0.000001
          });
  
  
          gsap.to(betTextaddtoBetElement, {
            x: -3,
            y: -1710,
           duration:0.000001
          });

        }else{
          gsap.to(this.chipbetref.current, {
            x: -1060,
            y: 170,
            duration:0.000001
          });
  
  
          gsap.to(betTextaddtoBetElement, {
            x: -1060,
            y: 170,
            duration:0.000001
           
          });


        }

      }else{
        this.betchipactivated = false;
      }


      if(this.buydrawchipactivated === true){
        if(isPortrait){
          gsap.to(this.chipdrawref.current, {
            x: 322,
            y: -1230,
            duration: 0.000001,
          });
        }else{
          gsap.to(this.chipdrawref.current, {
            x: -1230,
            y: 0,
            duration: 0.000001,
          });
        }
      }else{
        this.buydrawchipactivated = false
      }

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      if(this.ConfirmbuydealercardactivePopup === true){
        confirmbuydealerPopupElement.display = "block"
        if(isPortrait){
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
        }else{
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
        }

      }else{
        this.ConfirmbuydealercardactivePopup = false;
      }


      switch (this.state.count) {
        case 1:
          betchipsRussianpoker.src = chip_10;
          chipsElementText.textContent = this.chipVal[0].label;
          chipsElmtextclass = "valtextPortrait1";

          break;
        case 2:
          betchipsRussianpoker.src = chip_25;
          chipsElementText.textContent = this.chipVal[1].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 3:
          betchipsRussianpoker.src = chip_50;
          chipsElementText.textContent = this.chipVal[2].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 4:
          betchipsRussianpoker.src = chip_100;
          chipsElementText.textContent = this.chipVal[3].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;
        case 5:
          betchipsRussianpoker.src = chip_500;
          chipsElementText.textContent = this.chipVal[4].label;
          chipsElmtextclass = "bet-text-addtoBet20";
          break;

        default:
          break;

      }


      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      const rankinginfoElement = document.getElementById("rankinginfo");
      rankinginfodelerElement.className = "";
      rankinginfoElement.className = "";
      rankinginfoElement.style.cssText  = "";


      if (bonusInfoTheadElement && bonusInfoTbodyElement) {
        Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
        Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
      }
      betbtntext.className = "";
      foldbtntext.className = "";



      winpopuprspelement.classList.add("winPopupForMptIntl");
      if (isPortrait) {
        if(this.state.betshigh_balnce !==null ){
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait")
        }
       
        document.getElementById("inforussian").classList.add("portraitinfo");
        minmaxbetsElement.classList.add("minmaxbets_portrait");

        drwtext.classList.remove("drawtextlandspe");
        buybtntext.classList.remove("buytextlndspe");


        drwtext.classList.remove("drawtextwindows");
        buybtntext.classList.remove("buytextwindows");




        // document.getElementById("antetext").classList.remove("antetextwindows");
        document.getElementById("antetext").classList.remove("antetextlnspe,antetextwindows");


        containerPlayerdealerElement.classList.add("cntr_pyrdlr_prt");
        document.getElementById("Player").style.fontSize = "80px"
        pokertablelnspe.src = "";
        pokertablelnspe.style.cssText = "";

        playerDetailsElement.classList.add("playerIdDetailsPortrait");
        document.getElementById("playerDealerCardContainer").classList.add("stage-top-mobile-portrait");
        document.getElementById("topcardscontainer").classList.add("topcards");
        pokerrankingone.classList.add("royalflshportraite");
        pokerrankingtwo.classList.add("strightportraite");
        pokerrankingthree.classList.add("fourofkindportraite");
        pokerrankingfour.classList.add("fullhouseportraite");
        pokerrankingfive.classList.add("flushportraite");
        pokerrankingsix.classList.add("stright");
        pokerrankingseven.classList.add("threekindportraite");
        pokerrankingeight.classList.add("twopairportraite");
        pokerrankingnine.classList.add("onepairportraite");
        pokerrankingten.classList.add("acekingportraite");
        insurencepays.classList.add("insurencepaysportraite");
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianPortrait);

        drwtext.classList.add("drawtextportraite");
        buybtntext.classList.add("buytextportraite");
        betbtntext.classList.add("betportraite");
        foldbtntext.classList.add("foldtextportraite");
        document.getElementById("antetext").className = "";
        document.getElementById("antetext").classList.add("antetextportraite");
        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextPortrait", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextPortrait");
        }

        bacaratbgim.style.background = `url(${russianbg})`;
        bacaratbgim.style.backgroundSize = "cover";
        bacaratbgim.style.width = porttraitejson.bacaratbg.width;
        bacaratbgim.style.height = porttraitejson.bacaratbg.height;
        bacaratbgim.style.backgroundSize = porttraitejson.bacaratbg.background;
        bacaratbgim.style.left = 0;




        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchp);


        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.beincrementportraite);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrement)


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volume)

        bonusRussiangme.src = russianbetbtright;

        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtn);


        document.getElementById("bonustext").className = "";
        document.getElementById("bonustext").classList.add("bonustextportraite");

        insurenceRussiangme.src = russianbetbtnup;
        Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtn);

        document.getElementById("insurencetext").className = "";
        document.getElementById("insurencetext").classList.add("insurencetextportraite");

        buydrawRussiangme.src = russianbetbtnleft;
        buydrawRussiangme.style.position = porttraitejson.drawbuybtn.position;
        buydrawRussiangme.style.width = porttraitejson.drawbuybtn.width;
        buydrawRussiangme.style.height = porttraitejson.drawbuybtn.height;
        buydrawRussiangme.style.top = porttraitejson.drawbuybtn.top;
        buydrawRussiangme.style.left = porttraitejson.drawbuybtn.left;
        document.getElementById("buydrawtext").className = "";

        document.getElementById("buydrawtext").classList.add("buydrawtextportraite");

        betRussiangme.src = russianbetbtndown;

        Object.assign(betRussiangme.style, porttraitejson.betbtn);


        document.getElementById("bettext").className = "";

        document.getElementById("bettext").classList.add("bettextportraite");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becircle);

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtn);

        dealerBuyCardElement.classList.add('dealerBuyCard');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortrait');


        if (pokertable) {
          pokertable.src = pokertableruss;
          pokertable.style.backgroundSize = "cover";

          Object.assign(pokertable.style, porttraitejson.pokertable);

        }

        russianbncicon.src = balanceicon;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconportraite);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balanceportraite");
        // document.getElementById("balance").classList.remove("balancelndscape");

        // beticonrussian.src = beticon;
        beticonrussian.src = beticonwindows;

        Object.assign(beticonrussian.style, porttraitejson.beticon);


        document.getElementById("bets").className = "";
        // document.getElementById("bets").classList.remove("betslandspe");
        document.getElementById("bets").classList.add("betsportraite");

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, porttraitejson.exit);



        if (russiantiname) {
          russiantiname.src = russiantitle;

          Object.assign(russiantiname.style, porttraitejson.russiantitle);


        }

        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericon);



        document.getElementById("wins").className = "";
        document.getElementById("wins").classList.add("winsportraite");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicn);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridportraite);

        document.getElementById("useridtext").className = "";
        document.getElementById("useridtext").classList.add("useridportraite");



        
  
       



        
        if(this.state.pokerrankingdeler !== ""){
          document.getElementById('rankinginfodeler').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerPortrait")
        }else{
          document.getElementById('rankinginfodeler').className = "";
          
        }

        if(this.state.pokerranking !== ""){
          rankinginfoElement.classList.add("pokerrankingPortait")
        }else{
          document.getElementById('rankinginfo').className = "";
        }
      
        
        if(((this.props.gameState?.response?.action === "BUY_PLAYER_CARD"||
          this.props.gameState?.response?.playerFinalCards.length === 6)&&
          this.buyCardUnsequenceArray.length ===6)
        ){
              rankinginfoElement.style.left = "32.5%";
          }else{
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "34%";
            }

        }


        this.setState({
         
          heightOfLayer: 1500,
          widthOfLayer: 600,
          dealerDistanceFromX: -280,
          playerDistanceFromX: -150,


           // heightOfLayer: 1600,
          // widthOfLayer: 900,
          // dealerDistanceFromX: -130,
          // playerDistanceFromX: 2,
          dealerDistanceFromY: -135,
          PlayerDistanceFromY: 930,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,
        

          bnsactvglwcss: "bnsactivePortrait",
          betactvglwcss: "betactivePortrait",
          betbuttoncss: porttraitejson.Betbtnportraite,
          betbtntextcss: "betportrait",
          foldbuttoncss: porttraitejson.foldbtnportraite,
          foldbtntextcss: "rebettextportrait",
          buybtncss: porttraitejson.Buybtnportraite,
          buybtntxtcss: "buytextPortrait",
          drawbtncss: porttraitejson.Drawbtnportraite,
          drawbtntxtcss: "drawtextPortrait",
          betchipstoantebetcss: porttraitejson.Betchiptoanteprotrait,
          betchipstobonusbetcss: porttraitejson.betchipstobnsPortrait,
          chipsstoredcontainercss: "",
          antebetpdvcss: "chip-center-position",
          antechpimgcss: "chip20centerpositionPortrait",
          antespntxtcss: "antebetChipPortrait20",
          bonusdivcss:"bonusChipsContainerForPortrait",
          bonusimgcss:"bonusChipsForPortrait",
          bonuspntxtcss:"bonusChipsTextForPortrait",
          betchiptobetbtncss:porttraitejson.BetchiptobetPortrait,
          betbtntxtcss:`bet-text-addtoBet-portrait ${chipsElmtextclass}`,
          buydrawcontainer:"drawChipDiv",
          drawchipcss:"betchipsaddtodraw",
          drawchiptextcss:`betTextaddtodraw ${chipsElmtextclass}` ,
          insurancedivcss:"",
          insurancechipcss: "chiptoinsurencePortrait",
          insurancetxtcss:"insuranceTextPortrait",
          dealerhandcss:"pokerrankingdelerPortrait",
          playerhandcss:"pokerrankingPortait",
          mobileAntiMaskCovercss:"mobileAntiMaskCover",
          betpannelrussiancovercss:"PlayerCardpannelcoverPortrait",
          insurenceactivecss:"insurenceactivePortrait",
          rankinginfodelercss:"pokerrankingdelerPortrait",
          inforussiancss:"portraitinfo"
           
         

        })
                
      } else {
        document.getElementById("playerDealerCardContainer").classList.add("stage-top-mobile-landscape");
        this.setState({

          heightOfLayer: 800,
          widthOfLayer: 1300,
          dealerDistanceFromX: -385,
          playerDistanceFromX: 615,
          dealerDistanceFromY: 200,
          PlayerDistanceFromY: 220,

          // heightOfLayer: 900,
          // widthOfLayer: 1600,
          // dealerDistanceFromX: -300,
          // playerDistanceFromX: 850,
          // dealerDistanceFromY: 260,
          // PlayerDistanceFromY: 280,
          isMobilePortraitLndsp: true,
          scaleX: 2.8,
          scaleY: 2.8,
          activeScreen: 1,

          bnsactvglwcss: "bnsactiveLandscape",
          betactvglwcss: "betactiveLndsp",
          betbuttoncss: porttraitejson.Betbtnlndspe,
          betbtntextcss: "betLandscape",
          foldbuttoncss: porttraitejson.foldbtnlndspe,
          foldbtntextcss: "foldtextLandscape",
          buybtncss: porttraitejson.Buybtnlndspe,
          buybtntxtcss: "buytextLndsp",
          drawbtncss: porttraitejson.Drawbtnlndspe,
          drawbtntxtcss: "drawtextLndsp",
          betchipstoantebetcss: porttraitejson.betchiplndspe,
          betchipstobonusbetcss: porttraitejson.betchipstobnsLndsp,
          chipsstoredcontainercss: "chipsstoredcontainerLndsp",
          antebetpdvcss: "chip-center-position-lndsp",
          antechpimgcss: "chip20centerpositionPortrait",
          antespntxtcss: "antebetChipPortrait20",
          bonusdivcss:"bonusChipsCntrFrLndsp",
          bonusimgcss:"bonusChipsForPortrait",
          bonuspntxtcss:"bonusChipsTextfrLndsp",
          betchiptobetbtncss:porttraitejson.BetchiptobetLndsp,
          betbtntxtcss:`bet-text-addtoBet-lndsp ${chipsElmtextclass}`,
          buydrawcontainer:"drawChipDivLndsp",
          drawchipcss:"betchipsaddtodraw",
          drawchiptextcss:`betTextaddwLndsp ${chipsElmtextclass}`,
          insurancedivcss:"chip-to-insurence-lndsp",
          insurancechipcss: "chiptoinsurenceLndSpe",
          insurancetxtcss:"insuranceTextLndspe",
          dealerhandcss:"pokerrankingdelerLndSpe",
          playerhandcss:"pokerrankingPlayerLndsp",
          mobileAntiMaskCovercss:"mobileLndspAntiMaskCover",
          betpannelrussiancovercss:"PlayerCardpannelcoverLndsp",
          insurenceactivecss:"insurenceactiveLndsp",
          rankinginfodelercss:"pokerrankingdelerLndSpe",
          inforussiancss:"lndspinfo"
  

        });

        if(this.state.pokerrankingdeler !== ""){
          document.getElementById('rankinginfodeler').className = "";
          rankinginfodelerElement.classList.add("pokerrankingdelerLndSpe")
        }else{
          document.getElementById('rankinginfodeler').className = "";
        }


        if(this.state.pokerranking !== ""){
          document.getElementById('rankinginfo').className = "";
          rankinginfoElement.classList.add("pokerrankingPlayerLndsp")
        }else{
          document.getElementById('rankinginfo').className = "";
        }

        if((this.props.gameState?.response?.action === "BUY_PLAYER_CARD"||
          this.props.gameState?.response?.playerFinalCards.length === 6)&&
          this.buyCardUnsequenceArray.length ===6
        ){
              rankinginfoElement.style.left = "61.5%";
          }else{
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "62.5%";
            }

        }
        if(this.state.betshigh_balnce !==null ){
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp")
        }
        document.getElementById("inforussian").classList.add("lndspinfo");
        minmaxbetsElement.classList.add("minmaxbets_lndsp");

        containerPlayerdealerElement.classList.add("cntr_pyrdlr_lnsp");
        if (chipsstoredcontainerElement) {
          chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp");
        }
        document.getElementById("Player").style.fontSize = "80px";

        document.getElementById("inforussian").classList.add("lndspinfo");


        document.getElementById("topcardscontainer").classList.remove("topcards");
        document.getElementById("betcontainer").classList.remove("betpannelrussian");

        if (document.getElementById("val0") && chipsElmtextclass !== "") {
          document.getElementById("val0").classList.add("valtextLandscape", chipsElmtextclass);
        } else {
          document.getElementById("val0").classList.add("valtextLandscape");
        }
        Object.assign(betcontainerElement.style, porttraitejson.betpannelrussianIandscape);
        
        drwtext.classList.remove("drawtextportraite");
        buybtntext.classList.remove("buytextportraite");

        drwtext.classList.remove("drawtextwindows");
        buybtntext.classList.remove("buytextwindows");
        // foldbtntext.className = "";
        // betbtntext.className = "";

        drwtext.classList.add("drawtextlandspe");
        buybtntext.classList.add("buytextlndspe");
        betbtntext.classList.add("betLandscape");
        foldbtntext.classList.add("foldtextLandscape"); 

        pokerrankingone.classList.add("royalflshlndspe");
        document.getElementById("royalto").classList.add("royaltocolor");

        pokerrankingtwo.classList.add("strightflushlndspe");
        document.getElementById("strghtto").classList.add("royaltocolor");

        pokerrankingthree.classList.add("fourofkindlndspe");
        document.getElementById("frkndto").classList.add("royaltocolor");

        pokerrankingfour.classList.add("fullhouselndspe");
        document.getElementById("fullhseto").classList.add("royaltocolor");

        pokerrankingfive.classList.add("flushlndspe");
        document.getElementById("flushto").classList.add("royaltocolor");

        pokerrankingsix.classList.add("strightlndspe");
        document.getElementById("strightto").classList.add("royaltocolor");

        pokerrankingseven.classList.add("threekindlndspe");
        document.getElementById("Threekindto").classList.add("royaltocolor");

        pokerrankingeight.classList.add("twopairlndspe");
        document.getElementById("twopairto").classList.add("royaltocolor");

        pokerrankingnine.classList.add("onepairlndspe");
        document.getElementById("onepairto").classList.add("royaltocolor");

        pokerrankingten.classList.add("acekinglndspe");
        document.getElementById("acekingto").classList.add("royaltocolor");

        insurencepays.classList.add("insurencepayslndspe");
        document.getElementById("insurencepaysto").classList.add("insurenceratio");

        pokertable.src = "";
        pokertable.style.cssText = "";

      

        betcirclerussian.src = "";
        betcirclerussian.style.cssText = "";


        
        insurenceRussiangme.src = "";
        insurenceRussiangme.style.cssText = "";

        
        betRussiangme.src = "";
        betRussiangme.style.cssText = "";


        buydrawRussiangme.src = "";
        buydrawRussiangme.style.cssText = "";


        bonusRussiangme.src = "";
        bonusRussiangme.style.cssText = "";





        playerDetailsElement.classList.add("playerIdDetailsLandscape");
        bacaratbgim.style.background = `url(${landscapebg})`;
        bacaratbgim.style.backgroundSize = "cover";
        bacaratbgim.style.width = porttraitejson.bacaratbg.width;
        bacaratbgim.style.height = porttraitejson.bacaratbg.height;
        bacaratbgim.style.left = 0;



        dealerBuyCardElement.classList.add('dealerBuyCardLndsp');
        dealerChipsAddToBuyElement.classList.add('dealerBuyCardDrawButtonPortrait');



        pokertablelnspe.src = pokertablelandsp;
        Object.assign(pokertablelnspe.style, porttraitejson.pokertableland);


        // betchipsRussianpoker.src = betchipsRussian;
        Object.assign(betchipsRussianpoker.style, porttraitejson.betchiplndspe);


        bonusRussiangme.src = russianbetbtright;
        Object.assign(bonusRussiangme.style, porttraitejson.bonusbtnlndspe);
        document.getElementById("bonustext").className = "";
        document.getElementById("bonustext").classList.add("bonustextlndspe");

        insurenceRussiangme.src = russianbetbtnup;
        Object.assign(insurenceRussiangme.style, porttraitejson.insurencebtnlndspe);
        document.getElementById("insurencetext").className = "";
        document.getElementById("insurencetext").classList.add("insurencetextlndspe");

        russiantiname.src = russiantitle;
        Object.assign(russiantiname.style, porttraitejson.russiantitlelndscape);


        buydrawRussiangme.src = russianbetbtnleft;
        Object.assign(buydrawRussiangme.style, porttraitejson.drawbuybtnlndspe);


        document.getElementById("buydrawtext").className = "";

        document.getElementById("buydrawtext").classList.add("buydrawtextlndspe");

        document.getElementById("antetext").className = "";
        document.getElementById("antetext").classList.add("antetextlnspe");

        betRussiangme.src = russianbetbtndown;
        Object.assign(betRussiangme.style, porttraitejson.betbtnlndspe);


        document.getElementById("bettext").className = "";
        document.getElementById("bettext").classList.add("bettextlndspe");

        betcirclerussian.src = russianbetcle;
        Object.assign(betcirclerussian.style, porttraitejson.becirclelndspe);


        volumeRussianimg.src = volumeimg;
        Object.assign(volumeRussianimg.style, porttraitejson.volumelnspe);


        useriddetl.src = useridlmg;
        Object.assign(useriddetl.style, porttraitejson.useridlndspe);


        document.getElementById("useridtext").className = "";
        document.getElementById("useridtext").classList.add("useridlandscape");

        // optionrussianbtn.style.display = "none";
        optionrussianbtn.src = optionbtn;
        Object.assign(optionrussianbtn.style, porttraitejson.optionbtnlandspe);

        beticonrussian.src = beticonwindows;
        // beticonrussian.src = beticon;
        Object.assign(beticonrussian.style, porttraitejson.beticonlandspe);


        document.getElementById("bets").className = "";
        document.getElementById("bets").classList.add("betslandspe");
        // beticonrussian.style.transform =porttraitejson.beticonlandspe.transform;

        russianbncicon.src = balanceicon;
        Object.assign(russianbncicon.style, porttraitejson.balanceiconlndspe);


        document.getElementById("balance").className = "";
        document.getElementById("balance").classList.add("balancelndscape");
        // russianbncicon.style.transform = porttraitejson.balanceicon.transform;

        // exitbtn.src = exitrussian;
        Object.assign(exitbtn.style, porttraitejson.exitlanspe);


        winnericonrussian.src = winnericn;
        Object.assign(winnericonrussian.style, porttraitejson.winnericonlndspe);


        document.getElementById("wins").className = "";
        document.getElementById("wins").classList.add("winslandspe");

        informi.src = informicon;
        Object.assign(informi.style, porttraitejson.infrmicnlnspe);

        betchipincrementbtn.src = betincerementdecrement;
        Object.assign(betchipincrementbtn.style, porttraitejson.betincreamentlndspe);



        betchipdecrementbtn.src = betincerementdecrement;
        Object.assign(betchipdecrementbtn.style, porttraitejson.bedecrementlndspe);

      }

      const drawtextElement = document.getElementById("drawtext");
      if(drawtextElement){
        drawtextElement.classList.add("drawtextopacity");
      }

      // this.setState({
      //   // mobileLandScape,
      //   resetAnteChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0}, //"#chipcenterposition"
      //   resetBetChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//#betchipsaddtobet"
      //   resetBetText:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//"#betTextaddtoBet"
      //   resetInsuranceChip:{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0},//#chiptoinsurence"
      //   resetPyrDrwByChip:{x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0},//this.chipdrawref.current,
      //   resetDlrBycardChip:{x: 0, y: 0,duration:0.0001,zIndex: -2,opacity: 0 },// this.buyDealerCardDrawn.current,
      //   resetDlrLoseChip:{x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001},//"#dealerchip",
      //   resetwinpopuprspelement:{autoAlpha: 0, scale: 0, duration: 0.00001}// const winpopuprspelement = document.getElementById("winpopup_rsp");  this.resetPyrWnCpsAmn = true;
      // })


      // this.setState({
      //   // mobilePortrait
      //   resetAnteChip:{x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetBetChip:{x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetBetText:{x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetInsuranceChip:{x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetPyrDrwByChip:{x: 548, y: -36, duration:0.00001, zIndex: -5, opacity: 0},
      //   resetDlrBycardChip:{x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0},
      //   resetDlrLoseChip:{ x: -45,y: 20, opacity: 0, zIndex: -10 ,duration:0.00001},
      //   resetwinpopuprspelement:{autoAlpha: 0, scale: 0, duration: 0.00001}
      // })
      

      // if(this.resetPyrWnCpsAmn === true){
      //   if(isPortrait){
         
          
      //     if(document.getElementById("chipcenterposition")&&document.getElementById("betchipsaddtobet")&&document.getElementById("betTextaddtoBet")&&
      //       document.getElementById("chiptoinsurence")&&this.chipdrawref.current&&this.buyDealerCardDrawn.current&&document.getElementById("dealerchip")&&winpopuprspelement){
      //       gsap.to("#chipcenterposition",{x: 13, y: 1200, duration: 0.0000001, zIndex: -2, opacity: 0})
      //       gsap.to("#betchipsaddtobet",{x: 1, y: 76, duration: 0.0000001, zIndex: -2, opacity: 0})
      //       gsap.to("#betTextaddtoBet",{x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to("#chiptoinsurence",{x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to(this.chipdrawref.current,{x: 548, y: -36, duration:0.00001, zIndex: -5, opacity: 0})
      //       gsap.to( this.buyDealerCardDrawn.current,{x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0})
      //       gsap.to("#dealerchip",{ x: -45,y: 20, opacity: 0, zIndex: -10 ,duration:0.00001})
      //       gsap.to(winpopuprspelement,{autoAlpha: 0, scale: 0, duration: 0.00001})
          
      //   }

      //   }else{
      //     if(document.getElementById("chipcenterposition")&&document.getElementById("betchipsaddtobet")&&document.getElementById("betTextaddtoBet")&&
      //     document.getElementById("chiptoinsurence")&&this.chipdrawref.current&&this.buyDealerCardDrawn.current&&document.getElementById("dealerchip")&&winpopuprspelement){
      //     gsap.to("#chipcenterposition",{x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#betchipsaddtobet",  {x: 0, y: 0, duration: 0.000001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#betTextaddtoBet",   {x: 0, y: 0, duration: 0.000001 ,zIndex: -2,opacity: 0})
      //     gsap.to("#chiptoinsurence",   {x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0})
      //     gsap.to(this.chipdrawref.current,        {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0})
      //     gsap.to( this.buyDealerCardDrawn.current,{x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0})
      //     gsap.to("#dealerchip",{ x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})
      //     gsap.to(winpopuprspelement,{autoAlpha: 0, scale: 0, duration: 0.00001})
        
      // }

      //   }
      // }







    }
  };

  showPreviousBetsStatus = () =>{

    
    console.log(this.props.gameState);
    const {response} =this.props.gameState;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      if(response){
      document.getElementById("betpannelrussiancover").style.display = "block";
    document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
    }
    else{
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

    }
  }

if(response){
  this.buyCardUnsequenceArray = [...this.props?.gameState?.response?.playerFinalCards];
 
    this.setState({
      anteBetAmount:response.anteBetAmount,
      bonusbetamount:response.bonusBetAmount,
    });

  switch(response.action){
    case "PARAM_BET":
    
      this.showcardsAdBetsParamState(response,response.playerFinalCards,response.dealerCards);
      this.showParamBetStatus(false);
      this.ShowbonusstatusTousr(response);

      this.setState({
        infoText:response.message
      })
  
       
      break;         
     case "BET":   
            

    this.showrussianPokerresp(response);          
     break;
     case "FOLD":
    // this.Show_FoldHandresp(response);
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      this.Show_FoldHandresp(response);

    }else{
      this.showPlayerorDealerorFold(response);
    }

    break;
    case "CONFIRM_FOLD":
   // this.Show_FoldHandresp(response);
   if (
     navigator.userAgentData &&
     navigator.userAgentData.platform &&
     navigator.userAgentData.platform == "Windows"
   ) {
     this.Show_FoldHandresp(response);

   }else{
     this.showPlayerorDealerorFold(response);
   }
    

    break;
    case "REPLACE_CARDS":
    // this.ShowrpReplaceCards(response);
    
      if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
    //  this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards);
     this.ShowBuyplayercardresp(response);
    //  this.ShowrpReplaceCards(response);
    
    }else{
    
     this.ShowBuyplayercardresp(response);
    }
    
    
    
    
   
    
    break;
    case "BUY_PLAYER_CARD":
    
       if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
    
     this.ShowBuyplayercardresp(response);
      // this.showcardsAdBetsParamState(response,response.dealerCards,response.playerFinalCards)
    
    
    }else{
     this.ShowBuyplayercardresp(response);
    
    }
    
    
    
   
    break;
    case "BUY_DEALER_CARD":
      this.setState({cmfrmdealeraction: 1, cmfrmdealeractionNO: 1})
    this.ShowbuydealercardResponse(response);
    // this.showrussianPokerresp(response);  
   
    break;
    case "CONFIRM_DEALER_CARD":
    this.Showbuyconfirmdealercardresp(response);
   
    break;
    case "INSURANCE":
    // this.Showclaiminsurenceresp(response);
    

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      this.Showclaiminsurenceresp(response);

    }else{
      this.showrussianPokerresp(response);  
    }
    break;  

    default:
      return []  
  }

}
      


  
  }


showcardsAdBetsParamState = (response,playerFinalCards,dealerCards) =>{
  setTimeout(() =>{
    this.setState({
      totalbet:response.totalBetAmount,
      betdeductions:response.totalBetAmount
    })},250)
// this.onDrawingCards(response.playerFinalCards, false);
console.log(dealerCards)
console.log(playerFinalCards)
  

      

let convrAntebt = this.topLable_rsp(response.anteBetAmount);

if (
  navigator.userAgentData &&
  navigator.userAgentData.platform &&
  navigator.userAgentData.platform == "Windows"
) {

      if (Number(response.anteBetAmount) > 0) {
        let antebetstatebetamount=this.topLable_rsp(response.anteBetAmount)
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chipcenterposition">
              <img id="centerchips" className="chip10centerposition"
                src={chip_10}
                alt="Chip 10"
              />
              <span id="antebetval_10" className="antebet_textval10">{antebetstatebetamount}</span>
            </div>

          ],
        }));
      }

      // if(response.bonus){
      if (response.bonus) {
        if (Number(response.bonusBetAmount) > 0) {
          let bonusbetstateamnt=response.bonusBetAmount
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chip10centerbns">
              <img className="chip10centerbns"
                src={chip_10}
                alt="Chip 10"
              />
              <span id="antebetval_10" className="bonusChipsForwindows_val10">{bonusbetstateamnt}</span>
            </div>

          ],
        }));
      }

      }
     
      if (response.playerBuyCardAmount > 0 || response.playerReplaceCardAmount > 0) {
       
gsap.set("#betchipsaddtodraw", { clearProps: "transform" });
setTimeout(() => {
    gsap.to("#betchipsaddtodraw", {
        x: -1084,
        y: -598,
        duration: 0.1
    });
}, 10);

        let betexttodraw = document.getElementById("betchipsaddtodrawText");
        betexttodraw.classList.add("bet-text-addtodraw","bet-text-addtoinsurence10") 
        gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
        let betchipsdraw = document.getElementById("betchipsaddtodraw");


        console.log(betexttodraw)

        let betTextaddtoBetClass = "bet-text-addtodraw20";
        // this.setState((prevState) => ({
        //   totalbet: prevState.totalbet + prevState.anteBetAmount

        // }));
      let buyreplacestatebet 
        // Object.assign(betchipsdraw.style,porttraitejson.drawchpgamestatewindows)
        Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

        if (response.playerBuyCardAmount > 0) {
          // alert("...1")
          betchipsdraw.src = chip_10;
          buyreplacestatebet=this.topLable_rsp(response.playerBuyCardAmount)
          betexttodraw.textContent = buyreplacestatebet
          gsap.to("#betchipsaddtodraw", { x: -4, y: -2, duration: 0.1 })
          document.getElementById("betchipsaddtodrawText").classList.add("bet-text-addtodraw,bet-text-addtodraw10")
          console.log(betexttodraw)
        }
        else {
          betchipsdraw.src = chip_10;
          buyreplacestatebet=this.topLable_rsp(response.playerReplaceCardAmount)
          document.getElementById("betchipsaddtodrawText").classList.add("bet-text-addtodraw,bet-text-addtodraw10")
          betexttodraw.textContent = buyreplacestatebet
          console.log(betexttodraw)
        }

      }
      if (response.insuranceBetAmount > 0) {
      let insurencestatebetamount=this.topLable_rsp(response.insuranceBetAmount);
        this.setState((prevState) => ({
          storebetchips: [
            ...prevState.storebetchips,
            <div id="chiptoinsurence">

              <img
                className="chiptoinsurence_windows"
                src={chip_10}
                alt="Chip 10"
              />
              <span className="insuranceTextwndwsChip10"> {insurencestatebetamount} </span>
            </div>
          ],
        }));


      }


    }
    else {

      if (Number(response.anteBetAmount) > 0) {
        this.setState((prevState) => ({
          storeantebetchips: [
            ...prevState.storeantebetchips,
            { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },

          ],
        }));

      }


      // switch(response.action){
      //   case "PARAM_BET":


      if ((response.action === "PARAM_BET" )|| 
          (response.message === `Attention! You have a ${response.playerHand}. Are you sure you want to fold?`&&response.action === "FOLD" )||
          (response.action ==="BUY_PLAYER_CARD" || response.action ==="REPLACE_CARDS")
        ) {
        if (response.bonus) {
          if (Number(response.bonusBetAmount) > 0) {
            // if(true){
            let convrBonusbet = this.topLable_rsp(response.bonusBetAmount);
            this.setState((prevState) => ({
              storebonuschips: [
                ...prevState.storebonuschips, { chip: chip_10, chipvalue: convrBonusbet, chipcolor1: "insuranceTextPortraitChip10" },

              ],
            }));
          }
        }

      }




    }



this.shuffledPlayerCards = [...playerFinalCards]
this.randomDealerCards =[]
for (let m = 0; m < dealerCards.length; m++) { 
  if (dealerCards[m] === '-1') {
    this.randomDealerCards.push('xx')

  } else {
    this.randomDealerCards.push(dealerCards[m]);
   

  }
}

console.log("this.randomDealerCards", this.randomDealerCards);
console.log("this.randomPlayerCards", this.shuffledPlayerCards);

let cnt = this.shuffledPlayerCards.length;
let cnt2 = this.randomDealerCards.length;
for (let i = 0; i < cnt2; i++) {
  let b1 = this.randomDealerCards[i];
  switch (i) {
    case 0:
      this.dealerCardsContainer.current.showPreviousDealercards("cardZero", b1, cnt);
      break;
    case 1:
      this.dealerCardsContainer.current.showPreviousDealercards("cardOne", b1, cnt);
      break;
    case 2:
      this.dealerCardsContainer.current.showPreviousDealercards("cardTwo", b1, cnt);
      break;
    case 3:
      this.dealerCardsContainer.current.showPreviousDealercards("cardThree", b1, cnt);
      break;
    case 4:
      this.dealerCardsContainer.current.showPreviousDealercards("cardFour", b1, cnt);
      break;
    case 5:
      this.dealerCardsContainer.current.showPreviousDealercards("cardFive", b1, cnt);
      break;
    default:
      break;
  }
}

for (let i = 0; i < cnt; i++) {
  let b2 = this.shuffledPlayerCards[i];
  switch (i) {
    case 0:
      this.cardsRef.current.showPreviousPlayercards("cardZero", b2, cnt);
      break;
    case 1:
      this.cardsRef.current.showPreviousPlayercards("cardOne", b2, cnt);
      break;
    case 2:
      this.cardsRef.current.showPreviousPlayercards("cardTwo", b2, cnt);
      break;
    case 3:
      this.cardsRef.current.showPreviousPlayercards("cardThree", b2, cnt);
      break;
    case 4:
      this.cardsRef.current.showPreviousPlayercards("cardFour", b2, cnt);
      break;
    case 5:
      this.cardsRef.current.showPreviousPlayercards("cardFive", b2, cnt);
      // this.cardsRef.current.addCards("cardFive", b2, cnt);
      break;
    default:
      break;
  }

}



  }

  ShowbonusstatusTousr = (res) => {

    let info = "";
    let spokerranking = "";
    let betactive = ""
   
    setTimeout(() =>{
      this.setState({ drawbuttonhandle: true });
      document.getElementById("drawtext").classList.add("drawtextopacity");
      document.getElementById("drawbtn").classList.add("drwnodrop");

    },500)

    let mode = ""
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"

    ) {

      info = "windowsinfo";
      spokerranking = "windowspokerranking";
      betactive = "betactive"
      mode = "Windows"

    } else {
      document.getElementById("betpannelrussiancover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        spokerranking = "pokerrankingPlayerLndsp";
        info = "lndspinfo";
        // betactive = "betactiveLndsp";
        betactive = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        info = "portraitinfo";
        // betactive = "betactivePortrait";
        betactive = this.state.betactvglwcss;
        spokerranking = "pokerrankingPortait";
        mode = "portrait";
      }
    }

      if (document.getElementById("inforussian")) {
        document.getElementById("inforussian").classList.add(info);
      };

      this.setState({
        pokerranking: res.playerHand,
        infoText: res.message,
        multibuttonsebledsble: false,
        disablemultibutton: false


      });
      document.getElementById("playerDealerCardContainer").classList.add("addpointer")
      document.getElementById("rankinginfo").classList = "";
      document.getElementById("rankinginfo").classList.add(spokerranking);
      this.setState({ isbonuspattable: false });
      document.getElementById("bnsactive").src = "";
      document.getElementById("bonustext").style.color = "";
   





    if (res.bonus) {
      // if(true){
      if (mode === "Windows") {
        if (this.state.bonusbetamount) {


          setTimeout(() => {
            let t1 = gsap.timeline();
            t1.to("#chip10centerbns", {

              x: -149, y: 271, duration: 0.1, opacity: 1,
              onComplete: () => {
                gsap.set("#chip10centerbns", { opacity: 1 });
              }
            });
            t1.play();

          }, (100));

        }
      }
        else if (mode === "portrait") {
         
          this.playerWinBonus = true;
          this.setState({ isbonuspattable: false });
          document.getElementById("bonustext").style.color = "";
          if(res.bonus){
          // if(true){
           setTimeout(()=>{
             if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.000001,autoAlpha:1
              });
            }

           },500)
             

          }
          
        }else{
          this.playerWinBonus = true;
          document.getElementById("bonustext").style.color = "";
          if(res.bonus){
          setTimeout(()=>{
              if (document.getElementById('chip10centerbns')) {
              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.000001, autoAlpha: 1,
              });
           
            }
          },500)
        

          }
        }

      } else {

        if (mode === "Windows") {
          if(this.state.bonusbetamount){
          let t1 = gsap.timeline();
          t1.to("#chip10centerbns", {
            x: 0, y: -649, duration: 0.5, opacity: 1,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0 });
            }
          });
          t1.to("#chip10centerbns", {
            x: 861, y: 557, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0, delay: 2 });
            }

          });
          t1.play();
        }

        } else if (mode === "portrait") {
          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, opacity: 1,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0 });
                }
              });
              t1.to("#chip10centerbns", {
                
                x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0, });
                }
  
              });
              t1.play();
            }

          }
       

        }else{

          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });
              t1.to("#chip10centerbns", {
                x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,
  
              });
              t1.play();
            }

          }

        }


      }
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse", 
        "Straightflush", "frofkind", "twopair", 
        "flush", "stright", "royalflush"
      ];
      
      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });
      
      switch (res.playerHand) {
        case "Royal Flush":
          document.getElementById("royalflush").style.color = "#ffffff";
          break;
        case "Straight Flush":
          document.getElementById("Straightflush").style.color = "#ffffff";
          break;
        case "Four of a Kind":
          document.getElementById("frofkind").style.color = "#ffffff";
          break;
        case "Full House":
          document.getElementById("fullhse").style.color = "#ffffff";
          break;
        case "Flush":
          document.getElementById("flush").style.color = "#ffffff";
          break;
        case "Straight":
          document.getElementById("stright").style.color = "#ffffff";
          break;
        case "Three of a Kind":
          document.getElementById("Threekind").style.color = "#ffffff";
          break;
        case "Two Pair":
          document.getElementById("twopair").style.color = "#ffffff";
          break;
        case "One Pair":
          document.getElementById("onepair").style.color = "#ffffff";
          break;
        case "Ace King":
          document.getElementById("aceking").style.color = "#ffffff";
          break;
        default:
          break;
      }


  

  document.getElementById("betactive").src = bnsactive;
  document.getElementById("bettext").style.color = "#ffffff";
  document.getElementById("betRussian").classList.add("addpointer");
    if(mode === "Windows"){
      document.getElementById("betactive").classList.add(betactive);

    }else{

    }
     



    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }

 
  showPlayerorDealerorFold = (res) =>{


    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards);
      this.showParamBetStatus(false);
      this.ShowbonusstatusTousr(res);
      
      if(res.playerReplaceCardAmount > 0|| res.playerBuyCardAmount > 0){
        //  if(res.playerBuyCardAmount > 0){
           this.prevstateBuyorDrawChip(res);
         }

      this.setState({
        infoText:res.message,
        cmfrmdealeraction: 2, 
        cmfrmdealeractionNO: 2
      })
        const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
        if (window.innerHeight > window.innerWidth) {

          confirmbuydealerPopupElement.classList = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")

        }
        else {
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")

        }
      


    }else{


      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      const rankinginfoElement = document.getElementById("rankinginfo");
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse", 
        "Straightflush", "frofkind", "twopair", 
        "flush", "stright", "royalflush"
      ];
  
  
      
      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });
  
      
      this.setState( {
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        infoText:this.props.language.Place_antebet,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        pokerranking:"",
        pokerrankingdeler:"",
        //  totalbet:res.totalBetAmount,,
        betbutton: "REBET",
        buttontext: "REBETX2",
      });
      
  
      document.getElementById("betpannelrussiancover").style.display = "none";
      betbutton.src = Betbtn;
      foldbutton.src = Foldbnwindows;
  
      betbtntextElement.className ="";
      foldtextElement.className ="";
      rankinginfodelerElement.className ="";
      rankinginfoElement.className ="";
  
      
      betbtntextElement.textContent = this.props.language.Rebet;
      foldtextElement.textContent = this.props.language.RebetX2;
  
      if(Number(res.anteBetAmount) >0){
        let convrAntebt = this.topLable_rsp(res.anteBetAmount);
        this.setState((prevState) => ({
          // storeantebetchips: [
            prevStatestoreantebetchips: [
            ...prevState.storeantebetchips,
            { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
        
          ],
        }));
      
      }
    
    if(Number(res.bonusBetAmount) >0){
      let convrBonusbet = this.topLable_rsp(res.bonusBetAmount);
      this.setState((prevState) => ({
        // storebonuschips: [
          prevStatestorebonuschips: [
          ...prevState.storebonuschips, {chip:chip_10, chipvalue:convrBonusbet, chipcolor1:"insuranceTextPortraitChip10"},
    
        ],
      }));
    }
        
    
    this.playerWinBonus = false;
  if(window.innerHeight > window.innerWidth){
    // portrait;
    setTimeout(() =>{
      if (document.getElementById("chipcenterposition")) {
        gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.000001, zIndex: -2, opacity: 0 });
      }
    
      if (document.getElementById("betchipsaddtobet")) {
        gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.000001, zIndex: -2, opacity: 0 });
        gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.000001, zIndex: -2, opacity: 0 });
      }
    
    
      if (document.getElementById("chiptoinsurence")) {
        gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.000001, zIndex: -2, opacity: 0 });
      }
      gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.000001, zIndex: -5, opacity: 0 });
      if (document.getElementById("chip10centerbns")) {
        gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.000001, zIndex: -2, opacity: 1 });
        
    
        }
        if(this.buyDealerCardDrawn.current){
          gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.000001, zIndex: -2, opacity: 0 });
        }
    
    },100);
  
  
    
    betbtntextElement.classList.add("betportrait");
    Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
    // Object.assign(betbtntextElement.style, porttraitejson.betportraite);
    Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
    foldtextElement.classList.add("rebettextportrait");
  
  }  else{
    // landscape
  
  
    // betbtntextElement.classList.add("betportrait");
    Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
    betbtntextElement.classList.add('betLandscape');
    Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
    foldtextElement.classList.add("foldtextLandscape");
    this.ifPlayerWinBonusAmount();
  
    setTimeout(() =>{
  
      if(document.getElementById("chipcenterposition")){
        gsap.to("#chipcenterposition",  { x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0});
      }
  
  
      if(document.getElementById("betTextaddtoBet")){
        gsap.to("#betTextaddtoBet",     { x: 0, y: 0, duration:0.0001,zIndex: -2,opacity: 0});
        gsap.to("#betchipsaddtobet",    { x: 0, y: 0, duration: 0.0001});
  
      }
      if(this.chipdrawref){
        gsap.to(this.chipdrawref.current, {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0});
      }
  
  
      if(document.getElementById("chiptoinsurence")){
        gsap.to("#chiptoinsurence", { x: 0, y:0, duration: 0.0001,zIndex: -2, opacity: 0 });
      }
      if(this.buyDealerCardDrawn){
        gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2,duration:0.0001})
      }
  
  
      if(document.getElementById("dealerchip")){
        gsap.set('#dealerchip', {x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})
      }
  
    },100)       
    
  }
  this.clearActivesWeDid();



    }













  }




  betChipsIncrementDecremenAction = () => {
    const betchipsRussianpoker = document.getElementById("betchips");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {


      document.getElementById("val0").classList.remove("chip_10text");
      switch (this.state.count) {
        case 1:
          console.log(this.storebetchips);
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").classList.remove("valtext_windows");
          document.getElementById("val0").textContent = this.chipVal[0].label;
          document.getElementById("val0").classList.add("valtext_windows_chip10", "chip_10text");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          // this.setState({ totalbet: 20 })
          document.getElementById("val0").textContent = this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          // this.setState({ totalbet: 50 })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          // this.setState({ totalbet: 100 })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          // this.setState({ totalbet: 500 })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtext_windows");
          betchipsRussianpoker.src = chip_500;
          break;

        default:
          break;
      }

      betchipsRussianpoker.style.position = porttraitejson.betchpwindows.position;
      betchipsRussianpoker.style.width = porttraitejson.betchpwindows.width;
      betchipsRussianpoker.style.top = porttraitejson.betchpwindows.top;
      betchipsRussianpoker.style.left = porttraitejson.betchpwindows.left;
      betchipsRussianpoker.style.zIndex = porttraitejson.betchpwindows.zIndex;


    } else {
      const isPortrait = window.innerHeight > window.innerWidth;

        document.getElementById("val0").textContent = "";
        document.getElementById("val1").textContent = "";
        document.getElementById("val2").textContent = "";
        document.getElementById("val3").textContent = "";

      console.log(this.state.count)
      document.getElementById("val0").classList.remove("valtextPortrait1");

      switch (this.state.count){
        case 1:
          console.log(this.storebetchips);
          betchipsRussianpoker.src = chip_10;
          document.getElementById("val0").textContent = this.chipVal[0].label;
          // this.setState({ anteBetAmount: Number(this.chipVal[0].val) })
          document.getElementById("val0").classList.add("valtextPortrait", "valtextPortrait1");

          break;
        case 2:
          // this.setState({ anteBetAmount: Number(this.chipVal[1].val) })
          document.getElementById("val0").textContent =this.chipVal[1].label;
          document.getElementById("val0").classList.add("valtextPortrait");

          betchipsRussianpoker.src = chip_25;
          break;
        case 3:
          // this.setState({ anteBetAmount: Number(this.chipVal[2].val) })
          document.getElementById("val0").textContent = this.chipVal[2].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_50;

          break;
        case 4:
          // this.setState({ anteBetAmount: Number(this.chipVal[3].val) })
          document.getElementById("val0").textContent = this.chipVal[3].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_100;

          break;
        case 5:
          // this.setState({ anteBetAmount: Number(this.chipVal[4].val) })
          document.getElementById("val0").textContent = this.chipVal[4].label;
          document.getElementById("val0").classList.add("valtextPortrait");
          betchipsRussianpoker.src = chip_500;
          break;
        default:
          break;
      }

      if (isPortrait) {

        Object.assign( betchipsRussianpoker.style, porttraitejson.betchp);
      } else {
        Object.assign( betchipsRussianpoker.style, porttraitejson.betchiplndspe);
      }



    }

  }

  IncreamentBetchips = () => {
    document.getElementById("betcircle").classList.remove("insurencenodrop")
    if (this.state.count < 5) {
      this.betchipsnd = new Audio(betchipsnd);
      if (this.state.mutesounds) {
        this.betchipsnd.pause();

      }
      else {
        this.betchipsnd.play();

      }
      this.betchipsnd.play();
      this.setState(
        (prevState) => ({
          count: prevState.count + 1,
        }),

        () => {
          console.log(this.state.count)

          if (this.state.count > 5) {
            this.setState({ betchipincrementbtn: true, betdecreamentbtn: false });
          }
          else {
            if (this.state.mutesounds) {
              this.betchipsnd.pause();

            }
            else {
              this.betchipsnd.play();

            }
            this.setState({ betchipincrementbtn: false });

          }
          this.betChipsIncrementDecremenAction()
        })


    }

    console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
  };



  DecreamentBetchips = () => {
    console.log("this.state.count", this.state.count, "betdecreamentbtn", this.state.betdecreamentbtn, 'betchipincrementbtn', this.state.betchipincrementbtn)
  

    const betchipsRussianpoker = document.getElementById("betchips");
    document.getElementById("betcircle").classList.remove("insurencenodrop")


    if (this.state.count > 1) {
      this.betchipsnd = new Audio(betchipsnd);
      this.setState(
        (prevState) => ({
          count: prevState.count - 1,
        }),
        () => {

          console.log(this.state.count)
          if (this.state.count < 1) {
            this.setState({ betdecreamentbtn: true, betchipincrementbtn: false });
          } else {
            if (this.state.mutesounds) {
              this.betchipsnd.pause();

            }
            else {
              this.betchipsnd.play();

            }
            this.setState({ betdecreamentbtn: false });
          }

          this.betChipsIncrementDecremenAction();

     
        }
      );

    }

   
  };





  anteBet = () => {
    
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    
    if (this.state.mutesounds) {
      this.betrussiansnd.pause();
    }
    else {
      this.betrussiansnd.play();

    }

    document.getElementById("betincrenet").classList.add("drwnodrop")
    document.getElementById("betdecrement").classList.add("drwnodrop")


    console.log("this.props.minBetAmt", this.props.minBetAmt, "this.props.maxBetAmt", this.props.maxBetAmt)

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    if(popupbetGreaterthanBalnceElement){
      popupbetGreaterthanBalnceElement.className ="";
      popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
    }

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (this.state.anteBetAmount <= this.props.maxBetAmt) {

        document.getElementById("buttons_cover").classList.remove("buttons_cover")
      }else{
        document.getElementById("buttons_cover").classList.add("buttons_cover")

      }
      
      document.getElementById("foldtext").innerHTML="";

      document.getElementById("crdscover").classList.add("crdscover")
     

      let bnsactived = document.getElementById("bnsactive");
      bnsactived.src = "";
      bnsactived.classList.remove("bnsctivewindows");
      bnsactived.classList.remove("bnsblink");
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      console.log(this.state.anteBetAmount)
      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        this.setState({ infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
          disBetBtn:1,keyboardhandle:false,multibuttonsebledsble:true,disablemultibutton:true
        
        })
       
        // alert("..................."+this.state.anteBetAmount)
        console.log(this.state.anteBetAmount)
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtn");
          betbutton.src = "";
          betbutton.style.cssText=""
          document.getElementById("betbtntext").textContent = "";
          document.getElementById("foldtext").textContent = "";
      
          foldbutton.src = "";
          foldbutton.style.cssText=""
          
          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "RUSSIAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount.toFixed(2)),
            },
          };
          console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })
        
        }
        else {
       
          this.setState({ multibuttonsebledsble: true, disablemultibutton: false })
          let betbtntextElement = document.getElementById("betbtntext")
          let foldtextElement = document.getElementById("foldtext")
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
          betbtntextElement.classList.add("betbtntext");
          betbtntextElement.textContent = this.props.language.Deal;

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
          foldtextElement.classList.add("foldtext");
          foldtextElement.textContent = this.props.language.RP_Clear;
          this.setState({
             infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            multibuttonsebledsble: true
          })
        }
      }
      else {
        this.setState({ betdecreamentbtn: false, betchipincrementbtn: false });
        document.getElementById("foldtext").innerHTML=this.props.language.RP_Clear
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          multibuttonsebledsble: true


        })
      }

    } else {
      let bnsactived = document.getElementById("bnsactive");
          bnsactived.src = "";
          bnsactived.className ="";
          bnsactived.className = "";

      this.setState({
        bsbtn: true,
        buydrawCheck: false
      })

      let betbtntextElement = document.getElementById("betbtntext")
      let foldtextElement = document.getElementById("foldtext")
    

      if (this.state.anteBetAmount >= this.props.minBetAmt) {
        if (this.state.anteBetAmount <= this.props.maxBetAmt) {
          this.setState({ infoText: "", betchipincrementbtn: true, betdecreamentbtn: true,
            disBetBtn:1,keyboardhandle:false,multibuttonsebledsble:true,disablemultibutton:true
          
          })


          document.getElementById("mobileAntiMaskCover").style.display = "block";
          document.getElementById("betpannelrussiancover").style.display = "block";
          if (window.innerHeight > window.innerWidth) {
            document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          }

          betbtntextElement.textContent = "";
          foldtextElement.textContent = "";
  
          betbutton.src = "";
          foldbutton.src = "";


          const body = {
            sessionId: sessionStorage.getItem("sessionId"),
            CN: "RUSSIAN_POKER_BET",
            object: {
              gameId: this.props.gameId,
              action: "PARAM_BET",
              playerId: Number(this.props.playerId),
              anteBetAmount: Number(this.state.anteBetAmount.toFixed(2)),
              bonusBetAmount: Number(this.state.bonusbetamount.toFixed(2)),
            },
          };
          console.log(body);
          this.props.network.sendof(body);
          this.setState({ multibuttonsebledsble: false })

        }

        else {
          betbutton.src = Betbtn;
          foldbutton.src = Foldbn;
          if (window.innerHeight > window.innerWidth) {

            Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
            betbtntextElement.classList.add("betportrait");
            betbtntextElement.textContent = this.props.language.Deal;


            Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
            foldtextElement.classList.add("foldtextportrait");
            foldtextElement.textContent = this.props.language.RP_Clear;

          } else {

            Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
            betbtntextElement.classList.add("betLandscape");
            betbtntextElement.textContent = this.props.language.Deal;

            Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
            foldtextElement.classList.add("foldtextLandscape");
            foldtextElement.textContent = this.props.language.RP_Clear;

          }


          this.setState({
            infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
            // multibuttonsebledsble: true
          })
        }
      }
      else {
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("betpannelrussiancover").style.display = "block";
        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;
        if (window.innerHeight > window.innerWidth) {

          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          betbtntextElement.classList.add("betportrait");
          betbtntextElement.textContent = this.props.language.Deal;


          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          foldtextElement.classList.add("foldtextportrait");
          foldtextElement.textContent = this.props.language.RP_Clear;

        } else {

          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.classList.add("betLandscape");
          betbtntextElement.textContent = this.props.language.Deal;

          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.textContent = this.props.language.RP_Clear;

        }

        // document.getElementById("betpannelrussiancover").style.display = "none";
        // document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
        this.setState({
          infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
          // multibuttonsebledsble: true


        })
      }
    }
  };


  clearbets() {
   
    this.clearallsettimeouts()
    this.updateplayerbalnace();
   

    if (this.state.mutesounds) {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.pause()

    }
    else {
      this.clear_snd = new Audio(clear_snd)
      this.clear_snd.play()

    }

    document.getElementById("bonusRussian").classList.add("addpointer");



    this.setState({ totalbet: 0,disClearBtn:1, 
    isbonuspattable: false, anteBetAmount: 0, 
    bonusbetamount: 0, bsbtn: true, 
    // infoText: this.props.language.Play_Njoy 
    infoText: this.props.language.Place_antebet });
    document.getElementById("betcircle").classList.add("addpointer")
    document.getElementById("betcircle").classList.remove("insurencenodrop")
    document.getElementById("bonusRussian").classList.remove("addpointer")
    document.getElementById("bonusRussian").classList.add("drwnodrop")
    document.getElementById("bonustext").style.color = ""
    document.getElementById("bnsactive").src = "";
    document.getElementById("bnsactive").classList.remove("betactive");
    this.setState({ multibuttonsebledsble: false })
    // document.getElementById("betbtn").classList.add("addpointer")
    // document.getElementById("betbtn").classList.remove("drwnodrop")
    this.clertime1=setTimeout(() => {
      gsap.to(`#betchipsaddtoante${this.state.idincrement}`, { zIndex: -2 });
      gsap.to(this.chipbnsref.current, { zIndex: -2 });
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
    }, 1000);



    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    betbutton.src = "";
    document.getElementById("betbtntext").classList.remove("betwindows");
    document.getElementById("betbtntext").textContent = "";
    document.getElementById("betbtntext").innerHTML = "";
    betbutton.style.cssText = ""

    foldbutton.src = "";
    document.getElementById("foldtext").classList.remove("foldtextwindows");
    document.getElementById("foldtext").textContent = "";
    document.getElementById("foldtext").innerHTML = "";
    foldbutton.style.cssText = ""

    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });

   




    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        this.hidepopup_betshigh();
      }
    
       gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {
      x: 1,
      y: 0,
      duration: 0.1,
      ease: "power2.inOut",
      zIndex: 2,
      opacity:0
    });
    
    
      this.hidepopup_betshigh();

      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");
    if(document.getElementById("chip10centerbns")){
      gsap.to(`#chip10centerbns`, {
        x: 859,
        y: 547,
        duration: 0.5,
        ease: "power2.inOut",
        zIndex: -2,
        opacity: 0
      });

    }
    if(document.getElementById("chipcenterposition")){
      gsap.to("#chipcenterposition", {
        x: 993,
        y: 544,
        duration:0.3,
        ease: "power2.inOut",
        zIndex: -2,
        opacity: 0
      });

    }



    } else {


      gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {
        x: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.inOut",
        zIndex: 2,
      });

      setTimeout(() => {
        this.setState({
          // storebetchips:[]
        });
      }, 1000);


      const isPortrait = window.innerHeight > window.innerWidth;
      // if(this.state.betshigh_balnce){
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        this.hidepopup_betshigh();
      }
      // }

      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("mobileAntiMaskCover").style.display = "none";
      if (isPortrait) {
        if (document.getElementById("chipcenterposition")) {
          gsap.to("#chipcenterposition", {
            x: 25,
            y: 1200,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 0
          });
        }

        // let t1 = gsap.timeline();
        // t1.to("#chipcenterposition", {
        //   y: -966, duration: 0.2, opacity: 1,
        //   onComplete: function () {
        //     gsap.set("#chipcenterposition", { opacity: 0 });
        //   }
        // });
        // t1.to("#chipcenterposition", {
        //   x: 13, y:-2170, duration: 0.0001, zIndex: -12, opacity: 0,
        //   onComplete: function () {
        //     gsap.set("#chipcenterposition", { opacity: 0, });
        //   }

        // });
        // t1.play();


        if (document.getElementById('chip10centerbns')) {
          gsap.to(`#chip10centerbns`, {
            x: -160,
            y: 1200,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: -2,
            opacity: 0
          });

        }




      } else {

        if (document.getElementById("chipcenterposition")) {
          gsap.to("#chipcenterposition", {
            x: 1050,
            y: 0,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: 2,
            opacity: 0
          });
        }


        if (document.getElementById('chip10centerbns')) {
          gsap.to(`#chip10centerbns`, {
            x: 890,
            y: 0,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: -2,
            autoAlpha: 1,
            onComplete: () => {
              gsap.to(`#chip10centerbns`, {
                x: 0,
                y: 0,
                autoAlpha: 0,
                duration: 0.000001,
                zIndex: -2,
              })


            }
          });

        }

      }
    }
  }

  Showbonustouser = (res) => {

    let info = "";
    let spokerranking = "";
    let betactive = ""

    let mode = ""
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"

    ) {
      mode = "Windows"
      betactive ="betactive"
   

    } else {
      document.getElementById("betpannelrussiancover").style.display = "none";
      if (window.innerWidth > window.innerHeight) {
        betactive = "betactiveLndsp";
        // betactive = this.state.betactvglwcss;
        mode = "landscape";
      } else {
        betactive = "betactivePortrait";
        // betactive = this.state.betactvglwcss;
        mode = "portrait";
      }
    }

    // document.getElementById("betactive").src = bnsactive;
    document.getElementById("betactive").classList.add(betactive);

    this.clertime2 = setTimeout(() => {
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
  
      ) {
        
  
        info = "windowsinfo";
        spokerranking = "windowspokerranking"
        mode = "Windows"
     
  
      } else {
        // document.getElementById("betpannelrussiancover").style.display = "none";
        if (window.innerWidth > window.innerHeight) {
          spokerranking = "pokerrankingPlayerLndsp";
          info = "lndspinfo";
          mode = "landscape";
        } else {
          info = "portraitinfo";
          spokerranking = "pokerrankingPortait";
          mode = "portrait";
        }
      }
      if (document.getElementById("inforussian")) {
        document.getElementById("inforussian").classList.add(info);
      };

      this.setState({
        pokerranking: res.playerHand,
        // infoText: res.message,
        multibuttonsebledsble: false,
        disablemultibutton: false

        // betBtnValue:1,

      });
      document.getElementById("playerDealerCardContainer").classList.add("addpointer")
      document.getElementById("rankinginfo").classList = "";
      document.getElementById("rankinginfo").classList.add(spokerranking);
      this.setState({ isbonuspattable: false });
      document.getElementById("bnsactive").src = "";
      document.getElementById("bonustext").style.color = "";
    }, 4000);

    this.clertime3 = setTimeout(() => {
      if (this.state.bonusbetamount > 0) {
        if (this.state.mutesounds) {
          this.pokerchipsnd = new Audio(pokerchipsnd)
          this.pokerchipsnd.pause();
        }
        else {
          this.pokerchipsnd = new Audio(pokerchipsnd)
          this.pokerchipsnd.play();
        }
      }
      // const poker_Rankingsinsurence = [
      //   "Royal Flush","Straight" ,"Straight Flush", "Four of a Kind", 
      //   "Full House", "Flush", "Three of a Kind"
      // ];
      // if (poker_Rankingsinsurence.includes(res.playerHand)) {
    

        // res.playerHand == "Straight Flush" ||
        // res.playerHand == "Four of a Kind" ||
        // res.playerHand == "Full House" ||
        // res.playerHand == "Flush" ||
        // res.playerHand == "Three of a Kind"
        // ||res.playerHand == "High Card"||res.playerHand == "One Pair"
      
        if(res.bonus){
        // if(true){
        if (mode === "Windows") {
          if(this.state.bonusbetamount){
          let t1 = gsap.timeline();
          t1.to("#chip10centerbns", {
            x: -149, y: 291, duration:0.5, opacity: 1,delay:1,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 1 });
            }
          });
          // t1.to("#chip10centerbns", {
          //   x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,
          //   onComplete: () => {
          //     gsap.set("#chip10centerbns", { opacity: 0, delay: 2 });
          //   }

          // });
          t1.play();

          }

        }
        else if (mode === "portrait") {
          this.playerWinBonus = true;
          this.setState({ isbonuspattable: false });
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", {
                x: -630, y: 925, duration: 0.5, opacity: 1
                // x: -615, y: 920, duration: 0.5, opacity: 1
              });
            }

          }
          
        }else{
          this.playerWinBonus = true;
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
            
              gsap.to("#chip10centerbns", {
                x: 550, y: -130, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });
           
            }

          }
        }

      } else {

        if (mode === "Windows") {
          if(this.state.bonusbetamount){
          let t1 = gsap.timeline();
          t1.to("#chip10centerbns", {
            x: 0, y: -649, duration: 0.1, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0 });
            }
          });
          t1.to("#chip10centerbns", {
            x: 861, y: 557, duration: 0.1, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity: 0 });
            }

          });
          t1.play();
        }

        } else if (mode === "portrait") {
          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, opacity: 1,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0 });
                }
              });
              t1.to("#chip10centerbns", {
                // x: 0, y: 557, duration: 0.0005, zIndex: -2, opacity: 0,
                x: -163, y: 1195, duration: 0.0005, zIndex: -2, opacity: 0,
                onComplete: () => {
                  gsap.set("#chip10centerbns", { opacity: 0, });
                }
  
              });
              t1.play();
            }

          }
       

        }else{

          this.playerWinBonus = false;
          document.getElementById("bonustext").style.color = "";
          if(this.state.bonusbetamount){
            if (document.getElementById('chip10centerbns')) {
              let t1 = gsap.timeline();
              t1.to("#chip10centerbns", {
                x: 0, y: -900, duration: 0.5, autoAlpha: 1, ease: "power2.inOut",
              });
              t1.to("#chip10centerbns", {
                x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,
  
              });
              t1.play();
            }

          }

        }


      }
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse", 
        "Straightflush", "frofkind", "twopair", 
        "flush", "stright", "royalflush"
      ];
      
      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });
      
      switch (res.playerHand) {
        case "Royal Flush":
          document.getElementById("royalflush").style.color = "#ffffff";
          break;
        case "Straight Flush":
          document.getElementById("Straightflush").style.color = "#ffffff";
          break;
        case "Four of a Kind":
          document.getElementById("frofkind").style.color = "#ffffff";
          break;
        case "Full House":
          document.getElementById("fullhse").style.color = "#ffffff";
          break;
        case "Flush":
          document.getElementById("flush").style.color = "#ffffff";
          break;
        case "Straight":
          document.getElementById("stright").style.color = "#ffffff";
          break;
        case "Three of a Kind":
          document.getElementById("Threekind").style.color = "#ffffff";
          break;
        case "Two Pair":
          document.getElementById("twopair").style.color = "#ffffff";
          break;
        case "One Pair":
          document.getElementById("onepair").style.color = "#ffffff";
          break;
        case "Ace King":
          document.getElementById("aceking").style.color = "#ffffff";
          break;
        default:
          break;
      }


    }, 4000);

    if(mode === "Windows"){

    }else{
      document.getElementById("betactive").classList.add(betactive);
    }



   
setTimeout(()=>{
 
  // document.getElementById("bettext").style.color = "#ffffff";
  document.getElementById("betRussian").classList.add("addpointer");
  // document.getElementById("betactive").src = bnsactive;
  if(mode === "Windows"){
    document.getElementById("betactive").classList.add(betactive);
  } 
},5500)




    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];

    this.setState({
      revealCard: true,
      buttontext: "fold",
      betbutton: "Bet",
    });

  }







  rpAnteResultRes = (res) => {
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    // this.updateplayerbalnace();
   
   this.Showbonustouser(res);
this.setState({disClearBtn:1})
    console.log("RP Ante Result: ");
    console.log(res);
    console.log(res.dealerCards);
    console.log(res.playerCards);
    console.log(res.playerFinalCards);

    this.randomPlayerCards = [...res.playerCards];
    this.shuffledPlayerCards = [...res.playerFinalCards];
    console.log(this.randomPlayerCards)
    console.log(this.shuffledPlayerCards)

    // this.randomDealerCards = [...res.dealerCards];

  if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
  
      ) {
        document.getElementById("buttons_cover").classList.add("buttons_cover")
      setTimeout(() => {
      this.setState({ infoText: res.message })
      }, 6500)
      
      }

    for (let m = 0; m < res.dealerCards.length; m++) {
      if (res.dealerCards[m] === '-1') {
        this.randomDealerCards.push('xx')

      } else {
        this.randomDealerCards.push(res.dealerCards[m]);
      }
    }

    console.log("this.randomDealerCards", this.randomDealerCards);
    console.log("this.randomPlayerCards", this.randomPlayerCards);

    let cnt = this.randomPlayerCards.length;

    let mutesounds = this.state.mutesounds;

    function playSound(i) {
      let audio = new Audio(cardsdropping_snd);
      audio.currentTime = 0;
      if (mutesounds) {
        audio.pause();
      } else {
        audio.play();
      }


      audio.addEventListener('ended', function () {
        if (i + 1 < cnt) {
          this.clertime46 = setTimeout(() => {
            playSound(i + 1);
          }, 200)
          // },250)
        }
      });
    }


    for (let i = 0; i < cnt; i++) {
      var b1 = this.randomPlayerCards[i];
      playSound(i);

      switch (i) {
        case 0:
          this.cardsRef.current.addCards("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.addCards("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.addCards("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.addCards("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.addCards("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.addCards("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }

    document.getElementById("betpannelrussiancover").style.display = "block";
    this.clertime4 = setTimeout(() => {
      this.onDrawingCards(this.shuffledPlayerCards, false,res);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      // }, 1300)
    }, cnt * 2 * 500);

    setTimeout(() =>{
      document.getElementById("betpannelrussiancover").style.display = "none";
    },7000)


    let dealerCardsLength = this.randomDealerCards.length;

    this.clertime5 = setTimeout(() => {
      for (let i = 0; i < dealerCardsLength; i++) {
        var a1 = this.randomDealerCards[i];
        playSound(i);
        switch (i) {
          case 0:
            this.dealerCardsContainer.current.addCards(
              "cardZero",
              a1,
              dealerCardsLength
            );
            break;
          case 1:
            this.dealerCardsContainer.current.addCards(
              "cardOne",
              a1,
              dealerCardsLength
            );
            break;
          case 2:
            this.dealerCardsContainer.current.addCards(
              "cardTwo",
              a1,
              dealerCardsLength
            );
            break;
          case 3:
            this.dealerCardsContainer.current.addCards(
              "cardThree",
              a1,
              dealerCardsLength
            );
            break;
          case 4:
            this.dealerCardsContainer.current.addCards(
              "cardFour",
              a1,
              dealerCardsLength
            );
            break;
          default:
            break;
        }
      }
      // const foldbutton = document.getElementById("Foldbtn");
      // foldbutton.src = Foldbnwindows;
      // document.getElementById("foldtext").textContent = "Fold";
      // document.getElementById("foldtext").classList.add("foldtextwindows");
      // foldbutton.style.position = porttraitejson.foldbtnwindows.position;
      // foldbutton.style.top = porttraitejson.foldbtnwindows.top;
      // foldbutton.style.right = porttraitejson.foldbtnwindows.right;
      // foldbutton.style.width = porttraitejson.foldbtnwindows.width;
      // foldbutton.style.zIndex = porttraitejson.foldbtnwindows.zIndex;
      // }, dealerCardsLength * 300);
    }, dealerCardsLength * 400);
    document.getElementById("betcircle").classList.add("addpointer")
    document.getElementById("betcircle").classList.remove("insurencenodrop")
  
  };



  onDrawingCards(data, drawBuyHideAndShow,res) {

    console.log(data);
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      var b1 = data[i];
      if (b1) {
        this.cardsshuffle = new Audio(cardsshuffle);

        if (this.cardsshuffle) {
          this.cardsshuffle.currentTime = 0;
          if (this.state.mutesounds) {
            this.cardsshuffle.pause();
          } else {
            this.cardsshuffle.play();
          }
        }


      }
      switch (i) {
        case 0:
          this.cardsRef.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.cardsRef.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.cardsRef.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.cardsRef.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.cardsRef.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.cardsRef.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }
  

    this.clertime6=setTimeout(() => {
    this.setState({ infoText: res.message });
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.showParamBetStatus(drawBuyHideAndShow)
   
      }

      else {
        this.showParamBetStatus(drawBuyHideAndShow)
      }
    }, 2000)
  }


  showParamBetStatus = (drawBuyHideAndShow) =>{
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    betbtntextElement.textContent = "";
    foldtextElement.textContent = "";

    drawbutton.src = "";
    drawbutton.style.cssText="";
    

    buybutton.src = "";
    buybutton.style.cssText="";
   
    betbutton.src = "";
    betbutton.style.cssText="";
  

    foldbutton.src = "";
    foldbutton.style.cssText="";
   
    drawtextElement.classList.add("drawtextopacity");

    this.setState({ drawbuttonhandle: true });

    document.getElementById("bettext").style.color = "#ffffff";
    document.getElementById("betactive").src = bnsactive;

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      this.buyCardsequenceArray = [];
      this.setState({disDrawbtn:0,keyboardhandle:true,disBuybtn:0,keyboardhandle:true, Foldbtn:0})


       
       if (drawBuyHideAndShow) {
         drawbutton.src = "";
           buybutton.src = "";
           document.getElementById("buytext").classList.remove("buytextwindows");
           document.getElementById("drawtext").textContent = "";
 
           document.getElementById("buytext").classList.remove("buytextwindows");
           document.getElementById("buytext").textContent = "";
           this.setState({disDrawbtn:1,disBuybtn:1})

       } else {
         document.getElementById("crdscover").classList.remove("crdscover")
        document.getElementById("buttons_cover").classList.remove("buttons_cover")
         drawbutton.src = Drawbtnwindows;
           buybutton.src = Buybtnwindows;
           document.getElementById("buytext").classList.add("buytextwindows");
           document.getElementById("drawtext").textContent = this.props.language.Draw;
           document.getElementById("buytext").textContent = this.props.language.Buy;
           Object.assign(drawbutton.style, porttraitejson.Drawbtnwindows);
           Object.assign(buybutton.style, porttraitejson.Buybtnwindows);
           this.setState({disDrawbtn:0,keyboardhandle:true,disBuybtn:0,disBetBtn:0,keyboardhandle:true,betBtnValue:1})
 
         
       }

       
       betbutton.src = Betbtn;
       Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
       document.getElementById("betbtntext").textContent = this.props.language.Bet;
       document.getElementById("betbtntext").classList.add("betwindows");
     

       foldbutton.src = Foldbnwindows;
       Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
       document.getElementById("foldtext").textContent = this.props.language.Fold;
       document.getElementById("foldtext").classList.add("foldtextwindows");
       document.getElementById("betpannelrussiancover").style.display = "block";
       document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");

    }

    else {
      drawtextElement.textContent = "";
      const isPortrait = window.innerHeight > window.innerWidth;
      document.getElementById("mobileAntiMaskCover").style.display = "block";
      if (isPortrait) {
        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextPortrait");
          drawtextElement.textContent = "";
          buytextElement.classList.remove("buytextPortrait");
          buytextElement.textContent = "";
          drawbutton.src = "";
          buybutton.src = "";

        } else {
          
          drawbutton.src = Drawbtn;
          buybutton.src = Buybtn;
          drawtextElement.classList.add("drawtextPortrait");
          drawtextElement.textContent = this.props.language.Draw;
          buytextElement.classList.add("buytextPortrait");
          buytextElement.textContent = this.props.language.Buy;

          Object.assign(drawbutton.style, porttraitejson.Drawbtnportraite);
          Object.assign(buybutton.style, porttraitejson.Buybtnportraite);

        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betportrait");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextportrait");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

      
      } else {

        if (drawBuyHideAndShow) {
          drawtextElement.classList.remove("drawtextLndsp");
          drawtextElement.textContent = "";
          buytextElement.classList.remove("buytextLndsp");
          buytextElement.textContent = "";
          drawbutton.src = "";
          buybutton.src = "";

        } else {
          drawbutton.src = Drawbtn;
          buybutton.src = Buybtn;
          drawtextElement.classList.add("drawtextLndsp");
          drawtextElement.textContent = this.props.language.Draw;
          buytextElement.classList.add("buytextLndsp");
          buytextElement.textContent = this.props.language.Buy;

          Object.assign(drawbutton.style, porttraitejson.Drawbtnlndspe);
          Object.assign(buybutton.style, porttraitejson.Buybtnlndspe);

        }

        betbtntextElement.textContent = this.props.language.Bet;
        betbtntextElement.classList.add("betLandscape");
        foldtextElement.textContent = this.props.language.Fold;
        foldtextElement.classList.add("foldtextLandscape");
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);

        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);


      }
    }



  }



  drawCard() {
    const replaceCardslength = this.replaceCards.length
    if(this.state.anteBetAmount*3+ this.state.anteBetAmount*replaceCardslength + this.state.bonusbetamount > this.props.balance){
      this.setState({
        betshigh_balnce:true,
        lowBalaceErrorInfo:`insufficient Funds to place the Draw of ${this.topLable_rsp(this.state.anteBetAmount*replaceCardslength)},Please DEPOSIT to continue playing.` 
      });
      this.lowBalanceErrorInformationPopupanimation()
    }else{
      this.setState({betshigh_balnce:null})
    
      

      this.pokerchipsnd = new Audio(pokerchipsnd);
      if (this.state.mutesounds) {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.pause();
  
      }
      else {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.play();
  
      }
  
      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
  
      const drawtextElement = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");
  
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
  
  
      drawbutton.src = "";
      drawbutton.style.cssText = ""
  
      buybutton.src = "";
      buybutton.style.cssText = ""
  
  
      betbutton.src = "";
      betbutton.style.cssText = ""
  
  
      foldbutton.src = "";
      foldbutton.style.cssText = ""
  
  
  
      drawtextElement.textContent = "";
      buytextElement.textContent = "";
      betbtntextElement.textContent = "";
      foldtextElement.textContent = "";
  
  
  
      let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
      let betchipsdraw = document.getElementById("betchipsaddtodraw");
      let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");
  
  
  
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
       
        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount*replaceCardslength,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount*replaceCardslength
        }));
        document.getElementById("crdscover").classList.add("crdscover")
        this.drawbuybets(replaceCardslength)
  
        const drawbutton = document.getElementById("drawbtn");
        const buybutton = document.getElementById("buybtn");
  
        // document.getElementById("drawtext").classList.remove("drawtextwindows");
  
        document.getElementById("buytext").classList.remove("buytextwindows");
  
      }
  
      else {
  
        let betTextaddtodrawElementClass = "bet-text-addtoBet20";
  
        switch (this.state.count) {
          case 1:
            betchipsdraw.src = chip_10;
            // betTextaddtodrawElement.textContent = 10;
            // betTextaddtodrawElement.textContent = this.state.anteBetAmount;
            betTextaddtodrawElementClass = "bet-text-addtoBet10";
            break;
          case 2:
            betchipsdraw.src = chip_25;
            // betTextaddtodrawElement.textContent = this.state.anteBetAmount;
            break;
          case 3:
            betchipsdraw.src = chip_50;
            // betTextaddtodrawElement.textContent = this.state.anteBetAmount;
            break;
          case 4:
            betchipsdraw.src = chip_100;
            // betTextaddtodrawElement.textContent = this.state.anteBetAmount;
            break;
          case 5:
            betchipsdraw.src = chip_500;
            // betTextaddtodrawElement.textContent = this.state.anteBetAmount;
            break;
  
          default:
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
        }
  
        // let betAmount = this.state.anteBetAmount*3 + this.state.bonusbetamount;
        this.buydrawchipactivated = true;
        
        this.setState((prevState) => ({
          totalbet: prevState.totalbet + (prevState.anteBetAmount*replaceCardslength),
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + (prevState.anteBetAmount*replaceCardslength),
          infoText:""
        }));
  
        betTextaddtodrawElement.textContent = this.topLable_rsp(this.state.anteBetAmount*replaceCardslength);
        document.getElementById("betpannelrussiancover").style.display = "block";
        if (window.innerHeight > window.innerWidth) {
  
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          drawtextElement.classList.remove("betportrait");
          buytextElement.classList.remove("foldtextportrait");
  
          betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
          betTextaddtodrawElement.classList = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddtodraw');
          betchipsdraw.classList.add("betchipsaddtodraw");
  
          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: 322,
            y: -1230,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha:1
          });
  
        } else {
  
          drawtextElement.classList.remove("betLandscape");
          buytextElement.classList.remove("foldtextLandscape");
  
          betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
          betTextaddtodrawElement.classList = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');
          betchipsdraw.classList.add("betchipsaddtodraw")
  
          gsap.to(this.chipdrawref.current, {
            x:-1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            autoAlpha: 1,
            autoAlpha:1
          });
  
        }
      }
  
      console.log(this.replaceCards);
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          gameId: this.props.gameId,
          action: "REPLACE_CARDS",
          playerId: Number(this.props.playerId),
          // pcards: this.replaceCards,
          pcards: this.replaceCards,
        },
      };
      console.log(body);
      this.props.network.sendof(body);
    
    
    
    }

  
  }

  revealDealercards(res) {
    this.dealerCardsRandom = [...res.dealerCards];
    this.dealerShuffledCards = [...res.dealerFinalCards];
    this.setState({ totalwinRussian: res.totalWinAmount });
    console.log(this.state.totalwinRussian);
    let cnt = this.dealerCardsRandom.length;

   
    for (let i = 0; i < cnt; i++) {
      let b1 = this.dealerCardsRandom[i];
      if (b1) {
        this.reaveldealer_cards = new Audio(reaveldealer_cards);
        if (this.state.mutesounds) {

          this.reaveldealer_cards.pause();
        } else {
          this.reaveldealer_cards.play();
        }
      }
      switch (i) {
        case 0:
          if (this.randomDealerCards[0] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.randomDealerCards[1] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.randomDealerCards[2] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.randomDealerCards[3] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.randomDealerCards[4] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.randomDealerCards[5] != b1) {
            this.dealerCardsContainer.current.revealDealerCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime6 = setTimeout(() => {
      console.log("before", this.randomDealerCards);
      // this.suffleDealerCards(this.randomDealerCards.sort(() => Math.random()-0.5));
      this.suffleDealerCards(this.dealerShuffledCards);
      console.log("after", this.randomDealerCards);
    }, this.dealerShuffledCards.length * 500);

  }
  updateplayerbalnace = () => {
    this.props.gamstateHandle();
    // this.setState({betdeductions:0,noupdateblnce:true})
    const body2 = {
      CN: "GET_PLAYER_INFO",
      object: {
        gameId: Number(this.props.gameId,),
        playerId: Number(this.props.playerId),

      },
      sessionId: sessionStorage.getItem('sessionId'),
    }
    this.props.network.sendof(body2);
    this.body2 = {};

  }

  highlightPlayerCardsName =(playerHand) =>{
    this.setState({
      pokerranking: playerHand
     });

     const hands = [
      "onepair", "Threekind", "aceking", "fullhse", 
      "Straightflush", "frofkind", "twopair", 
      "flush", "stright", "royalflush"
    ];
    
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });
    
    switch (playerHand) {
      case "Royal Flush":
        document.getElementById("royalflush").style.color = "#ffffff";
        break;
      case "Straight Flush":
        document.getElementById("Straightflush").style.color = "#ffffff";
        break;
      case "Four of a Kind":
        document.getElementById("frofkind").style.color = "#ffffff";
        break;
      case "Full House":
        document.getElementById("fullhse").style.color = "#ffffff";
        break;
      case "Flush":
        document.getElementById("flush").style.color = "#ffffff";
        break;
      case "Straight":
        document.getElementById("stright").style.color = "#ffffff";
        break;
      case "Three of a Kind":
        document.getElementById("Threekind").style.color = "#ffffff";
        break;
      case "Two Pair":
        document.getElementById("twopair").style.color = "#ffffff";
        break;
      case "One Pair":
        document.getElementById("onepair").style.color = "#ffffff";
        break;
      case "Ace King":
        document.getElementById("aceking").style.color = "#ffffff";
        break;
      default:
        break;
    }


  }
  insurenceactiveornot=(res)=>{
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    
      if(res.insurance){
      
        // if(true){
        document.getElementById('betactive').src=""
        document.getElementById("betactive").classList.remove("betactive");
      document.getElementById("betdecrement").classList.remove("drwnodrop")
      document.getElementById("betincrenet").classList.remove("drwnodrop")
      document.getElementById("insurenceactive").src = bnsactive;
      document.getElementById("insurenceactive").classList.add("insurenceactivewindows");
      document.getElementById("insurenceactive").classList.add("insurenceblink");
      document.getElementById("insurenceRussian").classList.add("addpointer");
      document.getElementById("betpannelinsurececover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelinsurence");
      document.getElementById("insurenceRussian").classList.remove("insurencenodrop")
      document.getElementById("Foldbtn").classList.add("insurencenodrop")
      document.getElementById("foldtext").classList.remove("rebettextwindows")
      // document.getElementById("insurenceactive").classList.add("bnsctivewindows");

      // alert("...........????????????????????")
      //  this.revealDealercards(res)
      this.clertime56=setTimeout(() => {
        this.setState({ infoText: res.message})
      }, 3000);

      document.getElementById("insurencetext").style.color = "#ffffff";
      this.setState({
        buttontext: "insurence",
        betbutton: "noinsurence", insurencebtn: false, betdecreamentbtn: false, betchipincrementbtn: false, disablemultibutton: true
      });
      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
     
      document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
      document.getElementById("betbtntext").classList.add("noinsurence");

      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
    
      document.getElementById("foldtext").textContent = this.props.language.Insurence;
      document.getElementById("foldtext").classList.add("insurence");
    } else {
      this.clertime7=setTimeout(() => {
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        this.setState({ pokerrankingdeler: res.dealerHand, })
      }, 3000);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      this.revealDealercards(res)
      this.checkpayerordealerwins(res);

    }
  }
  
  
   Showinsurenceactiveornot=(res)=>{
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (res.insurance) {
      document.getElementById('betactive').src=""
      document.getElementById("betactive").classList.remove("betactive");
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
   
      this.setState({pokerranking: res.playerHand, })
      document.getElementById("betdecrement").classList.remove("drwnodrop")
      document.getElementById("betincrenet").classList.remove("drwnodrop")
      document.getElementById("insurenceactive").src = bnsactive;
      document.getElementById("insurenceactive").classList.add("insurenceactivewindows");
      document.getElementById("insurenceactive").classList.add("insurenceblink");
      document.getElementById("insurenceRussian").classList.add("addpointer");
      document.getElementById("betpannelrussiancover").style.display = "none";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      document.getElementById("insurenceRussian").classList.remove("insurencenodrop")
      document.getElementById("Foldbtn").classList.add("insurencenodrop")
      document.getElementById("foldtext").classList.remove("rebettextwindows")
      document.getElementById("foldtext").classList.remove("foldtextwindows")


   
      // document.getElementById("insurenceactive").classList.add("bnsctivewindows");

      // alert("...........????????????????????")
      //  this.revealDealercards(res)
      // this.clertime56=setTimeout(() => {
      //   this.setState({ infoText: res.message})
      // }, 3000);
      this.setState({ 
        insurencebtn: false, 
        disablemultibutton: true,
        betdecreamentbtn: false, 
        betchipincrementbtn: false,
        pokerranking:res.playerHand,
        infoText: res.message,
        anteBetAmount:res.anteBetAmount

      });

      document.getElementById("insurencetext").style.color = "#ffffff";
      this.setState({
        buttontext: "insurence",
        betbutton: "noinsurence", insurencebtn: false, betdecreamentbtn: false, betchipincrementbtn: false, disablemultibutton: true
      });
      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
     
      document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
      document.getElementById("betbtntext").classList.add("noinsurence");

      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
    
      document.getElementById("foldtext").textContent = this.props.language.Insurence;
      document.getElementById("foldtext").classList.add("insurence");

      this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards);
      if(res.betAmount>0){
              
        this.Showebetchipsgamestatehandle(res)
      }
     
    } else {
      // this.clertime7=setTimeout(() => {
      //   document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
      // }, 3000);

      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      this.revealDealercards(res)
      this.showcheckpayerordealerwins(res);

    }
  }
 
  showrussianPokerresp = (res) => {
  console.log(res)
    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ playerhand_status: res.playerHand })

  
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");
    
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
   
      // let t1 = gsap.timeline();
      //     t1.to("#chip10centerbns", {
      //       x:813, y:553, duration: 0.5, opacity: 0,
      //       onComplete: () => {
      //         gsap.set("#chip10centerbns", { opacity:0});
      //       }
      //     });
   this.Showinsurenceactiveornot(res)
      // this.revealDealercards(res);

    }

    else {
      document.getElementById("insurenceactive").className = "";
     
      if (window.innerHeight > window.innerWidth) {

          if(res.insurance && res.winner !== "Player"&& res.winner !== "Dealer"&&res.winner !== "Dealer_no_hand"){
          this.shwnPrvsSteBuyDrawBtchips(res); 
          this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards);
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactivePortrait");
          // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({ 
            buttontext: "insurence", 
            betbutton: "noinsurence", 
            insurencebtn: false, 
            disablemultibutton: true,
            betdecreamentbtn: false, 
            betchipincrementbtn: false,
            pokerranking:res.playerHand,
            // pokerrankingdeler:res.dealerHand,
            infoText: res.message,
            totalbet: res.totalBetAmount,
          });

           

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          betbtntextElement.textContent = this.props.language.No_Insurence;
          betbtntextElement.classList = "";
          betbtntextElement.style.cssText = "";
          betbtntextElement.classList.add("noinsurencePortrait");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("insurencePortrait");
        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
          // }, 3000);

            
          
          switch (res.winner) {
            case "Dealer":
            case "Player":
              this.showPlayerorDealerorFold(res)
                
                // this.ifDealerWinsMobilePortraitAction();
                // break;
                // this.ifPlayerWinsMobilePortraitAction();

                // if(Number(res.anteBetAmount) >0){
                //   let convrAntebt = this.topLable_rsp(res.anteBetAmount);
                //   this.setState((prevState) => ({
                //     storeantebetchips: [
                //       ...prevState.storeantebetchips,
                //       { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
                  
                //     ],
                //   }));
                
                // }


                break;
             
            case "Dealer_no_hand":


          this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res) 
          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
        this.setState({ betbutton: "YES", buttontext: "NO",Yesbtn:1,Nobtn:1,keyboardhandle:true,
          infoText: res.message, pokerrankingdeler: res.dealerHand,pokerranking:res.playerHand
          });
        document.getElementById("betbtntext").textContent = this.props.language.Yes;
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

        // foldbutton.src = Foldbn;
        foldtextElement.textContent = this.props.language.No;
        document.getElementById("betbtntext").classList.remove("insurence", "betwindows");
        foldtextElement.classList.remove("noinsurence", "foldtextwindows");
        document.getElementById("betbtntext").classList.add("betportrait");
        foldtextElement.classList.add("foldtextportrait");
      default:
        break;
            }
         
        }


      }else{

        if(res.insurance&& res.winner !== "Player"&& res.winner !== "Dealer"&&res.winner !== "Dealer_no_hand"){
          this.shwnPrvsSteBuyDrawBtchips(res); 
          this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards);
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactiveLndsp");
          if(this.state.pokerrankingdeler !==""){
            document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
          }
          // this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          this.setState({ infoText: res.message, })

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({ 
            buttontext: "insurence", 
            betbutton: "noinsurence", 
            insurencebtn: false, 
            disablemultibutton: true,
            betdecreamentbtn: false, 
            betchipincrementbtn: false,
          
          });
          
          
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").classList = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("betLandscape");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("foldtextLandscape");
        } else {
            this.prevStatecheckPlayerOrDealerWinMblLndspe(res);
          
        }

      }

    }

  }
  
  
  shwnPrvsSteBuyDrawBtchips = (res) =>{

    const rankinginfoElement = document.getElementById("rankinginfo");
    rankinginfoElement.classList.add("pokerrankingPortait");
    if(Number(res.anteBetAmount) >0){
     let convrAntebt = this.topLable_rsp(res.anteBetAmount);
     this.setState((prevState) => ({
      anteBetAmount:res.anteBetAmount,
      totalbet:res.totalBetAmount,
      infoText:res.message,
      //  storeantebetchips: [
      //    ...prevState.storeantebetchips,
      //    { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
      //  ],
     }));
   
   }
 
   if(res.playerReplaceCardAmount > 0|| res.playerBuyCardAmount > 0){
     this.prevstateBuyorDrawChip(res);
   }


  const betbutton = document.getElementById("betbtn");
  const foldbutton = document.getElementById("Foldbtn");

const drawbutton = document.getElementById("drawbtn");
const buybutton = document.getElementById("buybtn");                     
let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
let betTextaddtoBetClass = "bet-text-addtoBet20";





let convrBet = this.topLable_rsp(res.anteBetAmount * 2);

switch (this.state.count) {
case 1:
betchipsaddtobetwindows.src = chip_10;
// betTextaddtoBetElement.textContent = 10;
betTextaddtoBetElement.textContent = convrBet;
betTextaddtoBetClass = "bet-text-addtoBet10";
break;
case 2:
betchipsaddtobetwindows.src = chip_25;
betTextaddtoBetElement.textContent = convrBet;
break;
case 3:
betchipsaddtobetwindows.src = chip_50;
betTextaddtoBetElement.textContent = convrBet;
break;
case 4:
betchipsaddtobetwindows.src = chip_100;
betTextaddtoBetElement.textContent = convrBet;
break;
case 5:
betchipsaddtobetwindows.src = chip_500;
betTextaddtoBetElement.textContent = convrBet;
break;

default:
betTextaddtoBetClass = "bet-text-addtoBet20"
break;
}
this.betchipactivated = true;

document.getElementById("betpannelrussiancover").style.display = "block";
if (window.innerHeight > window.innerWidth) {
betTextaddtoBetElement.classList = "";
betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait");
Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait);
gsap.to(this.chipbetref.current, {
// x: -10,
// y: -950,
x: -27,
y: -925,
duration: 0.00001,
zIndex: 2,
autoAlpha:1,
opacity: 1,
});


gsap.to(betTextaddtoBetElement, {
// x: 12,
// y: -1739,
x: -3,
y: -1710,
duration: 0.00001,
zIndex: 2,
opacity: 1,
visibility: true,
onStart: function () {
 betTextaddtoBetElement.style.zIndex = 2;
 betTextaddtoBetElement.style.visibility = 'visible';
}
});

}else{


  betTextaddtoBetElement.classList = "";
  betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp");
  Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);
  gsap.to(this.chipbetref.current, {

    x: -1060,
    y: 170,
    duration: 0.00001,
    zIndex: 2,
    autoAlpha:1
  });


  gsap.to(betTextaddtoBetElement, {
    x: -1060,
    y: 170,
    duration: 0.00001,
    zIndex: 2,
    autoAlpha: 1,
    onStart: function () {
      betTextaddtoBetElement.style.zIndex = 2;
      betTextaddtoBetElement.style.visibility = 'visible';
    }
  });


  if(this.playerWinBonus === true){
    if (document.getElementById('chip10centerbns')) {
      gsap.to("#chip10centerbns", {
        x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
      });         
    }
  }


}



  }



  prevStatecheckPlayerOrDealerWinMblLndspe = (res) =>{

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
    document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";

    buytextElement.className = "";
    buytextElement.style.cssText = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";


    switch (res.winner) {
      case "Dealer":
      case "Player":
        this.showPlayerorDealerorFold(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":

      
        this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerFinalCards);
        this.shwnPrvsSteBuyDrawBtchips(res) 
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";

        betbtntextElement.className ="";
        foldtextElement.className = "";
        document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");        
        this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true,
          infoText: res.message, pokerrankingdeler: res.dealerHand,pokerranking:res.playerHand,disablemultibutton: false 
         });

        betbtntextElement.classList.add("betLandscape");
        betbtntextElement.textContent = this.props.language.Yes;
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);

        foldtextElement.classList.add("foldtextLandscape");
        foldbutton.src = Foldbn;
        Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
        foldtextElement.textContent = this.props.language.No;
      default:
        break;
    }


   


  }





  
  

  russianPokerresp = (res) => {

    //// }, dealerCardsLength*300+3000)
    // this.setState({betdeductions:0,noupdateblnce:false})
    // this.state.betdeductions=0;
    //  this.updateplayerbalnace();

    // document.getElementById("rankinginfodeler").style.textContent= res.message
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ playerhand_status: res.playerHand })
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      let t1 = gsap.timeline();
          t1.to("#chip10centerbns", {
            x:813, y:553, duration: 0.5, opacity: 0,
            onComplete: () => {
              gsap.set("#chip10centerbns", { opacity:0});
            }
          });
   this.insurenceactiveornot(res)
      // this.revealDealercards(res);

    }

    else {
      document.getElementById("insurenceactive").className = "";
      //  const poker_Rankingsinsurence = [
      //     "Royal Flush","Straight","Straight Flush", "Four of a Kind", 
      //     "Full House", "Flush", "Three of a Kind"
      //   ];
      if (window.innerHeight > window.innerWidth) {
       
        
        // if (poker_Rankingsinsurence.includes(res.playerHand)) {

        // if (
        //   res.playerHand == "Royal Flush" || "Straight Flush" || "Four of a Kind" || "Full House" || "Flush" || "Three of a Kind") {
          // res.playerHand == "One Pair" ||{

          if (this.playerWinBonus === true) {
            if (document.getElementById("chip10centerbns")) {
              gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
              this.playerWinBonus = false;
            }
          }
          if(res.insurance){
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactivePortrait");

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({ buttontext: "insurence", betbutton: "noinsurence", insurencebtn: false, disablemultibutton: true,betdecreamentbtn: false, betchipincrementbtn: false, });
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").classList = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("noinsurencePortrait");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("insurencePortrait");
        } else {

          // setTimeout(() => {
          //   this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
          //   document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
          // }, 3000);

          this.revealDealercards(res)
          this.clertime7 = setTimeout(() => {

            this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
            // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait");
            document.getElementById("rankinginfodeler").classList.add(`${this.state.rankinginfodelercss}`);

            switch (res.winner) {
              case "Dealer":
                this.ifDealerWinsMobilePortraitAction(res);
                break;

              // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
              case "Player":
                this.ifPlayerWinsMobilePortraitAction(res);
                break;
              // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
               case "Dealer_no_hand":
                document.getElementById("betactive").src = "";
                document.getElementById("bettext").style.color = "";

                this.setState({ betbutton: "YES", buttontext: "NO",Yesbtn:1,Nobtn:1,keyboardhandle:true });
                document.getElementById("betbtntext").textContent = this.props.language.Yes;
                betbutton.src = Betbtn;
                // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
                Object.assign(betbutton.style, this.state.betbuttoncss);


                foldbutton.src = Foldbn;
                // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
                Object.assign(foldbutton.style, this.state.foldbuttoncss);

                // foldbutton.src = Foldbn;
                document.getElementById("foldtext").textContent = this.props.language.No;
                document.getElementById("betbtntext").classList.remove("insurence", "betwindows");
                document.getElementById("foldtext").classList.remove("noinsurence", "foldtextwindows");
                document.getElementById("betbtntext").classList.add(`${this.state.betbtntextcss}`);
                document.getElementById("foldtext").classList.add(`${this.state.foldbtntextcss}`);
                // document.getElementById("betbtntext").classList.add("betportrait");
                // document.getElementById("foldtext").classList.add("foldtextportrait");
              default:
                break;
            }
          }, 3500)
        }


      }else{

        if(res.insurance){
          document.getElementById("insurencetext").style.color = "#ffffff";
          document.getElementById("insurenceactive").src = bnsactive;
          document.getElementById("insurenceactive").classList.add("insurenceactiveLndsp");
          // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
          this.setState({ infoText: res.message, })

          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          this.setState({ 
            buttontext: "insurence", 
            betbutton: "noinsurence", 
            insurencebtn: false, 
            disablemultibutton: true,
            betdecreamentbtn: false, 
            betchipincrementbtn: false,
            // pokerrankingdeler: res.dealerHand,
          });

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);


          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

          document.getElementById("betbtntext").textContent = this.props.language.No_Insurence;
          document.getElementById("betbtntext").classList = "";
          document.getElementById("betbtntext").style.cssText = "";
          document.getElementById("betbtntext").classList.add("betLandscape");
          document.getElementById("foldtext").textContent = this.props.language.Insurence;
          document.getElementById("foldtext").classList.add("foldtextLandscape");
        } else {

          this.revealDealercards(res);
          this.clertime7 = setTimeout(() => {
            this.checkPlayerOrDealerWinMblLndspe(res);
          }, 5000)
        }


        
      }

    }

  }

  


   checkPlayerOrDealerWinMblLndspe = (res) =>{

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const drawtextElement = document.getElementById("drawtext");
    const buytextElement = document.getElementById("buytext");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");

    const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })
    // document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe");
    document.getElementById("rankinginfodeler").classList.add(`${this.state.rankinginfodelercss}`);
    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");

    betbutton.className = "";
    betbutton.style.cssText = "";

    foldbutton.className = "";
    foldbutton.style.cssText = "";

    drawtextElement.className = "";
    drawtextElement.style.cssText = "";

    buytextElement.className = "";
    buytextElement.style.cssText = "";

    betbtntextElement.className = "";
    betbtntextElement.style.cssText = "";

    foldtextElement.className = "";
    foldtextElement.style.cssText = "";


    switch (res.winner) {
      case "Dealer":
      this.updateplayerbalnace();
      console.log(this.state.anteBetAmount, this.state.bonusbetamount);
      this.setState((prevState) => ({
      previousAnteBetAmount: res.anteBetAmount,
      previousBonusbetamount: res.bonusBetAmount,
      totalbet: 0,
      anteBetAmount: 0,
      bonusbetamount: 0,
      insurencetbet: 0,
      prevStatestoreantebetchips:[...prevState.storeantebetchips],
      prevStatestorebonuschips:[...prevState.storebonuschips],
      storebonuschips: [],
      storeinsurancechips:[],
      // betdeductions: 0
    }));

    this.clertime48 = setTimeout(() => {
      this.clearActivesWeDid();
      document.getElementById("mobileAntiMaskCover").style.display = "none";
    }, 2500)

    document.getElementById("mobileAntiMaskCover").style.display = "none";
   


    document.getElementById("betactive").src = "";
    document.getElementById("bettext").style.color = "";
    document.getElementById("betactive").classList.remove("betactive");
    this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
    let t1 = gsap.timeline();

    t1.to("#chipcenterposition", {
      y: -700, duration: 0.2, opacity: 1,
      onComplete: () => {
        gsap.set("#chipcenterposition", { autoAlpha:0 });
      }
    });
    t1.to("#chipcenterposition", {
       y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,
      onComplete: () => {
        gsap.set("#chipcenterposition", { autoAlpha: 0, });
      }
    });
    t1.play();


    let t2 = gsap.timeline();
    t2.to("#betchipsaddtobet", {
       y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
      onComplete: () => {
        gsap.set("#betchipsaddtobet", { autoAlpha: 0 });
      }
    });
    t2.to("#betchipsaddtobet", {
      x:0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0,

    });
    t2.play();

    let t3 = gsap.timeline();
    t3.to("#betTextaddtoBet", {
       y: -700, duration: 0.5, ease: "power2.inOut", autoAlpha: 0,
      onComplete: () => {
        gsap.set("#betTextaddtoBet", { autoAlpha: 0 });
      }
    });
    t3.to("#betTextaddtoBet", {
       x:0,y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,

    });

    t3.play();


    let t4 = gsap.timeline();
    t4.to(this.chipdrawref.current, {
       y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
      onComplete: () => {
        gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
      }
    });
    t4.to(this.chipdrawref.current, {
      x:0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
      onComplete: () => {
        gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
      }
    });

    t4.play();


    if (this.insuranceActive) {
      let t5 = gsap.timeline();
      t5.to("#chiptoinsurence", {
        x: 720, y: 45, duration: 0.5, ease: "power2.inOut", opacity: 1,
      });
      t5.play();
    };

    if (this.wanttobuydelercrd === true) {
      let t1 = gsap.timeline()
      t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
      t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
      t1.to(this.buyDealerCardDrawn.current, { opacity: 0, y: 0, zIndex: -5 });
      t1.play();
    }


    betbtntextElement.className ="";
    foldtextElement.className = "";


   
    this.clertime49 = setTimeout(() => {
      this.setState({ infoText: this.props.language.Place_antebet });
      betbutton.src = Betbtn;
      // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
      Object.assign(betbutton.style,this.state.betbuttoncss);
      betbtntextElement.textContent = this.props.language.Rebet;
      // betbtntextElement.classList.add('betLandscape');
      betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
      
      foldbutton.src = Foldbn;
      // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
      Object.assign(foldbutton.style, this.state.foldbuttoncss);
      foldtextElement.textContent = this.props.language.RebetX2;
      // foldtextElement.classList.add("foldtextLandscape");
      foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
    }, 3000);

    // delaerChipAnimDiv.className ="";
    // dealerchipElement.src = "";
    if(this.state.mutesounds){
      this.playerLose.pause();
    }else{
      this.playerLose.play();
    }

    document.getElementById("onepair").style.color = "";
    document.getElementById("Threekind").style.color = "";
    document.getElementById("aceking").style.color = "";
    document.getElementById("fullhse").style.color = "";
    document.getElementById("Straightflush").style.color = "";
    document.getElementById("frofkind").style.color = "";
    document.getElementById("twopair").style.color = "";
    document.getElementById("flush").style.color = "";
    document.getElementById("stright").style.color = "";
    document.getElementById("royalflush").style.color = "";
        break;

      // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
      case "Player":
        const winpopuprspelement = document.getElementById("winpopup_rsp");   
        const winamtElement = document.getElementById("winamt_rsp");
        const delaerChipAnimDiv = document.getElementById('delaerchipanim')
        const dealerchipElement = document.getElementById('dealerchip');

        this.updateplayerbalnace();
        this.clertime53 = setTimeout(() => {
          this.clearActivesWeDid();
          document.getElementById("mobileAntiMaskCover").style.display = "none";
        }, 2500);
    
        console.log(this.state.anteBetAmount, this.state.bonusbetamount);
    
        this.setState((prevState) => ({
          isbonuspattable: false,
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          insurencetbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          prevStatestoreantebetchips:[...prevState.storeantebetchips],
          prevStatestorebonuschips:[...prevState.storebonuschips],
          storebonuschips: [],
          storeinsurancechips:[],
        }));
    
    
        
        delaerChipAnimDiv.className = "";
        dealerchipElement.src = "";
        dealerchipElement.className = "";
    
        this.resetAnimationChips = true;
        document.getElementById("mobileAntiMaskCover").style.display = "none";
       
       
        
  
  
        winpopuprspelement.classList.add("winPopupForMLsp");
        winamtElement.classList.add("winamtMpSw");
  
        gsap.fromTo(winpopuprspelement,
          {autoAlpha: 0,scale:0,duration:0.5,},
          {autoAlpha: 1,scale:1,duration:0.5,rotate:0}
      )
    
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");
       
        
        gsap.to("#chipcenterposition", { x: 720, y: -130, duration: 0.5 });



        gsap.to("#betchipsaddtobet", { x: -340, y: -130, duration: 0.5, delay: 1 });
        gsap.to("#betTextaddtoBet", { x: -340, y: -130, duration: 0.5, delay: 1 });
        // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });
        let t5 = gsap.timeline();
        t5.to(this.chipdrawref.current, {
          // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
          x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { opacity: 0 });
          }
        });
        t5.to(this.chipdrawref.current, {
          x: 548, y: -36, duration: 0.001, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set(this.chipdrawref.current, { opacity: 0, delay: 2 });
          }
        });
    
        t5.play();
    
        if (this.insuranceActive) {
          gsap.to("#chiptoinsurence", { x: 720, y: 45, duration: 0.5, delay: 1 });
        };
    
        // if(this.wanttobuydelercrd === true){
        //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
        // }
    
        if (this.wanttobuydelercrd === true) {
          let t1 = gsap.timeline()
          t1.to(this.buyDealerCardDrawn.current, { y: -700, duration: 0.5 });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
          t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
          t1.play();
        }
    
        this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })

        
        betbtntextElement.className ="";
        foldtextElement.className = "";
    
       
    
        switch (this.state.count) {
          case 1:
            dealerchipElement.src = chip_10;
            break;
          case 2:
            dealerchipElement.src = chip_25;
            break;
          case 3:
            dealerchipElement.src = chip_50;
            break;
          case 4:
            dealerchipElement.src = chip_100;
            break;
          case 5:
            dealerchipElement.src = chip_500;
            break;
          default:
            break;
        }
    
        Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerLndsp)
    
        let t6 = gsap.timeline()
        t6.to("#dealerchip", {
          x: 645,
          y: 425,
          duration: 0.5,
          ease: "power2.inOut",
          onStart: () => {
            gsap.set('#dealerchip', { opacity: 1, zIndex: 2 })
          },
          onComplete: () => {
            gsap.set('#dealerchip', { opacity: 0, zIndex: -10 })
           
          },
        });
  
  

         
         
          
      

        if (this.state.mutesounds) {
          this.playerWin.pause();
        } else {
          this.playerWin.play();
        }
        this.resetPyrWnCpsAmn = true;

        this.clertime54 = setTimeout(() => {

          betbutton.src = Betbtn;
          // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          Object.assign(betbutton.style,this.state.betbuttoncss);
          betbtntextElement.textContent = this.props.language.Rebet;
          // betbtntextElement.classList.add('betLandscape');
          betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
      
          foldbutton.src = Foldbnwindows;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          Object.assign(foldbutton.style, this.state.foldbuttoncss);
          foldtextElement.textContent = this.props.language.RebetX2;
          // foldtextElement.classList.add("foldtextLandscape");
          foldtextElement.classList.add(`${this.state.foldbtntextcss}`);




          gsap.fromTo(winpopuprspelement, { scale: 1, duration: 0.5, rotate: 360 }, { autoAlpha: 0, scale: 0, duration: 0.5 })
          winpopuprspelement.classList.remove("winPopupForMLsp");
          winamtElement.classList.remove("winamtMpSw");
          this.setState({
            totalwinRussian: 0,
            infoText: this.props.language.Play_Njoy
          });

          document.getElementById("onepair").style.color = "";
          document.getElementById("Threekind").style.color = "";
          document.getElementById("aceking").style.color = "";
          document.getElementById("fullhse").style.color = "";
          document.getElementById("Straightflush").style.color = "";
          document.getElementById("frofkind").style.color = "";
          document.getElementById("twopair").style.color = "";
          document.getElementById("flush").style.color = "";
          document.getElementById("stright").style.color = "";
          document.getElementById("royalflush").style.color = "";


             
        gsap.to("#chipcenterposition",  { x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0});
        gsap.to("#betTextaddtoBet",     { x: 0, y: 0, duration:0.0001,zIndex: -2,opacity: 0});
        gsap.to("#betchipsaddtobet",    { x: 0, y: 0, duration: 0.0001,zIndex: -2,opacity: 0});
        gsap.to(this.chipdrawref.current, {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0});
        if (this.insuranceActive) {
          gsap.to("#chiptoinsurence", { x: 0, y:0, duration: 0.0001,zIndex: -2, opacity: 0 });
        };
        if (this.wanttobuydelercrd === true) {
          gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2,duration:0.0001})
        
        }
        gsap.set('#dealerchip', {x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})
        }, 4000)


        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      case "Dealer_no_hand":
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        betbtntextElement.className ="";
        foldtextElement.className = "";
                
        this.setState({ betbutton: "YES", buttontext: "NO", Yesbtn: 1, Nobtn: 1, keyboardhandle: true ,disablemultibutton: false });

        // betbtntextElement.classList.add("betLandscape");
        betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
        betbtntextElement.textContent = this.props.language.Yes;
        betbutton.src = Betbtn;
        // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
        Object.assign(betbutton.style,this.state.betbuttoncss);

        // foldtextElement.classList.add("foldtextLandscape");
        foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
        foldbutton.src = Foldbn;
        // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
        Object.assign(foldbutton.style, this.state.foldbuttoncss);
        foldtextElement.textContent = this.props.language.No;
      default:
        break;
    }


   


  }


 

















  ifDealerWinsMobilePortraitAction = (res) => {

    this.clertime47 = setTimeout(() => {

      this.updateplayerbalnace();
      console.log(this.state.anteBetAmount, this.state.bonusbetamount);
      this.setState((prevState) => ({
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        totalbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        insurencetbet: 0,
        prevStatestoreantebetchips:[...prevState.storeantebetchips],
        prevStatestorebonuschips:[...prevState.storebonuschips],
        storebonuschips: [],
        storeinsurancechips:[],
        // betdeductions: 0
      }));

      this.clertime48 = setTimeout(() => {
        this.clearActivesWeDid();
      }, 1000);

      document.getElementById("mobileAntiMaskCover").style.display = "none";
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const betTextaddtoBetElement = document.getElementById("betTextaddtoBet");


      document.getElementById("betactive").src = "";
      document.getElementById("bettext").style.color = "";
      document.getElementById("betactive").classList.remove("betactive");
      this.setState({ betbutton: "REBET", bsbtn: true, buttontext: "REBETX2", betchipincrementbtn: false, betdecreamentbtn: false });
      let t1 = gsap.timeline();

      t1.to("#chipcenterposition", {
        y: -2060, duration: 0.2, opacity: 1,
        onComplete: () => {
          gsap.set("#chipcenterposition", { opacity: 0 });
        }
      });
      t1.to("#chipcenterposition", {
        x: 13, y: 1200, duration: 0.0001, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chipcenterposition", { opacity: 0, });
        }
      });
      t1.play();


      let t2 = gsap.timeline();
      t2.to("#betchipsaddtobet", {
        x: -20, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set("#betchipsaddtobet", { opacity: 0 });
        }
      });
      t2.to("#betchipsaddtobet", {
        x: -2, y: 72, duration: 0.5, zIndex: -2, opacity: 0,

      });
      t2.play();

      let t3 = gsap.timeline();
      t3.to("#betTextaddtoBet", {
        x: 110, y: -2000, duration: 0.5, ease: "power2.inOut", opacity: 0,
        onComplete: () => {
          gsap.set("#betTextaddtoBet", { opacity: 0 });
        }
      });
      t3.to("#betTextaddtoBet", {
        x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0,

      });

      t3.play();


      let t4 = gsap.timeline();
      t4.to(this.chipdrawref.current, {
        // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set(this.chipdrawref.current, { opacity: 0 });
        }
      });
      t4.to(this.chipdrawref.current, {
        x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
        }
      });

      t4.play();


      if (this.insuranceActive) {
        let t5 = gsap.timeline();
        t5.to("#chiptoinsurence", {
          x: -430, y: 1136, duration: 0.5, ease: "power2.inOut", opacity: 1,

          onComplete: function () {
            gsap.set("#chiptoinsurence", { opacity: 0 });
          }
        });
        t5.to("#chiptoinsurence", {
          // x: 550, y: 145, duration: 0.5, zIndex: -2, zIndex: -2, opacity: 0,
          x: -430, y: 1125, duration: 0.5, zIndex: -2, zIndex: -2, opacity: 0,
          onComplete: function () {
            gsap.set("#chiptoinsurence", { opacity: 1, delay: 2 });
          }

        });
        t5.play();
      };

      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }

      betbutton.src = Betbtn;
      // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
      Object.assign(betbutton.style, this.state.betbuttoncss);

      foldbutton.src = Foldbn;
      // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
      Object.assign(foldbutton.style, this.state.foldbuttoncss);
      document.getElementById("betbtntext").textContent = this.props.language.Rebet;

      document.getElementById("betbtntext").classList.add(`${this.state.betbtntextcss}`);
      document.getElementById("foldtext").classList.add(`${this.state.foldbtntextcss}`);
      // document.getElementById("betbtntext").classList.add("betportrait");
      // document.getElementById("foldtext").classList.add("rebettextportrait");

      document.getElementById("foldtext").textContent = this.props.language.RebetX2;
      this.clertime49 = setTimeout(() => {
        this.setState({ infoText: this.props.language.Place_antebet });
      }, 3000);

      // delaerChipAnimDiv.className ="";
      // dealerchipElement.src = "";
      if (this.state.mutesounds) {
        this.playerLose.pause();
      } else {
        this.playerLose.play();
      }

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


    }, 2000);


  }



  suffleDealerCards = (data) => {
    let cnt = data.length;
    for (let i = 0; i < cnt; i++) {
      let b1 = data[i];
      this.cardsshuffle = new Audio(cardsshuffle);
      if (this.cardsshuffle) {
        this.cardsshuffle.currentTime = 0;
        if (this.state.mutesounds) {
          this.cardsshuffle.pause()
        } else {
          this.cardsshuffle.play()
        }



      }
      switch (i) {
        case 0:
          this.dealerCardsContainer.current.SuffleCard("cardZero", b1, cnt);
          break;
        case 1:
          this.dealerCardsContainer.current.SuffleCard("cardOne", b1, cnt);
          break;
        case 2:
          this.dealerCardsContainer.current.SuffleCard("cardTwo", b1, cnt);
          break;
        case 3:
          this.dealerCardsContainer.current.SuffleCard("cardThree", b1, cnt);
          break;
        case 4:
          this.dealerCardsContainer.current.SuffleCard("cardFour", b1, cnt);
          break;
        case 5:
          this.dealerCardsContainer.current.SuffleCard("cardFive", b1, cnt);
          break;
        default:
          break;
      }
    }
  };

  rpReplaceCards = (res) => {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      // this.insurenceactiveornot(res);
      // this.Showbonustouser(res);

    }




    // alert("...drawcrds");
   
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];

    console.log(res);

    this.unsequenceDrawCards = [...res.playerCards];
    this.sequenceDrawCards = [...res.playerFinalCards];
    let cnt = this.unsequenceDrawCards.length;

    let numberOfCardsToDraw = res.playerNewCardsToReplace.length;


    let mutesounds = this.state.mutesounds;

    function playSound(i) {
      let audio = new Audio(cardsdropping_snd);
      audio.currentTime = 0;
      if (mutesounds) {
        audio.pause();
      } else {
        audio.play();
      }


      audio.addEventListener('ended', function () {
        if (i  < (numberOfCardsToDraw*2)-1) {
          this.clertime50 = setTimeout(() => {
            playSound(i+1);
          // }, 200)
          },250)
        }
      });
    }
    playSound(0)
    for (let i = 0; i < cnt; i++) {
    
      var b1 = this.unsequenceDrawCards[i];
    
      switch (i) {
        case 0:
          if (this.shuffledPlayerCards[0] != b1) {
            this.cardsRef.current.Drawcard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.shuffledPlayerCards[1] != b1) {
            this.cardsRef.current.Drawcard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.shuffledPlayerCards[2] != b1) {
            this.cardsRef.current.Drawcard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.shuffledPlayerCards[3] != b1) {
            this.cardsRef.current.Drawcard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.shuffledPlayerCards[4] != b1) {
            this.cardsRef.current.Drawcard("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.shuffledPlayerCards[5] != b1) {
            this.cardsRef.current.Drawcard("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    this.clertime8 = setTimeout(() => {
      this.highlightPlayerCardsName(res.playerHand);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      this.setState({ pokerranking: res.playerHand })
      this.onDrawingCards(this.sequenceDrawCards, true,res);
      this.shuffledPlayerCards = [...this.sequenceDrawCards];

      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      document.getElementById("drawtext").textContent = "";
      document.getElementById("buytext").textContent = "";

      drawbutton.src = "";
      drawbutton.style.cssText="";
      
      buybutton.src = "";
      buybutton.style.cssText="";
     
    }, numberOfCardsToDraw * 2 * 500);
  };
 
  ShowrpReplaceCards = (res) => {
  
   if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      
    // this.insurenceactiveornot(res);
    // this.Showbonustouser(res);
    
    }else{
    
    this.setState({ infoText: res.message });
    
    console.log(res);

  

  this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards)
      
    }
  
  
  



      this.highlightPlayerCardsName(res.playerHand);
      if (this.state.isMobilePortraitLndsp === true) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      this.setState({ pokerranking: res.playerHand })
      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      document.getElementById("drawtext").textContent = "";
      document.getElementById("buytext").textContent = "";

      drawbutton.src = "";
      drawbutton.style.cssText="";
      
      buybutton.src = "";
      buybutton.style.cssText="";
     
    
  };

  BuyplayerCard() {
    
    if(this.state.anteBetAmount*4 + this.state.bonusbetamount > this.props.balance){
      this.setState({
        betshigh_balnce:true,
        lowBalaceErrorInfo:`insufficient Funds to place the Buy of ${this.state.anteBetAmount},Please DEPOSIT to continue playing.` 
      });
      this.lowBalanceErrorInformationPopupanimation()
    }else{
      this.setState({betshigh_balnce:null})
      this.pokerchipsnd = new Audio(pokerchipsnd);
      if (this.state.mutesounds) {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.pause();
  
      }
      else {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.play();
  
      }
  
      document.getElementById("crdscover").classList.add("crdscover");
      const drawbutton = document.getElementById("drawbtn");
      const buybutton = document.getElementById("buybtn");
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
  
  
      betbutton.src = "";
      betbutton.style.cssText="";
      
      foldbutton.src = "";
      foldbutton.style.cssText="";
      
      drawbutton.src = "";
      drawbutton.style.cssText="";
     
  
      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.cssText="";
    
  
  
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount
        }))
        this.drawbuybets();
        document.getElementById("betbtntext").innerHTML = "";
        document.getElementById("betbtntext").classList.remove("betwindows");
        document.getElementById("foldtext").classList.remove("foldtextwindows");
        document.getElementById("foldtext").textContent = "";
        document.getElementById("drawtext").textContent = "";
        document.getElementById("buytext").textContent = "";
  
      } else {
  
        this.setState((prevState) => ({
          totalbet: prevState.totalbet + prevState.anteBetAmount,
          buydrawCheck: !prevState.buydrawCheck,
          betdeductions: prevState.betdeductions + prevState.anteBetAmount,
          infoText:""
        }))
  
        let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
        let betchipsdraw = document.getElementById("betchipsaddtodraw");
        let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");
  
        document.getElementById("betbtntext").innerHTML = "";
        document.getElementById("betbtntext").classList.remove("betwindows", "betportrait");
        document.getElementById("foldtext").classList.remove("foldtextwindows");
        document.getElementById("foldtext").textContent = "";
        document.getElementById("drawtext").textContent = "";
  
  
  
  
        let betTextaddtodrawElementClass = "bet-text-addtoBet20";
        let convrBuy = this.topLable_rsp(this.state.anteBetAmount);
        switch (this.state.count) {
          case 1:
            betchipsdraw.src = chip_10;
            // betTextaddtodrawElement.textContent = 10;
            betTextaddtodrawElement.textContent = convrBuy;
            betTextaddtodrawElementClass = "bet-text-addtoBet10";
            break;
          case 2:
            betchipsdraw.src = chip_25;
            betTextaddtodrawElement.textContent = convrBuy;
            break;
          case 3:
            betchipsdraw.src = chip_50;
            betTextaddtodrawElement.textContent = convrBuy;
            break;
          case 4:
            betchipsdraw.src = chip_100;
            betTextaddtodrawElement.textContent = convrBuy;
            break;
          case 5:
            betchipsdraw.src = chip_500;
            betTextaddtodrawElement.textContent = convrBuy;
            break;
  
          default:
            betTextaddtodrawElementClass = "bet-text-addtoBet20";
            break;
        }
  
  
        this.buydrawchipactivated = true;
  
        if (window.innerHeight > window.innerWidth) {
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          document.getElementById("betbtntext").classList.remove("betportrait");
          document.getElementById("foldtext").classList.remove("foldtextportrait");
  
          betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
          betTextaddtodrawElement.classList = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddtodraw');
          betchipsdraw.classList.add("betchipsaddtodraw")
  
          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: 322,
            y: -1230,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha:1,
            onStart: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1 });
            }
          });
  
        } else {
  
          // document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          document.getElementById("betpannelrussiancover").style.display = "block";
          document.getElementById("betbtntext").classList.remove("betLandscape");
          document.getElementById("foldtext").classList.remove("foldtextLandscape");
  
          betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
          betTextaddtodrawElement.classList = "";
          betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');
          betchipsdraw.classList.add("betchipsaddtodraw")
  
          gsap.to(this.chipdrawref.current, {
            // x: 360,
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
            opacity: 1,
            autoAlpha:1,
            onStart: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1 });
            }
          });
  
  
        }
      }
  
  
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "BUY_PLAYER_CARD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
      console.log(body);
      this.props.network.sendof(body);

    }

   
  }


  buyplayercardresp(res) {

    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
     const rankinginfoElement = document.getElementById("rankinginfo");
   

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
    //  this.insurenceactiveornot(res);
    // this.Showbonustouser(res);

      // this.setState({ infoText: res.message });
    }
    else {
      this.setState({ 
        // infoText: res.message, 
        pokerranking: res.playerHand });

      if (rankinginfoElement) {
        if (this.state.isMobilePortraitLndsp) {
            if(window.innerHeight > window.innerWidth){
            rankinginfoElement.style.left = "32.5%";
          }else{
            rankinginfoElement.style.left = "61.5%";
          }
        }else{

        };
      }
    }
   
    // alert("....123");
    //  this.setState({ infoText: res.message, pokerrankingdeler: res.playerHand });
    console.log("RP Buy Card Result: ");
    console.log(res);

 

    this.buyCardUnsequenceArray = [...res.playerCards];
    this.buyCardsequenceArray = [...res.playerFinalCards];
    console.log(this.buyCardUnsequenceArray);
    // this.shuffledPlayerCards

    console.log("buyCardUnsequenceArray", this.buyCardUnsequenceArray);

    let cnt = this.buyCardUnsequenceArray.length;
   
    

    for (let i = 0; i < cnt; i++) {
      var b1 = this.buyCardUnsequenceArray[i];

      switch (i) {
        case 0:
          if (this.shuffledPlayerCards[0] == b1) {
            this.cardsRef.current.MoveCard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.shuffledPlayerCards[1] == b1) {
            this.cardsRef.current.MoveCard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.shuffledPlayerCards[2] == b1) {
            this.cardsRef.current.MoveCard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          if (this.shuffledPlayerCards[3] == b1) {
            this.cardsRef.current.MoveCard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.shuffledPlayerCards[4] == b1) {
            this.cardsRef.current.MoveCard("cardFour", b1, cnt);
          }
          break;
        case 5:
          console.log("this.buyCardUnsequenceArray[5] != b1  : ", this.buyCardUnsequenceArray[5], b1, this.buyCardUnsequenceArray[5] != b1);
          if (this.shuffledPlayerCards[5] != b1) {
            this.cardsRef.current.addCards("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }

    }

    
    this.clertime9 = setTimeout(() => {
      if (this.state.isMobilePortraitLndsp) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
      this.highlightPlayerCardsName(res.playerHand);
      this.onDrawingCards(this.buyCardsequenceArray, true,res);
      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";
      document.getElementById("drawtext").textContent = "";

      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";
      buybutton.style.transform = "";
    }, 3000);
  }

  ShowBuyplayercardresp(res) {
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");

    
   

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
      this.setState({ 
        infoText: res.message, 
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount:res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
       });
       this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards); 
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
  
      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows);
      document.getElementById("betbtntext").textContent = this.props.language.Bet;
      document.getElementById("betbtntext").classList.add("betwindows");
    

      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows);
      document.getElementById("foldtext").textContent = this.props.language.Fold;
      document.getElementById("foldtext").classList.add("foldtextwindows");

      document.getElementById("bnsactive").src =bnsactive
      document.getElementById("bnsactive").classList.add("betactive");
      let betexttodraw = document.getElementById("betTextaddtodraw");
      gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodraw");
     
     
      Object.assign(betchipsdraw.style,porttraitejson.betchipsdrawwindows)
   


    
      let betTextaddtoBetClass = "bet-text-addtodraw20";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      if (res.playerBuyCardAmount > 0) {
        betexttodraw.textContent = this.topLable_rsp(res.playerBuyCardAmount);
      }
      else {
        betexttodraw.textContent = this.topLable_rsp(res.playerReplaceCardAmount);
      }

      switch (this.state.count) {
        case 1:
         
          betchipsdraw.src = chip_10;
          betTextaddtoBetClass = "bet-text-addtodraw10";

          break;
        case 2:
         
          betchipsdraw.src = chip_25;
          betexttodraw.textContent = this.chipVal[1].label;
          break;
        case 3:
       
          betchipsdraw.src = chip_50;
          
          break;
        case 4:
          betchipsdraw.src = chip_100;
          break;
        case 5:
          betchipsdraw.src = chip_500;
          break;

        default:
          break;
      }
      this.clertime45= setTimeout(() => {
        betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

      }, 100)
      let t1 = gsap.timeline();
      // t1.to("#betchipsaddtodraw,betchipsaddtconfrm", {
        t1.to("#betchipsaddtodraw", {
        x: -1084,
        y: -598, duration: 0.1, opacity: 1,

      });

      t1.play();


    //  this.insurenceactiveornot(res);
    // this.Showbonustouser(res);

      this.setState({ infoText: res.message });
      
      
      
      
      
    }
    
    
    else {
      this.setState({ 
        infoText: res.message, 
        pokerranking: res.playerHand,
        totalbet: res.totalBetAmount,
        anteBetAmount:res.anteBetAmount,
        buttontext: "fold",
        betbutton: "Bet",
        buydrawCheck:true
        // bonusbetamount: res.bonusBetAmount,
        // betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
       });
      
      


    

      document.getElementById("betbtntext").innerHTML = "";
      document.getElementById("betbtntext").className ="";
      document.getElementById("foldtext").className ="";
      document.getElementById("foldtext").textContent = "";
      document.getElementById("drawtext").textContent = "";


      this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards); 
      this.showParamBetStatus(false);

       if(res.playerReplaceCardAmount > 0|| res.playerBuyCardAmount > 0){
      //  if(res.playerBuyCardAmount > 0){
         this.prevstateBuyorDrawChip(res);
       }

    
     
      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";
      document.getElementById("drawtext").textContent = "";

      document.getElementById("buytext").textContent = "";
      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";
   
  }



  }



  prevstateBuyorDrawChip = (res) =>{

    let betchipsaddtodrawContainerElement = document.getElementById("betchipsaddtodrawContainer");
    let betchipsdraw = document.getElementById("betchipsaddtodraw");
    let betTextaddtodrawElement = document.getElementById("betchipsaddtodrawText");
    let betTextaddtodrawElementClass = "bet-text-addtoBet20";
    const rankinginfoElement = document.getElementById("rankinginfo");

    let betactive
if (window.innerWidth > window.innerHeight) {
  betactive = "betactiveLndsp";
  // betactive = this.state.betactvglwcss;
  
} else {
  betactive = "betactivePortrait";
  // betactive = this.state.betactvglwcss;

}

if(res.betAmount>0){
  document.getElementById("betactive").src = "";
  document.getElementById("betactive").className ="";
}else{
  document.getElementById("betactive").src = bnsactive;
  document.getElementById("betactive").classList.add(betactive);

}







      let convrBuy = this.topLable_rsp(res.anteBetAmount);
      if(res.playerReplaceCardAmount > 0){
         convrBuy = this.topLable_rsp(res.playerReplaceCardAmount);

      }
      this.highlightPlayerCardsName(res.playerHand);
      switch (this.state.count) {
        case 1:
          betchipsdraw.src = chip_10;
          // betTextaddtodrawElement.textContent = 10;
          betTextaddtodrawElement.textContent = convrBuy;
          betTextaddtodrawElementClass = "bet-text-addtoBet10";
          break;
        case 2:
          betchipsdraw.src = chip_25;
          betTextaddtodrawElement.textContent = convrBuy;
          break;
        case 3:
          betchipsdraw.src = chip_50;
          betTextaddtodrawElement.textContent = convrBuy;
          break;
        case 4:
          betchipsdraw.src = chip_100;
          betTextaddtodrawElement.textContent = convrBuy;
          break;
        case 5:
          betchipsdraw.src = chip_500;
          betTextaddtodrawElement.textContent = convrBuy;
          break;

        default:
          betTextaddtodrawElementClass = "bet-text-addtoBet20";
          break;
      }
     
        
      this.buydrawchipactivated = true;
      if (window.innerHeight > window.innerWidth) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("pannelcoverrussian");
        document.getElementById("betbtntext").classList.remove("betportrait");
        document.getElementById("foldtext").classList.remove("foldtextportrait");

        betchipsaddtodrawContainerElement.classList.add("drawChipDiv");
        betTextaddtodrawElement.classList = "";
        betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddtodraw');
        betchipsdraw.classList.add("betchipsaddtodraw")
        rankinginfoElement.classList.add("pokerrankingPortait");
        gsap.to(this.chipdrawref.current, {
          // x: 360,
          x: 322,
          y: -1230,
          duration: 0.000001,
          zIndex: 12,
          autoAlpha:1,
          onStart: () => {
            gsap.set(this.chipdrawref.current, { opacity: 1 });
          }
        });

      } else {

        // document.getElementById("playerDealerCardContainer").style.zIndex = 20;
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverLndsp");
        document.getElementById("betbtntext").classList.remove("betLandscape");
        document.getElementById("foldtext").classList.remove("foldtextLandscape");

        betchipsaddtodrawContainerElement.classList.add("drawChipDivLndsp");
        betTextaddtodrawElement.classList = "";
        betTextaddtodrawElement.classList.add(betTextaddtodrawElementClass, 'betTextaddwLndsp');
        betchipsdraw.classList.add("betchipsaddtodraw")
        rankinginfoElement.classList.add("pokerrankingPlayerLndsp");
        gsap.to(this.chipdrawref.current, {
          x: -1230,
          y: 0,
          duration: 0.00001,
          zIndex: 12,
          opacity: 1,
          onStart: () => {
            gsap.set(this.chipdrawref.current, { opacity: 1 });
          }
        });


      }



      if (rankinginfoElement) {
        if (window.innerHeight > window.innerWidth) {
        if(res.action === "BUY_PLAYER_CARD"||
          res.playerFinalCards.length === 6
        ){
              rankinginfoElement.style.left = "32.5%";
          }else{
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "35%";
            }

        }

        }else{

          if(res.action === "BUY_PLAYER_CARD"||
            res.playerFinalCards.length === 6
          ){
                rankinginfoElement.style.left = "61.5%";
            }else{
              if (this.state.isMobilePortraitLndsp) {
                rankinginfoElement.style.left = "62.5%";
              }
  
          }


        }

        
      };
  
      
      if (this.state.isMobilePortraitLndsp) {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      }
    
    
    
    
    }


  FoldCards() {
    this.clearallsettimeouts()
    this.clear_snd = new Audio(clear_snd);
    if (this.state.mutesounds) {
      this.clear_snd.pause();
    }
    else {
      this.clear_snd.play();
    }

    // this.setState({bsbtn:true,insurencebtn:true,antebetcircle:true})
    // alert("foldcard");
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";

    betbutton.src = "";
    betbutton.style.cssText=""
    
    foldbutton.src = "";
    foldbutton.style.cssText=""
    
    drawbutton.src = "";
    drawbutton.style.cssText=""
    

    buybutton.src = "";
    buybutton.style.cssText=""
    

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      this.setState({ Foldbtn: 1 })
      // document.getElementById("betTextaddtodraw").style.zIndex=-1
      document.getElementById("betbtntext").classList.remove("betwindows");
      document.getElementById("foldtext").classList.remove("foldtextwindows");
      document.getElementById("drawtext").textContent = "";
      document.getElementById("betbtntext").textContent = "";

      buybutton.src = "";
      buybutton.style.cssText = ""
      document.getElementById("buytext").textContent = "";

      let t1 = gsap.timeline();

      t1.to("#chip10centerbns", {
        x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chip10centerbns", { opacity: 0 });
        }

      });
      t1.play();
      gsap.set(["#betchipsaddtconfrm", "#betchipsaddtodraw", "#chipcenterposition", "#betchipsaddtobet"], { clearProps: "all" });
    } else {

      document.getElementById("onepair").style.color = "";
      document.getElementById("Threekind").style.color = "";
      document.getElementById("aceking").style.color = "";
      document.getElementById("fullhse").style.color = "";
      document.getElementById("Straightflush").style.color = "";
      document.getElementById("frofkind").style.color = "";
      document.getElementById("twopair").style.color = "";
      document.getElementById("flush").style.color = "";
      document.getElementById("stright").style.color = "";
      document.getElementById("royalflush").style.color = "";


      this.setState({ betchipincrementbtn: false, betdecreamentbtn: false });

      document.getElementById("betbtntext").classList.remove("betportrait");
      document.getElementById("foldtext").classList.remove("foldtextportrait");
      document.getElementById("betbtntext").textContent ="";
      document.getElementById("foldtext").textContent = "";
      document.getElementById("mobileAntiMaskCover").style.display = "none";
      document.getElementById("betpannelrussiancover").style.display = "none";
      const isPortrait = window.innerHeight > window.innerWidth;
      if (isPortrait) {
        document.getElementById("mobileAntiMaskCover").style.display = "none";
        document.getElementById("betpannelrussiancover").style.display = "none";
        // alert(".......123portraiteclear");
      } else {
        // alert(".......landscape");
      }
    }

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),

      CN: "RUSSIAN_POKER_BET",
      object: {
        action: "FOLD",
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    console.log(body);
    this.props.network.sendof(body);



  }


  InsurenceClaim(txt) {



    if (txt == "insurence"&&this.state.insurencetbet  < this.state.anteBetAmount) {
        this.setState({
          infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are Fun ${this.state.anteBetAmount}.00 - Fun ${this.state.anteBetAmount * 3}.00
        Press NO INSURANCE to continue without insurance`,
        })
  
      }else{

        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtn");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextextElement = document.getElementById("foldtext");
        betbutton.src = "";
        betbtntextElement.className = "";
        betbtntextElement.textContent = "";
        foldbutton.src = "";
        foldtextextElement.className = "";
        foldtextextElement.textContent = "";
        if (txt == "insurence") {
      
          if (this.state.mutesounds) {
            this.betrussiansnd.pause();
          }
          else {
            this.betrussiansnd.play();
    
          }
    
        } else {

          this.setState({
            totalbet: this.state.totalbet - this.state.insurencetbet,
            betdeductions: this.state.betdeductions -this.state.insurencetbet,
            insurencetbet: 0,
          })
    
          if (this.state.mutesounds) {
            this.clear_snd = new Audio(clear_snd)
            this.clear_snd.pause()
    
          }
          else {
            this.clear_snd = new Audio(clear_snd)
            this.clear_snd.play()
    
          }
    
        }
    
    
        let insurencecliam;
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows"
        ) {
          document.getElementById("insurenceRussian").classList.add("insurencenodrop")
          this.setState({insurencebtn:true})
          if (txt == "insurence") {
            insurencecliam = true
            this.insuranceActive = true;
          }
          else {
            document.getElementById("insurenceactive").src = "";
            document.getElementById("insurenceactive").classList.remove("insurenceblink");
            document.getElementById("insurenceRussian").classList.remove("addpointer");
            insurencecliam = false
            this.insuranceActive = false;
            this.setState({disablemultibutton:true})
          }
    
    
        } else {
          this.setState({
            insurencebtn: true,
            betchipincrementbtn: true,
            betdecreamentbtn: true,
            infoText:""
    
          })
          document.getElementById("insurenceactive").src = "";
    
          if (txt == "insurence") {
            insurencecliam = true;
            this.insuranceActive = true;
          }
          else {
            insurencecliam = false;
            this.insuranceActive = false;
            if (window.innerHeight > window.innerWidth) {
              document.getElementById("insurenceactive").classList.remove("insurenceactivePortrait");
              if (document.getElementById("chiptoinsurence")) {
                gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.5, zIndex: -2, opacity: 0 });
              };
    
            }else{
              document.getElementById("insurenceactive").classList.remove("insurenceactiveLndsp");
              if (document.getElementById("chiptoinsurence")) {
                let t1 = gsap.timeline();
                t1.to("#chiptoinsurence", { x: 1050, y: 175, duration: 0.5, zIndex: 2, autoAlpha:1, ease: "power2.inOut"});
                t1.to("#chiptoinsurence", { x: 0, y: 0, duration: 0.00001, zIndex: -2, autoAlpha:0});
              };
            }
    
          }
    
        }
    
    
    
        const body = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "RUSSIAN_POKER_BET",
          object: {
            insuranceBetAmount: Number(this.state.insurencetbet.toFixed(2)),
            action: "INSURANCE",
            hasInsurance: insurencecliam,
            gameId: this.props.gameId,
            playerId: Number(this.props.playerId),
          },
        };
        console.log(body);
    
        this.props.network.sendof(body);

      }

    

    }



      
  FoldHandresp(res) {
   

    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {
      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2 })
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
   
     document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
       
      }
      else {
        const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
        if (window.innerHeight > window.innerWidth) {

          confirmbuydealerPopupElement.classList = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          
        }
        else {
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")

        }
      }


    }
    else {
    this.updateplayerbalnace();
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.clertime10 = setTimeout(() => {
      this.setState({ infoText: this.props.language.Place_antebet });
    }, 700);
    const hands = [
      "onepair", "Threekind", "aceking", "fullhse", 
      "Straightflush", "frofkind", "twopair", 
      "flush", "stright", "royalflush"
    ];
    
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });
    

    document.getElementById("betactive").src = "";
    document.getElementById("betactive").classList.remove("betactive");
    // document.getElementById("betpannelrussiancover").style.display = "block";
    document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

    // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
    // const betbutton = document.getElementById("betbtn");
    // const foldbutton = document.getElementById("Foldbtn");
    betbutton.src = Betbtn;
    //document.getElementById("betbtntext").textContent = "";
    document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

    // document.getElementById("betbtntext").classList.add("rebet");







    document.getElementById("foldtext").textContent = this.props.language.RebetX2;

    // if (document.getElementById("dealbtntext")) {
    //   document.getElementById("dealbtntext").textContent = "REBET";
    // }
    // if (document.getElementById("betbtntextrebet")) {
    //   document.getElementById("betbtntextrebet").textContent = "REBET";
    // }


    // this.setState({ infoText: res.message });



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message
        }));

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.remove("windowspokerranking");
        document.getElementById("bnsactive").classList.remove("betactive");



      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      // this.clertime11=setTimeout(() => {

        betbutton.src = Betbtn;
        Object.assign( betbutton.style,porttraitejson.Betbtnwindows)

        document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows");
    

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style,porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        document.getElementById("foldtext").classList.add("rebettextwindows");
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });
        document.getElementById("bonusRussian").classList.remove("addpointer");
        document.getElementById("bonusRussian").classList.add("drwnodrop");



      let t1 = gsap.timeline();
      t1.to("#chipcenterposition", {
        // y: -2060, duration: 20.5, opacity: 1,
        x: 0, y:-662,duration: 0.2, opacity: 1,
        onComplete:() =>{
          gsap.set("#chipcenterposition", { opacity: 0 });
        }
      });
      t1.to("#chipcenterposition", {
        x: 955, y:554, duration: 0.1, zIndex: -12, opacity: 0,
        onComplete: ()=> {
          gsap.set("#chipcenterposition", { opacity: 0});
        }

      });

        t1.play();
        // document.getElementById("centerchips").classList.remove("chip10centerposition")

        gsap.to("#betTextaddtodraw", { opacity: 0, duration: 0.1 })
        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodraw, betchipsaddtconfrm", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodraw ,betchipsaddtconfrm", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,
          
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0, delay: 2 });
          
          }
        
        });
        t2.play();
        gsap.set("#betTextaddtodraw ,betchipsaddtconfrm", { zIndex: -2 ,opacity:0,delay:0.5,duration:0.5})

         
    }
    else {
    
    
      this.setState((prevState) => ({
      previousAnteBetAmount: prevState.anteBetAmount,
      previousBonusbetamount: prevState.bonusbetamount,
      totalbet: 0,
      anteBetAmount: 0,
      bonusbetamount: 0,
      insurencetbet: 0,
      prevStatestoreantebetchips:[...prevState.storeantebetchips],
      prevStatestorebonuschips:[...prevState.storebonuschips],
      storebonuschips: [],
      storeinsurancechips:[],
      // betdeductions: 0,
      betbutton: "REBET",
      buttontext: "REBETX2",
      infoText: res.message
    }));



      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      const betactiveBlinkElement = document.getElementById("betactive");

      const drawbtnElement = document.getElementById("drawbtn");
      const buybtnElement = document.getElementById("buybtn");
      const drawtextElment = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");

      if(res.action === "CONFIRM_FOLD"){
        if(drawbtnElement&&buybtnElement&&drawtextElment&&buytextElement){
          drawbtnElement.src = "";
          buybtnElement.src = "";
          drawtextElment.textContent = "";
          buytextElement.textContent = "";
        }

        if (window.innerHeight > window.innerWidth) {
          let t4 = gsap.timeline();
          t4.to(this.chipdrawref.current, {
            // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 0 });
            }
          });
          t4.to(this.chipdrawref.current, {
            x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
            }
          });
    
          t4.play();
  
        }else{
          let t4 = gsap.timeline();
          t4.to(this.chipdrawref.current, {
             y: -700, duration: 0.5, zIndex: 12, ease: "power2.inOut", autoAlpha: 1,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { autoAlpha: 0 });
            }
          });
          t4.to(this.chipdrawref.current, {
            x:0, y: 0, duration: 0.5, zIndex: -2, autoAlpha: 0,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { autoAlpha: 0, });
            }
          });
      
          t4.play();
        }

      }
     
      

      betactiveBlinkElement.src = "";
      betactiveBlinkElement.classList.remove("betactivePortrait");
      let rankinginfoElement = document.getElementById("rankinginfo");
      rankinginfoElement.className = "";
      rankinginfoElement.textContent = "";

      this.clearActivesWeDid();

      betbutton.src = Betbtn;
      foldbutton.src = Foldbn;
      document.getElementById("mobileAntiMaskCover").style.display = "none";
      if (window.innerHeight > window.innerWidth) {
        Object.assign(betbutton.style, porttraitejson.Betbtnportraite)
        Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

        betbtntextElement.textContent = this.props.language.Rebet;
        foldtextElement.textContent = this.props.language.RebetX2;
        // porttraitejson.betportraite
        betbtntextElement.classList.add("betportrait");
        foldtextElement.classList.add("rebettextportrait");


        // x: 25,
        // y: 1200,
        let t1 = gsap.timeline();
        t1.to("#chipcenterposition", {
          // x:25, y: 1200, duration: 0.2, opacity: 1,
          x:25, y: -2060, duration: 0.2, opacity: 1,
          onComplete: function () {
            gsap.set("#chipcenterposition", { opacity: 0 });
          }
        });
        t1.to("#chipcenterposition", {
          // x: 13,
           y:-1130, duration: 0.0001, zIndex: -12, opacity: 0,
          onComplete: function () {
            gsap.set("#chipcenterposition", { opacity: 0, });
          }

        });
        t1.play();

        if (this.state.buydrawCheck) {

          let t4 = gsap.timeline();
          t4.to(this.chipdrawref.current, {
            x: 340, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 0 });
            }
          });
          t4.to(this.chipdrawref.current, {
            x: 548, y: -36, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
            }
          });
          t4.play();
        }


        if (this.playerWinBonus === true) {
          if (document.getElementById("chip10centerbns")) {
            gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.5, zIndex: -2, opacity: 1 });
            this.playerWinBonus = false;

          }

        }



      } else {

        // IF MOBILE LANDSCAPE ;

        Object.assign(betbutton.style, porttraitejson.Betbtnlndspe)
        Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);

        betbtntextElement.textContent = this.props.language.Rebet;
        foldtextElement.textContent = this.props.language.RebetX2;
        // porttraitejson.betportraite
        betbtntextElement.classList.add("betLandscape");
        foldtextElement.classList.add("foldtextLandscape");




        let t1 = gsap.timeline();
        t1.to("#chipcenterposition",
          { x: 0, y: -600, duration: 0.3, autoAlpha: 1, ease: "power2.inOut", });
        t1.to("#chipcenterposition",
          { x: 0, y: 0, duration: 0.0001, zIndex: -12, autoAlpha: 0 });
        t1.play();

        if (this.state.buydrawCheck) {
          let t4 = gsap.timeline();
          t4.to(this.chipdrawref.current, { y: -600, duration: 0.3, ease: "power2.inOut", autoAlpha: 1 });
          t4.to(this.chipdrawref.current, { x: 0, y: 0, duration: 0.0001, zIndex: -2, autoAlpha: 0 });
          t4.play();
        }

        if (this.playerWinBonus === true) {
          this.ifPlayerWinBonusAmount();
        }


                
        // gsap.to("#chipcenterposition",  { x: 0, y: 0, duration: 0.0001 ,zIndex: -2,opacity: 0});
        // gsap.to("#betTextaddtoBet",     { x: 0, y: 0, duration:0.0001,zIndex: -2,opacity: 0});
        // gsap.to("#betchipsaddtobet",    { x: 0, y: 0, duration: 0.0001});
        // gsap.to(this.chipdrawref.current, {x: 0, y: 0, duration: 0.0001, zIndex: -2, opacity: 0});
        // if (this.insuranceActive) {
        //   gsap.to("#chiptoinsurence", { x: 0, y:0, duration: 0.0001,zIndex: -2, opacity: 0 });
        // };
        // if (this.wanttobuydelercrd === true) {
        //   gsap.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -2,duration:0.0001})
        
        // }

        // gsap.set('#dealerchip', {x:0,y:0, opacity: 0, zIndex: -10 ,duration:0.00001})
        
}

      }
    }

  }


  







  Show_FoldHandresp(res) {
    
    if (res.message === `Attention! You have a ${res.playerHand}. Are you sure you want to fold?`) {


      this.setState({ cmfrmdealeraction: 2, cmfrmdealeractionNO: 2 ,infoText:res.message})
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerCards);
        this.showParamBetStatus(false);
        this.ShowbonusstatusTousr(res);
  
  

        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
       
      }
      
    }
    else {
    this.updateplayerbalnace();
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.clertime10 = setTimeout(() => {
      this.setState({ infoText: this.props.language.Place_antebet });
    }, 700);
    const hands = [
      "onepair", "Threekind", "aceking", "fullhse", 
      "Straightflush", "frofkind", "twopair", 
      "flush", "stright", "royalflush"
    ];
    
    hands.forEach(hand => {
      document.getElementById(hand).style.color = "";
    });
    

    document.getElementById("betactive").src = "";
    document.getElementById("betactive").classList.remove("betactive");
    // document.getElementById("betpannelrussiancover").style.display = "block";
    document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

    // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
    // const betbutton = document.getElementById("betbtn");
    // const foldbutton = document.getElementById("Foldbtn");
    betbutton.src = Betbtn;
    //document.getElementById("betbtntext").textContent = "";
    document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;

    // document.getElementById("betbtntext").classList.add("rebet");







    document.getElementById("foldtext").textContent = this.props.language.RebetX2;

    // if (document.getElementById("dealbtntext")) {
    //   document.getElementById("dealbtntext").textContent = "REBET";
    // }
    // if (document.getElementById("betbtntextrebet")) {
    //   document.getElementById("betbtntextrebet").textContent = "REBET";
    // }


    // this.setState({ infoText: res.message });



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.setState((prevState) => ({
          previousAnteBetAmount: res.anteBetAmount,
          previousBonusbetamount: res.bonusBetAmount,
          totalbet: 0,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,
          betdeductions: 0,
          betbutton: "REBET",
          buttontext: "REBETX2",
          keyboardhandle: true,
          betBtnValue: 2,
          disBetBtn: 0,
          infoText: res.message
        }));

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.remove("windowspokerranking");
        document.getElementById("bnsactive").classList.remove("betactive");



      document.getElementById("betbtntext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      // this.clertime11=setTimeout(() => {

      betbutton.src = Betbtn;
      Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

      document.getElementById("betbtntext").innerHTML = this.props.language.Rebet;
      document.getElementById("betbtntext").classList.add("betwindows");


      foldbutton.src = Foldbnwindows;
      Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
      document.getElementById("foldtext").textContent = this.props.language.RebetX2;
      document.getElementById("foldtext").classList.add("rebettextwindows");
      this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true });
      document.getElementById("bonusRussian").classList.remove("addpointer");
      document.getElementById("bonusRussian").classList.add("drwnodrop");



      let t1 = gsap.timeline();
      t1.to("#chipcenterposition", {
        // y: -2060, duration: 20.5, opacity: 1,
        x: 0, y:-662,duration: 0.2, opacity: 1,
        onComplete:() =>{
          gsap.set("#chipcenterposition", { opacity: 0 });
        }
      });
      t1.to("#chipcenterposition", {
        x: 955, y:554, duration: 0.1, zIndex: -12, opacity: 0,
        onComplete: ()=> {
          gsap.set("#chipcenterposition", { opacity: 0});
        }

      });

        t1.play();
        // document.getElementById("centerchips").classList.remove("chip10centerposition")

        gsap.to("#betTextaddtodraw", { opacity: 0, duration: 0.1 })
        let t2 = gsap.timeline();
        t2.to("#betchipsaddtodraw, betchipsaddtconfrm", {
          x: -1082,
          y: -1267, duration: 0.5, opacity: 1,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0 });
          }
        });
        t2.to("#betchipsaddtodraw ,betchipsaddtconfrm", {
          x: 45, y: -54, duration: 0.5, zIndex: -2, opacity: 0,
          onComplete: () => {
            gsap.set("#betchipsaddtodraw ,betchipsaddtconfrm", { opacity: 0, delay: 2 });
          }

        });
        t2.play();

        // gsap.to("#betTextaddtodraw ,betchipsaddtconfrm", { zIndex: -2 ,opacity:-1})

    }


    
    } 
  }

 







  ifPlayerWinBonusAmount = () => {
    if (document.getElementById("chip10centerbns")) {
      this.playerWinBonus = false;
      let tl = gsap.timeline();
      // tl.to("#chip10centerbns", { x: 890, y: 0, duration: 0.5, zIndex: 2, ease: "power2.inOut", autoAlpha: 1 });
      tl.to("#chip10centerbns", { x: 0, y: 0, duration: 0.00001, zIndex: -2, autoAlpha: 0 });

    }

  }

  TotalbetamountGreaterthanbalance(betamnt) {
    let balance = this.balnce;

    if (navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform === "Windows") {
      
      if (this.state.totalbet > this.props.balance) {
        
        document.getElementById("inforussian").classList.add("windowsinfo");
        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")
        
        this.setState({
          infoText: this.props.language.Low_Balance,
          disablemultibutton: false,
          multibuttonsebledsble: false


        });
        document.getElementById("betpannelrussiancover").style.display = "block";
        document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
        document.getElementById("betbtn").classList.add("addpointer")
        document.getElementById("Foldbtn").classList.add("addpointer")

      }
      else {
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");
        // this.setState({ infoText: this.props.language.deal_tostart })
        document.getElementById("betbtn").classList.remove("drwnodrop")
        document.getElementById("Foldbtn").classList.remove("drwnodrop")



      }
    } else {
      const inforussianElement = document.getElementById("inforussian");
      const betbtnElement = document.getElementById("betbtn");
      const FoldbtnElement = document.getElementById("Foldbtn");
      const mobileAntiMaskCoverElement = document.getElementById("mobileAntiMaskCover")
     

      if (window.innerHeight > window.innerWidth) {
        console.log(this.state.totalbet , this.props.balance)
        if (this.state.totalbet > this.props.balance) {
          inforussianElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianElement.classList.add('portraitinfo');
          mobileAntiMaskCoverElement.style.display = 'block';
          mobileAntiMaskCoverElement.classList.add('mobileAntiMaskCover');
          this.setState({
            infoText: this.props.language.Low_Balance,
            disablemultibutton: false,
            multibuttonsebledsble: false
          });

        }

      }  else {
        if (this.state.totalbet > this.props.balance) {
          inforussianElement.className = "";
          betbtnElement.className = "";
          FoldbtnElement.className = "";
          inforussianElement.classList.add('lndspinfo');
          mobileAntiMaskCoverElement.style.display = 'none';
          mobileAntiMaskCoverElement.classList.remove('mobileLndspAntiMaskCover');
          this.setState({ infoText: this.props.language.deal_tostart });
          betbtnElement.classList.remove("drwnodrop");
          FoldbtnElement.classList.remove("drwnodrop");
        }
        
      }
    }

  }



  previousantebonousamount(bettext){
    let rebetamount = 0;
    let rebetorrebetX2 = 1;
    if (bettext == "REBET") {
      rebetorrebetX2 = 1;
      console.log(bettext)
      rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
        totalbet: rebetamount,
        anteBetAmount: this.state.previousAnteBetAmount,
        bonusbetamount: this.state.previousBonusbetamount,
        betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
        betBtnValue: 0, disBetBtn: 0

      })
      console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (bettext == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
        totalbet: rebetamount,
        anteBetAmount: prevState.previousAnteBetAmount * 2,
        bonusbetamount: prevState.previousBonusbetamount * 2,
        betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
        betBtnValue: 0, disBetBtn: 0

      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
    }
  }

  anteclick(bettext) {
    // const betbutton = document.getElementById("betbtn");
    // const foldbutton = document.getElementById("Foldbtn");
    this.pokerchipsnd = new Audio(pokerchipsnd);
    if (this.state.mutesounds) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.pause();

    }
    else {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.play();

    }
    const chipsstoredcontainerElement = document.getElementById('chipsstoredcontainer');
    // if(chipsstoredcontainerElement !== null){
    //   chipsstoredcontainerElement.className = "";
    //   }

    //  alert("................."+this.state.totalbet+this.props.balance)

    // this.TotalbetamountGreaterthanbalance();


    const betchipstoantebet = document.getElementById(`betchipsaddtoante${this.state.idincrement}`);
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");
    let bnsactived = document.getElementById("bnsactive");
    // bnsactived.src = bnsactive;
    const winpopuprspelement = document.getElementById("winpopup_rsp");
    const winamtElement = document.getElementById("winamt_rsp");
    document.getElementById("bonusRussian").classList.remove("drwnodrop");
    document.getElementById("bonusRussian").classList.add("addpointer");


    document.getElementById("betbtn").classList.remove("bnspoiner")


    gsap.to(`#betchipsaddtoante${this.state.idincrement}`, {

      opacity: 1
    });
   

    // pokerranking

    this.setState((prevState) => ({
      idincrement: prevState.idincrement + 1,
      // betbutton: "DEAL",
      // buttontext: "clear",
      pokerranking: "",
      pokerrankingdeler: "",
      // totalwinRussian: 0,
      disClearBtn: 0,
      betBtnValue: 0,
      disBetBtn: 0,
      keyboardhandle: true
      //  totalbet:prevState.totalbet + 1,
    }));

    // this.cardsRef.current.ReBet();
    // this.dealerCardsContainer.current.ReBet();
    this.randomPlayerCards = [];
    this.shuffledPlayerCards = [];
    this.randomDealerCards = [];
    this.replaceCards = [];
    this.unsequenceDrawCards = [];
    this.sequenceDrawCards = [];
    this.buyCardUnsequenceArray = [];
    this.buyCardsequenceArray = [];
    this.dealerCardsRandom = [];
    this.dealerShuffledCards = [];
    this.dealerUnsequenceDrawCards = [];
    this.dealerSequenceDrawFinalCards = [];

    document.getElementById('rankinginfodeler').className = "";
    document.getElementById('rankinginfo').className = "";
    document.getElementById("bonusRussian").classList.remove("bnspoiner");
    let rebetamount = 0;
    let rebetorrebetX2 = 1;


    // this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })


    if (this.state.previousAnteBetAmount * rebetorrebetX2 <= this.props.maxBetAmt) {


      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        // alert(">.....rebetstutus"+)
            if (bettext == "REBET") {
      rebetorrebetX2 = 1;
      console.log(bettext)
      rebetamount = this.state.previousAnteBetAmount + this.state.previousBonusbetamount
      // this.setState({ totalbet: rebetamount }, () => {
      //   this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetamount));
      // })
      this.setState({
        totalbet: rebetamount,
        anteBetAmount: this.state.previousAnteBetAmount,
        bonusbetamount: this.state.previousBonusbetamount,
        betdeductions: this.state.previousAnteBetAmount + this.state.previousBonusbetamount,
        betBtnValue: 0, disBetBtn: 0

      })
      console.log("rebet    : ", this.state.anteBetAmount, this.state.bonusbetamount);

    }
    // console.log("this.state.previousAnteBetAmount", this.state.previousAnteBetAmount,
    //   "this.state.previousBonusbetamount", this.state.previousBonusbetamount
    // );
    else if (bettext == "REBETX2") {
      rebetorrebetX2 = 2;
      let rebetdouble = (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2;
      rebetamount = rebetdouble;
      this.setState((prevState) => ({
        totalbet: rebetamount,
        anteBetAmount: prevState.previousAnteBetAmount * 2,
        bonusbetamount: prevState.previousBonusbetamount * 2,
        betdeductions: (this.state.previousAnteBetAmount + this.state.previousBonusbetamount) * 2,
        betBtnValue: 0, disBetBtn: 0

      }));
      // this.balnce = this.topLable_rsp(Math.floor(this.props.balance - rebetdouble));
      console.log("rebetX2    : ", this.state.previousAnteBetAmount, this.state.previousBonusbetamount)
    }

    this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
        
        
        
        
        
        
        
        
        
        
        
        

        this.setState({ betbutton: "DEAL", buttontext: "clear",})
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
        const textbetsHighElement = document.getElementById("textbets_high");
        const attentionElement = document.getElementById("attention");
        const betbtnElement = document.getElementById("betbtn");

        let rebetstatus = 1;
        if (bettext == "REBETX2" || bettext == "REBET") {
          if(this.props.gameState.response){

          
       

        if(this.props.gameState.response.bonusBetAmount>0){
          // alert("......123")
        var tl = gsap.timeline();
        tl.to(this.chipbnsref.current, {
          x: -861,
          y: -554,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 12,
        });
        tl.to(this.chipbnsref.current, {
          x: -48,
          y: 0,
          opacity: 0,
          duration: 0.00001,
        });
        // tl.reverse();
        tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
        tl.play();
      }
    }
    
          let bnsactived = document.getElementById("bnsactive");
          bnsactived.classList.add("bnsctivewindows");
          bnsactived.classList.add("bnsblink");
  
          // document.getElementById("crdscover").classList.add("crdscover")
          document.getElementById("playerDealerCardContainer").classList.add("addpointer")

          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;
         
          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
              if (bettext == "REBETX2") {
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                 console.log(this.state.anteBetAmount)
                // alert("...................p1")
                // let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                // let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // console.log(chipcenterpositionElement)
                // for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //   chipcenterpositionElement[a].innerHTML = this.state.previousAnteBetAmount * 2;
                // }
                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount * 2;
                }

                totalAnteBetAmount = (this.state.previousAnteBetAmount) * 2;
                chip10centerbnsElement = (this.state.previousBonusbetamount) * 2;

                rebetstatus = 2;
                // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
              } else {
             
                let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // alert("...................p1")
                //  let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                // let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                // console.log(chipcenterpositionElement)
                // for (let a = 0; a < chipcenterpositionElement.length; a++) {
                //   chipcenterpositionElement[a].innerHTML = this.state.previousAnteBetAmount * 2;
                // }
                for (let b = 0; b < chip10centerbnsElement.length; b++) {
                  chip10centerbnsElement[b].innerHTML = this.state.previousBonusbetamount;
                }
                // if(this.props.gameState.response.action==="BET"){
                //   totalAnteBetAmount=this.props.gameState.response.anteBetAmount
                //   chip10centerbnsElement =this.props.gameState.response.bonusBetAmount

                // }

                console.log(this.state.previousAnteBetAmount)
                totalAnteBetAmount = this.topLable_rsp(this.state.previousAnteBetAmount);
                console.log(totalAnteBetAmount)
                chip10centerbnsElement = this.topLable_rsp(this.state.bonusbetamount);
                rebetstatus = 1;


                // alert("..............."+this.state.bonusbetamount)

                // totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
              }
            }
          }

          if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
            Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
            // console.log("this.state.anteBetAmount :------", this.state.anteBetAmount);
            // if (Number(this.state.anteBetAmount)  + Number(this.state.bonusbetamount) > 39) {

            this.setState({
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })
            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true });
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtn");
            const betbtntextElement = document.getElementById("betbtntext");
            const foldtextElement = document.getElementById("foldtext");
            
              betbutton.src ="" ;
              foldbutton.src ="" ;
              betbtntextElement.className ="";
              foldtextElement.className ="";
            
                
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
                this.setState({ 
                  betshigh_balnce: true,
                lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${this.state.previousAnteBetAmount},Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              }else if(Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance){
                this.setState({ 
                  betshigh_balnce: false,
                lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${this.state.previousAnteBetAmount},with bet ${this.state.previousAnteBetAmount*2} Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }

            document.getElementById("betbtn").classList.remove("addpointer")
            document.getElementById("betbtn").classList.add("drwnodrop")
            popupbetGreaterthanBalnceElement.className = "";
            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
              { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });
  
            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
  
              popupbetGreaterthanBalnceElement.classList.add("popupbetshigh");
              textbetsHighElement.classList.add("poptext_betshigh");
              attentionElement.classList.add("attention_text");
  
            }
  
          }
          else if (this.state.previousAnteBetAmount*rebetstatus > this.props.maxBetAmt) {
            document.getElementById("betbtntext").textContent = this.props.language.Deal;
            document.getElementById("betbtntext").classList.add("betwindows");
            document.getElementById("foldtext").classList.remove("rebettextwindows");
            document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
            document.getElementById("foldtext").classList.add("foldtextwindows");
            this.setState({ betbutton: "DEAL", buttontext: "clear",})
        
            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              multibuttonsebledsble: true,
              anteBetAmount: 0,
              bonusbetamount: 0,
              totalbet: 0,
            })

          } else {
            bnsactived.src = bnsactive;
            this.previousantebonousamount(bettext)
            this.setState({ multibuttonsebledsble: false ,bsbtn: false,});
            this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
            gsap.to("#chipcenterposition", { opacity: 0 });
            const betbutton = document.getElementById("betbtn");
            const foldbutton = document.getElementById("Foldbtn");


            document.getElementById("betbtntext").textContent = "";
            document.getElementById("foldtext").textContent = "";

            betbutton.src = "";
            betbutton.style.position = "";
            betbutton.style.width = "";
            betbutton.style.transform = "";

            foldbutton.src = "";
            foldbutton.style.position = "";
            foldbutton.style.width = "";
            foldbutton.style.transform = "";

            this.clertime25=setTimeout(() => {
              betbutton.src = Betbtn;
              Object.assign(betbutton.style,porttraitejson.Betbtnwindows)
              document.getElementById("betbtntext").classList.remove("betwindowsmlylm");
              document.getElementById("betbtntext").textContent = this.props.language.Deal;
              document.getElementById("betbtntext").classList.add("betwindows");
             
              foldbutton.src = Foldbnwindows;
              Object.assign(foldbutton.style,porttraitejson.foldbtnwindows)
              document.getElementById("foldtext").classList.remove("rebettextwindows");
              document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
              document.getElementById("foldtext").classList.add("foldtextwindows");

              document.getElementById("bonustext").style.color = "#ffffff";
              Object.assign(betchipstoantebet.style,porttraitejson.Betchiptoantewindows)
              
      
            }, 1500)

            if(this.props.gameState.response.bonusBetAmount){
           
              
                if(Number(this.props.gameState.response.bonusBetAmount>0)){
                  let totalbets_bonus
                  if(bettext == "REBET"){
                    totalbets_bonus=this.topLable_rsp(this.props.gameState.response.bonusBetAmount)
                  }
                  else{
                    totalbets_bonus=this.topLable_rsp(this.props.gameState.response.bonusBetAmount*2)
      
                  }
                  let betchipstobns = document.getElementById("betchipsaddtobonus");
              
              
               
              
                          


                          switch (this.state.count) {

                            case 1:
              
                            betchipstobns.src = chip_10;
                              this.clertime13 = setTimeout(() => {
                                this.setState((prevState) => ({
                                  storebetchips: [
                                    ...prevState.storebetchips,
                                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                                      <img className="chip10centerbns"
                                        src={chip_10}
                                        alt="Chip 10"
                                      />
                                      <span id="val_10" className="bonusChipsForwindows_val10">{totalbets_bonus}</span>
                                    </div>
                
                                  ],
                                }));
                              }, 150);
                
                              break;
                            case 2:
                              betchipstobns.src = chip_25;
                              //  document.getElementById("antebetval_10").textContent = "";
                        
                              this.clertime13 = setTimeout(() => {
                                this.setState((prevState) => ({
                                  storebetchips: [
                                    ...prevState.storebetchips,
                                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                                      <img className="chip100centerbns"
                                        src={chip_25}
                                        alt="Chip 10"
                                      />
                                      <span id="val_20" className="bonusChipsForwindows">{totalbets_bonus}</span>
                                    </div>
                
                                  ],
                                }));
                              }, 150);
                
                              break;
                            case 3:
                            
                            betchipstobns.src = chip_50;
                
                            
                              this.clertime13 = setTimeout(() => {
                                this.setState((prevState) => ({
                                  storebetchips: [
                                    ...prevState.storebetchips,
                                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                                      <img className="chip100centerbns"
                                        src={chip_50}
                                        alt="Chip 10"
                                      />
                                      <span id="val_50" className="bonusChipsForwindows">{totalbets_bonus}</span>
                                    </div>
                
                                  ],
                                }));
                              }, 150);
                
                              break;
                            case 4:
                              
                            betchipstobns.src = chip_100;
                
                            
                              this.clertime13 = setTimeout(() => {
                                this.setState((prevState) => ({
                                  storebetchips: [
                                    ...prevState.storebetchips,
                                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                                      <img className="chip100centerbns"
                                        src={chip_100}
                                        alt="Chip 10"
                                      />
                                      <span id="val_100" className="bonusChipsForwindows">{totalbets_bonus}</span>
                                    </div>
                
                                  ],
                                }));
                              }, 150);
                
                         
                              break;
                            case 5:
                              betchipstobns.src = chip_500;
                
                            
                              this.clertime13 = setTimeout(() => {
                                this.setState((prevState) => ({
                                  storebetchips: [
                                    ...prevState.storebetchips,
                                    <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                                      <img className="chip500centerbns"
                                        src={chip_500}
                                        alt="Chip 10"
                                      />
                                      <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                                    </div>
                
                                  ],
                                }));
                              }, 150);
                
                              break;
                
                            default:
                              break;
                          }
                


                          Object.assign(betchipstobns.style,porttraitejson.betchipstobnswindows)
                
                        }
      
      
                      
             
                    }

            if (this.state.bonusbetamount > 0) {
              //  gsap.to("#chip10centerbns", { x: 2, y: 2, duration: 0.5, opacity: 1 });

            }

            var tl = gsap.timeline();
            tl.to(this.chipref.current, {
              x: -994,
              y: -553,
              duration: 0.5,
              ease: "power2.inOut",
              zIndex: 12,
            });
            tl.to(this.chipref.current, {

              x: 0,
              y: 0,
              duration: 0.00001,
            });
            // tl.reverse();
            // tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
            tl.play();
            const betchipstoantebet = document.getElementById("betchipsaddtoantecclick");
            // let totalBetAmountForLimitsCheck = 0;

            // for (let k = 1; k <= this.chipVal.length; k++) {
            //   if (this.state.count === k) {
            //     totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
            //   }
            // }

            // this.clertime12=setTimeout(() => {
            document.getElementById("bonustext").style.color = "#ffffff";
            Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoantewindows)

            switch (this.state.count) {

              case 1:

                betchipstoantebet.src = chip_10;
                this.clertime13 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
                        <img  id="centerchips" className="chip10centerposition"
                          src={chip_10}
                          alt="Chip 10"
                        />
                        <span id="antebetval_10" className="antebet_textval10">{totalAnteBetAmount}</span>
                      </div>
  
                    ],
                  }));
                }, 150);
  
                break;
              case 2:
                betchipstoantebet.src = chip_25;
                //  document.getElementById("antebetval_10").textContent = "";
  
                this.clertime14=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip20centerposition"
                          src={chip_25}
                          alt="Chip 10"
                        />
                        <span id="antebetval_20" className="antebet_text20">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
  
                break;
              case 3:
              
                betchipstoantebet.src = chip_50;
  
                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip50centerposition"
                          src={chip_50}
                          alt="Chip 10"
                        />
                        <span className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
               
                break;
              case 4:
                
                betchipstoantebet.src = chip_100;
  
                // document.getElementById("antebetval_20").textContent = "";
                this.clertime15=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip100centerposition"
                          src={chip_100}
                          alt="Chip 10"
                        />
                        <span className="antebet_text100">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
               
           
                break;
              case 5:
                betchipstoantebet.src = chip_500;
  
                this.clertime17=setTimeout(() => {
                  this.setState((prevState) => ({
                  }));
  
  
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip500centerposition"
  
                          src={chip_500}
                          alt="Chip 10"
                        />
                        <span className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;
  
              default:
                break;
            }
  
  
            
            if (this.state.bonusbetamount > 0) {
              this.setState({ isbonuspattable: true })
              gsap.to("#chip10centerbns", { x: 0, y: 0, duration: 0.5, opacity: 1, zIndex: 1, });
              this.ShowBonuspayouttable()
  
            }
  
  
          }
  
  
         
  
          document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
          document.getElementById("rankinginfo").classList.remove("windowspokerranking");
          this.cardsRef.current.ReBet();
          this.dealerCardsContainer.current.ReBet();
          this.randomPlayerCards = [];
          this.shuffledPlayerCards = [];
  
          // gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.5 });
  
          // gsap.to("#betchipsaddtobet", { x: 0, y: 0, duration: 0.5 });
  
  
          if (this.state.mutesounds) {
            this.pokerchipsnd = new Audio(pokerchipsnd);
            this.pokerchipsnd.pause();
  
          }
          else {
            this.pokerchipsnd = new Audio(pokerchipsnd);
            this.pokerchipsnd.play();
  
          }
  
          // console.log(this.state.totalbet)
          // gsap.to("#chip10centerbns",{zIndex:-2})
  
  
  
          // document.getElementById("betpannelrussiancover").style.display = "none";
          // document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
          this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
          document.getElementById("betincrenet").classList.remove("drwnodrop")
          document.getElementById("betdecrement").classList.remove("drwnodrop")
  
          console.log(this.state.totalbet)
  
          console.log(this.balnce)
          // this.setState({infoText:"Press Deal to start the Game!"})
          // alert("................."+this.state.count)
          // const betbutton = document.getElementById("betbtn");
          // const foldbutton = document.getElementById("Foldbtn");
          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtn");
        
  
          document.getElementById("bonusRussian").classList.add("addpointer");
  
         
  
        
          this.setState((prevState) => ({
            idincrement: prevState.idincrement + 1,
            //  totalbet:prevState.totalbet + 1,
          }));
          this.clertime18=setTimeout(() => {
            gsap.to(`#chipcenterposition`, { zIndex: -15 })
          }, 550);
          // const betchipstoantebet = document.getElementById(
          //   `betchipsaddtoante${this.state.idincrement}`
          // );
          console.log(document.getElementById('chipcenterposition'))
          const betchipstoantebet = document.getElementById("betchipsaddtoantecclick");
  
          var tl = gsap.timeline();
          tl.to(this.chipref.current, {
            x: -994,
            y: -553,
            duration: 0.5,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipref.current, {
            opacity: 0,
            x: 0,
            y: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
          tl.play();
  
  
        }
        else {
          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount =0;
           for (let k = 1; k <= this.chipVal.length; k++) {
             if (this.state.count === k) {
               console.log(this.state.anteBetAmount)
               console.log(Number(this.chipVal[k - 1].val))
               totalBetAmountForLimitsCheck = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);
               totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].label);
             }
           }
           totalAnteBetAmount=this.topLable_rsp(totalBetAmountForLimitsCheck)
        console.log(this.state.anteBetAmount)
  
          if (Number(totalAnteBetAmount) + Number(this.state.bonusbetamount) > this.props.balance ||
            Number(totalAnteBetAmount * 3) + Number(this.state.bonusbetamount) > this.props.balance) {
            // if (totalBetAmountForLimitsCheck > 50) {
            // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })
            
            // if (totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) > this.props.balance) {
            //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
            // } else if (totalAnteBetAmount+ Number(this.state.bonusbetamount) > this.props.balance) {
            //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true,disBetBtn:1 })
            // }

            if (totalAnteBetAmount+ Number(this.state.bonusbetamount) > this.props.balance) {
              this.setState({ 
                betshigh_balnce: true, 
                multibuttonsebledsble: true,
                lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${totalAnteBetAmount},Please DEPOSIT to continue playing.` 
              })
              betbutton.src ="" ;
              foldbutton.src ="" ;
              betbtntextElement.className ="";
              foldtextElement.className ="";
            }else if(totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) > this.props.balance){
              this.setState({ 
              betshigh_balnce: true,
              // betshigh_balnce: false,
              lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${totalAnteBetAmount},with bet ${totalAnteBetAmount*2} Please DEPOSIT to continue playing.`
              }) // multibuttonsebledsble: true
            }



            betbtnElement.classList.remove("addpointer")
            betbtnElement.classList.add("drwnodrop");
            popupbetGreaterthanBalnceElement.className = "";
  
            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
              { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });
  
            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
              popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
              textbetsHighElement.classList.add("poptextBetshighwindows");
              attentionElement.classList.add("attentionTextwindows");
  
            }
  
          }
          //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
          //           this.state.anteBetAmount > this.props.maxBetAmt) {
          else if (totalAnteBetAmount > this.props.maxBetAmt) {
          
           
            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
            }, () => {
              console.log(this.state.infoText)
            })
            document.getElementById("betcircle").classList.remove("addpointer")
            document.getElementById("betcircle").classList.add("insurencenodrop")

          }
          else {

            this.setState({ multibuttonsebledsble: false,bsbtn: false, });
            this.setState({ infoText: this.props.language.deal_tostart, betchipincrementbtn: false, betdecreamentbtn: false })
            this.setState({ betbutton: "DEAL", buttontext: "clear", });
            let bnsactived = document.getElementById("bnsactive");
            bnsactived.src = bnsactive;
            bnsactived.classList.add("bnsctivewindows");
            bnsactived.classList.add("bnsblink");
            if (this.chipref) {

              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -994,
                y: -553,
                duration: 0.5,
                ease: "power2.inOut",
                zIndex: 12,
              });
              tl.to(this.chipref.current, {
                opacity: 0,
                x: 0,
                y: 0,
                zIndex: -2,
                duration: 0.00001,
              });
              // tl.reverse();
              tl.to(this.chipref.current, { opacity: 1, zIndex: -2 });
              tl.play();
            }
  
          
            switch (this.state.count) {
  
              case 1:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                }));
                betchipstoantebet.src = chip_10;
                this.clertime19=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
                        <img id="centerchips" className="chip10centerposition"
                          src={chip_10}
                          alt="Chip 10"
                        />
                        <span id="antebetval_10" className="antebet_textval10">{totalAnteBetAmount}</span>
                      </div>
  
                    ],
                  }));
                }, 150);
  
                break;
              case 2:
                betchipstoantebet.src = chip_25;
                //  document.getElementById("antebetval_10").textContent = "";
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                }));
                this.clertime20=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip20centerposition"
                          src={chip_25}
                          alt="Chip 10"
                        />
                        <span id="antebetval_20" className="antebet_text20">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
  
                break;
              case 3:
  
                betchipstoantebet.src = chip_50;
                // document.getElementById("antebetval_20").textContent = "";
                this.clertime21=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip50centerposition"
  
                          src={chip_50}
                          alt="Chip 10"
                        />
  
                        <span className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                }));
                break;
              case 4:
                betchipstoantebet.src = chip_100;
  
                // document.getElementById("antebetval_50").textContent = "";
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                  betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
                }));
                this.clertime22=setTimeout(() => {
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip100centerposition"
  
                          src={chip_100}
                          alt="Chip 10"
                        />
                        <span className="antebet_text100">{totalAnteBetAmount}</span>
                      </div>
                    ],

                  }));
                }, 150);
                break;
              case 5:
                betchipstoantebet.src = chip_500;
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                }));
                this.clertime23=setTimeout(() => {
            
                  this.setState((prevState) => ({
                    storebetchips: [
                      ...prevState.storebetchips,
                      <div id="chipcenterposition">
  
                        <img
                        id="centerchips"
                          className="chip500centerposition"
  
                          src={chip_500}
                          alt="Chip 10"
                        />
                        <span className="antebet_text50">{totalAnteBetAmount}</span>
                      </div>
                    ],
                  }));
                }, 150);
                break;
  
              default:
                break;
  
            }


            this.clertime25=setTimeout(() => {
              betbutton.src = Betbtn;
              Object.assign(betbutton.style,porttraitejson.Betbtnwindows)
              document.getElementById("betbtntext").classList.remove("betwindowsmlylm");
              document.getElementById("betbtntext").textContent = this.props.language.Deal;
              document.getElementById("betbtntext").classList.add("betwindows");
             
              foldbutton.src = Foldbnwindows;
              Object.assign(foldbutton.style,porttraitejson.foldbtnwindows)
              document.getElementById("foldtext").classList.remove("rebettextwindows");
              document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
              document.getElementById("foldtext").classList.add("foldtextwindows");

              document.getElementById("bonustext").style.color = "#ffffff";
              Object.assign(betchipstoantebet.style,porttraitejson.Betchiptoantewindows)
              
      
            }, 1500)
  
          }
          // this.clertime24=setTimeout(() => {
           
          
  
        }
  
  
        if (this.state.count > 0) {
          // alert("..........."+this.state.totalbet)
          this.TotalbetamountGreaterthanbalance(this.state.totalbet);
  
        }
  if(document.getElementById("chiptoinsurence")){
    gsap.to("#chiptoinsurence", { zIndex: -2 });
  
  }

  
      
       
      }
  
    else {

        this.betchipactivated = false;
        this.buydrawchipactivated = false;
        this.ConfirmbuydealercardactivePopup = false;
        this.resetPyrWnCpsAmn = false;


        document.getElementById("betpannelrussiancover").style.display = "block";
        if (document.getElementById("rankinginfodeler")) {
          document.getElementById("rankinginfodeler").style.cssText = "";
          document.getElementById("rankinginfo").style.cssText = "";
        };
        const betbtnElement = document.getElementById("betbtn");
        const betbtntextElement = document.getElementById("betbtntext");
        const foldtextElement = document.getElementById("foldtext");
        const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
        const textbetsHighElement = document.getElementById("textbets_high");
        const attentionElement = document.getElementById("attention");



       

        betbtntextElement.className = "";
        foldtextElement.className = "";

        betbtntextElement.textContent = this.props.language.Deal;
        foldtextElement.textContent = this.props.language.RP_Clear;

        betbutton.src = Betbtn;
        foldbutton.src = Foldbn;

        this.setState({
          multibuttonsebledsble: false,
          betbutton: "DEAL",
          buttontext: "clear",
        })

        const isPortrait = window.innerHeight > window.innerWidth;

        const rankinginfoElement = document.getElementById("rankinginfo");
        if (isPortrait) {
          document.getElementById("betpannelrussiancover").classList.add("PlayerCardpannelcoverPortrait");
            if (rankinginfoElement) {
              if (this.state.isMobilePortraitLndsp) {
                rankinginfoElement.style.left = "35%";
              }
            }

          if (this.resetAnimationChips) {

            if (document.getElementById("chipcenterposition")) {
              gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.5, zIndex: -2, opacity: 0 });
            }

            if (document.getElementById("betchipsaddtobet")) {
              gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.5, zIndex: -2, opacity: 0 });
              gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.5, zIndex: -2, opacity: 0 });
            }

            // gsap.to("#chiptoinsurence" , {x:550, y: 145, duration:0.5,zIndex:-2,opacity:0});
            if (document.getElementById("chiptoinsurence")) {
              gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.5, zIndex: -2, opacity: 0 });
            };

            // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
            gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration: 0.5, zIndex: -5, opacity: 0 });
            // if (this.playerWinBonus === true) {

              // if (document.getElementById("chip10centerbns")) {
              //   gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
              //   this.playerWinBonus = false;

              // }


            // }
            if (this.wanttobuydelercrd === true) {
              gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.5, zIndex: -2, opacity: 0 });
            }
          }

          this.resetAnimationChips = false;
          bnsactived.classList.add("bnsactivePortrait");
          bnsactived.classList.add("bnsblink");
          
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
          betbtntextElement.classList.add('betportrait');
         
              


          
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
          foldtextElement.classList.add('foldtextportrait');
         

          let rebetstatus = 1;
          if(bettext === "REBETX2"){
            rebetstatus =2;
          }
          let totalBetAmountForLimitsCheck=0;
          let totalAnteBetAmount=0;
          if (bettext == "REBETX2" || bettext == "REBET") {


            if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
              Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })
              // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {

              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }

                // betbutton.src ="" ;
                // foldbutton.src ="" ;
                // betbtntextElement.className ="";
                // foldtextElement.className ="";
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
                this.setState({ 
                  betshigh_balnce: true,
                lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${this.state.previousAnteBetAmount},Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              }else if(Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance){
                this.setState({ 
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${this.state.previousAnteBetAmount},with bet ${this.state.previousAnteBetAmount*2} Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }


              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {
              // this.setState({ multibuttonsebledsble: false });
              bnsactived.src = bnsactive;
              this.previousantebonousamount(bettext);
              
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;
              console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              this.setState({ 
                disablemultibutton: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                bsbtn: false, 
                betbutton: "DEAL",
                buttontext: "clear",
                storeantebetchips: [...this.state.prevStatestoreantebetchips],
                storebonuschips: [...this.state.prevStatestorebonuschips],
              
              }, () =>{

                for (let k = 1; k <= this.chipVal.length; k++) {
                  if (this.state.count === k) {
                    totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                    if (bettext == "REBETX2") {
                      let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                      let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                      for (let a = 0; a < chipcenterpositionElement.length; a++) {
                        chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                      }
                      // if(this.state.previousBonusbetamount > 0){
                        for (let b = 0; b < chip10centerbnsElement.length; b++) {
                          chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                        }

                      // }
                     
    
                      totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                      rebetstatus = 2;
                      // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                    } else {
                      totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                      rebetstatus = 1;
                    }
                  }
                }

                if (document.getElementById('chipcenterposition')) {
                  gsap.to("#chipcenterposition", {
                    x: 13, y: 1200, duration: 0.0001, zIndex: 0, opacity: 1, onStart: () => {
                      gsap.set("#chipcenterposition", { opacity: 0 });
                    }
                  });
                  gsap.to("#chipcenterposition", { x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 1, });
                  // gsap.to("#chipcenterposition", { x: 0, y: -2170, duration: 0.5, opacity: 1, zIndex: 1, });
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterposition");
                  console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {
  
                    chipcenterpositionElement[q].style.position = 'relative';
                  }
  
                }
                console.log(this.state.previousBonusbetamount)
                if (this.state.previousBonusbetamount > 0) {
                  this.setState({ isbonuspattable: true });
                  let tl2 = gsap.timeline();
                  tl2.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
                  tl2.to("#chip10centerbns", {
                    x: 0, y: 0, duration: 0.5, autoAlpha:1, zIndex: 2, delay: 0.5, onStart: () => {
                      this.pokerchipsnd = new Audio(pokerchipsnd);
                      if (this.state.mutesounds) {
                        this.pokerchipsnd.pause();
                      }
                      else {
                        this.pokerchipsnd.play();
  
                      }
                    }
                  });
                  this.ShowBonuspayouttable()
  
                }else{
                  gsap.to("#chip10centerbns",{ duration: 0.000001,opacity: 0 })
                }


              });
        

            }
          } else {

            let totalBetAmountForLimitsCheck = 0;
            let totalAnteBetAmount = 0;
            console.log(this.chipVal)

            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

              }
            }


            console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
              this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
              this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

            );


            if (Number(totalAnteBetAmount) + Number(this.state.bonusbetamount) > Number(this.props.balance) ||
              Number(totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) ) > Number(this.props.balance)) {

              // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {

              // if (totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true })
              // } else if (totalAnteBetAmount  + Number(this.state.bonusbetamount) > this.props.balance) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true })
              // }

              if (totalAnteBetAmount + Number(this.state.bonusbetamount) > this.props.balance) {
                this.setState({ 
                  betshigh_balnce: true, 
                  multibuttonsebledsble: true,
                  lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${totalAnteBetAmount},Please DEPOSIT to continue playing.` 
                })
                betbutton.src ="" ;
                foldbutton.src ="" ;
                betbtntextElement.className ="";
                foldtextElement.className ="";
              }else if(totalAnteBetAmount * 3 + Number(this.state.bonusbetamount) > this.props.balance){
                this.setState({ 
                  betshigh_balnce: true,
                  // betshigh_balnce: false,
                  lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${totalAnteBetAmount},with bet ${totalAnteBetAmount*2} Please DEPOSIT to continue playing.`
                }) // multibuttonsebledsble: true
              }

              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";

              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
                { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
                popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                // multibuttonsebledsble: true,
              }, () => {
                console.log(this.state.infoText)
              });



            } else {
              bnsactived.src = bnsactive;
              document.getElementById("bonustext").style.color = "#ffffff";
              betbtntextElement.textContent = this.props.language.Deal;
              foldtextElement.textContent = this.props.language.RP_Clear;
      
              console.log(betbtntextElement.textContent, foldtextElement.textContent, this.props.language.Deal, this.props.language.RP_Clear)
              this.setState({
                multibuttonsebledsble: false,
                betbutton: "DEAL",
                buttontext: "clear",
                bsbtn: false,
                infoText: this.props.language.deal_tostart,
                betchipincrementbtn: false,
                betdecreamentbtn: false,
                previousAnteBetAmount: 0,
                previousBonusbetamount: 0,
              });
              let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

              // antebetpdvcss:"chip-center-position",
              // antechpimgcss:"chip20centerpositionPortrait",
              // antespntxtcss:"antebetChipPortrait20",

              switch (this.state.count) {

                case 1:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                  }))
                  betchipstoantebet.src = chip_10;
                  this.clertime13 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
                       
                      ],
                    }));
                  }, 150);
                  break;
                case 2:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                  }))
                  betchipstoantebet.src = chip_25;
                  this.clertime14 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: chip_25, chipvalue: convrAntebt, chipcolor1: null },
                       
                      ],
                    }));
                  }, 150);
                  break;
                case 3:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                  }))
                  betchipstoantebet.src = chip_50;
                  this.clertime15 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: chip_50, chipvalue: convrAntebt, chipcolor1: null },
                     
                      ],
                    }));
                  }, 150);
                  break;
                case 4:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                  }))
                  betchipstoantebet.src = chip_100;
                  this.clertime16 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: chip_100, chipvalue: convrAntebt, chipcolor1: null },
                       
                      ],
                    }));
                  }, 150);
                  break;
                case 5:
                  this.setState((prevState) => ({
                    totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                    anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                    betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                  }))
                  betchipstoantebet.src = chip_500;
                  this.clertime17 = setTimeout(() => {
                    this.setState((prevState) => ({
                      storeantebetchips: [
                        ...prevState.storeantebetchips,
                        { chip: chip_500, chipvalue: convrAntebt, chipcolor1: null },
                      ],
                    }));
                  }, 150);
                  break;
                default:
                  break;
              }


              Object.assign(betchipstoantebet.style, porttraitejson.Betchiptoanteprotrait);
              var tl = gsap.timeline();
              tl.to(this.chipref.current, {
                x: -10,
                y: -1200,
                duration: 0.15,
                // duration: 0.2,
                ease: "power2.inOut",
                zIndex: 12,
                autoAlpha: 1
              });


              tl.to(this.chipref.current, {
                x: 0,
                y: 0,
                duration: 0.0001,
                autoAlpha: 0,
                zIndex: -3,
              });
              tl.play();
            }

          }

        } else {

         
         

         


          if (chipsstoredcontainerElement && chipsstoredcontainerElement !== null) {
            chipsstoredcontainerElement.classList.add("chipsstoredcontainerLndsp");
          }
          
          if (rankinginfoElement) {
            if (this.state.isMobilePortraitLndsp) {
              rankinginfoElement.style.left = "62.5%";
            }
          }


          betbutton.style.cssText = "";
          foldbutton.style.cssText = "";
          betbtntextElement.className = "";
          foldtextElement.className = "";

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.classList.add('betLandscape');

          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.classList.add('foldtextLandscape');



          let rebetstatus = 1;
          let totalBetAmountForLimitsCheck;
          let totalAnteBetAmount;
          console.log(this.state.previousAnteBetAmount)
          console.log(this.state.previousBonusbetamount,bettext)
          if(bettext === "REBETX2"){
            rebetstatus =2;
          }
          if (bettext === "REBETX2" || bettext === "REBET") {
            
            if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance) ||
                Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
                // if (Number(this.state.previousAnteBetAmount)  + Number(this.state.previousBonusbetamount) > 39) {
              console.log("this.state.anteBetAmount :------", this.state.previousAnteBetAmount, this.state.previousBonusbetamount);
              this.setState({
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
              })

                // betbutton.src ="" ;
                // foldbutton.src ="" ;
                // betbtntextElement.className ="";
                // foldtextElement.className ="";
              if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
                this.setState({
                  betshigh_balnce: true,
                  lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${this.state.previousAnteBetAmount},Please DEPOSIT to continue playing.`
                })
                // this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              }else if(Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance){
                this.setState({ 
                  betshigh_balnce: true,
                  lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${this.state.previousAnteBetAmount},with bet ${this.state.previousAnteBetAmount*2} Please DEPOSIT to continue playing.`                  
                 })
                // this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              }





              // if (Number(this.state.previousAnteBetAmount * 3 * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > this.props.balance) {
              //   this.setState({ betshigh_balnce: false, multibuttonsebledsble: true, disablemultibutton: true })
              // } else if (Number(this.state.previousAnteBetAmount * rebetstatus) + Number(this.state.previousBonusbetamount * rebetstatus) > Number(this.props.balance)) {
              //   this.setState({ betshigh_balnce: true, multibuttonsebledsble: true, disablemultibutton: true })
              // }


              betbtnElement.classList.remove("addpointer")
              betbtnElement.classList.add("drwnodrop");
              popupbetGreaterthanBalnceElement.className = "";
              gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0 },
                { scale: 1, duration: 1, rotate: 720, opacity: 1, ease: "power2.out" });

              if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

                popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
                textbetsHighElement.classList.add("poptextBetshighPortrait");
                attentionElement.classList.add("attentionTextPortrait");

              }

            }
            // else if(this.state.anteBetAmount < this.props.minBetAmt || 
            //           this.state.anteBetAmount > this.props.maxBetAmt) {
            else if (this.state.previousAnteBetAmount * rebetstatus > this.props.maxBetAmt) {
              this.setState({
                infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
                anteBetAmount: 0,
                bonusbetamount: 0,
                totalbet: 0,
                // multibuttonsebledsble: true,
                disablemultibutton: false,
              })

            } else {

             

              bnsactived.src = bnsactive;
              this.previousantebonousamount(bettext);

              betbutton.src = Betbtn;
              Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
              betbtntextElement.classList.add('betLandscape');
    
              foldbutton.src = Foldbn;
              Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
              foldtextElement.classList.add('foldtextLandscape');
    
              bnsactived.classList.add("bnsactiveLandscape");
              bnsactived.classList.add("bnsblink");


              // this.setState({ multibuttonsebledsble: false });
              this.setState({ 
                disablemultibutton: false, 
                betbutton: "DEAL", 
                buttontext: "clear",
                storeantebetchips: [...this.state.prevStatestoreantebetchips],
                storebonuschips: [...this.state.prevStatestorebonuschips],
                bsbtn: false,
                infoText: this.props.language.deal_tostart, 
                betchipincrementbtn: false, 
                betdecreamentbtn: false
                
              },()=>{


                for (let k = 1; k <= this.chipVal.length; k++) {
                  if (this.state.count === k) {
                    totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
                    if (bettext === "REBETX2") { 
                      let chipcenterpositionElement = document.querySelectorAll("#chipcenterposition span");
                      let chip10centerbnsElement = document.querySelectorAll("#chip10centerbns span");
                      for (let a = 0; a < chipcenterpositionElement.length; a++) {
                        chipcenterpositionElement[a].innerHTML = this.topLable_rsp(this.state.previousAnteBetAmount * 2);
                      }
                      for (let b = 0; b < chip10centerbnsElement.length; b++) {
                        chip10centerbnsElement[b].innerHTML = this.topLable_rsp(this.state.previousBonusbetamount * 2);
                      }
    
                      totalAnteBetAmount = (this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val)) * 2;
                      rebetstatus = 2;
                      // document.querySelector('antebetChipPortrait10').innerHTML = totalAnteBetAmount
                    } else {
                      totalAnteBetAmount = this.state.previousAnteBetAmount + Number(this.chipVal[k - 1].val);
                      rebetstatus = 1;
                    }
                  }
                }





                if (document.getElementById('chipcenterposition')) {
                  gsap.fromTo("#chipcenterposition", 
                    {x:1200,y:0,autoAlpha:0,duration:0.00001},
                    {x:0,y:0,autoAlpha:1,duration:0.2,zIndex:1,ease: "power2.out"},
                  )
                  const chipcenterpositionElement = document.querySelectorAll("#chipcenterposition");
                  console.log(chipcenterpositionElement)
                  for (let q = 0; q < chipcenterpositionElement.length; q++) {
  
                    chipcenterpositionElement[q].style.position = 'relative';
                  }
  
                }
                
                if (this.state.previousBonusbetamount > 0) {
  
                  this.setState({ isbonuspattable: true });
                  gsap.fromTo("#chip10centerbns",
                    {x:1200,y:0,autoAlpha:0,duration:0.00001,delay:1},
                    {x:0,y:0,autoAlpha:1,duration:0.2,ease: "power2.out",onStart: () => {
                      this.pokerchipsnd = new Audio(pokerchipsnd);
                      if (this.state.mutesounds) {
                        this.pokerchipsnd.pause();
                      }
                      else {
                        this.pokerchipsnd.play();
  
                      }
                    }});
                  this.ShowBonuspayouttable()
  
                }else{
                  gsap.to("#chip10centerbns",{ duration: 0.000001,opacity: 0 })
                }

              });
              

            }
          }else{


          let totalBetAmountForLimitsCheck = 0;
          let totalAnteBetAmount = 0;
          console.log(this.chipVal)

          for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalBetAmountForLimitsCheck = this.state.totalbet + Number(this.chipVal[k - 1].val);
              totalAnteBetAmount = this.state.anteBetAmount + Number(this.chipVal[k - 1].val);

            }
          }


          console.log("this.state.anteBetAmount :------", totalBetAmountForLimitsCheck,
            this.state.totalbet, this.props.minBetAmt, this.props.maxBetAmt,
            this.state.anteBetAmount + Number(this.chipVal[this.state.count - 1].val)

          );


          if (Number(totalAnteBetAmount)+ Number(this.state.bonusbetamount) > Number(this.props.balance) ||
            Number(totalAnteBetAmount * 3)+ Number(this.state.bonusbetamount) > Number(this.props.balance)) {

            // if (totalAnteBetAmount > 50 || totalAnteBetAmount*3 >50) {
           if (totalAnteBetAmount+ Number(this.state.bonusbetamount) > this.props.balance) {
              this.setState({ 
                betshigh_balnce: true, 
                multibuttonsebledsble: true,
                lowBalaceErrorInfo:`insufficient Funds to place the ANTE of ${totalAnteBetAmount},Please DEPOSIT to continue playing.` 
              })

              betbutton.src ="" ;
              foldbutton.src ="" ;
              betbtntextElement.className ="";
              foldtextElement.className ="";
            }else if(totalAnteBetAmount * 3+ Number(this.state.bonusbetamount) > this.props.balance){
              this.setState({ 
                betshigh_balnce: true,
                lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${totalAnteBetAmount},with bet ${totalAnteBetAmount*2} Please DEPOSIT to continue playing.`
              }) // multibuttonsebledsble: true
            }

            betbtnElement.classList.remove("addpointer")
            betbtnElement.classList.add("drwnodrop");
            popupbetGreaterthanBalnceElement.className = "";

            gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
              { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

            if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
              popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
              textbetsHighElement.classList.add("poptextBetshighPortrait");
              attentionElement.classList.add("attentionTextPortrait");

            }

          }
          //  else if(this.state.anteBetAmount < this.props.minBetAmt || 
          //           this.state.anteBetAmount > this.props.maxBetAmt) {
          else if (totalAnteBetAmount > Number(this.props.maxBetAmt)) {
            this.setState({
              infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl,
              // multibuttonsebledsble: true,
            }, () => {
              console.log(this.state.infoText)
            });



          } else {
            bnsactived.src = bnsactive;
            document.getElementById("bonustext").style.color = "#ffffff";

            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
            betbtntextElement.classList.add('betLandscape');
  
            foldbutton.src = Foldbn;
            Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
            foldtextElement.classList.add('foldtextLandscape');
  
            bnsactived.classList.add("bnsactiveLandscape");
            bnsactived.classList.add("bnsblink");


            this.setState({
              multibuttonsebledsble: false,
              betbutton: "DEAL",
              buttontext: "clear",
              bsbtn: false,
              infoText: this.props.language.deal_tostart, 
              betchipincrementbtn: false, 
              betdecreamentbtn: false,
              previousAnteBetAmount: 0,
              previousBonusbetamount: 0,
            });
            let convrAntebt = this.topLable_rsp(totalAnteBetAmount);

            Object.assign(betchipstoantebet.style, porttraitejson.betchiplndspe);
            var tl = gsap.timeline();
            tl.to(this.chipref.current, {
              x: -1050,
              y: 0,
              // duration: 0.5,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 12,
              autoAlpha: 1,
            });


            tl.to(this.chipref.current, {
              autoAlpha: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
              zIndex: -3,
            });
            tl.play();

            switch (this.state.count) {
              case 1:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
                }))
                betchipstoantebet.src = chip_10;
                this.clertime13 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeantebetchips: [
                      ...prevState.storeantebetchips,
                      { chip: chip_10, chipvalue: convrAntebt, chipcolor1: "valtextPortrait1" },
                    
                    ],
                  }));
                }, 150);
                break;
              case 2:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
                }))
                betchipstoantebet.src = chip_25;
                this.clertime14 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeantebetchips: [
                      ...prevState.storeantebetchips,
                      { chip: chip_25, chipvalue: convrAntebt, chipcolor1: null },
                   
                    ],
                  }));
                }, 150);
                break;
              case 3:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
                }))
                betchipstoantebet.src = chip_50;
                this.clertime15 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeantebetchips: [
                      ...prevState.storeantebetchips,
                      { chip: chip_50, chipvalue: convrAntebt, chipcolor1: null },
                    
                    ],
                  }));
                }, 150);
                break;
              case 4:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
                }))
                betchipstoantebet.src = chip_100;
                this.clertime16 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeantebetchips: [
                      ...prevState.storeantebetchips,
                      { chip: chip_100, chipvalue: convrAntebt, chipcolor1: null },
                   
                    ],
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  anteBetAmount: prevState.anteBetAmount + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
                }))
                betchipstoantebet.src = chip_500;
                this.clertime17 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeantebetchips: [
                      ...prevState.storeantebetchips,
                      { chip: chip_500, chipvalue: convrAntebt, chipcolor1: null },
                   
                    ],
                  }));
                }, 150);
                break;
              default:
                break;
            }

          }
          }




        }
      }


    }
    else {
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {

        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();
        document.getElementById("betbtntext").textContent = "";
        document.getElementById("foldtext").textContent = "";
        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("bnspoiner")
        document.getElementById("betbtntext").textContent = this.props.language.Deal;
        document.getElementById("foldtext").textContent = this.props.language.RP_Clear;
        this.setState({
          muteunmutesounds: true
        })
      } else {
        this.setState({
          // disablemultibutton: true,
        })

      }
      this.setState({
        totalbet: 0,
        infoText: this.props.language.MinBetAmt + " " + this.props.minBetLbl + " " + this.props.language.MaxBetAmt + " " + this.props.maxBetLbl
      })

    }



  }

 






  Showoptionsmenu() {
    this.setState((prevState) => ({ isoptionmenu:!prevState.isoptionmenu,isactiveoptionsmenu: true }), () => { 
    // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {
    // this.setState({ isoptionmenu:true,isactiveoptionsmenu: true }, () => {
   

    var tl = gsap.timeline();


    tl.to("#optionsmenubutton", {
      x: 513,
      y: 75,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      autoAlpha: 1,
      // opacity: 1,

    });

    tl.to("#optiontopmenu", {
      x: 316,
      y: 0,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      autoAlpha: 1,
      // opacity: 1,

    });


    tl.to("#limits", {
      x: 294,
      y: -5,
      duration: 0.2,
      ease: "power2.inOut",
      zIndex: 12,
      autoAlpha: 1,
      // opacity: 1,
      stagger: 2,

    });

    tl.to("#help", {
      x: 294,
      y: -5,
      duration: 0.2,
      ease: "power2.inOut",
      zIndex: 12,
      opacity: 1,
      stagger: 3,

    });
    tl.to("#history", {
      x: 294,
      y: -5,
      duration: 0.2,
      ease: "power2.inOut",
      zIndex: 12,
      autoAlpha: 1,
      // opacity: 1,
      stagger: 4,

    });
    tl.to("#exitmenu", {
      x: 313,
      y: -2,
      duration: 0.2,
      ease: "power2.inOut",
      zIndex: 12,
      autoAlpha: 1,
      // opacity: 1,
      stagger: 5,

    });


    tl.play()

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (document.getElementById("optionsmenubutton")) {
        document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe");
      }

      if (document.getElementById("optionsmenubutton")) {
        document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite");
      }



      if (document.getElementById("optionsmenubutton")) {
        document.getElementById("optionsmenubutton").classList.add("optionsmenu");
      }
      if (document.getElementById("optiontopmenu")) {
        document.getElementById("optiontopmenu").classList.add("topmenu");
      }
      if (document.getElementById("optiontext")) {
        document.getElementById("optiontext").classList.add("optionheading");
      }
      if (document.getElementById("limits")) {
        document.getElementById("limits").classList.add("limitstext");
      }
      if (document.getElementById("help")) {
        document.getElementById("help").classList.add("helptext");
      }
      if (document.getElementById("history")) {
        document.getElementById("history").classList.add("historytext");
      }
      if (document.getElementById("arraow")) {
        document.getElementById("arraow").classList.add("arraowtext");
      }
      if (document.getElementById("exitmenu")) {
        document.getElementById("exitmenu").classList.add("exitoptionmenu");
      }


    }
    else {
      if (window.innerHeight > window.innerWidth) {
        // alert("...por")
        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_lndspe");
        }


        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.add("optionsmenu_portraite");
        }
        if (document.getElementById("optiontopmenu")) {
          document.getElementById("optiontopmenu").classList.add("topmenu");
        }
        if (document.getElementById("optiontext")) {
          document.getElementById("optiontext").classList.add("optionheading");
        }
        if (document.getElementById("limits")) {
          document.getElementById("limits").classList.add("limitstext");
        }
        if (document.getElementById("help")) {
          document.getElementById("help").classList.add("helptext");
        }
        if (document.getElementById("history")) {
          document.getElementById("history").classList.add("historytext");
        }
        if (document.getElementById("arraow")) {
          document.getElementById("arraow").classList.add("arraowtext");
        }
        if (document.getElementById("exitmenu")) {
          document.getElementById("exitmenu").classList.add("exitoptionmenu");
        }


      }
      else {

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.remove("optionsmenu_portraite");
        }

        if (document.getElementById("optionsmenubutton")) {
          document.getElementById("optionsmenubutton").classList.add("optionsmenu_lndspe");
        }
        if (document.getElementById("optiontopmenu")) {
          document.getElementById("optiontopmenu").classList.add("topmenu");
        }
        if (document.getElementById("optiontext")) {
          document.getElementById("optiontext").classList.add("optionheading");
        }
        if (document.getElementById("limits")) {
          document.getElementById("limits").classList.add("limitstext");
        }
        if (document.getElementById("help")) {
          document.getElementById("help").classList.add("helptext");
        }
        if (document.getElementById("history")) {
          document.getElementById("history").classList.add("historytext");
        }
        if (document.getElementById("arraow")) {
          document.getElementById("arraow").classList.add("arraowtext");
        }
        if (document.getElementById("exitmenu")) {
          document.getElementById("exitmenu").classList.add("exitoptionmenu");
        }


      }

    }
  });





  }
  ShowBonuspayouttable() {
    document.getElementById("bonustext").style.color = "#ffffff";

    this.setState({ isbonuspattable: !this.state.isbonuspattable }, () => {
      for (let i = 0; i < 8; i++) {
        let bonuspayout_info = document.getElementById(`numbersvalrussian${i}`)
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows"
        ) {

          const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
          const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");

          if (bonusInfoTheadElement && bonusInfoTbodyElement) {
            Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadwindows);
            Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodywindows);
          }



          if (document.getElementById(`numbersvalrussian${i}`)) {

            if (document.getElementById("heading_table")) {
              document.getElementById("heading_table").classList.add("windowstablehead");
            }
            //  alert("........CLASSADD")
            bonuspayout_info.classList.add("numbersval");
          }
          if (document.getElementById(`numrussian${i}`)) {
            // alert("........CLASSADD1")
            document.getElementById(`numrussian${i}`).classList.add("num");
          }

          if (document.getElementById(`numbers${i}`)) {
            // alert("........CLASSADD2")

            document.getElementById(`numbers${i}`).classList.add("numbers");


          }
        }
        else {

          const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
          const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
          const rankingInfoTable = document.getElementById("bonuspaytableInfo");

          
          if(rankingInfoTable){
            rankingInfoTable.className = "";
            bonusInfoTheadElement.style.cssText = "";
            bonusInfoTbodyElement.style.cssText = "";
          }

          if (window.innerHeight > window.innerWidth) {
            if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
            }
            
            if (rankingInfoTable) {
              rankingInfoTable.classList.add("rankingInfoTablePortrait");
            }

            if (document.getElementById(`numbersvalrussian${i}`)) {

              if (document.getElementById("heading_table")) {
                document.getElementById("heading_table").style.cssText = "";
                document.getElementById("heading_table").classList.add("portraittablehead");
              }
             
              bonuspayout_info.classList.add("numbersvalPortrait");
            }
            if (document.getElementById(`numrussian${i}`)) {
              
              document.getElementById(`numrussian${i}`).style.cssText = "";
              document.getElementById(`numrussian${i}`).classList.add("numPortrait");
            }

            if (document.getElementById(`numbers${i}`)) {
              
              document.getElementById(`numbers${i}`).style.cssText = "";
              document.getElementById(`numbers${i}`).classList.add("numbers");
            }


          } else {

            //  IF MOBILE LANDSCAP ;
            if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
              Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
            }


            if (rankingInfoTable) {
              rankingInfoTable.classList.add("rankingInfoTableLndsp");
            }

            if (document.getElementById(`numbersvalrussian${i}`)) {
              if (document.getElementById("heading_table")) {
                document.getElementById("heading_table").style.cssText = "";
                document.getElementById("heading_table").classList.add("portraittablehead");
              }
              bonuspayout_info.classList.add("numbersvalPortrait");
            }

            if (document.getElementById(`numrussian${i}`)) {
              document.getElementById(`numrussian${i}`).style.cssText = "";
              document.getElementById(`numrussian${i}`).classList.add("numPortrait");
            }


            if (document.getElementById(`numbers${i}`)) {
              document.getElementById(`numbers${i}`).style.cssText = "";
              document.getElementById(`numbers${i}`).classList.add("numbers");
            }


          }
        }
      }
    });

  }

  HideBonuspayouttable() {
    document.getElementById("bonustext").style.color = "";
    if (this.state.isbonuspattable) {
      this.setState({ isbonuspattable: false });
    }



  }


  lowBalanceErrorInformationPopupanimation =() => {
    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    const textbetsHighElement = document.getElementById("textbets_high");
    const attentionElement = document.getElementById("attention");


    popupbetGreaterthanBalnceElement.className = "";

    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0,},
      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
        popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
        textbetsHighElement.classList.add("poptextBetshighwindows");
        attentionElement.classList.add("attentionTextwindows");
  
      }

    }else{
      if(window.innerHeight> window.innerWidth){
        // Portrait

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");
    
        }
      }else{
        // Landscape

        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {

          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");
    
        }
      }
    }
  }

  BonusActivated() {
    if (this.state.mutesounds) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.pause();

    }
    else {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.play();

    }
    this.TotalbetamountGreaterthanbalance();

    // alert("bonus")
    // document.getElementById("betcircle").classList.remove("addpointer")
    document.getElementById("bonusRussian").classList.remove("bnspoiner");


    let totalBetAmountForLimitsCheck = 0;

    for (let k = 1; k <= this.chipVal.length; k++) {
      if (this.state.count === k) {
        totalBetAmountForLimitsCheck = this.state.bonusbetamount + Number(this.chipVal[k - 1].val);

      }
    }
    let totalbets_bonus=this.topLable_rsp(totalBetAmountForLimitsCheck)
    let betchipstobns = document.getElementById("betchipsaddtobonus");

    if(this.state.anteBetAmount*3 + totalBetAmountForLimitsCheck > this.props.balance){
      this.setState({
        betshigh_balnce:true,
        lowBalaceErrorInfo:`insufficient Funds to follow up your ANTE of ${this.state.anteBetAmount},with bet ${this.state.anteBetAmount*2} Please DEPOSIT to continue playing.`
      });
      this.lowBalanceErrorInformationPopupanimation()
    }else{
      

      this.setState({ isbonuspattable: true,betshigh_balnce:null }, () => {

        for (let i = 0; i < 8; i++) {
          let bonuspayout_info = document.getElementById(`numbersvalrussian${i}`)
  
          if (
            navigator.userAgentData &&
            navigator.userAgentData.platform &&
            navigator.userAgentData.platform == "Windows"
          ) {
            const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
            const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
  
            if (bonusInfoTheadElement && bonusInfoTbodyElement) {
              Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadwindows);
              Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodywindows);
            }
            if (document.getElementById(`numbersvalrussian${i}`)) {
  
              if (document.getElementById("heading_table")) {
                document.getElementById("heading_table").classList.add("windowstablehead");
              }
              //  alert("........CLASSADD")
              bonuspayout_info.classList.add("numbersval");
            }
            if (document.getElementById(`numrussian${i}`)) {
              // alert("........CLASSADD1")
              document.getElementById(`numrussian${i}`).classList.add("num");
            }
  
            if (document.getElementById(`numbers${i}`)) {
              // alert("........CLASSADD2")
  
              document.getElementById(`numbers${i}`).classList.add("numbers");
            }
  
          } else {
  
            const bonusInfoTheadElement = document.getElementById('bonusInfoThead');
            const bonusInfoTbodyElement = document.getElementById("bonusInfoTbody");
            const rankingInfoTable = document.getElementById("bonuspaytableInfo");
  
  
            if (rankingInfoTable) {
              rankingInfoTable.className = "";
              bonusInfoTheadElement.style.cssText = "";
              bonusInfoTbodyElement.style.cssText = "";
            }
  
  
  
            if (window.innerHeight > window.innerWidth) {
  
              if (bonusInfoTheadElement && bonusInfoTbodyElement) {
                Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
                Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
              }
  
  
              if (rankingInfoTable) {
                rankingInfoTable.classList.add("rankingInfoTablePortrait");
              }
  
              if (document.getElementById(`numbersvalrussian${i}`)) {
                if (document.getElementById("heading_table")) {
                  document.getElementById("heading_table").classList = "";
                  document.getElementById("heading_table").classList.add("portraittablehead");
                }
                bonuspayout_info.classList.add("numbersvalPortrait");
              }
              if (document.getElementById(`numrussian${i}`)) {
                document.getElementById(`numrussian${i}`).style.cssText = "";
                document.getElementById(`numrussian${i}`).classList.add("numPortrait");
              }
  
              if (document.getElementById(`numbers${i}`)) {
                document.getElementById(`numbers${i}`).style.cssText = "";
                document.getElementById(`numbers${i}`).classList.add("numbers");
              }
  
  
            } else {
  
              //  IF MOBILE LANDSCAP ;
              if (bonusInfoTheadElement && bonusInfoTbodyElement) {
                Object.assign(bonusInfoTheadElement.style, porttraitejson.bonusTheadPortrait);
                Object.assign(bonusInfoTbodyElement.style, porttraitejson.bonusInfoTbodyPortrait);
              }
  
              if (rankingInfoTable) {
                rankingInfoTable.classList.add("rankingInfoTableLndsp");
              }
  
              if (document.getElementById(`numbersvalrussian${i}`)){
                if (document.getElementById("heading_table")) {
                  document.getElementById("heading_table").style.cssText = "";
                  document.getElementById("heading_table").classList.add("portraittablehead");
                }
                bonuspayout_info.classList.add("numbersvalPortrait");
              }
  
              if (document.getElementById(`numrussian${i}`)) {
                document.getElementById(`numrussian${i}`).style.cssText = "";
                document.getElementById(`numrussian${i}`).classList.add("numPortrait");
              }
  
              if (document.getElementById(`numbers${i}`)) {
                document.getElementById(`numbers${i}`).style.cssText = "";
                document.getElementById(`numbers${i}`).classList.add("numbers");
              }
  
  
            }
          }
        }
  
      });



      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
       Object.assign(betchipstobns.style,porttraitejson.betchipstobnswindows)
  
  
        switch (this.state.count) {
          case 1:
            betchipstobns.src = chip_10;
            // document.getElementById("val_500").textContent=""
         
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[0].val),
              bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
              betdeductions: prevState.totalbet + Number(this.chipVal[0].val),
            }))
          
            this.clertime31=setTimeout(() => {
              this.setState((prevState) => ({
              
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                    <img
                      className="chip10centerbns"
  
                      src={chip_10}
                      alt="Chip 10"
                    />
                    <span id="val_10" className="bonusChipsForwindows_val10">{totalbets_bonus}</span>
                  </div>
  
                ],
              }));
            }, 500);
  
            break;
          case 2:
            betchipstobns.src = chip_25;
            // document.getElementById("betchipsaddtobonustxt").textContent = "20"
            // document.getElementById("betchipsaddtobonustxt").classList.add("betchipsaddtobonustxt");
            // if(document.getElementById("val_10")){
            //   document.getElementById("val_10").textContent=""
  
            // }
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[1].val),
              bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
              betdeductions: prevState.totalbet + Number(this.chipVal[1].val),
            }))
            this.clertime32=setTimeout(() => {
              this.setState((prevState) => ({
               
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                    <img
                      className="chip10centerbns"
  
                      src={chip_25}
                      alt="Chip 10"
                    />
                    <span id="val_20" className="bonusChipsForwindows">{totalbets_bonus}</span>
                  </div>
                ],
                // storebetchips: [
                //   ...prevState.storebetchips,
                //   <img
                //     className="chip20centerbns"
                //     id="chip10centerbns"
                //     src={chip_25}
                //     alt="Chip 10"
                //   />,
                // ],
              }));
            }, 500);
  
            break;
          case 3:
            betchipstobns.src = chip_50;
        
            // document.getElementById("val_20").textContent=""
            // document.getElementById("betchipsaddtobonustxt").textContent = "50"
            // document.getElementById("betchipsaddtobonustxt").classList.add("betchipsaddtobonustxt");
            this.clertime33=setTimeout(() => {
              this.setState((prevState) => ({
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                    <img
                      className="chip10centerbns"
  
                      src={chip_50}
                      alt="Chip 10"
                    />
                    <span id="val_50" className="bonusChipsForwindows">{totalbets_bonus}</span>
                  </div>
                ],
              }));
            }, 500);
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[2].val),
              bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
              betdeductions: prevState.totalbet + Number(this.chipVal[2].val),
            }));
            break;
          case 4:
            betchipstobns.src = chip_100;
          
            // document.getElementById("betchipsaddtobonustxt").textContent = "100"
            // document.getElementById("betchipsaddtobonustxt").classList.add("betchipsaddtobonustxt");
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[3].val),
              bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
              betdeductions: prevState.totalbet + Number(this.chipVal[3].val),
            }));
            this.clertime34=setTimeout(() => {
              this.setState((prevState) => ({
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                    <img
                      className="chip10centerbns"
  
                      src={chip_100}
                      alt="Chip 10"
                    />
                    <span id="val_100" className="bonusChipsForwindows">{totalbets_bonus}</span>
                  </div>
                ],
              }));
            }, 500);
            break;
          case 5:
            betchipstobns.src = chip_500;
          
            // document.getElementById("val_100").textContent=""
            // document.getElementById("betchipsaddtobonustxt").textContent = "500"
            // document.getElementById("betchipsaddtobonustxt").classList.add("betchipsaddtobonustxt");
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[4].val),
              bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
              betdeductions: prevState.totalbet + Number(this.chipVal[4].val),
            }));
            this.clertime35 = setTimeout(() => {
              this.setState((prevState) => ({
                storebetchips: [
                  ...prevState.storebetchips,
                  <div id="chip10centerbns" className="chipContainer bonusChipsContainerwindows" key={prevState.storebetchips.length}>
                    <img
                      className="chip10centerbns"
                      src={chip_500}
                      alt="Chip 10"
                    />
                    <span id="val_500" className="bonusChipsForwindows">{totalbets_bonus}</span>
                  </div>
                ],
              }));
            }, 500);
            break;
  
          default:
            break;
        }
  
        //       if(this.betsamount >= this.props.balance){
  
  
        //         document.getElementById("bonusRussian").classList.remove("addpointer");
        //         document.getElementById("bonusRussian").classList.add("drwnodrop");
        //   // alert(".....amount greater"+this.props.language.Amount_Reduce)
        //   this.setState({ infoText: this.props.language.Amount_Reduce,bsbtn:true  })
        //   document.getElementById("inforussian").classList.add("windowsinfo");
        //   // this.setState({antebetcircle:true  })
        // }
  
  
  
  
  
        var tl = gsap.timeline();
        tl.to(this.chipbnsref.current, {
          x: -861,
          y: -554,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 12,
        });
        tl.to(this.chipbnsref.current, {
          x: -48,
          y: 0,
          opacity: 0,
          duration: 0.00001,
        });
        // tl.reverse();
        tl.to(this.chipbnsref.current, { opacity: 1, zIndex: -2 });
        tl.play();
      }
  
  
  
      else {
        let inforussianElement = document.getElementById("inforussian");
        inforussianElement.className = "";
        let convrBonusbet = this.topLable_rsp(totalBetAmountForLimitsCheck);
        if (window.innerHeight > window.innerWidth) {
          inforussianElement.classList.add("portraitinfo");
          Object.assign(betchipstobns.style, porttraitejson.betchipstobnsPortrait);
  
  
          this.setState((prevState) => ({
            // totalbet: prevState.totalbet + 1,
            isbonuspattable: true,
          }));
          switch (this.state.count) {
            case 1:
              betchipstobns.src = chip_10;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
  
              }));
              this.clertime31 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_10, chipvalue:convrBonusbet, chipcolor1:"insuranceTextPortraitChip10"},
  
                  ],
                }));
              }, 150);
  
              break;
            case 2:
              betchipstobns.src = chip_25;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
  
              }));
              this.clertime32 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_25, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
  
              break;
            case 3:
              betchipstobns.src = chip_50;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
  
              }));
              this.clertime33 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_50, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
            case 4:
              betchipstobns.src = chip_100;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
  
              }));
              this.clertime34 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_100, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
            case 5:
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
  
              }));
              betchipstobns.src = chip_500;
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_500, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
  
            default:
              break;
  
          }
  
          var tl = gsap.timeline();
          tl.to(this.chipbnsref.current, {
            autoAlpha:1,
            x: 160,
            y: -1200,
            duration: 0.15,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipbnsref.current, {
            autoAlpha:0,
            x: 0,
            y: 0,
            duration: 0.00001,
            zIndex: -2
          });
          tl.play();
  
  
        } else {
  
          //  IF MOBILE LAND SCAPE;
  
          inforussianElement.classList.add("lndspinfo");
          Object.assign(betchipstobns.style, porttraitejson.betchipstobnsLndsp);
  
  
          this.setState((prevState) => ({
            // totalbet: prevState.totalbet + 1,
            isbonuspattable: true,
          }));
  
          var tl = gsap.timeline();
          tl.to(this.chipbnsref.current, {
            autoAlpha: 1,
            x: -890,
            y: 0,
            duration: 0.15,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipbnsref.current, {
            autoAlpha: 0,
            x: 0,
            y: 0,
            duration: 0.00001,
            zIndex: -2
          });
          tl.play();
          switch (this.state.count) {
            case 1:
              betchipstobns.src = chip_10;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[0].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[0].val),
  
              }));
              this.clertime31 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_10, chipvalue:convrBonusbet, chipcolor1:"insuranceTextPortraitChip10"},
  
                  ],
                }));
              }, 150);
  
              break;
            case 2:
              betchipstobns.src = chip_25;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[1].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[1].val),
  
              }));
              this.clertime32 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_25, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
  
              break;
            case 3:
              betchipstobns.src = chip_50;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[2].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[2].val),
  
              }));
              this.clertime33 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_50, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
            case 4:
              betchipstobns.src = chip_100;
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[3].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[3].val),
  
              }));
              this.clertime34 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_100, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
            case 5:
              this.setState((prevState) => ({
                totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                bonusbetamount: prevState.bonusbetamount + Number(this.chipVal[4].val),
                betdeductions: prevState.betdeductions + Number(this.chipVal[4].val),
  
              }));
              betchipstobns.src = chip_500;
              this.clertime35 = setTimeout(() => {
                this.setState((prevState) => ({
                  storebonuschips: [
                    ...prevState.storebonuschips, {chip:chip_500, chipvalue:convrBonusbet, chipcolor1:null},
                  ],
                }));
              }, 150);
              break;
  
            default:
              break;
  
          }
        }
  
  
  
  
      }


    }

    
  }

  RusssianpokerBet() {
    
    let doubleamt = this.state.anteBetAmount * 2
    console.log(this.state.anteBetAmount, this.props.balance)
    if( this.state.betdeductions + doubleamt <= this.props.balance){
    this.setState((prevState) => ({
      betdeductions: prevState.betdeductions + doubleamt,
      totalbet: prevState.totalbet + doubleamt,
      disBetBtn: 1,
      keyboardhandle: false,
      infoText:""
    }));


    if (this.state.mutesounds) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.pause();
      this.betrussiansnd.pause();

    }
    else {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.play();
      this.betrussiansnd.play();

    }
    const drawbutton = document.getElementById("drawbtn");
    const buybutton = document.getElementById("buybtn");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      document.getElementById("crdscover").classList.add("crdscover")
      document.getElementById("betpannelrussiancover").style.display = "block";
      document.getElementById("betpannelrussiancover").classList.add("bet_pannelcoverrussian");
      document.getElementById("betactive").src = ""
      document.getElementById("betactive").classList.remove("betactive");
      document.getElementById("betbtntext").innerHTML="";
      let t1 = gsap.timeline();

      t1.to("#chip10centerbns", {
        x: 861, y: 546, duration: 0.5, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set("#chip10centerbns", { opacity: 0 });
        }

      });
      t1.play();





      document.getElementById("drawtext").textContent = "";
      document.getElementById("buytext").textContent = "";
      document.getElementById("foldtext").textContent = "";

      document.getElementById("betbtntext").classList.remove("betwindows");
      betbutton.src = "";
      betbutton.style.cssText = ""


      foldbutton.src = "";
      foldbutton.style.cssText = ""
      document.getElementById("foldtext").classList.remove("foldtextwindows");


      drawbutton.src = "";
      drawbutton.style.cssText = ""


      buybutton.src = "";
      buybutton.style.cssText=""
      

      let betTextaddtoBetClass = "bet-text-addtoBet20";

      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        gsap.to("#betTextaddtoBet", { opacity: 1, delay: 0.3 })
        let russianbet_amount
        switch (this.state.count) {

          case 1:
            betchipsaddtobetwindows.src = chip_10;
            russianbet_amount = this.topLable_rsp(this.state.anteBetAmount * 2) 
            betTextaddtoBetElement.textContent = russianbet_amount
            betTextaddtoBetClass = "bet-text-addtoBet10";
            // this.setState((prevState) => ({
            //   totalbet: prevState.totalbet + 10
            // }));
            break;
          case 2:
            betchipsaddtobetwindows.src = chip_25;
            russianbet_amount=this.topLable_rsp(this.state.anteBetAmount * 2) 
            betTextaddtoBetElement.textContent = russianbet_amount
            // this.setState((prevState) => ({
            //   totalbet: prevState.totalbet + 20
            // }));
            break;
          case 3:
            betchipsaddtobetwindows.src = chip_50;
            russianbet_amount=this.topLable_rsp(this.state.anteBetAmount * 2) 
            betTextaddtoBetElement.textContent = russianbet_amount
            // this.setState((prevState) => ({
            //   totalbet: prevState.totalbet + 50
            // }));
            break;
          case 4:
            betchipsaddtobetwindows.src = chip_100;
            russianbet_amount=this.topLable_rsp(this.state.anteBetAmount * 2) 
            betTextaddtoBetElement.textContent = russianbet_amount
            // this.setState((prevState) => ({
            //   totalbet: prevState.totalbet + 100
            // }));
            break;
          case 5:
            betchipsaddtobetwindows.src = chip_500;
            russianbet_amount=this.topLable_rsp(this.state.anteBetAmount * 2) 
            betTextaddtoBetElement.textContent = russianbet_amount
            // this.setState((prevState) => ({
            //   totalbet: prevState.totalbet + 500
            // }));
            break;

          default:
            break;
        }

        gsap.to(this.chipbetref.current, {
          x: -965,
          y: -484,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
          opacity: 1
        });

      
      }

      this.clertime36=setTimeout(() => {
        betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet");
        gsap.to(betTextaddtoBetElement, {
          zIndex:7
         });
      },  250)
      Object.assign(betchipsaddtobetwindows.style,porttraitejson.Betchiptobetwindows)
      
    }


    else {


      let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
      let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
      let betTextaddtoBetClass = "bet-text-addtoBet20";
      document.getElementById("drawtext").textContent = "";
      document.getElementById("buytext").textContent = "";
      document.getElementById("foldtext").textContent = "";
      betbutton.src = "";
      document.getElementById("betbtntext").classList.remove("betwindows");
      document.getElementById("betbtntext").textContent = "";
      betbutton.style.position = "";
      betbutton.style.width = "";
      betbutton.style.transform = "";

      foldbutton.src = "";
      document.getElementById("foldtext").classList.remove("foldtextwindows");
      foldbutton.style.position = "";
      foldbutton.style.width = "";
      foldbutton.style.transform = "";

      drawbutton.src = "";
      drawbutton.style.position = "";
      drawbutton.style.height = "";
      drawbutton.style.width = "";
      drawbutton.style.transform = "";

      buybutton.src = "";
      buybutton.style.position = "";
      buybutton.style.width = "";
      buybutton.style.transform = "";

      // let doubleamt = this.state.totalbet * 2;
      let convrBet = this.topLable_rsp(this.state.anteBetAmount * 2);

      switch (this.state.count) {
        case 1:
          betchipsaddtobetwindows.src = chip_10;
          // betTextaddtoBetElement.textContent = 10;
          betTextaddtoBetElement.textContent = convrBet;
          betTextaddtoBetClass = "bet-text-addtoBet10";
          break;
        case 2:
          betchipsaddtobetwindows.src = chip_25;
          betTextaddtoBetElement.textContent = convrBet;
          break;
        case 3:
          betchipsaddtobetwindows.src = chip_50;
          betTextaddtoBetElement.textContent = convrBet;
          break;
        case 4:
          betchipsaddtobetwindows.src = chip_100;
          betTextaddtoBetElement.textContent = convrBet;
          break;
        case 5:
          betchipsaddtobetwindows.src = chip_500;
          betTextaddtoBetElement.textContent = convrBet;
          break;

        default:
          betTextaddtoBetClass = "bet-text-addtoBet20"
          break;
      }
      this.betchipactivated = true;

      if (window.innerHeight > window.innerWidth) {
        document.getElementById("betpannelrussiancover").style.display = "block";
        betTextaddtoBetElement.classList = "";
        betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-portrait");
        Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetPortrait);
        gsap.to(this.chipbetref.current, {
          // x: -10,
          // y: -950,
          x: -27,
          y: -925,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
          autoAlpha:1,
          opacity: 1,
        });


        gsap.to(betTextaddtoBetElement, {
          // x: 12,
          // y: -1739,
          x: -3,
          y: -1710,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
          opacity: 1,
          visibility: true,
          onStart: function () {
            betTextaddtoBetElement.style.zIndex = 2;
            betTextaddtoBetElement.style.visibility = 'visible';
          }
        });

        if (this.playerWinBonus === true) {
          if (document.getElementById("chip10centerbns")) {
            gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
            this.playerWinBonus = false;
          }
        }




      }

      else {
        document.getElementById("betpannelrussiancover").style.display = "block";
        betTextaddtoBetElement.classList = "";
        betTextaddtoBetElement.classList.add(betTextaddtoBetClass, "bet-text-addtoBet-lndsp");
        Object.assign(betchipsaddtobetwindows.style, porttraitejson.BetchiptobetLndsp);
        gsap.to(this.chipbetref.current, {

          // x: -27,
          // y: -925,

          x: -1060,
          y: 170,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
          autoAlpha:1
        });


        gsap.to(betTextaddtoBetElement, {
          // x: 12,
          // y: -1739,
          x: -1060,
          y: 170,
          duration: 0.3,
          ease: "power2.inOut",
          zIndex: 2,
          autoAlpha: 1,
          onStart: function () {
            betTextaddtoBetElement.style.zIndex = 2;
            betTextaddtoBetElement.style.visibility = 'visible';
          }
        });


        if(this.playerWinBonus === true){
          if (document.getElementById('chip10centerbns')) {
            gsap.to("#chip10centerbns", {
              x: 0, y: 0, duration: 0.00001, autoAlpha: 0,
            });         
          }

        }

        this.playerWinBonus = false;

      }

    }
    

    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "RUSSIAN_POKER_BET",
      object: {
        action: "BET",
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    console.log(body);
    this.props.network.sendof(body);
    // const body2 = {
    //   CN: "GET_PLAYER_INFO",
    //   object: {
    //     gameId: Number(this.props.gameId,),
    //     playerId: Number(this.props.playerId),

    //   },
    //   sessionId: sessionStorage.getItem('sessionId'),
    // }
    // this.props.network.sendof(body2);
    // this.body2 = {};
  
  }else{

    const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
    const textbetsHighElement = document.getElementById("textbets_high");
    const attentionElement = document.getElementById("attention");

    popupbetGreaterthanBalnceElement.className = "";
    textbetsHighElement.className = "";
    attentionElement.className = "";
    this.setState({
      betshigh_balnce: true,
      lowBalaceErrorInfo:`insufficient Funds to place the BET of ${doubleamt},Please DEPOSIT to continue playing.`
    })
    gsap.fromTo(popupbetGreaterthanBalnceElement, { scale: 0, rotate: 0, opacity: 0, duration: 0.5 },
      { scale: 1, duration: 0.5, rotate: 720, opacity: 1, ease: "power2.out" });

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
        popupbetGreaterthanBalnceElement.classList.add("popupbetshighwindows");
        textbetsHighElement.classList.add("poptextBetshighwindows");
        attentionElement.classList.add("attentionTextwindows");
  
      }
    }else{
      if(window.innerHeight > window.innerWidth){
        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
    
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighPortrait");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");
    
        }
      }else{
        if (popupbetGreaterthanBalnceElement && textbetsHighElement && attentionElement) {
    
          popupbetGreaterthanBalnceElement.classList.add("popupbetshighLndsp");
          textbetsHighElement.classList.add("poptextBetshighPortrait");
          attentionElement.classList.add("attentionTextPortrait");
    
        }
      }
    }
















  }
  }


  Exitoptionmenu() {
    this.setState({ isoptionmenu: false });
    var tl = gsap.timeline();
    tl.to("#optionsmenubutton", {
      x: 458,
      y: 75,
      duration: 0.5,
      ease: "power2.inOut",
      zIndex: 12,
      opacity: 1
    });
    tl.play();

  }
  topLable_rsp(value) {

    // // Nine Zeroes for Billions
    // return Math.abs(Number(labelValue)) >= 1.0e+9

    //   ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    //   // Six Zeroes for Millions 
    //   : Math.abs(Number(labelValue)) >= 1.0e+6

    //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    //     // Three Zeroes for Thousands
    //     : Math.abs(Number(labelValue)) >= 1.0e+3

    //       ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    //       : Math.abs(Number(labelValue));


console.log(value)
    // Nine Zeroes for Billions
    const labelValue = Math.abs(Number(value));
    return labelValue >= 1.0e+9

      ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
      // Six Zeroes for Millions 
      : labelValue >= 1.0e+6

        ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
        // Three Zeroes for Thousands
        : labelValue >= 1.0e+3

          ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"

          :Number(labelValue.toFixed(2));
  }
  // disabledrwbutton(){

  //    this.setState({drawbuttonhandle:false})
  //     document.getElementById("drawtext").classList.remove("drawtextopacity");
  // }
  numberOfCardsToBeDraw = (drawCardArray) => {
    console.log("drawCardArray", drawCardArray);
    this.replaceCards = [...drawCardArray];
    console.log(this.replaceCards.length);
    if (this.replaceCards.length > 0) {
      // alert("................>0length")
      this.setState({ drawbuttonhandle: false });
      document.getElementById("drawtext").classList.remove("drawtextopacity");
      document.getElementById("drawbtn").classList.remove("drwnodrop");
    } else {
      // alert("................0length")
      this.setState({ drawbuttonhandle: true });
      document.getElementById("drawtext").classList.add("drawtextopacity");
      document.getElementById("drawbtn").classList.add("drwnodrop");
    }
  };


  checkpayerordealerwins(res) {

    this.clearallsettimeouts()
    document.getElementById("insurenceactive").src = "";
    document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
    document.getElementById("insurenceactive").classList.remove("insurenceblink");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    let bnsactived = document.getElementById("bnsactive");
    this.clertime37 = setTimeout(() => {
      this.setState((prevState) => ({
        previousAnteBetAmount: prevState.anteBetAmount,
        previousBonusbetamount: prevState.bonusbetamount,

        // betdeductions: 0
      }));

      const hands = [
        "onepair", "Threekind", "aceking", "fullhse",
        "Straightflush", "frofkind", "twopair",
        "flush", "stright", "royalflush"
      ];

      switch (res.winner) {
        case "Dealer":
          setTimeout(()=>{
          
            // this.setState({infoText:res.message})
          
          },1000)
          
          hands.forEach(hand => {
            document.getElementById(hand).style.color = "";
          });

          this.updateplayerbalnace();

          if (this.insuranceActive) {
            var tl = gsap.timeline();
            tl.to("#chiptoinsurence", {
              x: 0,
              y: 414,
              duration: 0.6,
              ease: "power2.inOut",
              zIndex: 12,
              onComplete: () => {
                gsap.set("#chiptoinsurence", { opacity: 0 })

              }
            });
            tl.to("#chiptoinsurence", {
              opacity: 0,
              x: 956,
              y: 692,
              duration: 0.1,
            });
            // tl.reverse();
            // tl.to("#chiptoinsurence", { opacity: 0, zIndex: -2 });
            tl.play();

          }
          document.getElementById("foldtext").classList.remove("insurence");
          document.getElementById("betdecrement").classList.remove("drwnodrop")
          document.getElementById("betincrenet").classList.remove("drwnodrop")
        
          this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
          if (this.state.mutesounds) {
            this.pokerchipsnd = new Audio(pokerchipsnd);
            this.pokerchipsnd.pause();

          }
          else {
            this.pokerchipsnd = new Audio(pokerchipsnd);
            this.pokerchipsnd.play();

          }

          // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          document.getElementById("betactive").classList.remove("betactive");
          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true });



          let t1 = gsap.timeline();
          t1.to("#chipcenterposition", {
            x: 2,
            y: -662,
            duration: 0.5,
            zIndex: 12,
            ease: "power2.inOut",
            opacity: 1,
            onComplete: () => {
              gsap.set("#chipcenterposition", { opacity: 0 });
            }
          });


          let t2 = gsap.timeline();
          t2.to("#betchipsaddtobet", {
            x: -967,
            y: -1281,
            duration: 0.5,
            zIndex: 12,
            ease: "power2.inOut",
            opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtobet", { opacity: 0 });
            }
          });
          t2.to("#betchipsaddtobet", {
            x: -2,
            y: 72,
            zIndex: -10,
            duration: 0.1,
            opacity: 0,
            delay: 0.5,

          });

          gsap.to("#betTextaddtoBet", { duration: 0.1, zIndex: -2 })




          let t4 = gsap.timeline();
          t4.to("#betchipsaddtodraw", {
            // x: 360, y: -2060, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            x: -1084, y: -1266, duration: 0.5, zIndex: 12, ease: "power2.inOut", opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtodraw", { opacity: 0 });
            }
          });
          t4.to("#betchipsaddtodraw", {
            x: 12, y: -51, zIndex: -10, duration: 0.1, opacity: 0, delay: 0.5

          });
          gsap.to("#betTextaddtodraw", { opacity: 0, duration: 0.1, zIndex: -15 })

          let centerchips = gsap.timeline();
          centerchips.to("#centerchips", {
            zIndex: -2,
            opacity: 0,
            onComplete: () => {
              document.getElementById("centerchips").removeAttribute("src");
              document.getElementById("centerchips").classList.remove("chip10centerposition");
            }
          });
          centerchips.pause();


          let t6 = gsap.timeline();
          t6.to("#betchipsaddtconfrm", {
            x: -1084,
            y: -1266, duration: 0.3, opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtconfrm", { opacity: 0 });
            }
          });
          t6.to("#betchipsaddtconfrm", {
            x: 12, y: -51, zIndex: -10, duration: 0.3, opacity: 0, delay: 1.5

          });
          // t6.play();

          // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -3 })
          gsap.from("#betTextaddtoinsurence", { zIndex: -2 })

          setTimeout(() => {
            document.getElementById("buttons_cover").classList.remove("buttons_cover")
      
            document.getElementById("betpannelrussiancover").style.display = "none";
            document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");
  
            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnwindows)

            foldbutton.src = Foldbnwindows;
            Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

            document.getElementById("foldtext").textContent = "";

            document.getElementById("betbtntext").textContent = this.props.language.Rebet;
            document.getElementById("betbtntext").classList.add("betwindows");
            document.getElementById("foldtext").classList.add("rebettextwindows");


            document.getElementById("foldtext").textContent = this.props.language.RebetX2;
            this.setState({
              infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0,
              totalbet: 0,
              anteBetAmount: 0,
              bonusbetamount: 0,
              insurencetbet: 0,
              updateBet: 0,
            });

            document.getElementById("bonusRussian").classList.remove("addpointer");
            document.getElementById("bonusRussian").classList.add("drwnodrop");
            document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
            document.getElementById("rankinginfo").className = "";
            document.getElementById("rankinginfodeler").textContent = "";
            document.getElementById("rankinginfo").textContent = "";
            this.cardsRef.current.ReBet();
            this.dealerCardsContainer.current.ReBet();

          }, 3000);


          gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
          gsap.to("#betchipsaddtodraw", { zIndex: -2 })
          gsap.to("#betTextaddtodraw", { zIndex: -2 })

          gsap.to("#betchipsaddtobet", { zIndex: -2 })

          bnsactived.src = "";
          bnsactived.classList.remove("bnsctivewindows");
          bnsactived.classList.remove("bnsblink");
          gsap.set(["#betchipsaddtconfrm", "#betchipsaddtodraw", "#chipcenterposition", "#betchipsaddtobet"], { clearProps: "all" });
          break;
        case "Player":
          // alert(".....12")
          gsap.to("#betTextaddtoinsurence", { zIndex: -2 })
          gsap.to("#betTextaddtodraw", { opacity: 0, zIndex: -20 })
          // gsap.to("#betTextaddtoBet", { zIndex: -20 })
          hands.forEach(hand => {
            document.getElementById(hand).style.color = "";
          });

          this.updateplayerbalnace();
          this.Dealerbetstoplayer()
          var tl = gsap.timeline();
          tl.to("#chiptoinsurence", {
            x: -3,
            y: -410,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to("#chiptoinsurence", {
            opacity: 0,
            x: 990,
            y: 696,
            duration: 0.1,
          });
          // tl.reverse();
          tl.to("#chiptoinsurence", { opacity: 0, zIndex: -2 });



          // document.getElementById("foldtext").textContent = "";
         
          document.getElementById("betdecrement").classList.remove("drwnodrop")
          document.getElementById("betincrenet").classList.remove("drwnodrop")
          document.getElementById("foldtext").innerHTML = "";
          document.getElementById("foldtext").classList.remove("insurence");
          document.getElementById("foldtext").classList.add("rebettextwindows");

          if (this.state.mutesounds) {
            this.plyerwin_snd = new Audio(plyerwin_snd);
            this.plyerwin_snd.pause();

          }
          else {
            this.plyerwin_snd = new Audio(plyerwin_snd);
            this.plyerwin_snd.play();

          }
          this.setState(prevState => ({
            previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

          }))

          //  gsap.to("#chip10centerbns",{x:-950,y:338,zIndex:-2})
          gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
          // gsap.to("#chip10centerbns",{x:-126,y:269,zIndex:2,duration:1})
          // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
          // gsap.from("#betchipsaddtodraw",{x:-949,y:-337})
          // gsap.from("#betchipsaddtodraw",{x:44,y:-48,delay:4,opacity:0})
          // gsap.from("#betchipsaddtobet",{zIndex:-2})
          // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -2 })
          // gsap.to("#betTextaddtoBet", { zIndex: -20 })

          
         let finalwinamount=this.topLable_rsp(res.totalWinAmount)
       
          this.setState({
            betbutton: "REBET", buttontext: "REBETX2",
            keyboardhandle: true,
            totalwinRussian: res.totalWinAmount,
            betchipincrementbtn: false, betdecreamentbtn: false,
         
            betBtnValue: 2
          })
          // a
    
          // setTimeout(()=>{
          //   this.setState({infoText:`CONGRATULATIONS! You won ${finalwinamount}`,})
         
          //  },2500)
          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          document.getElementById("betactive").classList.remove("betactive");

          // ............................commmented on 24-07-24 ......................../
          // if(document.getElementById("chipcenterposition")){
          //   gsap.to("#chipcenterposition", { x: 0, y: 264, duration: 0.5 });

          // }

          let ttop = gsap.timeline();
          ttop.to("#chipcenterposition", {
            x: 2,
            y: -662,
            duration: 0.5,
            zIndex: 12,
            ease: "power2.inOut",
            opacity: 1,
            onComplete: () => {
              gsap.set("#chipcenterposition", { opacity: 0 });
            }
          });

          if (document.getElementById("winpopup_rsp")) {
            document.getElementById("winpopup_rsp").classList.add("winpopup_russian")
            document.getElementById("winamt_rsp").classList.add("winamt_russian")

          }
          document.getElementById("glowonwinchip").classList.add("chipglow_russian")

          let t7 = gsap.timeline();
          t7.to("#betchipsaddtodraw", {
            x: -1083,
            y: -1265, duration: 0.5, opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtodraw", { opacity: 0 });
            }
          });
          t7.to("#betchipsaddtodraw", {
            x: 47, y: -52, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#betchipsaddtodraw", { opacity: 1, delay: 2 });
            }

          });
          t7.play();
          let t8 = gsap.timeline();
          t8.to("#betchipsaddtconfrm", {
            x: -1083,
            y: -1265, duration: 0.5, opacity: 1,
            onComplete: () => {
              gsap.set("#betchipsaddtconfrm", { opacity: 0 });
            }
          });
          t8.to("#betchipsaddtconfrm", {
            x: 47, y: -52, duration: 0.5, zIndex: -2, opacity: 0,
            onComplete: () => {
              gsap.set("#betchipsaddtconfrm", { opacity: 1, delay: 2 });
            }

          });
          t8.play();

          let t9 = gsap.timeline();
          t9.to("#betchipsaddtobet", {
            x: -968,
            y: -352, duration: 0.5, opacity: 1, delay: 0.5, duration: 0.5
            // onComplete:()=> {
            //   gsap.set("#betchipsaddtodraw", { opacity: 0 });
            // }
          });
          t9.to("#betchipsaddtobet", {
            // x: 26, y: -66, duration: 0.1, opacity: 0, delay: 3,
            onComplete: () => {
              // gsap.set("#betchipsaddtobet", { opacity: 0 });
            }

          });
          t9.play()

          gsap.to("#betTextaddtoBet", {
            x: -3,
            y: 134, duration: 0.5, delay: 0.5, opacity: 1,


          });
          gsap.to("#betTextaddtoBet", {
            x: 0,
            y: -2, duration: 0.1, delay: 3, opacity: 0,

          });



          setTimeout(() => {
            document.getElementById("buttons_cover").classList.remove("buttons_cover")
          document.getElementById("betpannelrussiancover").style.display = "none";
          document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
            document.getElementById("betbtntext").textContent = this.props.language.Rebet;

            document.getElementById("betbtntext").classList.add("betwindows");

            foldbutton.src = Foldbnwindows;
            Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

            document.getElementById("foldtext").textContent = this.props.language.RebetX2;
            document.getElementById("foldtext").classList.add("rebettextwindows");

            gsap.to("#betchipsaddtobet", { x: 27, y: 0, duration: 0 });
            if (document.getElementById("winpopup_rsp")) {
              document.getElementById("winpopup_rsp").classList.remove("winpopup_russian")
              document.getElementById("winamt_rsp").classList.remove("winamt_russian")
            }
            document.getElementById("glowonwinchip").classList.remove("chipglow_russian")
            gsap.to("#betTextaddtoBet", { zIndex: -20 })

            this.setState({
              infoText: this.props.language.Place_antebet,

              totalbet: 0,
              anteBetAmount: 0,
              bonusbetamount: 0,
              insurencetbet: 0,

              disBetBtn: 0, bsbtn: true, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0
            });

            // gsap.to("#chipcenterposition", {
            //    x: 955, y: 290, duration: 0.1, opacity: 0,

            // });
            document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");

            gsap.to("#betchipsaddtobet", { zIndex: -2 })
            gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
            gsap.to("#betchipsaddtodraw", { zIndex: -2 })
            gsap.to("#chipcenterposition", { zIndex: -2 })
            gsap.to("#betchipsaddtodrawText", { zIndex: -2,duration:0.1 })
            
            document.getElementById("rankinginfo").className = "";
            document.getElementById("rankinginfodeler").textContent = "";
            document.getElementById("rankinginfo").textContent = "";
            this.cardsRef.current.ReBet();
            this.dealerCardsContainer.current.ReBet();


          }, 3000);



          gsap.set(["#betchipsaddtconfrm", "#betchipsaddtodraw", "#chipcenterposition"], { clearProps: "all" });
          bnsactived.src = "";
          bnsactived.classList.remove("bnsctivewindows");
          bnsactived.classList.remove("bnsblink");

          break;
        // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
        // alert(".............pppppyada")
        case "Dealer_no_hand":

          // gsap.from("#betchipsaddtobet", { zIndex: -2 })
          //gsap.from("#betTextaddtodraw",{zIndex:-2})
          gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
          document.getElementById("betbtntext").classList.remove("noinsurence");
          document.getElementById("betactive").src = "";
          document.getElementById("bettext").style.color = "";
          this.clertime55 = setTimeout(() => {
            this.setState({ betbutton: "YES", infoText: res.message, buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true, disablemultibutton: false });
            betbutton.src = Betbtn;
            Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
            document.getElementById("betbtntext").textContent = this.props.language.Yes;

            foldbutton.src = Foldbnwindows;
            Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
            document.getElementById("foldtext").textContent = this.props.language.No;

            document.getElementById("betbtntext").classList.remove("insurence");
            document.getElementById("foldtext").classList.remove("noinsurence");
            document.getElementById("betbtntext").classList.add("betwindows");
            document.getElementById("foldtext").classList.add("foldtextwindows");

          }, 500);

        default:
          break;
      }
    }, 3500)
  }


  Showebetchipsgamestatehandle = (res) => {


    let betTextaddtoBetElement = document.getElementById("betTextaddtoBet");
    let betchipsaddtobetwindows = document.getElementById("betchipsaddtobet");
    betchipsaddtobetwindows.src = chip_10;
    // alert("............"+betchipsaddtobetwindows)
    betTextaddtoBetElement.textContent = this.topLable_rsp(res.betAmount);
    betTextaddtoBetElement.classList.add("bet-text-addtoBet")
    gsap.to(this.chipbetref.current, {
      x: -965,
      y: -484,
      duration: 0.1,
      ease: "power2.inOut",
      zIndex: 2,
      opacity: 1
    });
    // document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
    // document.getElementById("rankinginfo").classList.add("windowspokerranking");
    Object.assign(betchipsaddtobetwindows.style, porttraitejson.Betchiptobetwindows)

    this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand })
  }


  showcheckpayerordealerwins(res) {

    this.clearallsettimeouts()
    document.getElementById("insurenceactive").src = "";
    document.getElementById("insurenceactive").classList.remove("insurenceactivewindows");
    document.getElementById("insurenceactive").classList.remove("insurenceblink");
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");


    // this.clertime37=setTimeout(() => {
    this.setState((prevState) => ({
      previousAnteBetAmount: prevState.anteBetAmount,
      previousBonusbetamount: prevState.bonusbetamount,
      totalbet: 0,
      // anteBetAmount: 0,
      // bonusbetamount: 0,
      insurencetbet: 0,

      // betdeductions: 0
    }));


    const hands = [
      "onepair", "Threekind", "aceking", "fullhse",
      "Straightflush", "frofkind", "twopair",
      "flush", "stright", "royalflush"
    ];

    switch (res.winner) {
      case "Dealer":
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });

        this.updateplayerbalnace();

        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")
        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

        this.setState({ betchipincrementbtn: false, betdecreamentbtn: false })
        if (this.state.mutesounds) {
          this.pokerchipsnd = new Audio(pokerchipsnd);
          this.pokerchipsnd.pause();

        }
        else {
          this.pokerchipsnd = new Audio(pokerchipsnd);
          this.pokerchipsnd.play();

        }

        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1})
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");
        this.setState({
          betbutton: "REBET", previousAnteBetAmount: res.anteBetAmount,
          anteBetAmount: 0,
          bonusbetamount: 0,
          insurencetbet: 0,

          previousBonusbetamount: res.bonusBetAmount,


          buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true, totalWinAmount: 0
        });


        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })

        // setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebet");




        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = "";
        document.getElementById("foldtext").textContent = this.props.language.RebetX2;
        document.getElementById("foldtext").classList.add("rebettextwindows");
        // }, 2000);
        this.setState({ infoText: this.props.language.Place_antebet, bsbtn: true, disBetBtn: 0, totalwinRussian: 0, updateBet: 0, bonusBetAmount: 0 });

        document.getElementById("bonusRussian").classList.remove("addpointer");
        document.getElementById("bonusRussian").classList.add("drwnodrop");
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").className = "";
        document.getElementById("rankinginfodeler").textContent = "";
        document.getElementById("rankinginfo").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();




        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { zIndex: -2 })

        gsap.to("#betchipsaddtobet", { zIndex: -2 })

        // this.showcardsAdBetsParamState(res)

        break;

      //  case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
      case "Player":

        this.setState({
          infoText: this.props.language.Place_antebet,
          anteBetAmount: 0,
          bonusbetamount: 0,
          disBetBtn: 0,
          bsbtn: true,
          totalwinRussian: 0,
          updateBet: 0,
          bonusBetAmount: 0
        });

        gsap.to("#betTextaddtoinsurence", { zIndex: -2 })
        gsap.to("#betTextaddtodraw", { opacity: 0, zIndex: -20 })
        gsap.to("#betTextaddtoBet", { zIndex: -20 })
        hands.forEach(hand => {
          document.getElementById(hand).style.color = "";
        });


        this.updateplayerbalnace();
        // this.Dealerbetstoplayer()



        document.getElementById("foldtext").textContent = "";
      
        document.getElementById("betdecrement").classList.remove("drwnodrop")
        document.getElementById("betincrenet").classList.remove("drwnodrop")

        document.getElementById("foldtext").classList.remove("insurence");
        document.getElementById("foldtext").classList.add("rebettextwindows");

        document.getElementById("betpannelrussiancover").style.display = "none";
        document.getElementById("betpannelrussiancover").classList.remove("bet_pannelcoverrussian");

        if (this.state.mutesounds) {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.pause();

        }
        else {
          this.plyerwin_snd = new Audio(plyerwin_snd);
          this.plyerwin_snd.play();

        }
        this.setState(prevState => ({
          previousAnteBetAmount: prevState.anteBetAmount, previousBonusbetamount: prevState.bonusbetamount

        }))

        //  gsap.to("#chip10centerbns",{x:-950,y:338,zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        // gsap.to("#chip10centerbns",{x:-126,y:269,zIndex:2,duration:1})
        // gsap.from("#chip10centerbns",{x:1046,y:276,zIndex:-2,duration:1,delay:5})
        // gsap.from("#betchipsaddtodraw",{x:-949,y:-337})
        // gsap.from("#betchipsaddtodraw",{x:44,y:-48,delay:4,opacity:0})
        // gsap.from("#betchipsaddtobet",{zIndex:-2})
        // gsap.from("#betTextaddtodraw", { opacity: 0, zIndex: -2 })
        gsap.to("#betTextaddtoBet", { zIndex: -20 })
        this.setState({
          betbutton: "REBET", buttontext: "REBETX2",
          keyboardhandle: true,
          betchipincrementbtn: false, betdecreamentbtn: false,
          previousAnteBetAmount: res.anteBetAmount, previousBonusbetamount: res.bonusBetAmount,

          betBtnValue: 2
        })
        // alert("..........congratss")
        document.getElementById("betactive").src = "";
        document.getElementById("bettext").style.color = "";
        document.getElementById("betactive").classList.remove("betactive");

        // ............................commmented on 24-07-24 ......................../
        // if(document.getElementById("chipcenterposition")){
        //   gsap.to("#chipcenterposition", { x: 0, y: 264, duration: 0.5 });

        // }



        if (document.getElementById("winpopup_rsp")) {
          document.getElementById("winpopup_rsp").classList.add("winpopup_russian")
          document.getElementById("winamt_rsp").classList.add("winamt_russian")

        }
        document.getElementById("glowonwinchip").classList.add("chipglow_russian")


        //  setTimeout(() => {
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Rebet;
        document.getElementById("betbtntext").classList.add("betwindows_rebet");



        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)

        document.getElementById("foldtext").textContent = this.props.language.RebetX2;

        document.getElementById("foldtext").classList.add("rebettextwindows");
        // }, 2000);

        // gsap.to("#betchipsaddtobet", { x: 27, y: 0, duration: 0 });
        if (document.getElementById("winpopup_rsp")) {
          document.getElementById("winpopup_rsp").classList.remove("winpopup_russian")
          document.getElementById("winamt_rsp").classList.remove("winamt_russian")
        }
        document.getElementById("glowonwinchip").classList.remove("chipglow_russian")


        // gsap.to("#chipcenterposition", {
        //    x: 955, y: 290, duration: 0.1, opacity: 0,

        // });


        gsap.to("#betchipsaddtobet", { zIndex: -2 })
        gsap.to("#betchipsaddtconfrm", { zIndex: -2 })
        gsap.to("#betchipsaddtodraw", { zIndex: -2 })
        gsap.to("#chipcenterposition", { zIndex: -2, opacity: 0 })
        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");
        document.getElementById("rankinginfo").className = "";
        document.getElementById("rankinginfodeler").textContent = "";
        document.getElementById("rankinginfo").textContent = "";
        this.cardsRef.current.ReBet();
        this.dealerCardsContainer.current.ReBet();



        document.getElementById("rankinginfodeler").classList.remove("windowspokerrankingdeler");

        // this.showcardsAdBetsParamState(res)

        break;
      // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
      // alert(".............pppppyada")
      case "Dealer_no_hand":
        this.setState({
          anteBetAmount: res.anteBetAmount,
          totalbet: res.totalBetAmount,
          infoText: res.message,
          pokerranking:res.playerHand,
        pokerrankingdeler:res.dealerHand
        
  

        })
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        document.getElementById("rankinginfo").classList.add("windowspokerranking");
        this.showcardsAdBetsParamState(res, res.playerFinalCards, res.dealerFinalCards);
        if (res.betAmount > 0) {

          this.Showebetchipsgamestatehandle(res)
          //  let betTextaddtoBetClass = "bet-text-addtoBet10";
          // Object.assign(betchipsaddtobetwindows.style,porttraitejson.Betchiptobetwindows)
        }
        // gsap.from("#betchipsaddtobet", { zIndex: -2 })
        //gsap.from("#betTextaddtodraw",{zIndex:-2})
        gsap.from("#betTextaddtoinsurence", { zIndex: -2 })
        document.getElementById("betbtntext").classList.remove("noinsurence");
        document.getElementById("betactive").src = "";
        document.getElementById("betactive").classList.remove("betactive");
        document.getElementById("bettext").style.color = "";
        // this.clertime55=setTimeout(() => {
        this.setState({
          betbutton: "YES", buttontext: "NO", Yesbtn: 0, Nobtn: 0, keyboardhandle: true,

          totalwinRussian: res.totalWinAmount, disablemultibutton: false
        });
        betbutton.src = Betbtn;
        Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
        document.getElementById("betbtntext").textContent = this.props.language.Yes;

        foldbutton.src = Foldbnwindows;
        Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
        document.getElementById("foldtext").textContent = this.props.language.No;

        document.getElementById("betbtntext").classList.remove("insurence");
        document.getElementById("foldtext").classList.remove("noinsurence");
        document.getElementById("betbtntext").classList.add("betwindows");
        document.getElementById("foldtext").classList.add("foldtextwindows");

      // }, 500);

      default:
        break;
    }
    // }, 3500)

  }
  buydealercardResponse(res) {
    this.setState({ totalwinRussian: res.totalWinAmount,cmfrmdealeraction: 1, cmfrmdealeractionNO: 1})


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      betbutton.src = "";
      document.getElementById("betbtntext").classList.remove("betwindows");
      betbutton.style.position = "";
      betbutton.style.width = "";
      betbutton.style.transform = "";

      foldbutton.src = "";
      document.getElementById("foldtext").classList.remove("foldtextwindows");
      foldbutton.style.position = "";
      foldbutton.style.width = "";
      foldbutton.style.transform = "";
      document.getElementById("confirmbuydealer_popup").style.display = "none";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")


      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        //   document.getElementById("russiangamecover").style.display = "block";
        //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

        this.setState({
          multibuttonsebledsble: true, disablemultibutton: true, 

          Yesbtn: 1, Nobtn: 1
        })
        // alert(".......popupdealerconfirmation")

        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")
        // document.getElementById("crdscover").style.display="block"
        document.getElementById("crdscover").classList.add("crdscover")
        document.getElementById("playerDealerCardContainer").classList.remove("addpointer")

        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
        this.setState({ infoText: res.message })
      }
      else {
        this.checkpayerordealerwins(res);
      }
      // else {
      //   const betbutton = document.getElementById("betbtn");
      //   const foldbutton = document.getElementById("Foldbtn");
      //   if (
      //     navigator.userAgentData &&
      //     navigator.userAgentData.platform &&
      //     navigator.userAgentData.platform == "Windows"
      //   ) {
      //     this.setState({ betbutton: "REBET", buttontext: "REBETX2",disBetBtn:0,betBtnValue:2,keyboardhandle:true })
      //     document.getElementById("drawtext").textContent = "";
      //     document.getElementById("buytext").textContent = "";
      //     betbutton.src = "";
      //     betbutton.style.cssText=""
      //     document.getElementById("betbtntext").classList.remove("betwindows");


      //     foldbutton.src = "";
      //     foldbutton.style.cssText=""
      //     document.getElementById("foldtext").classList.remove("foldtextwindows");

      //     document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      //     document.getElementById("alertTxt").classList.remove("alertTxt");
      //     document.getElementById("alertTxt").innerHTML ="";
      //     document.getElementById("yes").classList.remove("yesbutton_popup")
      //     document.getElementById("no").classList.remove("nobutton_popup")
      //     this.setState({ infoText: res.message })

      //     betbutton.src = Betbtn;
      //     Object.assign(betbutton.style,porttraitejson.Betbtnwindows)
      //     document.getElementById("betbtntext").textContent = this.props.language.Rebet;

      //     foldbutton.src = Foldbnwindows;
      //     Object.assign(foldbutton.style,porttraitejson.foldbtnwindows)
      //     document.getElementById("foldtext").textContent = this.props.language.RebetX2;

      //   }

      // }

    } else {
      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;

      // foldtext betbtntext

          const betbutton = document.getElementById("betbtn");
          const foldbutton = document.getElementById("Foldbtn");
          const drawtextElement = document.getElementById("drawtext");
          const buytextElement = document.getElementById("buytext");
          const foldtextElement = document.getElementById("foldtext");
          const betbtntextElement = document.getElementById("betbtntext");
          betbutton.src ="";
          foldbutton.src = "";
          drawtextElement.textContent = "";
          buytextElement.textContent = "";
          foldtextElement.textContent = "";
          betbtntextElement.textContent = "";
          
      if (window.innerHeight > window.innerWidth) {
   


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
          //   document.getElementById("russiangamecover").style.display = "block";
          //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

          this.setState({ multibuttonsebledsble: true, disablemultibutton: true })
          // alert(".......popupdealerconfirmation")
          confirmbuydealerPopupElement.classList = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          this.setState({ infoText: res.message })
        }
        else {

          // this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          // drawtextElement.textContent = "";
          // buytextElement.textContent = "";
          
          // betbutton.style.cssText=""
          // document.getElementById("betbtntext").classList.remove("betportrait");
          
          // foldbutton.src = "";
          // foldbutton.style.cssText=""
          // document.getElementById("foldtext").classList.remove("foldtextportrait");
          
          // confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
          // confirmbuydealerPopupElement.style.display = "none";
          // document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          // document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })

          // betbutton.src = Betbtn;
          // document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          // foldbutton.src = Foldbn;
          // document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

          switch (res.winner) {
            case "Dealer":
              this.ifDealerWinsMobilePortraitAction(res);
              break;
  
            // case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`:
            // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
            case "Player":
              this.ifPlayerWinsMobilePortraitAction(res);
              break;
  
              case "Dealer_no_hand":
            // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
              // alert(".............pppppyada")
              ;
            default:
              break;
          }

        }

      }else{

      this.checkPlayerOrDealerWinMblLndspe(res);
      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
      console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
      //   document.getElementById("russiangamecover").style.display = "block";
      //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

      this.setState({ multibuttonsebledsble: true, disablemultibutton: true })
      confirmbuydealerPopupElement.classList = "";
      confirmbuydealerPopupElement.style.display = "block";
      confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
      document.getElementById("alertTxt").innerHTML = res.message;
      document.getElementById("alertTxt").classList.add('alertTxtPortrait');
      document.getElementById("yes").classList.add("yesbutton_popup_portrait")
      document.getElementById("no").classList.add("nobutton_popup_portrait")
      this.setState({ infoText: res.message })
    }
    else {

      this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
      drawtextElement.textContent = "";
      buytextElement.textContent = "";
      
      betbutton.style.cssText=""
      betbtntextElement.className = "";
      
      foldbutton.src = "";
      foldbutton.style.cssText=""
      foldtextElement.className = "";
      
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
      confirmbuydealerPopupElement.style.display = "none";
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")
      this.setState({ infoText: res.message })

      // betbutton.src = Betbtn;
      // Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
      // betbtntextElement.textContent = this.props.language.Rebet;
      // betbtntextElement.classList.add('betLandscape');
      

      // foldbutton.src = Foldbn;
      // Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
      // foldtextElement.textContent = this.props.language.RebetX2;
      // foldtextElement.classList.add("foldtextLandscape");

    

    }
      }
    }

  };
  removeconfirmdealercard_popup = () => {
    document.getElementById("confirmbuydealer_popup").style.display = "none";
    document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
    document.getElementById("alertTxt").classList.remove("alertTxt");
    document.getElementById("alertTxt").innerHTML = "";
    document.getElementById("yes").classList.remove("yesbutton_popup")
    document.getElementById("no").classList.remove("nobutton_popup")

  }
  
    ShowbuydealercardResponse(res) {


    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      // document.getElementById("rankinginfo").classList.add("windowspokerranking");
      document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      this.setState({

        pokerrankingdeler: res.dealerHand,
        // pokerranking:res.playerHand,
        totalwinRussian: res.totalWinAmount
      })



      // this.showcardsAdBetsParamState(res,res.dealerCards,res.playerFinalCards)
      this.showcheckpayerordealerwins(res);

      if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        //   document.getElementById("russiangamecover").style.display = "block";
        //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

        this.setState({ multibuttonsebledsble: true, disablemultibutton: true, Yesbtn: 1, Nobtn: 1 })
        // alert(".......popupdealerconfirmation")

        document.getElementById("betbtn").classList.remove("addpointer")
        document.getElementById("Foldbtn").classList.remove("addpointer")
        document.getElementById("betbtn").classList.add("drwnodrop")
        document.getElementById("Foldbtn").classList.add("drwnodrop")
        // document.getElementById("crdscover").style.display="block"
        document.getElementById("crdscover").classList.add("crdscover")
        document.getElementById("playerDealerCardContainer").classList.remove("addpointer")

        document.getElementById("confirmbuydealer_popup").style.display = "block";
        document.getElementById("confirmbuydealer_popup").classList.add("popup_cnfrmbuydealrcard");
        document.getElementById("alertTxt").classList.add("alertTxt");

        document.getElementById("alertTxt").innerHTML = res.message;
        document.getElementById("yes").classList.add("yesbutton_popup")
        document.getElementById("no").classList.add("nobutton_popup")
        this.setState({ infoText: res.message })
      }
      else {
        const betbutton = document.getElementById("betbtn");
        const foldbutton = document.getElementById("Foldbtn");
        if (
          navigator.userAgentData &&
          navigator.userAgentData.platform &&
          navigator.userAgentData.platform == "Windows"
        ) {
          this.removeconfirmdealercard_popup();

          this.setState({ betbutton: "REBET", buttontext: "REBETX2", disBetBtn: 0, betBtnValue: 2, keyboardhandle: true })
          document.getElementById("drawtext").textContent = "";
          document.getElementById("buytext").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText = ""
          document.getElementById("betbtntext").classList.remove("betwindows");


          foldbutton.src = "";
          foldbutton.style.cssText = ""
          document.getElementById("foldtext").classList.remove("foldtextwindows");

          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnwindows)
          document.getElementById("betbtntext").textContent = this.props.language.Rebet;

          foldbutton.src = Foldbnwindows;
          Object.assign(foldbutton.style, porttraitejson.foldbtnwindows)
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;

        }

      }

    } else {

      const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
      document.getElementById("playerDealerCardContainer").style.zIndex = 1;
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      if (window.innerHeight > window.innerWidth) {
        switch (res.winner) {
          case "Dealer":
          case "Player":
            this.showPlayerorDealerorFold(res);

            break;
            case "Dealer_no_hand":
          // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
            // alert(".............pppppyada")
            ;
          default:
            break;
        }


        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
          console.log("Attention! You have a Three of a Kind. Are you sure you want to buy a dealer card?")
        this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerFinalCards);
        this.shwnPrvsSteBuyDrawBtchips(res) ;
        this.ConfirmbuydealercardactivePopup = true
        // this.randomDealerCards = [...res.dealerFinalCards];
        this.dealerShuffledCards=[...res.dealerFinalCards]

        if(res.insurance){
          if(res.insuranceBetAmount>0){
          let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
          this.setState((prevState) => ({
            storeinsurancechips:[
              ...prevState.storeinsurancechips, {chip:chip_10, chipvalue:convrInsurenceBet, chipcolor1:"insuranceTextPortraitChip10"},
            ],
            insurencetbet:res.insuranceBetAmount
          }));
        }
        }
        

          this.setState({ 
            multibuttonsebledsble: true, 
            disablemultibutton: true,
            infoText: res.message,
            pokerranking:res.playerHand,
            pokerrankingdeler:res.dealerHand
          
          })
          
          confirmbuydealerPopupElement.classList = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardPortrait");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerPortrait")
          document.getElementById("rankinginfo").classList.add("pokerrankingPortait")
          
        }
        else {
         

          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          document.getElementById("drawtext").textContent = "";
          document.getElementById("buytext").textContent = "";
          betbutton.src = "";
          betbutton.style.cssText=""
          document.getElementById("betbtntext").classList.remove("betportrait");
          
          foldbutton.src = "";
          foldbutton.style.cssText=""
          document.getElementById("foldtext").classList.remove("foldtextportrait");
          document.getElementById("confirmbuydealer_popup").style.display = "none";
          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })

          betbutton.src = Betbtn;
          document.getElementById("betbtntext").textContent = this.props.language.Rebet;
          Object.assign(betbutton.style, porttraitejson.Betbtnportraite);


          foldbutton.src = Foldbn;
          document.getElementById("foldtext").textContent = this.props.language.RebetX2;
          Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

        

        }

      }else{

          const drawtextElement = document.getElementById("drawtext")
          const buytextElement = document.getElementById("buytext")
          const foldtextElement = document.getElementById("foldtext")
          const betbtntextElement = document.getElementById("betbtntext")
          this.prevStatecheckPlayerOrDealerWinMblLndspe(res);

        
        if (res.message == `Attention! You have a ${res.playerHand}. Are you sure you want to buy a dealer card?`) {
        
          //   document.getElementById("russiangamecover").style.display = "block";
          //  document.getElementById("russiangamecover").classList.add("gamecover_enbledisable")

          this.showcardsAdBetsParamState(res,res.playerFinalCards,res.dealerFinalCards);
          this.shwnPrvsSteBuyDrawBtchips(res) ;
          this.ConfirmbuydealercardactivePopup = true;
          this.dealerShuffledCards=[...res.dealerFinalCards];
          if(res.insurance){
            if(res.insuranceBetAmount>0){
            let convrInsurenceBet = this.topLable_rsp(res.insuranceBetAmount);
            this.setState((prevState) => ({
              storeinsurancechips:[
                ...prevState.storeinsurancechips, {chip:chip_10, chipvalue:convrInsurenceBet, chipcolor1:"insuranceTextPortraitChip10"},
              ],
              insurencetbet:res.insuranceBetAmount
            }));
          }
          };

          this.setState({ 
            multibuttonsebledsble: true, 
            disablemultibutton: true ,
            infoText: res.message,
            pokerranking:res.playerHand,
            pokerrankingdeler:res.dealerHand
          })
          confirmbuydealerPopupElement.classList = "";
          confirmbuydealerPopupElement.style.display = "block";
          confirmbuydealerPopupElement.classList.add("popup_cnfrmbuydealrcardLndsp");
          document.getElementById("alertTxt").innerHTML = res.message;
          document.getElementById("alertTxt").classList.add('alertTxtPortrait');
          document.getElementById("yes").classList.add("yesbutton_popup_portrait")
          document.getElementById("no").classList.add("nobutton_popup_portrait")
          document.getElementById("rankinginfodeler").classList.add("pokerrankingdelerLndSpe")
          document.getElementById("rankinginfo").classList.add("pokerrankingPlayerLndsp")
          
        }
        else {
    
          this.setState({ betbutton: "REBET", buttontext: "REBETX2" })
          drawtextElement.textContent = "";
          buytextElement.textContent = "";
          
          betbutton.style.cssText=""
          betbtntextElement.className = "";
          
          foldbutton.src = "";
          foldbutton.style.cssText=""
          foldtextElement.className = "";
          
          confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
          confirmbuydealerPopupElement.style.display = "none";
          document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
          document.getElementById("no").classList.remove("nobutton_popup_portrait")
          // this.setState({ infoText: res.message })
    
          betbutton.src = Betbtn;
          Object.assign(betbutton.style, porttraitejson.Betbtnlndspe);
          betbtntextElement.textContent = this.props.language.Rebet;
          betbtntextElement.classList.add('betLandscape');
          
    
          foldbutton.src = Foldbn;
          Object.assign(foldbutton.style, porttraitejson.foldbtnlndspe);
          foldtextElement.textContent = this.props.language.RebetX2;
          foldtextElement.classList.add("foldtextLandscape");
    
        
    
        }
      }
    }

  };
  
  
  
  
  


  buyDealerCard(Text) {

    this.setState({infoText:""})
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext");
    betbutton.style.position = "";
    betbutton.style.width = "";
    betbutton.style.transform = "";
    betbtntextElement.textContent="";

    foldbutton.src = "";
    foldbutton.style.position = "";
    foldbutton.style.width = "";
    foldbutton.style.transform = "";
    foldtextElement.textContent="";
    }

    let wanttobuydelercrd;
    if ((Text == "YES")&&(this.state.anteBetAmount + this.state.totalbet > this.props.balance)) {
      this.setState({
        betshigh_balnce:true,
        lowBalaceErrorInfo:`insufficient Funds to place the Buy of ${this.state.anteBetAmount},Please DEPOSIT to continue playing.` 
      });
      this.lowBalanceErrorInformationPopupanimation()


    }else{
      this.setState({betshigh_balnce:null});

      if (Text == "YES") {
        wanttobuydelercrd = true;
        this.ConfirmbuydealercardactivePopup = true;
        if (this.state.mutesounds) {
          this.selectCard.pause();
        } else {
          this.selectCard.play();
        }
  
      } else {
        wanttobuydelercrd = false;
        this.ConfirmbuydealercardactivePopup = false;
        if (this.state.mutesounds) {
          this.unselectCard.pause();
        } else {
          this.unselectCard.play();
        }
      }
  
      const body = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "BUY_DEALER_CARD",
          wantToBuyDealerACard: wanttobuydelercrd,
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
  
      console.log(body);
      this.props.network.sendof(body);


    }
   
     

    
  

  }


  Insurenceactived = () => {


    let totalInsuranceBtAt = 0;
    for (let k = 1; k <= this.chipVal.length; k++) {
      if (this.state.count === k) {
        totalInsuranceBtAt = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
      }
    }
    
    if(this.state.totalbet + totalInsuranceBtAt > this.props.balance){
      this.setState({
        betshigh_balnce:true,
        lowBalaceErrorInfo:`insufficient Funds to place the Insurance of ${totalInsuranceBtAt},Please DEPOSIT to continue playing.` 
      });
      this.lowBalanceErrorInformationPopupanimation();
    }else{
      this.setState({betshigh_balnce:null});


      let betchipstoinsurence = document.getElementById("betchipsaddinsurence");
      document.getElementById("foldtext").classList.remove("insurenceopacity");
  
      this.setState({
        insurencebtn: false,
        disablemultibutton: false,
        buttontext: "insurence",
      });
      // this.setState({disablemultibutton:false})
  
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
      
        if (document.getElementById("Foldbtn").classList.contains("insurencenodrop")) {
          // alert(".....added")
          document.getElementById("Foldbtn").classList.remove("insurencenodrop");
          document.getElementById("Foldbtn").classList.add("addpointer");
        }
        
        this.pokerchipsnd = new Audio(pokerchipsnd);
        if (this.state.mutesounds) {
          this.pokerchipsnd = new Audio(pokerchipsnd);
          this.pokerchipsnd.pause();
  
        }
        else {
          this.pokerchipsnd = new Audio(pokerchipsnd);
          this.pokerchipsnd.play();
  
        }
      
      
      
        let totalInsuranceBetAmount = 0;
        if (this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) > this.state.anteBetAmount * 3 
        // || this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
      ) {
  
          this.setState({
            infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are Fun ${this.state.anteBetAmount}.00 - Fun ${this.state.anteBetAmount * 3}.00
            Press NO INSURANCE to continue without insurance`
          })
        } else {
       for (let k = 1; k <= this.chipVal.length; k++) {
            if (this.state.count === k) {
              totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
              
            }
          
          }
          let insurencebetamount=this.topLable_rsp(totalInsuranceBetAmount)
          if(document.getElementById("betTextaddtoinsurence")){
            gsap.from("#betTextaddtoinsurence", { zIndex: 25 })
  
          }
       
          this.setState({
            insurencebtn: false,
            disablemultibutton: false,
            buttontext: "insurence",
          });
          // this.setState({disablemultibutton:false})
          switch (this.state.count) {
            case 1:
              betchipstoinsurence.src = chip_10;
            this.setState({
              totalbet: this.state.totalbet + Number(this.chipVal[0].val),
              insurencetbet: this.state.insurencetbet + Number(this.chipVal[0].val),
              betdeductions: this.state.betdeductions + Number(this.chipVal[0].val)
              })
              this.clertime38=setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
  
                      <img
                        className="chiptoinsurence_windows"
                        src={chip_10}
                        alt="Chip 10"
                      />
                      <span className="insuranceTextwndwsChip10"> {insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 500);
  
              break;
            case 2:
              betchipstoinsurence.src = chip_25;
            this.setState({
              totalbet: this.state.totalbet + Number(this.chipVal[1].val),
              insurencetbet: this.state.insurencetbet + Number(this.chipVal[1].val),
              betdeductions: this.state.betdeductions + Number(this.chipVal[1].val)
            })
              this.clertime39=setTimeout(() => {
                this.setState((prevState) => ({
                 
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
  
                      <img
                        className="chiptoinsurence_windows"
                        src={chip_25}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">  {insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 500);
  
              break;
            case 3:
              betchipstoinsurence.src = chip_50;
              
              this.clertime40=setTimeout(() => {
                this.setState((prevState) => ({
  
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
  
                      <img
                        className="chiptoinsurence_windows"
                        src={chip_50}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">{insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 500);
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[2].val),
              insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
              betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
            }));
              break;
            case 4:
              betchipstoinsurence.src = chip_100;
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[3].val),
              insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
              betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
            }));
              this.clertime41=setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
                      <img
                        className="chiptoinsurence_windows"
                        src={chip_100}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows"> {insurencebetamount}</span>
  
                    </div>
  
                  ],
                }));
              }, 500);
              break;
            case 5:
              betchipstoinsurence.src = chip_500;
            this.setState((prevState) => ({
              totalbet: prevState.totalbet + Number(this.chipVal[4].val),
              insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
              betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
            }));
              this.clertime42=setTimeout(() => {
                this.setState((prevState) => ({
                  storebetchips: [
                    ...prevState.storebetchips,
                    <div id="chiptoinsurence">
  
                      <img
                        className="chiptoinsurence_windows"
                        src={chip_500}
                        alt="Chip 10"
                      />
                      <span className="insuranceText_windows">{insurencebetamount} </span>
                    </div>
                  ],
                }));
              }, 500);
              break;
  
            default:
              break;
          }
          // betchipsinsurence.classList.add(betTextaddtoBetClass, "bet-text-addtoinsurence");
          Object.assign(betchipstoinsurence.style, porttraitejson.betchipstoinsurencewindows)
         
  
  
          var tl = gsap.timeline();
          tl.to(this.chipinsurenceref.current, {
            x: -930,
            y: -628,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 12,
          });
          tl.to(this.chipinsurenceref.current, {
            opacity: 0,
            x: 0,
            y: 0,
            duration: 0.00001,
          });
          // tl.reverse();
          tl.to(this.chipinsurenceref.current, { opacity: 1, zIndex: -2 });
          tl.play();
        }
  
      }
  
  
      else {
  
        let totalInsuranceBetAmount = 0;
        if (window.innerHeight > window.innerWidth) {
          if (this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) > this.state.anteBetAmount * 3
          // this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
        ) {
  
            this.setState({
              infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are Fun ${this.state.anteBetAmount}.00 - Fun ${this.state.anteBetAmount * 3}.00
            Press NO INSURANCE to continue without insurance`,
            // disablemultibutton:true
            })
          } else {
            this.setState({
              // disablemultibutton:false
            })
            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
              }
            }
  
            this.pokerchipsnd = new Audio(pokerchipsnd);
            if (this.state.mutesounds) {
              this.pokerchipsnd = new Audio(pokerchipsnd);
              this.pokerchipsnd.pause();
  
            }
            else {
              this.pokerchipsnd = new Audio(pokerchipsnd);
              this.pokerchipsnd.play();
  
            }
  
            Object.assign(betchipstoinsurence.style, porttraitejson.betchipstobnsPortrait)
            
            let convrInsurenceBet = this.topLable_rsp(totalInsuranceBetAmount);
  
  
            switch (this.state.count) {
              case 1:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[0].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val)
                }));
                betchipstoinsurence.src = chip_10;
                this.clertime38 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_10, chipvalue:convrInsurenceBet, chipcolor1:"insuranceTextPortraitChip10"},
                    ],
                  
                  }));
                }, 150);
  
                break;
              case 2:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[1].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val)
                }));
  
                betchipstoinsurence.src = chip_25;
                this.clertime39 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_25, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
               
                  }));
                }, 150);
  
                break;
              case 3:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
                }));
                betchipstoinsurence.src = chip_50;
                this.clertime40 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_50, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                 
                  }));
                }, 150);
                break;
              case 4:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
                }));
                betchipstoinsurence.src = chip_100;
                this.clertime41 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_100, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                   
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
                }));
                betchipstoinsurence.src = chip_500;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_500, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                  
                  }));
                }, 150);
                break;
  
              default:
                break;
            }
  
  
            var tl = gsap.timeline();
            tl.to(this.chipinsurenceref.current, {
              x: -16,
              y: -1373,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 15,
              autoAlpha:1,
              opacity: 1,
              //   onStart: function() {
              //     betchipstoinsurence.style.zIndex = 5;
              //     document.getElementById('chiptoinsurence').style.visibility = 'visible';
  
              // }
  
            });
            tl.to(this.chipinsurenceref.current, {
              opacity: 0,
              x: 0,
              y: 0,
              duration: 0.00001,
            });
  
            tl.to(this.chipinsurenceref.current, {opacity: 0, zIndex: -2 });
            tl.play();
  
          }
  
        }else{
  
          if (this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) > this.state.anteBetAmount * 3
          // || this.state.insurencetbet + Number(this.chipVal[this.state.count - 1].val) < this.state.anteBetAmount 
        ) {
  
            this.setState({
              infoText: `You have a ${this.state.playerhand_status}! Bet limits for INSURANCE are Fun ${this.state.anteBetAmount}.00 - Fun ${this.state.anteBetAmount * 3}.00
            Press NO INSURANCE to continue without insurance`
            })
          } else {
            for (let k = 1; k <= this.chipVal.length; k++) {
              if (this.state.count === k) {
                totalInsuranceBetAmount = this.state.insurencetbet + Number(this.chipVal[k - 1].val);
              }
            }
  
            this.pokerchipsnd = new Audio(pokerchipsnd);
            if (this.state.mutesounds) {
              this.pokerchipsnd = new Audio(pokerchipsnd);
              this.pokerchipsnd.pause();
  
            }
            else {
              this.pokerchipsnd = new Audio(pokerchipsnd);
              this.pokerchipsnd.play();
  
            }
  
            Object.assign(betchipstoinsurence.style, porttraitejson.betchipstobnsLndsp);
  
            var tl = gsap.timeline();
            tl.to(this.chipinsurenceref.current, {
              x: -1050,
              y: -175,
              duration: 0.15,
              ease: "power2.inOut",
              zIndex: 15,
              autoAlpha:1
            });
            tl.to(this.chipinsurenceref.current, {
             autoAlpha:0,
              x: 0,
              y: 0,
              duration: 0.00001,
            });
  
            tl.play();
          
  
            let convrInsurenceBet = this.topLable_rsp(totalInsuranceBetAmount);
            switch (this.state.count) {
              case 1:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[0].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[0].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[0].val)
                }));
                betchipstoinsurence.src = chip_10;
                this.clertime38 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_10, chipvalue:convrInsurenceBet, chipcolor1:"insuranceTextPortraitChip10"},
                    ],
                    
                  }));
                }, 150);
  
                break;
              case 2:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[1].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[1].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[1].val)
                }));
  
                betchipstoinsurence.src = chip_25;
                this.clertime39 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_25, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                  
                  }));
                }, 150);
  
                break;
              case 3:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[2].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[2].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[2].val)
                }));
                betchipstoinsurence.src = chip_50;
                this.clertime40 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_50, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                  
                  }));
                }, 150);
                break;
              case 4:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[3].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[3].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[3].val)
                }));
                betchipstoinsurence.src = chip_100;
                this.clertime41 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_100, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                  
                  }));
                }, 150);
                break;
              case 5:
                this.setState((prevState) => ({
                  insurencetbet: prevState.insurencetbet + Number(this.chipVal[4].val),
                  totalbet: prevState.totalbet + Number(this.chipVal[4].val),
                  betdeductions: prevState.betdeductions + Number(this.chipVal[4].val)
                }));
                betchipstoinsurence.src = chip_500;
                this.clertime42 = setTimeout(() => {
                  this.setState((prevState) => ({
                    storeinsurancechips:[
                      ...prevState.storeinsurancechips, {chip:chip_500, chipvalue:convrInsurenceBet, chipcolor1:null},
                    ],
                   
                  }));
                }, 150);
                break;
  
              default:
                break;
            }
  
  
            
  
          }
        }
  
      }
    
    
    }




   
 

  }




  claiminsurenceresp(res) {
  
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {


      this.clertime43 = setTimeout(() => {
        document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
        this.setState({
          infoText: res.message, pokerrankingdeler: res.dealerHand,
          // totalwinRussian: res.totalWinAmount
        })



      }, 2000)


      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");

      this.checkpayerordealerwins(res);

      this.revealDealercards(res);

    } else {
      this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand, })

      // console.log("..........congratss", this.state.totalwinRussian);
      const rankinginfodelerElement = document.getElementById("rankinginfodeler");
      rankinginfodelerElement.style.cssText = "";
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      document.getElementById("insurencetext").style.color = "";

      if (window.innerHeight > window.innerWidth) {
        rankinginfodelerElement.classList.add('pokerrankingdelerPortrait');
        switch (res.winner) {
          case "Dealer":

            this.ifDealerWinsMobilePortraitAction(res);
            break;

          // case `CONGRATULATIONS! You won FUN ${this.state.totalwinRussian}`: 
          case "Player":
            this.ifPlayerWinsMobilePortraitAction(res);
            break;
            case "Dealer_no_hand":
          // case "Dealer doesn't have a hand. Would you like to replace the dealer's higher card for the cost equivalent of ANTE? Press either NO or YES.":
            this.setState({ disablemultibutton: false });

            document.getElementById("betactive").src = "";
            document.getElementById("bettext").style.color = "";

            this.setState({ betbutton: "YES", buttontext: "NO" });
            betbutton.src = Betbtn;
            betbtntextElement.textContent = this.props.language.Yes;
            Object.assign(betbutton.style, porttraitejson.Betbtnportraite)


            foldbutton.src = Foldbn;
            foldtextElement.textContent = this.props.language.No;
            Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);

            betbtntextElement.classList.remove('noinsurencePortrait','betportraite');
            foldtextElement.classList.remove('insurencePortrait','foldtextportraite');

            betbtntextElement.classList.add("betportrait");
            foldtextElement.classList.add("foldtextportrait");
          default:
            break;
        }

      }else{
        rankinginfodelerElement.classList.add('pokerrankingdelerLndSpe');
        this.setState({ infoText: res.message, pokerrankingdeler: res.dealerHand })

        // this.clertime7 = setTimeout(() => {
          this.checkPlayerOrDealerWinMblLndspe(res);
        // }, 5000)
      }
    }
    this.revealDealercards(res);
  }

  Showclaiminsurenceresp(res) {

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {


      // this.clertime43=setTimeout(() => {
      // document.getElementById("rankinginfo").classList.add("windowspokerranking");
      // document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      // this.setState({
      //   infoText: res.message, pokerrankingdeler: res.dealerHand, pokerranking: res.playerHand,
      //   totalwinRussian: res.totalWinAmount
      // })


      // }, 2000)


      // const betbutton = document.getElementById("betbtn");
      // const foldbutton = document.getElementById("Foldbtn");

      this.showcheckpayerordealerwins(res);

      // this.revealDealercards(res);

    } 
    

  }

  cnfrmdelercardchipanim(){
    if (this.state.mutesounds) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.pause();

    }
    else {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.play();

    }
    //alert("........drawcards")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      let betexttodraw = document.getElementById("betTextaddtodraw");
      // gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtconfrm");

      let betTextaddtoBetClass = "bet-text-addtodraw20";
      this.setState((prevState) => ({
        totalbet: prevState.totalbet + prevState.anteBetAmount

      }));
      betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount);
      switch (this.state.count) {
        case 1:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + prevState.anteBetAmount

          // }));
          betchipsdraw.src = chip_10;
          // betexttodraw.textContent = this.state.anteBetAmount
          betTextaddtoBetClass = "bet-text-addtodraw10";

          break;
        case 2:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[1].val)
          // }));
          betchipsdraw.src = chip_25;
          // betexttodraw.textContent = this.chipVal[1].label;
          break;
        case 3:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[2].val)
          // }));
          betchipsdraw.src = chip_50;
          // betexttodraw.textContent = this.chipVal[2].label;
          break;
        case 4:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[3].val)
          // }));
          betchipsdraw.src = chip_100;
          // betexttodraw.textContent = this.chipVal[3].label;
          break;
        case 5:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[4].val)
          // }));
          betchipsdraw.src = chip_500;
          // betexttodraw.textContent = this.chipVal[4].label;
          break;

        default:
          break;
      }
       this.clertime44=setTimeout(() => {
        betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

       }, 100)
       Object.assign(betchipsdraw.style,porttraitejson.betchipsdrawwindows)
      

      let t1 = gsap.timeline();
      t1.to("#betchipsaddtconfrm", {
        x: -1084,
        y: -598, duration: 0.1, opacity: 1,

      });

      t1.play();


    }

  }
  drawbuybets(drawbwts) {


    if (this.state.mutesounds) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.pause();

    }
    else {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      this.pokerchipsnd.play();

    }
    //alert("........drawcards")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
     this.setState({infoText:""})
      let betexttodraw = document.getElementById("betTextaddtodraw");
      gsap.to("#betTextaddtodraw", { opacity: 2, zIndex: 15 })
      let betchipsdraw = document.getElementById("betchipsaddtodraw");


      Object.assign(betchipsdraw.style, porttraitejson.betchipsdrawwindows)

      let betTextaddtoBetClass = "bet-text-addtodraw20";
      // this.setState((prevState) => ({
      //   totalbet: prevState.totalbet + prevState.anteBetAmount

      // }));
      if (drawbwts > 0) {

        betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount * drawbwts)
      }
      else {
        betexttodraw.textContent = this.topLable_rsp(this.state.anteBetAmount)


      }
      switch (this.state.count) {
        case 1:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + prevState.anteBetAmount

          // }));
          betchipsdraw.src = chip_10;
          // betexttodraw.textContent = this.state.anteBetAmount
          betTextaddtoBetClass = "bet-text-addtodraw10";

          break;
        case 2:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[1].val)
          // }));
          betchipsdraw.src = chip_25;
          betexttodraw.textContent = this.chipVal[1].label;
          break;
        case 3:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[2].val)
          // }));
          betchipsdraw.src = chip_50;
          // betexttodraw.textContent = this.chipVal[2].label;
          break;
        case 4:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[3].val)
          // }));
          betchipsdraw.src = chip_100;
          // betexttodraw.textContent = this.chipVal[3].label;
          break;
        case 5:
          // this.setState((prevState) => ({
          //   totalbet: prevState.totalbet + Number(this.chipVal[4].val)
          // }));
          betchipsdraw.src = chip_500;
          // betexttodraw.textContent = this.chipVal[4].label;
          break;

        default:
          break;
      }
      this.clertime45= setTimeout(() => {
        betexttodraw.classList.add(betTextaddtoBetClass, "bet-text-addtodraw");

      }, 100)
      let t1 = gsap.timeline();
      // t1.to("#betchipsaddtodraw,betchipsaddtconfrm", {
        t1.to("#betchipsaddtodraw", {
        x: -1084,
        y: -598, duration: 0.1, opacity: 1,

      });

      t1.play();


    }
  }






  Confirmbuydealercard(txt) {
    this.wanttobuydelercrd = txt;

    if (txt === true) {
      this.pokerchipsnd = new Audio(pokerchipsnd);
      if (this.state.mutesounds) {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.pause();

      }
      else {
        this.pokerchipsnd = new Audio(pokerchipsnd);
        this.pokerchipsnd.play();

      }
    } else {
      this.unselectCard = new Audio(unselectCard);
      if (this.state.mutesounds) {
        this.unselectCard.pause();
      } else {
        this.unselectCard.play();
      }
    }



    const body = {
      sessionId: sessionStorage.getItem("sessionId"),
      CN: "RUSSIAN_POKER_BET",
      object: {
        action: "CONFIRM_DEALER_CARD",
        wantToBuyDealerACard: txt,
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    console.log(body);
    this.props.network.sendof(body);

    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    betbutton.src = "";
    document.getElementById("betbtntext").classList.remove("betwindows");
    document.getElementById("betbtntext").textContent ="";
    betbutton.style.position = "";
    betbutton.style.width = "";
    betbutton.style.transform = "";

    foldbutton.src = "";
    document.getElementById("foldtext").classList.remove("foldtextwindows");
    document.getElementById("foldtext").textContent ="";
    foldbutton.style.position = "";
    foldbutton.style.width = "";
    foldbutton.style.transform = "";

 

    const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
    const yesbuttoElement = document.getElementById("yes");
    const nobuttonElement = document.getElementById("no")

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
  
      if (txt === true) {

      this.setState((prevState) => ({
        betdeductions: prevState.betdeductions + prevState.anteBetAmount,
        totalbet: prevState.totalbet + prevState.anteBetAmount,
        infoText:""
      }));


      gsap.to("#betTextaddtodraw", { opacity:4 ,zIndex:15})
      // let confirmtobuydealercard
      // if (txt == "YES") {
      // confirmtobuydealercard = true
      gsap.to(`#betchipsaddtodraw,betchipsaddtconfrm`, {
        x: -1084,
        y: -598,
        duration: 0.3,
        zIndex: 12,
      });
      this.cnfrmdelercardchipanim();

      // }
      // else {
      //   confirmtobuydealercard = false

      // }
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("confirmbuydealer_popup").style.display = "none";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")
    }
    } else {


      let betchipsdraw = document.getElementById("dealerChipsAddToBuy");
      let dealerBuyTextaddtoElement = document.getElementById("dealerChipsAddToBuyText");
      let dealerBuyText = "bet-text-addtoBet20";

      switch (this.state.count) {
        case 1:
          betchipsdraw.src = chip_10;
          // dealerBuyTextaddtoElement.textContent = 10;
          dealerBuyText = "bet-text-addtoBet10";
          break;
        case 2:
          betchipsdraw.src = chip_25;
          // dealerBuyTextaddtoElement.textContent = 20;
          break;
        case 3:
          betchipsdraw.src = chip_50;
          // dealerBuyTextaddtoElement.textContent = 50;
          break;
        case 4:
          betchipsdraw.src = chip_100;
          // dealerBuyTextaddtoElement.textContent = 100;
          break;
        case 5:
          betchipsdraw.src = chip_500;
          // dealerBuyTextaddtoElement.textContent = 500;
          break;

        default:
          dealerBuyText = "bet-text-addtoBet20";
          break;
      }



      confirmbuydealerPopupElement.style.display = 'none';
      yesbuttoElement.classList.remove("yesbutton_popup_portrait");
      nobuttonElement.classList.remove("nobutton_popup_portrait");
      const confirmDelearcard =  this.topLable_rsp(this.state.anteBetAmount);
      if (window.innerHeight > window.innerWidth) {
        confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
        if (txt === true) {
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          dealerBuyTextaddtoElement.classList.add("dealerBuyCardtext", dealerBuyText);
          dealerBuyTextaddtoElement.textContent = confirmDelearcard;
          this.setState((prevState) => ({
            betdeductions: prevState.betdeductions + prevState.anteBetAmount,
            totalbet: prevState.totalbet + prevState.anteBetAmount,
            infoText:""
          }));


          gsap.to(this.buyDealerCardDrawn.current, {
            // x: -190,
            x: -230,
            y: -1200,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 13,
            opacity:1
          });

          gsap.to(dealerBuyTextaddtoElement, { zIndex: 14, width: "100%", opacity: 1, visibility: true })

        }


      }else{
        confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
        if (txt === true) {
          document.getElementById("playerDealerCardContainer").style.zIndex = 20;
          dealerBuyTextaddtoElement.classList.add("dealerBuyCardtextLndsp", dealerBuyText);
          dealerBuyTextaddtoElement.textContent = confirmDelearcard;
          this.setState((prevState) => ({
            betdeductions: prevState.betdeductions + prevState.anteBetAmount,
            totalbet: prevState.totalbet + prevState.anteBetAmount,
            infoText:""
          }));


          gsap.to(this.buyDealerCardDrawn.current, {
            // x: -190,
            x: -1230,
            y: 0,
            duration: 0.3,
            ease: "power2.inOut",
            zIndex: 13,
            autoAlpha:1
          });

          gsap.to(dealerBuyTextaddtoElement, { zIndex: 14, width: "100%", opacity: 1, visibility: true })

        }



        
      }
    }

  }
  buyconfirmdealercardresp(res) {
    this.setState({ multibuttonsebledsble: false, disablemultibutton: false, infoText: res.message })
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.setState({ betbutton: "REBET", buttontext: "REBETX2",disBetBtn:0,betBtnValue:2,keyboardhandle:true })
    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";
    betbutton.src = "";
    foldbutton.src = "";

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {


      this.setState({ infoText: res.message })
      this.setState({
        multibuttonsebledsble: false, disablemultibutton: false,
        infoText: res.message, pokerrankingdeler: res.dealerHand
      })


      document.getElementById("betbtn").classList.remove("drwnodrop")
      document.getElementById("Foldbtn").classList.remove("drwnodrop")
      document.getElementById("foldtext").textContent = "";
      document.getElementById("betbtntext").textContent = "";
      this.checkpayerordealerwins(res);

    }

    else {
    this.setState({totalwinRussian: res.totalWinAmount})

      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext")

      if (betbtntextElement) {
        betbtntextElement.classList.remove("betportrait");
        betbtntextElement.className = ""
        betbtntextElement.textContent = "";
      }

      
      if (foldtextElement) {
        foldtextElement.classList.remove("foldtextportrait");
        foldtextElement.className = "";
        foldtextElement.textContent = "";
      }

        betbutton.src ="" ;
        betbutton.style.position = "";
        betbutton.style.width = "";
        betbutton.style.transform = "";


        foldbutton.src = "";
        foldbutton.style.position = "";
        foldbutton.style.width = "";
        foldbutton.style.transform = "";

        document.getElementById("yes").classList.remove("yesbutton_popup_portrait");
        document.getElementById("yes").className = "";
        document.getElementById("no").classList.remove("nobutton_popup_portrait");
        document.getElementById("no").className = "";
        this.setState({ infoText: res.message })


      if (window.innerHeight > window.innerWidth) {   
        // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
        switch (res.winner) {
          case "Dealer":
            this.ifDealerWinsMobilePortraitAction(res);
            break;

          // case `CONGRATULATIONS! You won FUN ${res.totalWinAmount}`:
          case "Player":
            this.ifPlayerWinsMobilePortraitAction(res);
            break;
          default:
            break;

        }

      }else{

        this.checkPlayerOrDealerWinMblLndspe(res)
      }
    }




    this.dealerUnsequenceDrawCards = [...res.dealerCards];
    this.dealerSequenceDrawFinalCards = [...res.dealerFinalCards];
    let cnt = this.dealerUnsequenceDrawCards.length;

 
    let mutesounds = this.state.mutesounds;

    function playSound(i) {
      let audio = new Audio(cardsdropping_snd);
      audio.currentTime = 0;
      if (mutesounds) {
        audio.pause();
      } else {
        audio.play();
      }


      audio.addEventListener('ended', function () {
        if (i  < 1) {
          this.clertime51 =  setTimeout(() => {
            playSound(i+1);
          // }, 200)
          },250)
        }
      });
    }
   

    for (let i = 0; i < cnt; i++) {
      var b1 = this.dealerUnsequenceDrawCards[i];
      console.log(this.dealerShuffledCards, b1)

      switch (i) {
        case 0:
          if (this.dealerShuffledCards[0] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardZero", b1, cnt);
          }
          break;
        case 1:
          if (this.dealerShuffledCards[1] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardOne", b1, cnt);
          }
          break;
        case 2:
          if (this.dealerShuffledCards[2] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardTwo", b1, cnt);
          }
          break;
        case 3:
          
          if (this.dealerShuffledCards[3] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardThree", b1, cnt);
          }
          break;
        case 4:
          if (this.dealerShuffledCards[4] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardFour", b1, cnt);
          }
          break;
        case 5:
          if (this.dealerShuffledCards[5] != b1) {
            playSound(0)
            this.dealerCardsContainer.current.dealerDrawcard("cardFive", b1, cnt);
          }
          break;
        default:
          break;
      }


      setTimeout(() => {
        document.getElementById("playerDealerCardContainer").style.zIndex = 1;
        // this.suffleDealerCards(this.dealerSequenceDrawFinalCards);
        // this.shuffledPlayerCards = [...this.dealerSequenceDrawFinalCards];
      }, 200 * cnt)

    }
  }

  Showbuyconfirmdealercardresp(res) {
   
    const betbutton = document.getElementById("betbtn");
    const foldbutton = document.getElementById("Foldbtn");
    this.setState({ betbutton: "REBET", buttontext: "REBETX2",disBetBtn:0,betBtnValue:2,keyboardhandle:true })
    document.getElementById("drawtext").textContent = "";
    document.getElementById("buytext").textContent = "";
    betbutton.src = "";
    foldbutton.src = "";

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {
      this.setState({ multibuttonsebledsble: false, disablemultibutton: false, infoText: res.message, totalwinRussian: res.totalWinAmount })
      this.setState({
        previousAnteBetAmount: res.anteBetAmount,
        anteBetAmount: res.anteBetAmount,

      })
      document.getElementById("rankinginfodeler").classList.add("windowspokerrankingdeler");
      document.getElementById("rankinginfo").classList.add("windowspokerranking");
        this.setState({pokerrankingdeler: res.dealerHand,pokerranking:res.playerHand })
      // betbutton.src = "";
      // document.getElementById("betbtntext").classList.remove("betwindows");
      // betbutton.style.position = "";
      // betbutton.style.width = "";
      // betbutton.style.transform = "";

      // foldbutton.src = "";
      // document.getElementById("foldtext").classList.remove("foldtextwindows");
      // foldbutton.style.position = "";
      // foldbutton.style.width = "";
      // foldbutton.style.transform = "";
      // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")
      this.setState({ infoText: res.message })
      this.setState({
        multibuttonsebledsble: false, disablemultibutton: false,
        infoText: res.message, totalwinRussian: res.totalWinAmount, pokerrankingdeler: res.dealerHand
      })


      document.getElementById("betbtn").classList.remove("drwnodrop")
      document.getElementById("Foldbtn").classList.remove("drwnodrop")
      document.getElementById("foldtext").textContent = "";
      document.getElementById("betbtntext").textContent = "";
      this.showcheckpayerordealerwins(res);

    }

    else {
    this.setState({ multibuttonsebledsble: false, disablemultibutton: false,})

    const betbtntextElement = document.getElementById("betbtntext");
    const foldtextElement = document.getElementById("foldtext")

    if (betbtntextElement) {
      betbtntextElement.classList.remove("betportrait");
      betbtntextElement.textContent = "";
    }
    if (foldtextElement) {
      foldtextElement.classList.remove("foldtextportrait");
      foldtextElement.textContent = "";
    }

    betbutton.style.position = "";
    betbutton.style.width = "";
    betbutton.style.transform = "";

    foldbutton.src = "";

    foldbutton.style.position = "";
    foldbutton.style.width = "";
    foldbutton.style.transform = "";
      // document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").className="";
      document.getElementById("no").className ="";
      this.setState({ infoText: res.message })
      if (window.innerHeight > window.innerWidth) {
        switch (res.winner) {
          case "Dealer":
          case "Player":
            this.showPlayerorDealerorFold(res)
            break;
          default:
            break;

        }

      }else{
        this.prevStatecheckPlayerOrDealerWinMblLndspe(res)
      }
    }

  }



  ifPlayerWinsMobilePortraitAction = (res) => {

    const winpopuprspelement = document.getElementById("winpopup_rsp");   
    const winamtElement = document.getElementById("winamt_rsp");

    this.clertime52 = setTimeout(() =>{
      this.updateplayerbalnace();
      this.clertime53 = setTimeout(() => {
        this.clearActivesWeDid();
      }, 1000);
  
      console.log(this.state.anteBetAmount, this.state.bonusbetamount);
  
      this.setState((prevState) => ({
        isbonuspattable: false,
        previousAnteBetAmount: res.anteBetAmount,
        previousBonusbetamount: res.bonusBetAmount,
        totalbet: 0,
        insurencetbet: 0,
        anteBetAmount: 0,
        bonusbetamount: 0,
        prevStatestoreantebetchips:[...prevState.storeantebetchips],
        prevStatestorebonuschips:[...prevState.storebonuschips],
        storebonuschips: [],
        storeinsurancechips:[],
      }));
  
  
      const delaerChipAnimDiv = document.getElementById('delaerchipanim')
      const dealerchipElement = document.getElementById('dealerchip');
     
      
      
      
      delaerChipAnimDiv.className = "";
      dealerchipElement.src = "";
      dealerchipElement.className = "";
  
      this.resetAnimationChips = true;
      document.getElementById("mobileAntiMaskCover").style.display = "none";
      const betbutton = document.getElementById("betbtn");
      const foldbutton = document.getElementById("Foldbtn");
      const betbtntextElement = document.getElementById("betbtntext");
      const foldtextElement = document.getElementById("foldtext");
      


      winpopuprspelement.classList.add("winPopupForMptSw");
      winamtElement.classList.add("winamtMpSw");

      gsap.fromTo(winpopuprspelement,
        {autoAlpha: 0,scale:0,duration:0.5,},
        {autoAlpha: 1,scale:1,duration:0.5,rotate:0}
    )
  
      document.getElementById("betactive").src = "";
      document.getElementById("bettext").style.color = "";
      document.getElementById("betactive").classList.remove("betactive");
     
      // let t1 = gsap.timeline()
      gsap.to("#chipcenterposition", { x: -440, y: 925, duration: 0.5 });
      gsap.to("#betchipsaddtobet", { x: -455, y: -200, duration: 0.5, delay: 1 });
      gsap.to("#betTextaddtoBet", { x: -430, y: -990, duration: 0.5, delay: 1 });
      // gsap.to(this.chipdrawref.current, { x: 102, y: -130, duration: 0.5, delay: 1 });
      let t4 = gsap.timeline();
      t4.to(this.chipdrawref.current, {
        // x: 360, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
        x: 320, y: -2060, duration: 0.2, zIndex: 12, ease: "power2.inOut", opacity: 1,
        onComplete: () => {
          gsap.set(this.chipdrawref.current, { opacity: 0 });
        }
      });
      t4.to(this.chipdrawref.current, {
        x: 548, y: -36, duration: 0.001, zIndex: -2, opacity: 0,
        onComplete: () => {
          gsap.set(this.chipdrawref.current, { opacity: 1, delay: 2 });
        }
      });
  
      t4.play();
  
      if (this.insuranceActive) {
        gsap.to("#chiptoinsurence", { x: -430, y: 1130, duration: 0.5, delay: 1 });
      };
  
      // if(this.wanttobuydelercrd === true){
      //    gsap.to(this.buyDealerCardDrawn.current, { x: -450, y: -280, duration: 0.5, delay: 1 });
      // }
  
      if (this.wanttobuydelercrd === true) {
        let t1 = gsap.timeline()
        t1.to(this.buyDealerCardDrawn.current, { x: -190, y: -2200, duration: 0.5 });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, });
        t1.to(this.buyDealerCardDrawn.current, { opacity: 0, x: 0, y: 0, zIndex: -5 });
        t1.play();
      }
  
      this.setState({ betbutton: "REBET", buttontext: "REBETX2", disablemultibutton: false, bsbtn: true, betchipincrementbtn: false, betdecreamentbtn: false })
  
  
      betbutton.src = Betbtn;
      // Object.assign(betbutton.style, porttraitejson.Betbtnportraite);
      Object.assign(betbutton.style, this.state.betbuttoncss);
  
      betbtntextElement.classList.remove('noinsurencePortrait', 'betportraite');
      console.log('betbtntextElement', betbtntextElement)
      betbtntextElement.textContent = this.props.language.Rebet;
  
  
      // betbtntextElement.classList.add("betportrait");
      betbtntextElement.classList.add(`${this.state.betbtntextcss}`);
      console.log(porttraitejson.betportraite)
      // Object.assign(betbtntextElement.style, porttraitejson.betportraite);
  
      foldbutton.src = Foldbnwindows;
      // Object.assign(foldbutton.style, porttraitejson.foldbtnportraite);
      Object.assign(foldbutton.style, this.state.foldbuttoncss);
  
      foldtextElement.classList.remove('insurencePortrait', 'foldtextportraite', 'foldtextportrait');
      foldtextElement.textContent = this.props.language.RebetX2;
      // foldtextElement.classList.add("rebettextportrait");
      foldtextElement.classList.add(`${this.state.foldbtntextcss}`);
  
  
  
  
      switch (this.state.count) {
        case 1:
          dealerchipElement.src = chip_10;
          break;
        case 2:
          dealerchipElement.src = chip_25;
          break;
        case 3:
          dealerchipElement.src = chip_50;
          break;
        case 4:
          dealerchipElement.src = chip_100;
          break;
        case 5:
          dealerchipElement.src = chip_500;
          break;
        default:
          break;
      }
  
  
      Object.assign(dealerchipElement.style, porttraitejson.dealerchipstoplayerPortrait)
  
      let t1 = gsap.timeline()
      t1.to("#dealerchip", {
        x: -45,
        y: 2000,
        duration: 0.2,
        ease: "power2.inOut",
        onStart: () => {
          gsap.set('#dealerchip', { opacity: 1, zIndex: 2 })
        },
        onComplete: () => {
          gsap.set('#dealerchip', { opacity: 0, zIndex: -10 })
        },
  
  
      });


      t1.to("#dealerchip", {
        x: -45,
        y: 20,
        duration: 0.002,
        ease: "power2.inOut",
        onStart: () => {
          gsap.set('#dealerchip', { opacity: 0, zIndex: -10 })
        },
      })
      
      if(this.state.mutesounds){
        this.playerWin.pause();
      }else{
        this.playerWin.play();
      }

    },2000)

    this.resetPyrWnCpsAmn = true;
    this.clertime54 = setTimeout(() =>{
      
      gsap.fromTo(winpopuprspelement,{scale:1,duration:0.5,rotate:360},{autoAlpha:0,scale:0,duration:0.5})
      winpopuprspelement.classList.remove("winPopupForMptSw");
      winamtElement.classList.remove("winamtMpSw");
      this.setState({
        totalwinRussian: 0,
        infoText: this.props.language.Play_Njoy
      });
      const hands = [
        "onepair", "Threekind", "aceking", "fullhse", 
        "Straightflush", "frofkind", "twopair", 
        "flush", "stright", "royalflush"
      ];
      hands.forEach(hand => {
        document.getElementById(hand).style.color = "";
      });
    

      if (this.resetAnimationChips) {

        if (document.getElementById("chipcenterposition")) {
          gsap.to("#chipcenterposition", { x: 13, y: 1200, duration: 0.00001, zIndex: -2, opacity: 0 });
        }
  
        if (document.getElementById("betchipsaddtobet")) {
          gsap.to("#betchipsaddtobet", { x: 1, y: 76, duration: 0.00001, zIndex: -2, opacity: 0 });
          gsap.to("#betTextaddtoBet", { x: 153, y: 185, duration: 0.00001, zIndex: -2, opacity: 0 });
        }
  
        // gsap.to("#chiptoinsurence" , {x:550, y: 145, duration:0.5,zIndex:-2,opacity:0});
        if (document.getElementById("chiptoinsurence")) {
          gsap.to("#chiptoinsurence", { x: 15, y: 1376, duration: 0.00001, zIndex: -2, opacity: 0 });
        };
  
        // gsap.to(this.chipdrawref.current, { x: 548, y: 140, duration: 0.5, delay: 1, zIndex: -5, opacity: 1 });
        gsap.to(this.chipdrawref.current, { x: 548, y: -36, duration:0.00001, zIndex: -5, opacity: 0 });
        // if (this.playerWinBonus === true) {
  
          // if (document.getElementById("chip10centerbns")) {
          //   gsap.to("#chip10centerbns", { x: -170, y: 1200, duration: 0.00001, zIndex: -2, opacity: 1 });
          //   this.playerWinBonus = false;
  
          // }
  
  
        // }
        if (this.wanttobuydelercrd === true) {
          gsap.to(this.buyDealerCardDrawn.current, { x: 0, y: 0, duration: 0.00001, zIndex: -2, opacity: 0 });
        }
      }





    },4000)


   


  }



  muteunmutesounds() {
   
    if (!this.state.mutesounds) {
     
      this.setState({ mutesounds: true })
      document.getElementById('volumeRussian').src = mutesnds
      document.getElementById('volumeRussian').classList.add("addpointer")
    }
    else {
      
      this.setState({ mutesounds: false })
      document.getElementById('volumeRussian').src = volumeimg
      document.getElementById('volumeRussian').classList.add("addpointer")

    }
  }


  Dealerbetstoplayer=()=>{
    let dealerchip = document.getElementById("dealerchip")
  
    switch (this.state.count) {

      case 1:
        dealerchip.src = chip_10;

        break;
      case 2:
        dealerchip.src = chip_25;

        break;
      case 3:
        dealerchip.src = chip_50;

        break;
      case 4:
        dealerchip.src = chip_100;

        break;
      case 5:
        dealerchip.src = chip_500;

        break;

      default:
        break;
    }
    Object.assign(dealerchip.style,porttraitejson.dealerchipstoplayer)
    let tl=gsap.timeline()
    tl.to(dealerchip, {
      x: -21,
      y: 952,
      duration: 0.5,
      opacity:1,
      ease:"power2.inOut",
      onComplete:()=>{
        gsap.set(dealerchip,{opacity:0})

      }
    });
    tl.to(dealerchip, {
      x: 31,
      y: 0,
      duration: 0.5,
      ease:"power2.inOut",
    
    });
    // tl.play();
   
  }


  hidepopup_betshigh = () => {

    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
          }

        }
        );

      }
    } else {
      this.setState({betshigh_balnce:false})
      const popupbetGreaterthanBalnceElement = document.getElementById("popupbet_greaterthanbalnce");
      if (document.getElementById("popupbet_greaterthanbalnce")) {
        gsap.to(popupbetGreaterthanBalnceElement, {
          scale: 0,
          duration: 1,
          rotate: 0,
          opacity: 0,
          ease: "power2.out",
          onComplete: () => {
            popupbetGreaterthanBalnceElement.className = "";
            popupbetGreaterthanBalnceElement.classList.add("popupbetshighMobileIntial");
          }

        }
        );

      }


    }






  }

  updatePlayerInfo() {
   
    this.setState({
      betdeductions: 0
    });


  //  const rankinginfoElement = document.getElementById("rankinginfo");
  //  if (rankinginfoElement) {
   //   if (this.state.isMobilePortraitLndsp) {
  //      rankinginfoElement.style.left = "34%";
  //    }
  //  }

 }


  showAnteBetchips = () => {

    return (
      this.state.storeantebetchips.map((eachchip, index) => (
        <div key={index} id="chipcenterposition" className={`${this.state.antebetpdvcss}`}>
          <img
            className={`${this.state.antechpimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          <span className={`${eachchip.chipcolor1} ${this.state.antespntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )
  }

  showBonusBetchips = () => {
      

    console.log(this.state.storebonuschips)
    return (

      this.state.storebonuschips.map((eachchip, index) => (
        <div key={index} id="chip10centerbns" className={`${this.state.bonusdivcss}`}>
          <img
            className={`${this.state.bonusimgcss}`}
            src={eachchip.chip}
            alt="Chip"
          />
          {/* id="val_10" */}
          <span  className={`${eachchip.chipcolor1} ${this.state.bonuspntxtcss}`} >{eachchip.chipvalue}</span>
        </div>
      ))
    )

  }

  showInsuranceBetChips = () =>{

  return(
    this.state.storeinsurancechips.map((eachchip, index)=>(
      <div key={index} id="chiptoinsurence" className={`${this.state.insurancedivcss}`}>
      <img
        className={`${this.state.insurancechipcss}`}
        src={eachchip.chip}
        alt="Chip"
      />
      {/* id="val_10" */}
      <span  className={`${eachchip.chipcolor1} ${this.state.insurancetxtcss}`} >{eachchip.chipvalue}</span>
    </div>
    ))
  )



  }

  ShowHistory() {



    this.setState({ShowHistory:true,ishistorytable:true},()=>{
     

   
    if (
      navigator.userAgentData &&
      navigator.userAgentData.platform &&
      navigator.userAgentData.platform == "Windows"
    ) {

      if (document.getElementById("histry_russian")) {
        document.getElementById("histry_russian").classList.remove("histry_russianportraite")

      }
      if (document.getElementById("exithistory")) {
        document.getElementById("exithistory").classList.remove("exihstrybuttonportraite")

      }
      if (document.getElementById("prevhistory")) {
        document.getElementById("prevhistory").classList.remove("showprevhsrytbleportraite")

      }
      if (document.getElementById("histry_body")) {
        document.getElementById("histry_body").classList.remove("histry_bodyportraite")
      }
      if (document.getElementById("heading_hstiry")) {
        document.getElementById("heading_hstiry").classList.remove("heading_hstiryportraite")

      }
      if (document.getElementById("histry_russian")) {
        document.getElementById("histry_russian").classList.remove("histry_russianlndspe")

      }
      if (document.getElementById("exithistory")) {
        document.getElementById("exithistory").classList.remove("exihstrybuttonlndspe")

      }
      if (document.getElementById("prevhistory")) {
        document.getElementById("prevhistory").classList.remove("showprevhsrytblelndspe")

      }
      if (document.getElementById("histry_body")) {
        document.getElementById("histry_body").classList.remove("histry_bodylndspe")

      }
      if (document.getElementById("heading_hstiry")) {
        document.getElementById("heading_hstiry").classList.remove("heading_hstirylndspe")

      }


      if (document.getElementById("histry_russian")) {
        document.getElementById("histry_russian").classList.add("histry_russian")

      }
      if (document.getElementById("exithistory")) {
        document.getElementById("exithistory").classList.add("exihstrybutton")

      }
      if (document.getElementById("prevhistory")) {
        document.getElementById("prevhistory").classList.add("showprevhsrytble")

      }
      if (document.getElementById("histry_body")) {
        document.getElementById("histry_body").classList.add("histry_body")

      }
      if (document.getElementById("heading_hstiry")) {
        document.getElementById("heading_hstiry").classList.add("heading_hstiry")

      }

      // document.getElementById("dealercards_hstry").classList.add("dealercards_hstry")
      // document.getElementById("dealerhand_hstry").classList.add("dealerhand_hstry")
      // document.getElementById("playercards_hstry").classList.add("playercards_hstry")
      // document.getElementById("playerhand_hstry").classList.add("playerhand_hstry")
      // document.getElementById("totalbet_histry").classList.add("totalbet_histry")
      // document.getElementById("totalwin_hstry").classList.add("totalwin_hstry")

    }
    else {

      if (window.innerHeight > window.innerWidth) {

        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.remove("histry_russianlndspe")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.remove("exihstrybuttonlndspe")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.remove("showprevhsrytblelndspe")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.remove("histry_bodylndspe")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.remove("heading_hstirylndspe")

        }
        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.remove("histry_russian")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.remove("exihstrybutton")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.remove("showprevhsrytble")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.remove("histry_body")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.add("heading_hstiry")

        }

        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.add("histry_russianportraite")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.add("exihstrybuttonportraite")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.add("showprevhsrytbleportraite")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.add("histry_bodyportraite")

          if (document.getElementById("heading_hstiry")) {
            document.getElementById("heading_hstiry").classList.add("heading_hstiryportraite")

          }

        }


      }
      else {


        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.remove("histry_russian")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.remove("exihstrybutton")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.remove("showprevhsrytble")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.remove("histry_body")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.remove("heading_hstiry")

        }

        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.remove("histry_russianportraite")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.remove("exihstrybuttonportraite")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.remove("showprevhsrytbleportraite")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.remove("histry_bodyportraite")
        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.remove("heading_hstiryportraite")

        }


        if (document.getElementById("histry_russian")) {
          document.getElementById("histry_russian").classList.add("histry_russianlndspe")

        }
        if (document.getElementById("exithistory")) {
          document.getElementById("exithistory").classList.add("exihstrybuttonlndspe")

        }
        if (document.getElementById("prevhistory")) {
          document.getElementById("prevhistory").classList.add("showprevhsrytblelndspe")

        }
        if (document.getElementById("histry_body")) {
          document.getElementById("histry_body").classList.add("histry_bodylndspe")

        }
        if (document.getElementById("heading_hstiry")) {
          document.getElementById("heading_hstiry").classList.add("heading_hstirylndspe")

        }

      }

    }

  })
   

  }



  exithistory() {
    this.setState({ ShowHistory: false })

  }
  hideshowoptionmenu() {
  
    this.setState({ isoptionmenu: false,ShowLimits:false })
  }
  // animexit(){

  //   if(document.getElementById("exithistory")){
  //     document.getElementById("exithistory").classList.add("exithistory")

  //   }

  // }
  confirmfoldresp() {

  }


  confirmFoldactin(cnfrmtext) {
     const confirmbuydealerPopupElement = document.getElementById("confirmbuydealer_popup");
    if (cnfrmtext === true) {
      let betexttodraw = document.getElementById("betchipsaddtodrawText");
      betexttodraw.classList.remove("bet-text-addtodraw","bet-text-addtoinsurence10") 
      const drawtextElement = document.getElementById("drawtext");
      const buytextElement = document.getElementById("buytext");
      const buybutton = document.getElementById("buybtn");
      const drawbutton = document.getElementById("drawbtn");
      buybutton.src ="";
      drawbutton.src = "";
      drawtextElement.textContent = "";
      buytextElement.textContent = "";
      
      document.getElementById("alertTxt").classList.remove("alertTxt");

      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("confirmbuydealer_popup").style.display = "none";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")

      confirmbuydealerPopupElement.classList = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")

      const body = {
        sessionId: sessionStorage.getItem("sessionId"),

        CN: "RUSSIAN_POKER_BET",
        object: {
          action: "CONFIRM_FOLD",
          gameId: this.props.gameId,
          playerId: Number(this.props.playerId),
        },
      };
      console.log(body);
      this.props.network.sendof(body);

    }

    else {
      this.showParamBetStatus(false)
      document.getElementById("alertTxt").classList.remove("alertTxt");

      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("confirmbuydealer_popup").classList.remove("popup_cnfrmbuydealrcard");
      document.getElementById("yes").classList.remove("yesbutton_popup")
      document.getElementById("no").classList.remove("nobutton_popup")

      confirmbuydealerPopupElement.classList = "";
      confirmbuydealerPopupElement.style.display = "none";
      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardPortrait");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")



      confirmbuydealerPopupElement.classList.remove("popup_cnfrmbuydealrcardLndsp");
      document.getElementById("alertTxt").innerHTML = "";
      document.getElementById("alertTxt").classList.remove('alertTxtPortrait');
      document.getElementById("yes").classList.remove("yesbutton_popup_portrait")
      document.getElementById("no").classList.remove("nobutton_popup_portrait")

    }

  }




  ShowLimits(){
    this.setState({ShowLimits:true},()=>{

      // exitLimits
      const exitLimitsElement = document.getElementById("exitLimits");
      exitLimitsElement.className = "";
      if (
        navigator.userAgentData &&
        navigator.userAgentData.platform &&
        navigator.userAgentData.platform == "Windows"
      ) {
        document.getElementById("Limits").classList.remove("LimitsPopup_landspe")
        document.getElementById("Limits").classList.remove("LimitsPopup_portraite")
        document.getElementById("Limits").classList.add("LimitsPopup");
        exitLimitsElement.classList.add("exihstrybuttonportraite");
      }
      else{
        if(window.innerHeight>window.innerWidth){
          document.getElementById("Limits").classList.remove("LimitsPopup")
          document.getElementById("Limits").classList.remove("LimitsPopup_landspe")
          document.getElementById("Limits").classList.add("LimitsPopup_portraite")
          exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
        else{
        
          document.getElementById("Limits").classList.remove("LimitsPopup")
          document.getElementById("Limits").classList.remove("LimitsPopup_portraite")
          document.getElementById("Limits").classList.add("LimitsPopup_landspe")
          exitLimitsElement.classList.add("exihstrybuttonportraite");

        }
      }

    })
   
  }

  exitLimits = () =>{
    this.setState({ShowLimits:false})
  }
  render() {
    this.roundNumber = this.props.balance - this.state.betdeductions
  
    console.log(this.roundNumber)
    this.betAmount = Math.floor(this.roundNumber)
    // this.state.balance_rsp = this.topLable_rsp(this.betAmount);
    // this.state.updateBet = this.topLable_rsp(this.state.totalbet)

    // let balance_rsp = this.topLable_rsp(this.betAmount);
    // let updateBet = this.topLable_rsp(this.state.totalbet)
    let balance_rsp = Number(this.betAmount.toFixed(2));
    let updateBet = Number(this.state.totalbet.toFixed(2));
    let popup_winamount=this.topLable_rsp(this.state.totalwinRussian)
    // console.log(this.props.balance, "     ",this.state.betdeductions,"         ",this.roundNumber,balance_rsp)

    console.log(this.state.betactvglwcss)
    const {
      heightOfLayer,
      widthOfLayer,
      dealerDistanceFromX,
      playerDistanceFromX,
      dealerPlayerDistanceFromY,
      isMobilePortraitLndsp,
      dealerDistanceFromY,
      PlayerDistanceFromY,
    } = this.state;
    console.log(window.innerWidth);
    console.log(window.innerHeight);


console.log(this.state.storebetchips)
    return (
      <Fragment>

        <div id="bacaratbgim" className="RP_MainDiv">

          <img src="" id="pokertableim" alt="" />
          <img src="" id="pokertablelandscape" alt="" />
          {/* <div className="optBtn" > */}
          <img id="optionrussian" src="" onClick={(e) => { e.preventDefault(); this.Showoptionsmenu(); }} alt="" />

          {/* </div> */}

          <img src="" id="russiantitle" alt="" />
          <img src="" id="balnceicon" alt="" />
          <span id="balance">{balance_rsp}</span>
          <img src="" id="beticon" alt="" />
          <span id="bets">{updateBet}</span>
          <img src="" id="exitbtn" alt="" />
          <img src="" id="winnericon" alt="" />
          <div id="playerDetails">
            <span>P ID:{this.props.playerId}</span>
            <span>ID:{this.props.gameState.handId}</span>
          </div>
          <div id="wins">
            <span id="winamount_rsp">{this.state.totalwinRussian}</span>
          </div>

          <div id="betcontainer" className="betpannelrussian">
            {/* <div id="betcontainer"> */}
            <button disabled={this.state.antebetcircle}>
              <img src="" id="betcircle" onClick={(e) => { e.preventDefault(); this.anteclick(); }} alt="" />
            </button>

            <button disabled={this.state.insurencebtn}>
              <img src="" id="insurenceRussian" onClick={(e) => { e.preventDefault(); this.Insurenceactived(); }} alt="" />
            </button>

            <button disabled={this.state.bsbtn}>
              <img src="" id="bonusRussian" onClick={(e) => { e.preventDefault(); this.BonusActivated(); }} alt="" />
            </button>
            <img src="" id="bnsactive" alt="" className={this.state.bnsactvglwcss} />
            <img src="" id="betactive" alt=""  className={this.state.betactvglwcss}/>
            <img src="" id="insurenceactive" alt="" className={this.state.insurenceactivecss}/>

            <img src="" id="buydrawRussian" alt="" />
            <img src="" id="betRussian" alt="" />

            {/* <div id="insurencecontainer"> */}
            <span id="insurencetext">{this.props.language.Insurence}</span>
            {/* </div> */}
            <span id="bonustext">{this.props.language.Bonus}</span>
            <span id="bettext">{this.props.language.Bet}</span>
            <span id="buydrawtext">
              {this.props.language.Draw}
              <br />
              {`/${this.props.language.Buy}`}
            </span>
            <span id="antetext">{this.props.language.Ante}</span>
          </div>

          <div ref={this.buyDealerCardDrawn} id="dealerBuyCard">
            <button type="button" id="dealerChipsAddToBuyButton"> <img src="" id="dealerChipsAddToBuy" alt="" /></button>
            <span id="dealerChipsAddToBuyText"></span>
          </div>

          <div id="betpannelrussiancover" className={this.state.betpannelrussiancovercss}></div>
          <div id="betpannelinsurececover"></div>
          
          <div id="mobileAntiMaskCover" className={this.state.mobileAntiMaskCovercss}></div> 


          <div className="player-dealer-card-container" id="playerDealerCardContainer">

            <div id="container_playerdealer">
              <h1 id="Dealer">
                DEALER
              </h1>
              <h2 id="Player">
                PLAYER
              </h2>
            </div>
            {/* <Stage width={window.innerWidth} height={window.innerHeight} > */}
            {/* <Stage width={2350} height={heightOfLayer}> */}
            <Stage width={widthOfLayer} height={heightOfLayer} >
              <Layer>
                <Group x={dealerDistanceFromX} y={dealerDistanceFromY} stroke="black">
                  <DealerCardsContainer ref={this.dealerCardsContainer} x={450} y={400} xPadding={18}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    isMobilePortrait={isMobilePortraitLndsp} activeScreen={this.state.activeScreen} />
                </Group>
                <Group x={playerDistanceFromX} y={PlayerDistanceFromY} stroke="black">
                  <Cards ref={this.cardsRef} x={450} y={400} xPadding={18} previousGamestateCardLength6 = {this.props.previousGamestateCardLength6}
                    scaleX={this.state.scaleX} scaleY={this.state.scaleY}
                    ratioed={1} hidecardPos={30} numberOfCardsToBeDraw={this.numberOfCardsToBeDraw}
                    isMobilePortrait={isMobilePortraitLndsp} mutesounds={this.state.mutesounds} activeScreen={this.state.activeScreen} />
                </Group>
              </Layer>

            </Stage>
          </div>


          {(this.state.isMobilePortraitLndsp === true) && (
            <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
              {/* {this.state.storebetchips} */}
              {this.showAnteBetchips()}
              {this.showBonusBetchips()}
              {this.showInsuranceBetChips()}

            </div>

          )}


          {/* <img src="" id="dealbtn" onClick={(e) => { e.preventDefault(); this.anteBet(); }} alt="" /> */}
          {/* <span className="dealbtntext">Deal</span> */}
          <button disabled={this.state.drawbuttonhandle}>
            <img src="" id="drawbtn" onClick={(e) => { e.preventDefault(); this.drawCard(false); }} alt="" style={this.state.drawbtncss}/>
          </button>

          <img src="" id="buybtn" onClick={(e) => { e.preventDefault(); this.BuyplayerCard(); }} alt="" style={this.state.buybtncss}/>
          <button disabled={this.state.multibuttonsebledsble}>
            <img style={this.state.betbuttoncss} src="" id="betbtn" onClick={(e) => {
              e.preventDefault();
              console.log(this.state.betbutton);
              if (this.state.betbutton === "Bet") {
                this.RusssianpokerBet();
              } else if (this.state.betbutton === "DEAL") {
                this.anteBet();
              } else if (this.state.betbutton === "REBET") {
                this.anteclick(this.state.betbutton);
              } else if (
                this.state.betbutton === "YES"
              ) {
                this.buyDealerCard(this.state.betbutton);
              }
              else if (this.state.betbutton == "noinsurence") {
                this.InsurenceClaim(this.state.betbutton);
              }
            }}
              alt="" />
          </button>

          <button disabled={this.state.disablemultibutton}>
            <img style={this.state.foldbuttoncss}
              src=""
              id="Foldbtn"
              onClick={(e) => {
                e.preventDefault();
                if (this.state.buttontext == "clear") {
                  this.clearbets();
                } else if (this.state.buttontext == "fold") {
                  this.FoldCards();
                } else if (this.state.buttontext == "insurence") {
                  this.InsurenceClaim(this.state.buttontext);
                } else if (this.state.buttontext == "NO") {
                  this.buyDealerCard(this.state.buttontext);
                } else if (this.state.buttontext == "REBETX2") {
                  this.anteclick(this.state.buttontext);
                }
              }}
              alt="" />
          </button>

          <img src="" id="infrmicon" onMouseDown={(e) => {
            e.preventDefault();
            this.ShowBonuspayouttable();
            this.HideBonuspayouttable();
          }}
            // onMouseLeave={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            // onMouseOut={(e) => {
            //   e.preventDefault();
            //   this.HideBonuspayouttable();
            // }}
            alt="" />
          <img src="" id="volumeRussian" onClick={(e) => { e.preventDefault(); this.muteunmutesounds(); }} alt="" />
          <button disabled={this.state.betchipincrementbtn}>
            <img src="" id="betincrenet" onClick={(e) => { e.preventDefault(); this.IncreamentBetchips(); }} alt="" />
          </button>
          <button disabled={this.state.betdecreamentbtn}>

            <img src="" id="betdecrement"
              onClick={(e) => { e.preventDefault(); this.DecreamentBetchips(); }} alt="" />
          </button>
          <img src="" id="betchips" alt="" />
          {this.chipVal.map((obj, i) => (
            <span key={i} className={`valtext${i}`} id={`val${i}`}></span>
          ))}

          <img src="" ref={this.chipref} id={`betchipsaddtoante${this.state.idincrement}`} alt="" style={this.state.betchipstoantebetcss} />
          <img src="" id="betchipsaddtobonus" ref={this.chipbnsref} alt="" style={this.state.betchipstobonusbetcss}/>
          <img src="" id="betchipsaddtoantecclick" ref={this.chiprefee} alt="" />

          

          <div ref={this.chipdrawref} id="betchipsaddtodrawContainer" className={this.state.buydrawcontainer}>
            <img src="" id="betchipsaddtodraw" alt="" className={this.state.drawchipcss}/>
            <span id="betchipsaddtodrawText" className={this.state.drawchiptextcss}></span>
          </div>
          <div >
            <img src="" id="betchipsaddtconfrm" alt="" />
            {/* <span id="betchipsaddtodrawText"></span> */}
          </div>

          <span id="betTextaddtodraw"></span>
          
          <img src="" id="betchipsaddtobet" ref={this.chipbetref} alt=""  style={this.state.betchiptobetbtncss}/>
          <span id="betTextaddtoBet" className={this.state.betbtntxtcss}></span>
          <img src="" id="betchipsaddinsurence" ref={this.chipinsurenceref} alt="" />
          <div id="delaerchipanim">
            <img src="" id="dealerchip" alt="" />
          </div>
          <img src="" id="playerwinchip" alt="" />
          <img src="" id="userid" alt="" />
          <span id="useridtext">{this.props.user}</span>
          <img src="" id="Russianbgwindows" onClick={(e) => { e.preventDefault(); this.hidepopup_betshigh() }}

          // onMouseLeave={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}
          // onMouseMove={(e) => {
          //   e.preventDefault();
          //   this.hidepopup_betshigh();
          // }}


          />
          <img src="" id="rsntblewindows" alt="" />
          <h1 id="royalflush">
            Royal Flush-100 <span id="royalto">to</span> 1
          </h1>
          <h1 id="Straightflush">
            Straight Flush-50 <span id="strghtto">to</span> 1
          </h1>
          <h1 id="frofkind">
            Four of a kind-20 <span id="frkndto">to</span> 1
          </h1>
          <h1 id="fullhse">
            Full House-7 <span id="fullhseto">to</span> 1
          </h1>
          <h1 id="flush">
            Flush-5 <span id="flushto">to</span> 1
          </h1>
          <h1 id="stright">
            Straight-4 <span id="strightto">to</span> 1
          </h1>
          <h1 id="Threekind">
            Three Of a Kind-3 <span id="Threekindto">to</span> 1
          </h1>
          <h1 id="twopair">
            Two Pair-2 <span id="twopairto">to</span> 1
          </h1>
          <h1 id="onepair">
            One Pair-1 <span id="onepairto">to</span> 1
          </h1>
          <h1 id="aceking">
            Ace-King-1 <span id="acekingto">to</span> 1
          </h1>
          <h1 id="insurencepays">
            Insurance Pays <span id="insurencepaysto">1:1</span>
          </h1>


          <div id="confirmbuydealer_popup" className="confirmbuydealerPopupcss">
            <span id="alertTxt" ></span>
            <button id="yes" onClick={(e) => {

              if (this.state.cmfrmdealeraction === 1) {
                e.preventDefault();
                this.Confirmbuydealercard(true)
              }
              else {
                this.confirmFoldactin(true);

              }

            }}>YES</button>
            <button id="no" ref={this.no}
              onClick={(e) => {
                if (this.state.cmfrmdealeractionNO === 1) {
                  e.preventDefault();
                  this.Confirmbuydealercard(false)
                }
                else {
                  this.confirmFoldactin(false);

                }

              }}
            >NO</button>
          </div>


          <span id="drawtext" className={this.state.drawbtntxtcss}></span>
          <span id="buytext" className={this.state.buybtntxtcss}></span>
          <span id="betbtntext" className={this.state.betbtntextcss}></span>
          <span id="foldtext" className={this.state.foldbtntextcss}></span>
          <span id="pokerinfotext"></span>
          <span id="bet_val"></span>
          <div id="topcardscontainer"></div>
        </div>

        {(this.state.isMobilePortraitLndsp === false) && (
          <div id="chipsstoredcontainer" ref={this.chipsarray} className={this.state.chipsstoredcontainercss}>
            {/* {this.storebetchips} */}
            {this.state.storebetchips}
            {/* <span id="betchipsaddtobonustxt"></span> */}
          </div>

        )}



        {this.state.isbonuspattable ? (
          <div className="grid">
            {/* {this.state.isbonuspattable ? ( */}

            <table className="bonuspaytable" id="bonuspaytableInfo">
              <thead id="bonusInfoThead">
                <tr>
                  <th id="heading_table" colSpan="2">BONUS PAY TABLE</th>
                </tr>
              </thead>
              <tbody id="bonusInfoTbody">
                {this.bonuspaytable.map((obj, i) => (
                  <tr key={`card-${obj.k}`} id={`numbers${i}`}>
                    <td id={`numrussian${i}`}>{obj.k}</td>
                    <td id={`numbersvalrussian${i}`}>{obj.val}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}

        <div>
          <span id="inforussian" className= {this.state.inforussiancss}>{this.state.infoText}</span>
        </div>

        <div>
          <span id="rankinginfo" >{this.state.pokerranking}</span>
        </div>
        <div>
          <span id="rankinginfodeler">{this.state.pokerrankingdeler}</span>
        </div>

        <div id="anitecircleactive"></div>
        {this.state.isoptionmenu ? (
          <div id="optionsmenubutton">
            <div id="optiontopmenu">
              <span id="optiontext">OPTIONS</span>
            </div>
            <div id="buttontscontainer">
              <button id="limits"  onClick={(e) => {
                    e.preventDefault();
                    this.ShowLimits(); }}>LIMITS</button>
              <button id="help">HELP</button>
              <button id="history"
                onClick={(e) => {
                  e.preventDefault();
                  this.ShowHistory()
                }}>HISTORY</button>
              {/* <button id="arrow">
                <img
                  // src={exitrussian}
                  src={popupExit}
                  id="exitmenu"
                  onClick={(e) => {
                    e.preventDefault();
                    this.hideshowoptionmenu();
                  }} /></button> */}

            </div>
          </div>
        ) : (
          ""
        )}
{this.state.ShowLimits ? (
          <div id="Limits">
             <span>MinBet:{this.props.minBetAmt}</span>
             <span>MaxBet:{this.props.maxBetAmt}</span>
             <button id="exitLimits" onClick={(e) => {
              e.preventDefault();
              this.exitLimits()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button>
            </div>
):""}

      

        {this.state.ShowHistory ? (
          <div id="prevhistory">
            <table id="histry_russian">
              <thead id="heading_hstiry">
                <tr>
                  <th>Dealer Cards</th>
                  <th>Dealer Hand</th>
                  <th>Player Cards</th>
                  <th>Player Hand</th>
                  <th>Total Bet</th>
                  <th>Total Win</th>
                </tr>
              </thead>

              <tbody id="histry_body">
                {this.props.gameState.history.map((obj, i) => (
                  <tr key={`card-${i}`} id={`${i}`}>
                    <td id="dealercards_hstry">
                      {obj.dealerCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (

                          <img
                            className="cardsimages"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />


                        );
                      })}
                    </td>
                    <td id="dealerhand_hstry">{obj.dealerHand}</td>
                    <td id="playercards_hstry">
                      {obj.playerCards.map((card_num, index) => {
                        const matchingCard = resultCards.find(res => res.rsc_val === card_num);
                        return (
                          <img
                            className="cardsimages"
                            key={index}
                            src={matchingCard ? matchingCard.src : ''}
                            alt={card_num}
                          />
                        );
                      })}
                    </td>
                    <td id="playerhand_hstry">{obj.playerHand}</td>
                    <td id="totalbet_histry">{obj.totalBetAmount}</td>
                    <td id="totalwin_hstry">{obj.totalWinAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button id="exithistory" onClick={(e) => {
              e.preventDefault();
              this.exithistory()
            }}
            // onMouseOver={() => this.animexit()}
            >EXIT</button>
          </div>
        ) : null}

        <div id="russiangamecover">
        </div>
        <div id="crdscover"></div>
        <div id="buttons_cover"></div>
        <div id="glowonwinchip"></div>
        <div id="winpopup_rsp">
          <span id="winamt_rsp">{popup_winamount}</span>
        </div>

         <div id="minmaxbets">
        <span>MinBet:{this.props.minBetAmt}</span>
        <span>MaxBet:{this.props.maxBetAmt}</span>

         </div>

        <div id="popupbet_greaterthanbalnce">
           {(this.state.betshigh_balnce !== null)&& 
          <button className="exit_popup" id="exitPopup" onClick={this.hidepopup_betshigh}><img src = {popupExit}  alt={popupExit} /></button>
           }
          <span id="textbets_high">
              <h4 id="attention">Attention!</h4>
              {this.state.lowBalaceErrorInfo}
            </span>


          {/* {this.state.betshigh_balnce ?
            <span id="textbets_high">
              <h4 id="attention">Attention!</h4>
              insufficient Funds to place the ANTE of FUN {this.state.totalbet},Please DEPOSIT to continue playing.
            </span> 
            : <span id="textbets_high">
              <h4 id="attention">Attention!</h4>
              insufficient Funds to follow up your ANTE of FUN {this.state.totalbet},with bet FUN {this.state.totalbet}*2 Please DEPOSIT to continue playing.
            </span>
          } */}
        </div>


      </Fragment>
    );
  }

}



const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj
  }
}

export default connect(mapStatesToProps)(RussianPoker)


