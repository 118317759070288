import{
    FETCH_ENGLISH_REQUEST, FETCH_ENGLISH_SUCCESS, FETCH_ENGLISH_FAIL,
    FETCH_GUJARATHI_REQUEST, FETCH_GUJARATHI_SUCCESS, FETCH_GUJARATHI_FAIL,
    FETCH_BENGALI_REQUEST, FETCH_BENGALI_SUCCESS, FETCH_BENGALI_FAIL,
    FETCH_MARATHI_REQUEST, FETCH_MARATHI_SUCCESS, FETCH_MARATHI_FAIL,
    FETCH_TELUGU_REQUEST, FETCH_TELUGU_SUCCESS, FETCH_TELUGU_FAIL,
    FETCH_TAMIL_REQUEST, FETCH_TAMIL_SUCCESS, FETCH_TAMIL_FAIL,
    FETCH_KANNADA_REQUEST, FETCH_KANNADA_SUCCESS, FETCH_KANNADA_FAIL,
    FETCH_MALAYALAM_REQUEST, FETCH_MALAYALAM_SUCCESS, FETCH_MALAYALAM_FAIL,
    FETCH_PORTUGUESE_REQUEST, FETCH_PORTUGUESE_SUCCESS, FETCH_PORTUGUESE_FAIL,
    //Gujarathi , Bengali , Marathi , Telugu , Tamil , Portuguese , Kannada  and malayalam
} from "./actiontypes";
import axios from "axios";

/* --------- English ----------------*/
export const fetchEnglishRequest = () =>{
    return{
        type: FETCH_ENGLISH_REQUEST
    }
}

export const fetchEnglishSuccess = (data) =>{
    return{
        type: FETCH_ENGLISH_SUCCESS,
        payload: data
    }
}

export const fetchEnglishFail =(error)=>{
    return{
        type: FETCH_ENGLISH_FAIL,
        payload: error
    }
}

export const fetchEnglish = () => {
    return function(dispatch){ 
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
        dispatch(fetchEnglishRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/eng_lang.json').then((data) => {
           // axios.get('https://gearupcasino.undertesting.com/language/eng_lang.json').then((data) => {
                axios.get('https://ballagames.com/language/eng_lang.json').then((data) => {
              
            console.log(data);
            // alert(data)
            dispatch(fetchEnglishSuccess(data.data))
        }).catch((error)=> {
            // alert(error);
            console.log(error);
            dispatch(fetchEnglishFail(error))
        })
    }
}


/***************** FETCHING TELUGU ********************/

export const fetchTeluguRequest = ()=>{
    return {
        type: FETCH_TELUGU_REQUEST
    }
}
export const fetchTeluguSuccess = (data)=>{
    return {
        type: FETCH_TELUGU_SUCCESS,
        payload: data
    }
}
export const fetchTeluguFail = (error)=>{
    return {
        type: FETCH_TELUGU_FAIL,
        payload: error
    }
} 
export const fetchTelugu = () => { 
    return function(dispatch){ 
        dispatch(fetchTeluguRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/telu_lang.json').then((data) => {
            //axios.get('https://gearupcasino.undertesting.com/language/telu_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/telu_lang.json').then((data) => {
            dispatch(fetchTeluguSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchTeluguFail(error))
        })
    }
}

/***************** FETCHING BENGALI ********************/
export const fetchBengaliRequest = ()=>{
    return {
        type: FETCH_BENGALI_REQUEST
    }
}
export const fetchBengaliSuccess = (data)=>{
    return {
        type: FETCH_BENGALI_SUCCESS,
        payload: data
    }
}
export const fetchBengaliFail = (error)=>{
    return {
        type: FETCH_BENGALI_FAIL,
        payload: error
    }
} 
export const fetchBengali = () => { 
    return function(dispatch){ 
        dispatch(fetchBengaliRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/ben_lang.json').then((data) => {
          //  axios.get('https://gearupcasino.undertesting.com/language/ben_lang.json').then((data) => {
             axios.get('https://ballagames.com/language/ben_lang.json').then((data) => {
            dispatch(fetchBengaliSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchBengaliFail(error))
        })
    }
}


/***************** FETCHING MARATI ********************/
export const fetchMaratiRequest = ()=>{
    return {
        type: FETCH_MARATHI_REQUEST
    }
}
export const fetchMaratiSuccess = (data)=>{
    return {
        type: FETCH_MARATHI_SUCCESS,
        payload: data
    }
}
export const fetchMaratiFail = (error)=>{
    return {
        type: FETCH_MARATHI_FAIL,
        payload: error
    }
} 
export const fetchMarati = () => { 
    return function(dispatch){ 
        dispatch(fetchMaratiRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/mar_lang.json').then((data) => {
           // axios.get('https://gearupcasino.undertesting.com/language/mar_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/mar_lang.json').then((data) => {
            dispatch(fetchMaratiSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchMaratiFail(error))
        })
    }
}

/***************** FETCHING GUJARATI ********************/
export const fetchGujaratiRequest = ()=>{
    return {
        type: FETCH_GUJARATHI_REQUEST
    }
}
export const fetchGujaratiSuccess = (data)=>{
    return {
        type: FETCH_GUJARATHI_SUCCESS,
        payload: data
    }
}
export const fetchGujaratiFail = (error)=>{
    return {
        type: FETCH_GUJARATHI_FAIL,
        payload: error
    }
} 
export const fetchGujarati = () => { 
    return function(dispatch){ 
        console.log('Gujarati -----------')
        dispatch(fetchGujaratiRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/guj_lang.json').then((data) => {
            //axios.get('https://gearupcasino.undertesting.com/language/guj_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/guj_lang.json').then((data) => {
            dispatch(fetchGujaratiSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchGujaratiFail(error))
        })
    }
}

//FETCH_TAMIL_REQUEST, FETCH_TAMIL_SUCCESS, FETCH_TAMIL_FAIL

/***************** FETCHING GUJARATI ********************/
export const fetchTamilRequest = ()=>{
    return {
        type: FETCH_TAMIL_REQUEST
    }
}
export const fetchTamilSuccess = (data)=>{
    return {
        type: FETCH_TAMIL_SUCCESS,
        payload: data
    }
}
export const fetchTamilFail = (error)=>{
    return {
        type: FETCH_TAMIL_FAIL,
        payload: error
    }
} 
export const fetchTamil = () => { 
    return function(dispatch){ 
        console.log('Tamil -----------')
        dispatch(fetchTamilRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/tami_lang.json').then((data) => {
            //axios.get('https://gearupcasino.undertesting.com/language/tami_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/tami_lang.json').then((data) => {
            dispatch(fetchTamilSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchTamilFail(error))
        })
    }
}

// FETCH_KANNADA_REQUEST, FETCH_KANNADA_SUCCESS, FETCH_KANNADA_FAIL,
//     FETCH_MALAYALAM_REQUEST, FETCH_MALAYALAM_SUCCESS, FETCH_MALAYALAM_FAIL,
//     FETCH_PORTUGUESE_REQUEST, FETCH_PORTUGUESE_SUCCESS, FETCH_PORTUGUESE_FAIL,

/***************** FETCHING Kannada ********************/
export const fetchKannadaRequest = ()=>{
    return {
        type: FETCH_KANNADA_REQUEST
    }
}
export const fetchKannadaSuccess = (data)=>{
    return {
        type: FETCH_KANNADA_SUCCESS,
        payload: data
    }
}
export const fetchKannadaFail = (error)=>{
    return {
        type: FETCH_KANNADA_FAIL,
        payload: error
    }
} 
export const fetchKannada = () => { 
    return function(dispatch){ 
        console.log('Kannada -----------')
        dispatch(fetchKannadaRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/kan_lang.json').then((data) => {
           // axios.get('https://gearupcasino.undertesting.com/language/kan_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/kan_lang.json').then((data) => {
            dispatch(fetchKannadaSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchKannadaFail(error))
        })
    }
}

//     FETCH_MALAYALAM_REQUEST, FETCH_MALAYALAM_SUCCESS, FETCH_MALAYALAM_FAIL,
//     FETCH_PORTUGUESE_REQUEST, FETCH_PORTUGUESE_SUCCESS, FETCH_PORTUGUESE_FAIL,

/***************** FETCHING MALAYALAM ********************/
export const fetchMalayalamRequest = ()=>{
    return {
        type: FETCH_MALAYALAM_REQUEST
    }
}
export const fetchMalayalamSuccess = (data)=>{
    return {
        type: FETCH_MALAYALAM_SUCCESS,
        payload: data
    }
}
export const fetchMalayalamFail = (error)=>{
    return {
        type: FETCH_MALAYALAM_FAIL,
        payload: error
    }
} 
export const fetchMalayalam = () => { 
    return function(dispatch){ 
        console.log('MALAYALAM -----------')
        dispatch(fetchMalayalamRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/mali_lang.json').then((data) => {
            //axios.get('https://gearupcasino.undertesting.com/language/mali_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/mali_lang.json').then((data) => {
          
            dispatch(fetchMalayalamSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchMalayalamFail(error))
        })
    }
}

//     FETCH_PORTUGUESE_REQUEST, FETCH_PORTUGUESE_SUCCESS, FETCH_PORTUGUESE_FAIL,

/***************** FETCHING PORTUGUESE ********************/
export const fetchPortugueseRequest = ()=>{
    return {
        type: FETCH_PORTUGUESE_REQUEST
    }
}
export const fetchPortugueseSuccess = (data)=>{
    return {
        type: FETCH_PORTUGUESE_SUCCESS,
        payload: data
    }
}
export const fetchPortugueseFail = (error)=>{
    return {
        type: FETCH_PORTUGUESE_FAIL,
        payload: error
    }
} 
export const fetchPortuguese = () => { 
    return function(dispatch){ 
        console.log('PORTUGUESE -----------')
        dispatch(fetchPortugueseRequest())
        // axios.get('https://devp4m.czargaming.com/Indie_lang/port_lang.json').then((data) => {
           // axios.get('https://gearupcasino.undertesting.com/language/port_lang.json').then((data) => {
                 axios.get('https://ballagames.com/language/port_lang.json').then((data) => {
          
            console.log(typeof(data.data))
            dispatch(fetchPortugueseSuccess(data.data))
        }).catch((error)=> {
            dispatch(fetchPortugueseFail(error))
        })
    }
}