import React from "react";
// import "../../../../../css/games/funTargetTimer/betPanel.css";

export default class  BetPanel extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      wins : [
        { id: 1, amount: 0 },
        { id: 2, amount: 0 },
        { id: 3, amount: 0 },
        { id: 4, amount: 0 },
        { id: 5, amount: 0 },
        { id: 6, amount: 0 },
        { id: 7, amount: 0 },
        { id: 8, amount: 0 },
        { id: 9, amount: 0 },
        { id: 0, amount: 0 },      
      ]
    }
  }
  componentDidMount(){
    
  }
  updateBetAmt(id, value){
    let val = Number(Number(value).toFixed(2))
    console.log(id+"     "+val);
    for(var i =0; i< 10; i++){
      if(this.state.wins[i].id == id){     
        
        if(val >= 1000 && val <=999999){
          val = Number((val / 1000).toFixed(2))+"K";
          console.log(val);
        }else if(val >= 1000000 && val <=999999999){
          val = Number((val / 1000).toFixed(2))+"M";
          console.log(val);
        }else if(val >999999999){
          val = Number((val / 1000).toFixed(2))+"B";
          console.log(val);
        }

        this.state.wins[i].amount = val;
      }
    }    
    this.render();
  }

  mouseDownSelectCard(data){   
    console.log(data)  
    clearInterval(this.startInt );
    this.props.action(data) 
    this.startInt = setInterval( () => { 
      this.props.action(data)
    }, 120); 
  } 
  mouseUpStopCards(){
    clearInterval(this.startInt);
  }
  boxSmlDown(val){    
    document.getElementById(val).style.transform = "scale(0.9)";
  }
  boxSmlUp(val){
   document.getElementById(val).style.transform = "scale(1)";
  }
  render(){
    return (
      <div className="FGAfunTargetTimerBetPanel">
      {this.state.wins.map((value, i) => (
          <div key={this.state.wins[i].id} className="funTTBet"> 
            <div className="buttonZero" id={"FTTboxSml_"+this.state.wins[i].id}> 
              <div className={value.id === this.props.showWin ? "buttonOneBg" : "buttonZeroBg"}>
                <span
                // onMouseDown={(e) => { e.preventDefault(); this.mouseDownSelectCard(`${value.id}`)}}
                onMouseDown={(e) => { e.preventDefault(); this.boxSmlDown('FTTboxSml_'+this.state.wins[i].id); this.mouseDownSelectCard((this.state.wins[i].id))}}

                onMouseUp={(e)=>{e.preventDefault(); this.boxSmlUp('FTTboxSml_'+this.state.wins[i].id); this.mouseUpStopCards()}}
                onMouseOut={(e)=>{e.preventDefault(); this.boxSmlUp('FTTboxSml_'+this.state.wins[i].id); this.mouseUpStopCards()}}>
                    
                  <div className="valueOne">{value.id}</div>
                  <div className="valueTwo">{value.amount == 0 ?  null : value.amount }</div>
                </span>
              </div> 
          </div>
        </div>
      ))}
    </div>
    );
  }
}
