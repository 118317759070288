import React from "react";
import WheelInner from "./americanRouletteWheel";
// import WheelBorder from "./americanRouletteWheelBorder"; 

export default class Wheel extends React.Component{
    constructor(props){
        super(props)
        this.artWheelRef = React.createRef();
        this.inWid = window.innerWidth
        this.inHt = window.innerHeight 
         
            //alert( ' 12  ' )
            this.widthMain = 950;
            this.heightMain = 520;
            this.ScaleX = 1; 
            this.posX = 0.500;
            this.ScaleY = 0.49;
            this.posY = 0.93;
            this.fontSize = 35;
            this.ballSize = 13;
            this.textYpos = 0.13
         
    }
    componentStateHandler(){

    }
    // stopWheelSound =() =>{
    //     this.artWheelRef.current.stopWheel();
    // }
	spinTheWheel = (s) => {
        this.artWheelRef.current.spinTheWheel(s);
    }
    wheelHandler(position){
        this.props.action(position);
    }
    render(){
        return(
            <React.Fragment>
            <div id="amRoutTimerWheel" 
            style={{
                width:this.widthMain,      
                height:this.heightMain,                 
                transform: `scale(${this.props.zoom})`,
                transformOrigin: 'top',
                zIndex:this.props.zindex,
                position:'relative',}} >
                <WheelInner 
                textYpos={this.textYpos} ballSize={this.ballSize} fontSize={this.fontSize} 
                width={this.widthMain} height={this.heightMain} 
                ScaleX={this.ScaleX} ScaleY={this.ScaleY} posX={this.posX} posY={this.posY}
                onFocus={this.props.onFocus} gameState={this.props.gameState} showGlow={this.props.showGlow} ref={this.artWheelRef} zoom={this.props.zoom} action={this.wheelHandler.bind(this)} isMobile={this.props.isMobile}
               ></WheelInner>
                {/* <WheelBorder americanRouletteTimerLoded={this.props.americanRouletteTimerLoded} width={this.widthMain} zoom={this.props.zoom} height = {this.heightMain} x={0} y={0}></WheelBorder> */}
            </div>
            </React.Fragment>
        )
    }
}
