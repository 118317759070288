import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import history from "./history";
import { Provider } from 'react-redux';
import store from './code/redux/store';
// import store2  from './code/js/appStates/dragonTiger/store';
import axios from "axios";
// axios.defaults.baseURL = 'http://10.10.20.58:8899/';
ReactDOM.render(
  <React.StrictMode>
  <Router history={history}>
    <Provider store={store}>
      <App />
    </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);