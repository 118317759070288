import React from "react";
//import tickSound from "../../../../../../assets/sounds/tick.mp3";

export default class AmericanTimerInSeconds extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeInSec: 60,
            getTime: 60, 
            isBetSent: false,
            isDisableSent: false,
            isTimerSent: false,
        };
        this.timerv = this.props.timesend;
    }
    
    sendTimer(tt) {
        document.getElementById('timeSpan').style.display = 'block';
        console.log("American Current Time: "+tt)
        if(tt != NaN){
            this.setState({ getTime: tt });
            this.myTimer();
        }
    }
    clearTimer() {
      clearInterval(this.timerclear);
    }
    componentDidMount() {
      //document.getElementById("timeSpan").style.display = "none";
      this.timerclear = setInterval(this.myTimer, 1000);
      document.getElementById("americanRltTimerCover").style.display = 'none';
    }
     
    componentWillUnmount(){
        clearInterval(this.timerclear);
    }
    
    myTimer = () => { 
        if (this.state.getTime !== null && this.state.getTime != NaN && this.state.getTime >= 0) {
            var d = this.state.getTime;
            var timer = document.getElementById("timer");
            var americanRltTimerCover = document.getElementById("americanRltTimerCover");
                
            var n = --d;
            var sec = parseInt(n % 60);
            var secS = sec.toString();
            if (String(sec).length < 2) {
                secS = "0" + sec.toString();
            }
            this.setState({ getTime: secS })
            this.setState({ timeInSec: secS });
            
            if(n == 10 && !this.state.isTimerSent){
                this.setState({isTimerSent:true});
                this.getTimer();
            }

            if (n <= 0) {
                this.setState({isTimerSent:false});
                this.setState({ getTime: 60 });
            }
        
            if (n == 0) {               
                this.setState({isDisableSent: false});
                this.state.isBetSent = false;
            }  
            
            if (n <= 5 && n > 0) {
                americanRltTimerCover.style.display = 'block'; 
                if(!this.state.isBetSent){
                    this.setState({isBetSent: true});     
                    this.props.action("BET OK"); 
                }
                if(!this.state.isDisableSent){
                    this.setState({isDisableSent: true});
                    this.props.action("disableBtns");
                }  
                                      
            }

            if(n <= 4 ){
                americanRltTimerCover.style.display = 'block';               
            }
            
           

            if (n <= 10  && n >= 5) {
                timer.classList.add("timerYelloBorder"); 
                // this.tickSound = new Audio(tickSound);
                // this.tickSound.play();
                this.props.action("PlayTickSound"); 
                this.setState({isBetSent: false});
            } else {
                if (timer && timer.classList.contains("timerYelloBorder")) {
                    timer.classList.remove("timerYelloBorder"); 
                    this.props.action("StopTickSound"); 
                }
            } 
        } 
    }

    getTimer() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_TIMER",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        this.props.network.sendof(body);
    }
    render() {
        return <div className="fd">
            <div id="americanRltTimerCover" className="americanRltTimerCover"></div>
            <div className="timer_Sec" id="timer"> <span className="" id="timeSpan"> {this.state.timeInSec} </span> </div>
        </div>;
    }
}
