import React from "react";
import "../../../../../css/games/americanRoulette/title.css";
import european_roulette from "../../../../../../assets/titles/indie/europeaRoulette.svg";

export default function Title(props) {
    return (
        <div className="fd text_center">
            <img className='titleImg' src={european_roulette} alt={european_roulette}></img>
        </div>
    )
}
