import React from "react";
import "../../../../../css/games/americanRoulette/title.css"; 
import european_roulette_timer from "../../../../../../assets/titles/indie/europeaRouletteTimer.svg";

export default function Title(props) {
    return (
        <div className="fd text_center">
            <img className='titleImg' src={european_roulette_timer} alt={european_roulette_timer}></img>
        </div>
    )
}
