import React from "react";
import "../../../../../css/games/americanRoulette/title.css";
import american_roulette from "../../../../../../assets/titles/indie/americanRoulette.svg";

export default function Title(props) {
    return (
        <div className="fd text_center">
            <img className='titleImg' src={american_roulette} alt={american_roulette}></img>
        </div>
    )
}
