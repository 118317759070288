import React from "react";
import "../../../../../css/games/funTargetTimer/infoBoard.css";
import tableData from "../../funSorat_timer/UI/table";

export default function InfoBoard(props) {
  return (
    <div className="fd">
      <div className="funTrgtTimerBoard">
         {props.text} 
         </div>
      <div className="fd m_t_5">
      {props.gameState.history ?
        <table className="HistoryTbl ftTBg" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              {props.gameState.history.map((value, index) => {
                return index >= 0 ?
                  <td key={index}>{value.resultNo}</td>
                  :
                  <span></span>
              })}

            </tr>
            <tr>
              {props.gameState.history.map((value, index) => {
                return index >= 0 ?
                  <td key={index}>{value.extraInfo}X</td>
                  :
                  <span></span>
              })}
            </tr>
          </tbody>
        </table>
        :
        <table className="HistoryTbl ftTBg" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> </tr>
              <tr> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> <td> 0 </td> </tr>
            </tbody>
        </table>
        }
      </div>
    </div>
  );
}