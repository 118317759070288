import React from "react";
import "../../../../../css/games/funTargetTimer/leftButtonPanel.css";
import { useSelector} from "react-redux";


function LeftButtonPanel(props) {
  const language = useSelector((state) => state.languageObjs.languageObj)
  const roundNumber = props.balance - props.totalBettarget
  
  // const betAmount = Math.round(roundNumber*100,2)/100
  const betAmount = Number(roundNumber)

  // let updatebal = topLable_fn(Number(betAmount)).toFixed(2);
  // let updatebal = topLable_fn(betAmount)
  // let updateBet = topLable_fn(props.totalBet);
  
  let updatebal = Number(betAmount.toFixed(2));
  let updateBet = Number(props.totalBet.toFixed(2));
  


  // function topLable_fn(labelValue) 
  // {
  //     // Nine Zeroes for Billions
  //     return Math.abs(Number(labelValue)) >= 1.0e+9
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
  //     // Six Zeroes for Millions 
  //     : Math.abs(Number(labelValue)) >= 1.0e+6
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
  //     // Three Zeroes for Thousands
  //     : Math.abs(Number(labelValue)) >= 1.0e+3
  
  //     ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"
  
  //     : Math.abs(Number(labelValue));
  // }
function topLable_fn(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? Number((Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2)) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? Number((Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2)) + "M"
  // Three Zeroes for Thousands
  : Math.abs(Number(labelValue)) >= 1.0e+3

  ? Number((Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2)) + "K"

  : Number(Math.abs(Number(labelValue.toFixed(2))));
}
  return (
    <div className="targetTimerLables">
      <div className="fl_1">
        <div className="funBal_Target fd">
          {/* <div>{props.balance - props.totalBet}</div> */}
          <div>{updatebal }</div>

          <div>{language.Balance}</div>
        </div>
      </div>
      <div className="fl_1">
        <div className="funBal_Target fd">
          <div>{updateBet}</div>
          <div>{language.Total_Bet}</div>
        </div>
      </div>
      {/* <div className="fl_1">
        <div className="funBal_Target fd">
          <div>{props.winning}</div>
          <div>WINNING</div>
        </div>
      </div> */}
    </div>
  );
}export default LeftButtonPanel