import React from 'react';

import chip_1 from '../../../../../../assets/games/dragon_tiger/chips/chip_1.svg';
import chip_10 from '../../../../../../assets/games/dragon_tiger/chips/chip_10.svg';
import chip_25 from '../../../../../../assets/games/dragon_tiger/chips/chip_25.svg';
import chip_50 from '../../../../../../assets/games/dragon_tiger/chips/chip_50.svg';
import chip_100 from '../../../../../../assets/games/dragon_tiger/chips/chip_100.svg';
import chip_500 from '../../../../../../assets/games/dragon_tiger/chips//chip_500.svg';
import chip_1000 from '../../../../../../assets/games/dragon_tiger/chips/chip_1000.svg';
import chip_5000 from '../../../../../../assets/games/dragon_tiger/chips/chip_5000.svg';

const chips = [
    { src: chip_1, val:1 },
    { src: chip_10, val:10 },
    { src: chip_25, val:25 },
    { src: chip_50, val:50 },
    { src: chip_100, val:100 },
    { src: chip_500, val:500 },
    { src: chip_1000, val:1000 },
    { src: chip_5000, val:5000 },
]

export default chips;