import React, { Component } from "react";
import chips from '../../../chips';

class ChipSelection extends Component {
    constructor(props){
     
        super(props);
        this.state = {
            chipSelect : 1,
        }
        this.chipVal = [ 
            { label: "1" , val: 1 }, 
            { label: "5" , val: 5 }, 
            { label: "10" , val: 10 }, 
            { label: "25" , val: 25 }, 
            { label: "50" , val: 50 }, 
            { label: "100" , val: 100 }, 
            { label: "500" , val: 500 }, 
            { label: "1000" , val: 1000 },
            { label: "" , val: "" }
        ];      
    }
    componentDidMount(){
       
        if(this.props.fst_chipValues){
            let newChips = this.props.fst_chipValues.split(",");
            console.log(newChips);
            console.log(newChips.length)
            let lblTxt ="";
            for(let i =0; i<newChips.length; i++){                   
                if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
                    lblTxt = "K"
                }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
                    lblTxt = "M"                        
                }else if(Number(newChips[i]) > 999999999){            
                    lblTxt = "B"
                }                     
                this.chipVal[i].val = newChips[i]         
                console.log(this.chipVal[i].val, lblTxt);
                this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
            }
        }
    }

    checkChipValue(chipValue, chpTxt, i) {       
        if(chipValue >= 1000){
            chipValue = chipValue / 1000;
            this.checkChipValue(chipValue, chpTxt, i)
        }else{
            console.log(chpTxt);
            this.showResultChipValue(chipValue, chpTxt, i);
            return chipValue
        }
    }

    showResultChipValue(chVal, chipTxt, index){
        console.log(chVal);
        console.log(chVal+""+chipTxt)
        this.chipVal[index].label = chVal+""+chipTxt;
        console.log(this.chipVal)
    }
    render(){
        return(
            <React.Fragment> 
                {chips.map((imgs, i) => (
                    <div key={imgs.src} className="chipSorat">
                        <p className="chipImg" onClick={(e)=> {e.preventDefault(); this.props.betAmount(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);this.setState({chipSelect : imgs.val})}} >
                            <img src={imgs.src} alt={imgs.src} className={this.state.chipSelect === imgs.val ? 'active chp_img Select' : 'chp_img UnSelect'}  />
                            {/* <span className={`fst_chip${i}`}>{this.chipVal[i].label}</span> */}
                            <div class="fst_overlay_text"> {this.chipVal[i].label}
                            </div>
                        </p>
                    </div>
                ))} 
            </React.Fragment>
        )
    }
}
export default ChipSelection;
