import left_chip from "../../../../../../assets/games/dragon_tiger/DT_PREV.png";
import right_chip from "../../../../../../assets/games/dragon_tiger/DT_Next.png";
import big from "../../../../../../assets/games/dragon_tiger/DT_BIG.png";
import small from "../../../../../../assets/games/dragon_tiger/DT_SMALL.png";
import tie from "../../../../../../assets/games/dragon_tiger/DT_TIE_BET.png";
import tiger_bet from "../../../../../../assets/games/dragon_tiger/DT_Tiger_Bet.png";
import dragon_bet from "../../../../../../assets/games/dragon_tiger/DT_Dragon_Bet.png";

export const betPlanelImgs = [
    { src: big },
    { src: small },
    { src: tie },
    { src: tiger_bet },
    { src: dragon_bet }
];

export const gameAssets = [
    {src: left_chip},
    {src: right_chip},
]