import { applyMiddleware, createStore, combineReducers } from "redux";
import languageReducer from "./reducers/languageReduces";
import dragontigerReducer from './../js/appStates/dragonTiger/effects'; 
import thunk from  "redux-thunk";
import logger from "redux-logger";
console.log( languageReducer )

const rootReducers = combineReducers({
    languageObjs : languageReducer,
    dragontigerReducer : dragontigerReducer
})
const store = createStore(rootReducers, applyMiddleware(thunk, logger));

export default store;
