import React from "react";
import user from "../../../../../../assets/login/user.svg";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import {connect} from "react-redux";

 class RightButtonPanel extends React.Component {
    constructor(props) {
        super(props);        
        this.gameHistroy = [];
        this.filterHistory = [];
        this.state = {historyArray:[]};
        // this.setState({
        //     historyArray: []
        // });
        setTimeout(() => {
            // this.updateHistory();
        }, 100);
    }

    componentDidMount(){
        const {childRef} = this.props 
        childRef(this)
    }
    updateHistory() {
        console.log("ER Update Game History: "+this.props.gameState.history)
        this.state.historyArray = []
        console.log(this.props.gameState.history.length);
        if (this.props.gameState.history !== undefined && this.props.gameState.history.length > 0) {
            this.gameHistroy = this.props.gameState.history;
            this.filterHistory = this.gameHistroy.slice(Math.max(this.gameHistroy.length - 5, 0));
            console.log(this.filterHistory);
            for (let k = 0; k < this.filterHistory.length; k++) {
                let idNo = Number( this.filterHistory[k].resultNo )
                let resClassList = document.getElementById("id_" + idNo).parentElement.classList;
                const hist = {
                    numb: this.filterHistory[k].resultNo,
                    color: resClassList[0],
                };
                // this.historyArray.push(hist);
                this.state.historyArray[k] = hist
            }
        }
        console.log(this.state.historyArray);
    }


    render() {
        return (
            <div className="fd indErltBtns">
                <div className=" history history_AR fd">
                    <div id="rouletteCover" className="rouletteCover"></div>
                    <div className="fd clr_ff text-center m_b_5 font_30 ernt_histTxt">{this.props.language.History}</div>
                    <div className="fd">
                        <table className="fd american_HistoryTbl" cellPadding="0" cellSpacing="0">
                            <tbody>
                            <tr>
                            {this.state.historyArray.length != 0 ? (
                              this.state.historyArray.map((value, index) => {
                                console.log("History update "+value.numb)
                                return (
                                  <td key={index}>
                                    {" "}
                                    <span style={{color : value.color }}> {value.numb} </span>{" "}
                                  </td>
                                );
                              })
                            ) : (
                              <td> &nbsp; </td>
                            )}
                          </tr>
                            </tbody>
                        </table>           
                    </div>
                </div> 
                <div className="fd">
                    <button
                        id="zoomBtn"
                        className="fd ar_bt_1 " disabled={this.props.disZoomBtn == 1}
                        name={this.props.zoomButtonText} onClick={(e) => {
                        e.preventDefault();
                        console.log(e.target.name);
                        // if (e.target.name === "Wheel Zoom: ON") {
                        if (this.props.zoomButtonValue === 0) {

                            this.props.action("ZoomON");
                        }
                        // if (e.target.name === "Wheel Zoom: OFF") {
                        if (this.props.zoomButtonValue === 1) {

                            this.props.action("ZoomOFF");
                        }
                    }}>{this.props.zoomButtonText}</button>
                </div>

                <div className="fd m_t_5">
                    <button
                        id="bet_take"
                        className={this.props.btnActive ? "fd ar_bt_1 active z_1020" : "fd ar_bt_1  z_0"}
                        name={this.props.betBtnText}
                        disabled={this.props.disBetBtn == 1 && this.props.betBtnText !== "TAKE"}
                        onClick={(e) => {
                            e.preventDefault();
                            console.log(e.target.name);

                            // let betSound;
                            // if (e.target.name === "TAKE") {
                            if (this.props.betBtnValue=== 2) {

                                console.log("TAKE Button ");
                                this.props.action("TAKE");
                            // } else if (e.target.name === "PREV") {
                            } else if (this.props.betBtnValue=== 0) {

                                console.log("Prev Button Name: " + e.target.name)
                                this.props.action("PREV");
                            // } else if (e.target.name === "SPIN") {
                            } else if (this.props.betBtnValue=== 1) {

                                console.log("Send Bet Request");
                                this.betSound = new Audio(betSound);
                                this.betSound.play();
                                this.props.action("SPIN");
                            }
                        }}>{this.props.betBtnText}
                    </button>
                    </div>
            
                    <div className="fd "> 
                        <button className="fd ar_bt_1 " onClick={(e) => {
                            e.preventDefault();
                            this.props.action("double")
                        }} disabled={this.props.disDoubleBtn == 1}> {this.props.language.Double} 
                        </button> 
                    </div>

                    <div className="fd m_t_5"> 
                        <button className="fd ar_bt_1 " onClick={(e) => {
                            e.preventDefault();
                            this.props.action("clear")
                        }} disabled={this.props.disClearBtn == 1}>{this.props.language.Clear}
                        </button> 
                    </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) =>{
    return{
        language:state.languageObjs.languageObj
    }
}


export default connect(mapStatesToProps)(RightButtonPanel)
