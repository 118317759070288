import React from "react";
import "../../../../../css/games/funTarget/balanceLabel.css";
import user from '../../../../../../assets/login/user.svg';

export default function BalanceLabel(props) {
    return (
        <div className="fd">
                <div className="userLbl">
                    <img className="user" src={user} alt={user} /> {props.name}
                </div>
            </div>
    );
}
