import React from "react";
import card_items from "./cards";
import resultCards from "./resultCards";

import chips from "../../../chips";
import cardFallSound from "../../../../../../assets/sounds/slide.mp3";
import betoncard from "../../../../../../assets/sounds/betoncard.mp3";
import BottomPanel from './bottomPanel';

import { connect } from "react-redux";

 class ChipsDealerPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chipSelect: 1,
      index: -1,
      currentCard: 0,
      DlrCards: [],
      PlrCards: [],
      getRes: "",
      betResults: [],
      setTimeDealer: 250,
      setTimePlayer: 250,
      winnerObj : [],
      betObj : [],
      cardsObj : [],
    };
    this.audio = null;
    this.audioD = null;
    this.cardsInt = "";  
    this.a = 0;
    this.startInt = null;
    this.updateHistoryData = [];
    this.winnerNameObj = [];
    this.winnerObj = [];
    this.betObj = [];
    this.cardsObj = [];

    this.chipVal = [ 
      { label: "1k" , val: 1000 }, 
      { label: "5k" , val: 5000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "50k" , val: 50000 }, 
      { label: "100k" , val: 100000 }, 
      { label: "5M" , val: 5000000 }, 
      { label: "1B" , val: 100000000 },
      { label: "" , val: 0 }
    ];
  } 
  componentDidMount(){ 

    if(this.props.fc_chipValues){
      let newChips = this.props.fc_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt ="";
      for(let i =0; i<newChips.length; i++){                   
          if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
              lblTxt = "K"
          }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
              lblTxt = "M"                        
          }else if(Number(newChips[i]) > 999999999){            
              lblTxt = "B"
          }                     
          this.chipVal[i].val = newChips[i]         
          console.log(this.chipVal[i].val, lblTxt);
          this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
      }
    }   
    const { childRef } = this.props;
    childRef(this);
  }
  
  checkChipValue(chipValue, chpTxt, i) {       
    if(chipValue >= 1000){
        chipValue = chipValue / 1000;
        this.checkChipValue(chipValue, chpTxt, i)
    }else{
        console.log(chpTxt);
        this.showResultChipValue(chipValue, chpTxt, i);
        return chipValue
    }
  }

  showResultChipValue(chVal, chipTxt, index){
      console.log(chVal);
      console.log(chVal+""+chipTxt)
      this.chipVal[index].label = chVal+""+chipTxt;
      console.log(this.chipVal)
  }

  componentWillUnmount(){ 
    this.audio = null;
    this.audioD = null;
    this.setState({
      DlrCards: [],
      PlrCards: [],
    })
  }
  
  dealerCards = () => { 
    this.setState({
      DlrCards: [],
      PlrCards: [],
    })
    
    if(this.props.delearFirst){
      this.state.setTimeDealer = 0 
      this.state.setTimePlayer = 250 
    } else { 
      this.state.setTimePlayer = 0
      this.state.setTimeDealer = 250 
    }
    var dealerBox = document.getElementById("dealerBox");
    var playerBox = document.getElementById("playerBox");

    this.setState({
      DlrCards: this.props.funCardResult.dealerCards,
      PlrCards: this.props.funCardResult.playerCards,
    });
    console.log(this.state.DlrCards);
    console.log(this.state.PlrCards);

    /****************DEALER CARDS SHOWING AFTER DEAL START ******************/
    console.log(this.state.setTimeDealer);
    
    setTimeout(()=>{

      var dlrArray = [];
      for (var v = 0; v < this.state.DlrCards.length; v++) {
        for (var b = 0; b < resultCards.length; b++) { 
          if (resultCards[b].rsc_val === this.state.DlrCards[v]) {
            dlrArray.push(resultCards[b].src);
          }
        }
      }
      
      var plrArray = [];
      for (var pl = 0; pl < this.state.PlrCards.length; pl++) {
        for (var rs = 0; rs < resultCards.length; rs++) {
          if (resultCards[rs].rsc_val === this.state.PlrCards[pl]) {
            plrArray.push(resultCards[rs].src);
          }
        }
      }

      let totalCardsTime =  dlrArray.length + plrArray.length;

      let pCount = 0;
      let dCount = 0;
      const myLoop = (dPlus) => {
       
        console.log("Dealer Array: "+dlrArray.length);
        if(this.props.delearFirst){
          if(dPlus % 2 == 0){
            if(this.audioD){
              this.audioD.play()
            }
            dealerBox.innerHTML += `<img class="dCard_${dCount+1}" alt=${dCount} src=${dlrArray[dCount]} />`;
            dCount++;
          }else{
            if(this.audio){
              this.audio.play()
            }
            playerBox.innerHTML += `<img src=${plrArray[pCount]} />`;
            pCount++;
          }  
        }else{
          if(dPlus % 2 != 0){
            if(this.audioD){
              this.audioD.play()
            }
            dealerBox.innerHTML += `<img class="dCard_${dCount+1}" alt=${dCount} src=${dlrArray[dCount]} />`;
            dCount++;
          }else{
            if(this.audio){
              this.audio.play()
            }
            playerBox.innerHTML += `<img src=${plrArray[pCount]} />`;
            pCount++;
          }  
        }      
      } 
      var i = 0;
      const countDlr = () => {
        var dPlus = i++; 
        console.log("dPlus:  "+dPlus);
        if(dPlus < totalCardsTime){
          myLoop(dPlus);
        }
      }
      const myDlr = setInterval(function() { 
        countDlr()
        }, 600);  
      
      setTimeout(() => {
        clearInterval(myDlr);       
      }, totalCardsTime * 650);

    }, this.state.setTimeDealer)

    /****************DEALER CARDS SHOWING AFTER DEAL END ******************/
  };


  showCardHistory = (targetHistory) =>{ 
    console.log(targetHistory);
    if(this.props.delearFirst){
      this.state.setTimeDealer = 0 
      this.state.setTimePlayer = 10 
    } else { 
      this.state.setTimePlayer = 0
      this.state.setTimeDealer = 10 
    }
    var dealerBox = document.getElementById("dealerBox");
    var playerBox = document.getElementById("playerBox");

    console.log(this.props.funCardResult);

    if(targetHistory.dealerCards.length > 0){
      this.setState({DlrCards: targetHistory.dealerCards});
      this.state.DlrCards = targetHistory.dealerCards;
    }
    if(targetHistory.playerCards.length > 0){
      this.setState({PlrCards: targetHistory.playerCards});
      this.state.PlrCards = targetHistory.playerCards;
    }
  
    //setTimeout(()=>{
      if(this.state.DlrCards.length > 0){
        var dlrArray = [];
        for (var v = 0; v < this.state.DlrCards.length; v++) {
          for (var b = 0; b < resultCards.length; b++) { 
            if (resultCards[b].rsc_val === this.state.DlrCards[v]) {
              dlrArray.push(resultCards[b].src);
            }
          }
        }
      }
      
      if(this.state.PlrCards.length > 0){
        var plrArray = [];
        for (var pl = 0; pl < this.state.PlrCards.length; pl++) {
          for (var rs = 0; rs < resultCards.length; rs++) {
            if (resultCards[rs].rsc_val === this.state.PlrCards[pl]) {
              plrArray.push(resultCards[rs].src);
            }
          }
        }
      }

      console.log(dlrArray);
      console.log(plrArray);

      let totalCardsTime =  dlrArray.length + plrArray.length+1;

      let pCount = 0;
      let dCount = 0;
      const myLoop = (dPlus) => {       
        console.log("Dealer Array: "+dlrArray.length);        
        if(this.props.delearFirst){
          if(dPlus % 2 == 0){
            if(dCount < dlrArray.length){
              dealerBox.innerHTML += `<img class="dCard_${dCount+1}" alt=${dCount} src=${dlrArray[dCount]} />`;            
              dCount++;
            }
          }else{
            if(pCount < plrArray.length){
              playerBox.innerHTML += `<img src=${plrArray[pCount]} />`;
              pCount++;
            }
          }  
        }else{
          if(dPlus % 2 != 0){            
            if(dCount < dlrArray.length){
              dealerBox.innerHTML += `<img class="dCard_${dCount+1}" alt=${dCount} src=${dlrArray[dCount]} />`;
              dCount++;
            }
          }else{
            if(pCount < plrArray.length){
              playerBox.innerHTML += `<img src=${plrArray[pCount]} />`;
              console.log(pCount);
              pCount++;
            }
          }  
        }      
      } 
      var i = 0;
      const countDlr = () => {
        var dPlus = i++; 
        myLoop(dPlus);
      }
      const myDlr = setInterval(function() { 
        countDlr()
        }, 10);  
      
      setTimeout(() => {
        clearInterval(myDlr);       
      }, totalCardsTime * 100);

    //}, this.state.setTimeDealer)

    /****************DEALER CARDS SHOWING AFTER DEAL END ******************/
  };


  cleareCards() {
    document.getElementById("dealerBox").innerHTML = ``;
    document.getElementById("playerBox").innerHTML = ``;
  } 
  
  mouseDownSelectCard(data){     
    this.audio = new Audio(cardFallSound);
    this.audioD = new Audio(cardFallSound);
    clearInterval(this.startInt );
    document.getElementById("card_"+data).style.transform = "scale(0.9)";
    this.props.selectedCard(data)
    this.betoncard = new Audio(betoncard)
    this.betoncard.play();
    this.startInt = setInterval( () => {
      this.betoncard2 = new Audio(betoncard)
      this.betoncard2.play();
      this.props.selectedCard(data)
    }, 120); 
  } 
  mouseUpStopCards(cardNum){
    if(document.getElementById("card_"+cardNum)){
      setTimeout(() => {
        document.getElementById("card_"+cardNum).style.transform = "scale(1)";
      }, 100);
    }
    clearInterval(this.startInt);
  }

  updateHistory(historyData){
    this.updateHistoryData = historyData;
    // console.log(historyData)
    // console.log("###############################################################");
    // console.log(this.updateHistoryData[0]);
    // console.log(this.updateHistoryData[1]);
    // console.log(this.updateHistoryData[2]);
    if(historyData.length == 3 ){

      historyData[2].map(result =>{
        if(result ==="Player"){
       
         
          this.setState(prevState =>({
            cardsObj:[...prevState.cardsObj, this.props.language.Player]
          }))
         
        }else{
         
          this.setState(prevState =>({
            cardsObj:[...prevState.cardsObj, this.props.language.Dealer]
          }))

        }
      })
  }
    

    this.winnerObj = this.updateHistoryData[0];
    this.betObj = this.updateHistoryData[1];
    this.cardsObj = this.updateHistoryData[2];


    console.log(this.cardsObj);
    
    console.log("###############################################################");


  }

getLanguagePlayerDeal = () =>{
  let plyDel = []
  this.cardsObj.map((data, i) => {

    if(data ==="Player"){
      console.log(data)
      plyDel.push( this.props.language.Player)
     
    }else if(data ==="Dealer"){
      plyDel.push( this.props.language.Dealer)
     
    }
})

return plyDel

}
  
render() {
    let player = this.getLanguagePlayerDeal()   
    return (
      <React.Fragment>
        <div className="fd">
          <div> </div>
          {/************************** PLAY CARDS START  ******************/}
          <div className="fd cards p_lr_20 m_tb_35"> 
            {card_items.map((data) => (
              <div
                className="cardDiv"
                id={"card_"+data.c_val}
                key={data.c_val}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.currentTarget.style.transform = "scale(0.9)"; 
                  this.mouseDownSelectCard(data.c_val)
                  // this.props.selectedCard(data.c_val);
                }}
                onMouseUp={(e)=>{this.mouseUpStopCards(data.c_val)}}
                onMouseOut={(e)=>{this.mouseUpStopCards(data.c_val)}}
              >
                <img src={data.src} alt={data.c_val} />
                <div className="gldDiv" id={"fID_" + data.c_val}>
                  
                </div>
              </div>
            ))}
          </div>
          {/************************** PLAY CARDS END  ******************/}
          <div className="fd  p_lr_10">
            <div className="row"> 
              {/************************** DEALER & PLAYER TABLE START  ******************/}
              <div className="funCardTable">
                <div className="AddCards">
                  <div className="cardBox">
                    <div className="dealerBox" id="dealerBox"></div>
                    <span className="boxName"> {this.props.language.Dealer} </span>
                  </div>
                  <div className="cardBox">
                    <div className="playerBox " id="playerBox"></div>
                    <span className="boxName"> {this.props.language.Player} </span>
                  </div>

                  {/************************** CHIPS RIGHT END  ******************/}
              <div className="funCardChips chipsRight">
                {chips.map((value, index) =>
                
                    <div className="chipCard" key={index} onClick={(e) => {
                        e.preventDefault();
                        this.props.getChipVal(`${this.chipVal[index].val}`);
                        this.setState({ chipSelect: value.val });
                      }}>
                      <img
                        className={this.state.chipSelect === value.val ? "active" : "inactive"}
                        src={value.src}
                        
                        alt={value.src}
                      />
                      <div class="fc_overlay_text"> {this.chipVal[index].label}</div>
                    </div>  
                    
                    
                //     <div key={imgs.src} className="chipSorat">
                //     <p className="chipImg" onClick={(e)=> {e.preventDefault(); this.props.betAmount(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`);this.setState({chipSelect : imgs.val})}} >
                //         <img src={imgs.src} alt={imgs.src} className={this.state.chipSelect === imgs.val ? 'active chp_img Select' : 'chp_img UnSelect'}  />
                //         {/* <span className={`fst_chip${i}`}>{this.chipVal[i].label}</span> */}
                //         <div class="fst_overlay_text"> {this.chipVal[i].label}
                //         </div>
                //     </p>
                // </div>
                  
                )}
              </div>
              {/************************** CHIPS RIGHT END  ******************/}

                </div>
                <div className="winnerTable">
                  <table border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                      <tr>
                        <th> {this.props.language.Card} </th>
                        <th> {this.props.language.Bet} </th>
                        <th> {this.props.language.Winner}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> 
                          {player.map((data, i) => (
                            <span key={i}>                              
                              {data} 
                              {/* {player} */}
                              <br></br>
                            </span>
                          ))}
                        </td>
                        <td> 
                          {this.betObj.map((data, i) => (
                            <span key={i}>
                              
                              {data} 
                             
                              <br></br>
                            </span>
                          ))}
                        </td>
                        <td>
                          {this.winnerObj.map((data, i) => (
                            <span key={i}>
                              
                              {data} 
                            
                              <br></br>
                            </span>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                </div>
                <div className="fc_buttonList">
                  <BottomPanel
                   disableClearBtn = {this.props.disableClearBtn} 
                   disableDealBtn = {this.props.disableDealBtn} 
                   disable={this.props.disable} 
                   btnActive={this.props.btnActive} 
                   betBtnTxt={this.props.betBtnTxt} 
                   betCard={this.props.betCard} 
                   doubleAmount={this.props.doubleAmount} 
                   message={this.props.message}  
                   dealBtn={this.props.dealBtn} 
                   betButtonValue = {this.props.betButtonValue}
                   cleareBtn={this.props.cleareBtn} 
                   childRef={ref => (this.BottomPanel= ref)}
                  
                  ></BottomPanel>
                  <div className="fd d-flex">
                    <label className="playerCheck">
                       <input type="checkbox" id="playerDealer" value="true" /> 
                       {this.props.language.PlayerFirst}
                    </label>
                  </div>
                </div>
              </div>
              {/************************** DEALER & PLAYER TABLE END  ******************/}
              
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStatesToProps=(state)=>{
  return{
    language:state.languageObjs.languageObj

  }
}
  export default connect(mapStatesToProps)(ChipsDealerPlayer)