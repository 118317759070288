
import React from "react";
import LeftButtonPanel from "./LeftButtonPanel";
import RightButtonPanel from "./RightButtonPanel";

export default class MiddleButtonPanel extends React.Component {
    LeftButtonPanel = React.createRef();
    RightButtonPanel = React.createRef();

    componentDidMount() {
        const { childRef } = this.props
        childRef(this);
    }

    showwinswhentake = () => {
        this.LeftButtonPanel.showswins();
    }

    betPanelHandler = (id) => {
        const { betPanelHandler } = this.props;
        betPanelHandler(id)
    }

    betplace = (id, amount) => {
        this.LeftButtonPanel.betplace(id, amount);
    }

    bingoResultAnimation = (BngResult, winamt) => {

        const { bingoResultHandler } = this.props;
        //bingoResultHandler(BngResult);
        console.log("BngResult")
        console.log(BngResult)
        this.RightButtonPanel.showResultInBetHouses(BngResult);
        this.LeftButtonPanel.bingoRstlsshowbetpannel(BngResult)
    }
    updateBetAmt = (id, val) => {
        // alert("updated")
        this.LeftButtonPanel.updateBetAmts(id, val);

    }
    clearevntsbngo = () => {
        this.LeftButtonPanel.clearsettimeevnts();
        this.RightButtonPanel.clearintervalsright();

    }
    disablekeyboard = () => {
        this.LeftButtonPanel.disablekeys();

    }
    disablekeyboradbets = () => {
        this.LeftButtonPanel.disablebetskeyboard();
    }

    clearcolrbethouses = () => {
        this.LeftButtonPanel.clearcolrbethousesleft();
    }

    clearbethouses = () => {
       
        this.LeftButtonPanel.clearwinsinhouse();

       
    }

    render() {
        return (
            <React.Fragment>
                <div className="bet-panel-container">

                    <div className="leftPanel row">
                        <LeftButtonPanel
                            childRef={ref => (this.LeftButtonPanel = ref)}
                            action={this.betPanelHandler}
                            ref={this.LeftButtonPanel}
                            user={this.props.user}
                            Total_Bet_Amount={this.props.Total_Bet_Amount}
                            betButtonValue={this.props.betButtonValue}
                            network={this.props.network}
                            playerId={this.props.playerId}
                            gameId={this.props.gameId}
                            handId={this.props.handId}
                            bngogamestate={this.props.bngogamestate} 
                            infoText={this.props.infoText}
                            bnbonusmnt_bingo={this.props.bnbonusmnt_bingo}
                            actiondup={this.props.rightButtonPanelHandler} 
                            showDupbngo={this.props.showDupbngo}
                            isMobile={this.props.isMobile}
                            minbetamnt={this.props.minbetamnt}

                        />
                    </div>
                    <div className="rightPanel row">
                        <RightButtonPanel
                            childRef={ref => (this.RightButtonPanel = ref)}
                            action={this.props.rightButtonPanelHandler}
                            ref={this.RightButtonPanel}
                            resultAction={this.bingoResultHandler}
                            betButtonValue={this.props.betButtonValue}
                            betButtonText={this.props.betButtonText}
                            disClearBtn={this.props.disClearBtn}
                            disdouble={this.props.disdoubleBt}
                            disbetBt={this.props.disbetBt} 
                            handId={this.props.handId}
                            network={this.props.network}
                            playerId={this.props.playerId}
                            gameId={this.props.gameId}
                            isMobile={this.props.isMobile}
                            // enablebngodupright={this.enablebngodup.bind(this)}
                            bnbonusmnt_bingo={this.props.bnbonusmnt_bingo}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


