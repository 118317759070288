import React from "react";
import WheelInner from "./funTargetWheel";
// import WheelBorder from "./funTargetWheelBorder";
import Jackpot from './jackpot';

export default class Wheel extends React.Component{
    constructor(props){
        super(props)
        this.ftWheelRef = React.createRef();
        this.jackpotXimg = React.createRef();
        this.state = {
            targetResult:props.targetResult
        }

        this.inWid = window.innerWidth
        this.inHt = window.innerHeight 
        
            this.wheelWidth = 850;
            this.wheelHeight = 850;

            this.wheelY = this.wheelWidth * 0.46;
            this.wheelX = this.wheelWidth * 0.5;

            this.fontSize = 100;
            this.jackWidth = 400;
            this.jackTop = 180;
            this.wheelBoxHeight = 660;
            this.y = 40;
         
 
    }
    componentStateHandler(){

    }
    stopWheelSound =() =>{
       // this.ftWheelRef.current.stopWheel();
    }
	spinTheWheel = (s) => {
        console.log("FunTarget Result Number: "+s);
        this.ftWheelRef.current.spinTheWheel(s);
        // this.jackpotXimg.current.startJockpot()
    }
    wheelHandler(position){
        console.log(position)
        this.props.action(position);
    }
    sendJock2(jackNum){  
      
        this.jackpotXimg.current.sendJock2(jackNum);        
    }
    sendJock(){  
        this.jackpotXimg.current.combine();              
    }
    render(){
        return(
            <div className="wheelBox_1" style={{height:`${this.wheelBoxHeight}px`}}> 
                <Jackpot jackTop={this.jackTop} targetResult={this.props.targetResult} ref={this.jackpotXimg} width={this.jackWidth} height={this.jackWidth}/>
                <WheelInner wheelX={this.wheelX} wheelY={this.wheelY} yVal={this.y} fontSize={this.fontSize} onFocus={this.props.onFocus} gameState={this.props.gameState} targetResult={this.props.targetResult} ref={this.ftWheelRef} height={this.wheelHeight} width={this.wheelWidth} action={this.wheelHandler.bind(this)} isMobile={this.props.isMobile}></WheelInner>




                
                {/* <WheelBorder trgtLoded={this.props.trgtLoded} width={this.wheelWidth} height={this.wheelHeight} x={0} y={0}></WheelBorder> */}
            </div>
        )
    }
}
