import React from "react";
import "../../../../css/games/funMatka/funMatka.css";
import "./UI/funMatka.css";
import Close from "./UI/close";
import Title from "./UI/title";
import Wheel from "./UI/wheel";
import RightButtonPanel from "./UI/matkaRight";
import MatkaLeft from "./UI/matkaLeft";
import matkanums from "./UI/matkaNumbers";
import fmnt_Bg from "../../../../../assets/games/funMatka/matkaBg.jpg";
import clearSound from "../../../../../assets/sounds/clear.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
import takeSound from "../../../../../assets/sounds/take.mp3";
import winSound from "../../../../../assets/sounds/win.mp3";
import duploseSound from "../../../../../assets/sounds/duplose.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import betoncardSound from "../../../../../assets/sounds/betoncard.mp3";
import wheelSound from "../../../../../assets/sounds/matkareel.mp3";

import {connect} from "react-redux"


class FunMatka extends React.Component {
    constructor(props) {
        super(props);
        const{childRef} = this.props 
        childRef(this)
        this.fmntWheelRef = React.createRef();
        this.RightButtonPanel = React.createRef();
        this.MatkaLeft = React.createRef();
        this.wheelDimension = window.innerHeight * 0.6;
        this.state = {
        balance: this.props.balance,
        gameId: this.props.gameId,
        playerId: this.props.playerId,
        stop: "NA",
        betString: [],
        previousBetArray: [],
        finalPreviousBetArray: [],
        betAmount: 1,
        winBet: 0,
        totalBet: 0,
        betstr: "",
        bets: "",
        betButtonText: this.props.language.Prev,
        betButtonValue:0,
        infoText:this.props.language.Play_Njoy ,
        btnActive: false,
        disDoubleBtn: 1,
        disBetBtn: 0,
        disClearBtn: 1,
        eurpRlt: true,
        };
        this.topRowWinNumber = "";
        this.resultNum = 0;
        this.isloadImg = false;
        this.isLoad = false;
        this.updatePlayerBalnce = false;
        this.is_fm_GameOn = true;
        this.newLimits = [];
        this.ak_limits= [];
        this.sp_limits= [];
        this.dp_limits= [];
        this.tr_limits= [];
        this.winAmount = 0;
        this.loadBg(); 
 
    }
    componentDidMount() {
 
        console.log(this.props.gameState);       
        if(this.props.gameState && this.props.gameState.response){           
            if(this.props.gameState.response.resultNum != null){
                console.log(this.props.gameState.response.resultNum);
                this.fmntWheelRef.current.updateResultNumber(this.props.gameState.response.resultNum); 
            }
        }
        this.clearSound = new Audio(clearSound);
        this.exitSound = new Audio(exitSound);
        this.betSound = new Audio(betSound);
        this.takeSound = new Audio(takeSound);
        this.winSound = new Audio(winSound);
        this.duploseSound = new Audio(duploseSound);
        this.betoncardSound = new Audio(betoncardSound);

        this.state.betString = [];
        for (let l = 0; l < 230; l++) {
            this.state.betString.push({
                id: l,
                amount: 0,
            });
        } 
        let bet_take = document.getElementById("bet_take");
        if (bet_take && bet_take.classList.contains("active")) {
            bet_take.classList.remove("active");
        }
        document.getElementById("matkaCover").style.display = "none";

        this.setState({
            betButtonText: this.props.language.Prev,
            betButtonValue:0,
            disDoubleBtn: 1,
            disBetBtn: 0,
            disClearBtn: 1,
        });

        console.log(this.props.gameState)
        if(this.props.gameState['betRequest'] == undefined){
            console.log("Disable Previous Button");
            this.setState({disBetBtn:1});
        }

        if(this.props.gameState.history && this.props.gameState.history.length == 0){
            this.setState({disBetBtn: 1});
        }

        if(this.props.fm_chipValues){
            let newChips = this.props.fm_chipValues.split(",");
            try{
                console.log(newChips[0]);  
                let initChipVal = Number(newChips[0]);
                let lblTxt;
                if(initChipVal >= 1000 && initChipVal <= 999999){
                    lblTxt = "K"
                }else if(initChipVal >= 1000000 && initChipVal <= 999999999){
                    lblTxt = "M"                        
                }else if(initChipVal > 999999999){            
                    lblTxt = "B"
                }                 
                this.state.betAmount = initChipVal;
            }catch(err){
                console.log("Fun Matka Chip values error: "+err.message);
            }
            
            // this.state.selectedChipLbl = initChipVal+""+lblTxt;
          }

        if(this.props.fm_innerLimits){
            this.newLimits = this.props.fm_innerLimits.split("|");
            console.log(this.newLimits);
            for(let t=0; t<this.newLimits.length; t++){
              this.newLimits[t] = this.newLimits[t].split("-");
              console.log(" *******   "+this.newLimits[t]);
              switch(t){
                case 0:
                    this.ak_limits[0] = Number(this.newLimits[0][0]);
                    this.ak_limits[1] = Number(this.newLimits[0][1]);
                break;
                case 1:
                    this.sp_limits[0] = Number(this.newLimits[1][0]);
                    this.sp_limits[1] = Number(this.newLimits[1][1]);
                break;
                case 2:
                    this.dp_limits[0] = Number(this.newLimits[2][0]);
                    this.dp_limits[1] = Number(this.newLimits[2][1]);
                break;
                case 3:
                    this.tr_limits[0] = Number(this.newLimits[3][0]);
                    this.tr_limits[1] = Number(this.newLimits[3][1]);
                break;
              }
            }
            console.log(this.ak_limits);
            console.log(this.sp_limits);
            console.log(this.dp_limits);
            console.log(this.tr_limits);
          }

        document.addEventListener("keyup", this.fmt_handleKeyboardEvent);    
        document.addEventListener("keydown", this.fmt_bets_handleKeyboardEvent);     
         
    }
    componentWillUnmount() {
        document.removeEventListener("keyup", this.fmt_handleKeyboardEvent);
        document.removeEventListener("keydown", this.fmt_bets_handleKeyboardEvent);
    }

    fmt_bets_handleKeyboardEvent= (evt) => {
        console.log(this.state.betButtonText);
        console.log(this.state.disBetBtn);
        console.log(this.props.gameState.betRequest);
        // if(this.state.betButtonText != this.props.language.Take && this.state.disBetBtn == 0){
        if(this.state.betButtonValue != 2 && (this.props.gameState.betRequest == undefined || this.state.disBetBtn == 0)){
        // if(this.state.betButtonValue != 1  ){

            console.log(evt.keyCode)
          if(evt.keyCode > 48 && evt.keyCode <= 57){
            console.log(evt.key);      
            if(evt.key >6){
                this.betPanelHandler(evt.key-1, "leftClick");                
            }else{
                this.betPanelHandler(evt.key-1, "rightClick");
            }  
          }
          if(evt.keyCode > 96 && evt.keyCode <= 105 ){
            console.log(evt.key);      
            if(evt.key <6){
                this.betPanelHandler(evt.key-1, "leftClick");                
            }else{
                this.betPanelHandler(evt.key-1, "rightClick");
            }     
          }
          if(evt.keyCode == 48 || evt.keyCode == 96){
            this.betPanelHandler(9, "rightClick");

          }
          if(evt.keyCode == 65){
            this.betPanelHandler(231, "rightClick");
          }
          if(evt.keyCode == 68){
            this.betPanelHandler(233, "rightClick");
          }
          if(evt.keyCode == 83){
            this.betPanelHandler(232, "rightClick");
          }
          if(evt.keyCode == 84){
            this.betPanelHandler(234, "rightClick");
          }
        }
    }


    fmt_handleKeyboardEvent = (evt) => {
        console.log(evt.code +"    "+  this.state.betButtonText+"     "+this.state.disBetBtn);
        switch(evt.code){
          case "Enter":
          case "NumpadEnter":
            // if (this.state.betButtonText == this.props.language.Prev && this.state.disBetBtn == 0) {
                // alert("enter")
                console.log(this.state.betButtonValue)
                console.log(this.state.disBetBtn)
            if (this.state.betButtonValue == 0 && this.state.disBetBtn == 0) {
              this.rightButtonPanelHandler("PreviousBet");
            // }else if (this.state.betButtonText == this.props.language.Spin && this.state.disBetBtn == 0) {
            }else if (this.state.betButtonValue == 1 && this.state.disBetBtn == 0) {

                this.rightButtonPanelHandler("SPIN");
                this.setState({btnActive: false, disBetBtn: 1});
            }
            break;        
          case "Escape":
            //this.rightButtonPanelHandler("exit");
            break;

          case "KeyC":      
            // if(this.state.betButtonText == this.props.language.Spin && this.state.disClearBtn == 0){
            if(this.state.betButtonValue == 1 && this.state.disClearBtn == 0){

              this.rightButtonPanelHandler("clear");
            }
            break;      
          default:
              break;
        }         
    }

    resize = () => { 
        let w = window.innerWidth;
        let h = window.innerHeight;  
        let marg_left = (h*this.dvWid/this.dvHt - w) / 2;
        let marg_top = (w*this.dvHt/this.dvWid - h)/2;
        let checkWid = h*this.dvWid/this.dvHt; 
    
        if(w > h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight && w > checkWid ) {  
          this.gameBox.style.transform =  "scale(" + h/this.dvHt + ")";
          this.gameBox.style.marginLeft =  marg_left*-1 +"px";
        } else if(w < h && w < this.gameBox.clientWidth && h < this.gameBox.clientHeight ) { 
          this.gameBox.style.transform =  "scale(" + w/this.dvWid + ")";
          this.gameBox.style.marginTop =  marg_top*-1 +"px";
        } else {
          this.gameBox.style.transformOrigin =  "left top";
          this.gameBox.style.transform =  "scale(" + w/this.dvWid + ")"; 
        }
    } 

    gameCloseHandler(game) {      
        this.exitSound = new Audio(exitSound);
        this.is_fm_GameOn = false;
        this.exitSound.play();
        this.fmntWheelRef.current.stopWheelSound();       
        this.clearSound.pause();
        this.betSound.pause();
        this.takeSound.pause();
        this.winSound.pause();
        this.duploseSound.pause();
        this.betoncardSound.pause();
        this.props.action(game);
    }

    wheelHandler(position) {
        if(this.is_fm_GameOn){
            console.log("Wheel Handler");
            this.setState({ stop: position })      
            this.declareWin();
                
     
            
            this.rightButtonPanelHandler("takeWinAmount");
            console.log("wheel position");
        
            let valId = document.getElementById('bet_take');
            if(valId && valId.classList.contains("active")){
                valId.classList.remove('active');
            }
            if(this.updatePlayerBalnce == false){
                this.updatePlayerBalnce = true;
            }
        }         
    }

    declareWin() {
        this.setState({ betstr: "", bets: "" });
       
        if (this.props.targetResult.winAmount > 0) {
            this.winAmount = (this.props.targetResult.winAmount);

            document.getElementById('winPop').style.display = 'block';
            this.winSound.play();
            this.getPlayerInfo();
          
            setTimeout(()=>{
            
                this.setState({
                    
                    balance: this.props.balance,
                    infoText: this.props.language.Play_Njoy,
                    winBet: 0,
                    btnActive: false
                });
                this.setState({totalBet:0})
                document.getElementById('winPop').style.display = 'none';
            },2000);
            document.getElementById("matkaCover").style.display = "block";
            console.log("declare win success");
        }else {       
            console.log(this.props.gameState.takeAmount)
            this.duploseSound.play();
            this.getPlayerInfo();
            this.winAmount = 0;
            setTimeout(()=>{
                this.setState({
                    betButtonText: this.props.language.Prev,
                    infoText: this.props.language.Play_Njoy,
                    balance: this.props.balance,
                    winBet: 0,
                    btnActive: false,
                    disDoubleBtn: 1, 
                    disClearBtn: 1,
                    disBetBtn: 0,
                    totalBet:0,

                });                
                this.updateWinsArray(0);    
                if(document.getElementById("matkaCover")){
                    document.getElementById("matkaCover").style.display = "none";
                }
                console.log("declare win fail");
            },2000);
        }
        this.isReady = false;
        this.resultNum = [this.props.targetResult.resultNum];
        console.log(this.resultNum[0]);

        console.log("length: "+this.resultNum.length);
        for (let a = 0; a <this.resultNum.length; a++) {
            for (let k = 0; k < 230; k++) {
                let betnumber = document.getElementById(k).getAttribute("betnumber");
                if (Number(betnumber) == this.resultNum[a]) {
                    document.getElementById(k).classList.add('activeIn');
                }
            }
        }
        let rowNumber = 0;
        let f = Number(String(this.resultNum).charAt(0));
        let s = Number(String(this.resultNum).charAt(1));
        let t = Number(String(this.resultNum).charAt(2));
        rowNumber = String(f + s + t);
        console.log(this.resultNum);
        if(this.resultNum == 1000){
            this.topRowWinNumber = 10;
        }else{
            this.topRowWinNumber = rowNumber.charAt(rowNumber.length - 1);
        }

        console.log("topRowWinNumber " + this.topRowWinNumber);
        if(this.topRowWinNumber == 0){
            document.getElementById(9).classList.add('activeIn');
        }else{
            document.getElementById(Number(this.topRowWinNumber) - 1).classList.add('activeIn');
        }
        setTimeout(this.removeActive, 3000);
        this.props.gamstateHandle(this.state.gameId);    
    }

    rightButtonPanelHandler(action) {
        console.log("Right Handler: action")
        console.log(action);
        switch (action) {
            case "SPIN":
                this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                if(this.state.totalBet != 0 && this.state.totalBet < this.props.minBetAmt){                    
                    this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
          
                  }else{ 
                    this.fmntWheelRef.current.updateResultNumber("");
                    document.getElementById("matkaCover").style.display = "block";
                    // this.RightButtonPanel.current.removeScale();
                    this.RightButtonPanel.removeScale();                
                    this.wheelSound = new Audio(wheelSound);
                    this.takeSound = new Audio(takeSound);
                    this.winSound = new Audio(winSound);
                    this.duploseSound = new Audio(duploseSound);
    
                    this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                    console.log(this.state.betAmount);
                    console.log(this.state.totalBet)
                    let validateBet=[];
                    let innerMin_Limit = 1;
                    this.setState({                       
                        betstr: [],
                        bets: [],
                        btnActive: false
                    })
                    if (this.state.totalBet !== 0) {
                        if (this.props.balance >= this.state.totalBet) {
                            
                            for (let i = 0; i < this.state.betString.length; i++) {
                                if( this.state.betString[i].amount > 0){
                                    if((i >= 0 && i <=9) || i == 231){
                                        console.log("Row 1");
                                        innerMin_Limit = Number(this.ak_limits[0]);                                        
                                    }else if((i >= 10 && i <=129) || i == 232){
                                        console.log("Row 2");             
                                        innerMin_Limit = Number(this.sp_limits[0]);                       
                                    }else if((i >= 130 && i <=219) || i == 233){
                                        console.log("Row 3");          
                                        innerMin_Limit = Number(this.dp_limits[0]);                          
                                    }else if((i >= 220 && i <=229) || i == 234){
                                        console.log("Row 4");          
                                        innerMin_Limit = Number(this.tr_limits[0]);                          
                                    }

                                    if(this.state.betString[i].amount >= Number(innerMin_Limit)){
                                        validateBet[i] = true;
                                    }else{
                                        validateBet[i] = false;
                                    }
                                }else{
                                    validateBet[i] = true;
                                }

                                if (this.state.betString[i].id == 10 || this.state.betString[i].id == 130 || this.state.betString[i].id == 220) {
                                    this.state.bets = this.state.bets.slice(0, -1);
                                    this.state.bets += "," + Number(this.state.betString[i].amount.toFixed(2)) + "|";
                                } else {
                                    this.state.bets += Number(this.state.betString[i].amount.toFixed(2)) + "|";
                                }
                            }
                            this.state.betstr = this.state.bets.slice(0, -1);
                            console.log(validateBet);
                            console.log(this.state.betstr);

                            let sendBet = true;;
                            for(let u=0; u<validateBet.length;u++){
                                if(validateBet[u] ==  false){
                                    sendBet = false;                             
                                    break;
                                }
                            }
                            if(sendBet){
                                this.setState({ disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 1})
                                this.placebet();
                                return this.state.betstr;
                            }else{
                              
                                document.getElementById("matkaCover").style.display = "none";
                                const minIntLmt = this.changeAmtlabl(innerMin_Limit)
                                this.setState({ infoText: this.props.language.InerLmt + minIntLmt });     
                            }
                        }
                    }
                  }
            break;
            case "exit":
                this.gameCloseHandler("target");
                const body = {
                sessionId: sessionStorage.getItem('sessionId'),
                CN: "EXIT_GAME",
                object: {
                    gameId: this.props.gameId,
                    playerId: Number(this.props.playerId),
                },
                };
                this.props.network.sendof(body);
                break;
            case "takeWinAmount":
               
                console.log("take amount action");
                //this.takeSound.play();
                 this.takeAmountResult();
            break;
            case "PreviousBet":
                this.betSound.play();
                console.log("Previous Bet action");
                this.PreviousBetMethod();
            break;
            case "double":
                this.betSound = new Audio(betSound);
                this.betSound.play();
                this.updateWinsArray(2);                
            break;
            case "clear":                
                this.clearSound.play();
                this.updateWinsArray(0);
                this.RightButtonPanel.removeScale();
                this.MatkaLeft.removeScale();
                this.setState({ betButtonText:this.props.language.Prev,betButtonValue:0, disDoubleBtn: 1, disClearBtn: 1, disBetBtn: 0, btnActive : false  });
                if(this.props.gameState.betRequest == undefined){
                    this.setState({disBetBtn: 1});
                }
            break;
            case "disableBtns":
                // this.RightButtonPanel.current.mouseUpStopCards();
                // this.fmTmrLeft.current.mouseUpStopCards();
                this.RightButtonPanel.mouseUpStopCards();
                this.MatkaLeft.mouseUpStopCards();

                this.setState({ disDoubleBtn: 1, disBetBtn: 1, disClearBtn: 1, winCover:true, });
                // document.getElementById("funMatkaCover").style.display = "block";
            break;
            case "PlayTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.play();
                }
            break;
            case "StopTickSound":
                if(document.getElementById('iospopup').style.display == "none"){
                    this.tickSound.pause();
                }
            break;
            default:
            break;
        }
    }
    PreviousBetMethod() {
        console.log("Show Previous Bet");
        console.log(this.props.gameState.betRequest.betAmount);
        console.log(this.props.balance);
        if(this.props.gameState.betRequest != undefined){
            console.log(this.props.gameState.betRequest.betAmount);

            if(this.props.gameState.betRequest.betAmount <= this.props.balance){ 
                if(this.props.gameState.betRequest.betAmount > this.props.maxBetAmt){
                    this.setState({ infoText:this.props.language.PreBet_MaxLmt });
                }else{
                    this.updateWinsArray(0);
                    let preBet = this.props.gameState['betRequest']['betString'].replace(/,/g, '|');
                    this.state.previousBetArray.push({
                        amount: preBet.split("|"),
                    });
                    this.setState({previousBetArray: this.state.previousBetArray, btnActive: true});
                    if(this.state.previousBetArray){
                        this.toCreatePreviousBetArray();
                        this.isReady = true;
                    }
                    //console.log(this.state.previousBetArray[0].amount[0]);
                    if(this.props.gameState.handId){
                        this.handId = this.props.gameState.handId;
                    }
                }
            }else{
                this.setState({ infoText: this.props.language.Bet_Amt_MaxBal});
            }  
        }      
    }
    updateMessage(){
        console.log("--------- "+this.props.infoText);
        console.log(this.props.language);        
        this.setState({infoText: this.props.infoText});

        if(this.props.infoText == "Bet Rejected!! Time Over"){
            this.setState({infoText: this.props.language.BetRejTimeOver});
            console.log("______________________")
            console.log(this.state.infoText);
        }
        
        console.log("error_code: "+this.props.error_code);
        if(this.props.error_code== "game.bet.invalid"){
            this.setState({
                betButtonText:this.props.language.Prev,
                betButtonValue:0,
                btnActive : false,
                disBetBtn : 0,
                winCover:false,
            });
            this.updateWinsArray(0);
            // document.getElementById('funMatkaCover').style.display = 'none';
        }
    }

    toCreatePreviousBetArray() {
        for (let i = 0; i < this.state.previousBetArray[0].amount.length; i++) {
            this.state.finalPreviousBetArray.push({
                id:i,
                amount: Number(this.state.previousBetArray[0].amount[i])
            });
        }
        this.setState({ finalPreviousBetArray: this.state.finalPreviousBetArray });
        console.log(this.state.finalPreviousBetArray);
        if(this.state.finalPreviousBetArray){
            this.toGenerteBetString();
        }
    }
    toGenerteBetString() {
        console.log(this.state.finalPreviousBetArray);
        console.log(this.state.betString);
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == this.state.finalPreviousBetArray[i].id) {
                this.state.betString[i].amount += this.state.finalPreviousBetArray[i].amount;
                if(this.state.betString[i].amount != 0){
                    let preBetValue = this.changeAmtlabl(this.state.betString[i].amount);
                    let id_span = document.getElementById(i).getElementsByTagName('span');
                    // id_span[0].innerText = this.state.betString[i].amount;
                    id_span[0].innerText = preBetValue;
                }
            }
        }
        this.setState({
            betString: this.state.betString,
            betButtonText:this.props.language.Spin,
            betButtonValue:1,
            previousBetArray: [],
            finalPreviousBetArray: []
        });
        console.log(this.state.betString);

        this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
        console.log("___________________ ");
        console.log(this.state.totalBet);
        if(this.state.totalBet > 0){
            this.setState({disClearBtn: 0, disDoubleBtn: 0})
        }
    }

    betPanelHandler(no, val) {
        console.log("Matka Timer Click on Bet Pannel");
        console.log(no+"   "+val);
        if(val == 'rightClick'){
            let secList_left = document.getElementsByClassName('secList_left');
            console.log(secList_left)
            for (let j = 0; j < secList_left.length; j++) {
                secList_left[j].classList.remove('matkaScale')
            }
        } else {
            let secList = document.getElementsByClassName('secList');
            for (let i = 0; i < secList.length; i++) {
                secList[i].classList.remove('matkaScale')
            }
        }
        console.log(no);
        this.betoncardSound1 = new Audio(betoncardSound)
        this.betoncardSound1.play();

        let betValue;
        switch(no){
            case 231:
            case 234:
                betValue = (Number(this.state.betAmount) * 10 )
                break;
            case 232:
                betValue = (Number(this.state.betAmount) * 120 )
                break;
            case 233:
                betValue = (Number(this.state.betAmount) * 90 )
                break;
            default:
                betValue = Number(this.state.betAmount)
                break;
        }

        console.log(Number(this.state.betString.reduce((prev, cur) => prev + cur.amount,0)) + betValue);

        this.isReady = true;
        let totalBetVal = (Number(this.state.betString.reduce((prev, cur) => prev + cur.amount, 0)) + Number(betValue));
        console.log("totalBetVal:  "+totalBetVal);
        if(totalBetVal == 0 && this.state.betAmount == 0){
            this.setState({ infoText: this.props.language.MinBetAmt +" "+this.props.minBetLbl});
            setTimeout(()=>{
                this.setState({ infoText:  this.props.language.Play_Njoy });
            },1500)
        }else if(totalBetVal > this.props.maxBetAmt){            
            this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
        }else{
            console.log("---->  "+this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0));
            console.log("Total Bet Amt: "+totalBetVal);
            console.log(this.state.betAmount);
            console.log("--->  "+this.props.balance);
            this.setState({betButtonText:this.props.language.Spin, betButtonValue:1});
            if (totalBetVal <= this.props.balance) {
                // this.setState({ infoText: this.props.language.Play_Njoy });   
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});             
                this.setState({ disBetBtn:0, betButtonText:this.props.language.Spin, betBtttonVal: 1});
                if (this.state.betAmount !== 0) {
                    const x = no;
                    switch (true) {
                        case (x < 230):
                            if(x>=10 && x<130){
                                if(Number(this.state.betAmount) <= Number(this.sp_limits[1])){                                                                  
                                    if((this.state.betString[no].amount+ Number(this.state.betAmount)) <= Number(this.sp_limits[1])){
                                        this.showBetValue(no);
                                    }else{
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
                                        console.log("Inner Limit exceeded.")
                                    }
                                }else{
                                    // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
                                    console.log("Inner Limit exceeded.")
                                }
                            }else if(x>129 && x<231){
                                console.log("############  "+x);
                                //if(this.state.betAmount <= Number(this.tr_limits[1])){
                                if(x>=220 && x<=229){
                                    if(this.state.betAmount <= Number(this.tr_limits[1])){
                                        if((this.state.betString[no].amount+Number(this.state.betAmount)) <= Number(this.tr_limits[1])){
                                            this.showBetValue(no);
                                        } else{ 
                                            // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.tr_limits[1]))});
                                        }
                                    }
                                }else{
                                    if(this.state.betAmount <= Number(this.dp_limits[1])){
                                        if((this.state.betString[no].amount+Number(this.state.betAmount)) <= Number(this.dp_limits[1])){
                                            this.showBetValue(no);
                                        }else{ 
                                            // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.dp_limits[1]))});
                                        }
                                    }
                                }                               
                                //}
                            }else {                           
                                if(this.state.betAmount <= Number(this.ak_limits[1])){
                                    if((this.state.betString[no].amount+Number(this.state.betAmount)) <= Number(this.ak_limits[1])){
                                        this.showBetValue(no);
                                    }else{ 
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
                                    }
                                }else{
                              
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
                                }
                            }
                        break;
                        case (x == 231):
                            if(Number(this.state.betAmount) <= Number(this.ak_limits[1]) ){
                                let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topLeft');
                                let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'topRight');
                                let data = [...arr1, ...arr2];
                                let checkBet = 0;
                                console.log("--------------231-------------------------------");
                                console.log("data.length: "+data.length)
                                let checkAmt_231 = 0;
                                checkAmt_231 = data.length * Number(this.state.betAmount);
                                checkBet = checkAmt_231 + this.state.totalBet;
                                if (checkBet <= this.props.balance) {
                                    for (let aa = 0; aa < data.length; aa++) {
                                        if((this.state.betString[data[aa].betId].amount+ Number(this.state.betAmount)) <= Number(this.ak_limits[1])){
                                            this.state.betString[data[aa].betId].amount += Number(this.state.betAmount);
                                            let id_span = document.getElementById([data[aa].betId]).getElementsByTagName("span");
                                            let ak_betText = this.changeAmtlabl(this.state.betString[data[aa].betId].amount);
                                            // id_span[0].innerText = this.state.betString[data[aa].betId].amount;
                                            id_span[0].innerText = ak_betText;
                                        }else{
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
                                        }
                                    }
                                    this.state.totalBet = checkBet;
                                } else {
                                    this.setState({ infoText:this.props.language.Amount_Reduce});
                                }
                                this.setState({ betString: this.state.betString });
                                // this.RightButtonPanel.current.removeScale();
                                this.RightButtonPanel.removeScale();

                                console.log(this.state.betString);
                            }
                        break;
                        case (x == 232):
                            if(Number(this.state.betAmount) <= Number(this.sp_limits[1])){
                                let data = [...matkanums[0].sectionOne, ...matkanums[2].sectionThree];
                                let checkAmt_232 = 0;
                                let checkBet_232 = 0;
                                checkAmt_232 = data.length * Number(this.state.betAmount);
                                checkBet_232 = checkAmt_232 + this.state.totalBet;
                                console.log("SP Total Bet amount: "+checkBet_232);
                                if (checkBet_232 <= this.props.balance) {
                                    for (let ab = 0; ab < data.length; ab++) {
                                        if((this.state.betString[data[ab].betId].amount+ Number(this.state.betAmount)) <= Number(this.sp_limits[1])){
                                            this.state.betString[data[ab].betId].amount += Number(this.state.betAmount);
                                            let id_span = document.getElementById(data[ab].betId).getElementsByTagName("span");
                                            let sp_betText = this.changeAmtlabl(this.state.betString[data[ab].betId].amount);
                                            id_span[0].innerText = sp_betText ;
                                        }else{
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))});
                                        }
                                    }
                                } else {
                                    this.setState({ infoText: this.props.language.Amount_Reduce });
                                }
                                this.setState({ betString: this.state.betString });
                                // this.RightButtonPanel.current.removeScale();
                                this.RightButtonPanel.removeScale();
                                console.log(this.state.betString);
                            }
                        break;
                            case (x == 233):
                            if(Number(this.state.betAmount) <= Number(this.tr_limits[1])){
                                let data = [...matkanums[1].sectionTwo, ...matkanums[3].sectionFour];
                                console.log("Data Length: "+data.length);
                                let checkAmt_233 = 0;
                                let checkBet_233 = 0;
                                checkAmt_233 = data.length * Number(this.state.betAmount);
                                checkBet_233 = checkAmt_233 + this.state.totalBet;
                                console.log("totalBet  "+this.state.totalBet );
                                if (checkBet_233 <= this.props.balance) {
                                    for (let ac = 0; ac < data.length; ac++) {
                                        if((this.state.betString[data[ac].betId].amount+ Number(this.state.betAmount)) <= Number(this.dp_limits[1])){
                                            this.state.betString[data[ac].betId].amount += Number(this.state.betAmount);
                                            let id_span = document.getElementById(data[ac].betId).getElementsByTagName("span");
                                            let dp_betText = this.changeAmtlabl(this.state.betString[data[ac].betId].amount);
                                            id_span[0].innerText = dp_betText;
                                        }else{
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.dp_limits[1]))});
                                        }
                                    }
                                }  else {
                                    this.setState({ infoText: this.props.language.Amount_Reduce });
                                }
                                this.setState({ betString: this.state.betString });
                                // this.RightButtonPanel.current.removeScale();
                                this.RightButtonPanel.removeScale();

                                console.log(this.state.betString);
                            }
                        break;
                        case (x == 234):
                            if(Number(this.state.betAmount) <= Number(this.tr_limits[1]) ){
                                let arr1 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmLeft');
                                let arr2 = matkanums[4].top_bottom.filter(data => data.tpBtm === 'btmRight');
                                let data = [...arr1, ...arr2]; console.log(data);
                                let checkAmt_234 = 0;
                                let checkBet_234 = 0;
                                checkAmt_234 = data.length * Number(this.state.betAmount);

                                checkBet_234 = checkAmt_234 + this.state.totalBet;
                                if (checkBet_234 <= this.props.balance) {
                                    for (let ad = 0; ad < data.length; ad++) {
                                        if((this.state.betString[data[ad].betId].amount+ Number(this.state.betAmount)) <= Number(this.tr_limits[1])){
                                            this.state.betString[data[ad].betId].amount += Number(this.state.betAmount);
                                            let id_span = document.getElementById(data[ad].betId).getElementsByTagName("span");
                                            let tr_betText = this.changeAmtlabl(this.state.betString[data[ad].betId].amount);
                                            id_span[0].innerText = tr_betText;
                                        }else{
                                            this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.tr_limits[1]))});
                                        }
                                    }
                                } else {
                                    this.setState({ infoText: this.props.language.Amount_Reduce });
                                }
                                // this.RightButtonPanel.current.removeScale();
                                this.RightButtonPanel.removeScale();

                                this.setState({ betString: this.state.betString });
                                // console.log(this.state.betString);
                            }
                        break;
                        default:
                        // alert("none");
                        break;
                    }
                    this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                } else {
                    if (no == 231 || no == 232 || no == 233 || no == 234) {
                        return;
                    }
                    
                    console.log(" --- no --- "+no);
                    console.log(" --- no --- "+this.state.betString);
                    console.log(" --- no --- "+this.state.betString[no]);
                    this.state.betString[no].amount = 0;
                    let id_span = document.getElementById(no).getElementsByTagName('span');
                    id_span[0].innerText = ''
                    this.state.totalBet = this.state.betString.reduce((prev, cur) => prev + Number(cur.amount), 0);
                }
            } else {
                this.setState({ infoText: this.props.language.LowBalance  });
                //alert('amount reduces to your balance');
            }
            
            if(this.state.totalBet > 0){
                this.setState({disClearBtn: 0, disDoubleBtn: 0, disBetBtn:0, btnActive: true});
            }else{
                this.setState({disClearBtn: 1, disDoubleBtn: 1, disBetBtn:0});
                console.log(this.props.gameState);
                console.log(this.props.gameState.betRequest);
            
                if(this.props.gameState.betRequest){
                    this.setState({ betButtonText:this.props.language.Prev, betButtonValue:0, btnActive: false})
                }else{
                    this.setState({disBetBtn:1})
                }                
            }
        }
    }

    showBetValue(num){
        for (let i = 0; i < this.state.betString.length; i++) {
            if (this.state.betString[i].id == num) {
              
                let id_span = document.getElementById(num).getElementsByTagName("span");
                if(this.state.betAmount ==0){
                    this.state.betString[i].amount = 0;
                    id_span[0].innerText = "";
                }else{
                    this.state.betString[i].amount += Number(this.state.betAmount);
                    let betText = this.changeAmtlabl(this.state.betString[i].amount);
                    console.log(betText);
                    // id_span[0].innerText = this.state.betString[num].amount;
                    id_span[0].innerText = betText;
                }
                
            }
        }
        this.setState({ betString: this.state.betString });
    }

    // changeAmtlabl(chngAmt){
    //  console.log(chngAmt)
    //     let newAmt = chngAmt;
    //     if(newAmt >= 1000 && newAmt <=999999){
    //       newAmt = (newAmt / 1000).toFixed(2)+"K";      
    //     }else if(newAmt >= 1000000 && newAmt <=999999999){
    //       newAmt = (newAmt / 1000).toFixed(2)+"M";    
    //     }else if(newAmt >999999999){
    //       newAmt = (newAmt / 1000).toFixed(2)+"B";      
    //     }
    //     return Number(newAmt);
    // }
    changeAmtlabl(chngAmt){
        let newAmt = Number(chngAmt.toFixed(2));
        if(newAmt >= 1000 && newAmt <=999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"K";      
        }else if(newAmt >= 1000000 && newAmt <=999999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"M";    
        }else if(newAmt >999999999){
          newAmt = Number((newAmt / 1000).toFixed(2))+"B";      
        }
        return newAmt;
    }

    placebet() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "MATKA_NON_BET",
            object: {
                betString: this.state.betstr,
                playerId: Number(this.props.playerId),
                gameId: this.props.gameId,
            },
        };
        this.props.network.sendof(body);
        this.body={};
        let BetValue = Number(this.state.totalBet.toFixed(2));
        this.setState({infoText: this.props.language.BetAccepted  +BetValue }) ;
    }

    getPlayerInfo() {
     
        const body2 = {
            CN: "GET_PLAYER_INFO",
            object: {
            gameId: Number(this.state.gameId),
            playerId: Number(this.props.playerId),
            },
            sessionId: sessionStorage.getItem('sessionId'),
        }
        this.props.network.sendof(body2);
        this.body2 ={};
    }

    takeAmountResult() {
     
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "TAKE_AMOUNT",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            },
        };
        // this.props.network.sendof(body);
        this.body = {};

        this.setState({
            winBet: 0,
            btnActive: false,
            disDoubleBtn : 1,
            disClearBtn: 1,
            disBetBtn: 0
        });
setTimeout(()=>{
    if(this.topRowWinNumber == 0){
        document.getElementById(9).style.background = "";
    }else{
        document.getElementById(Number(this.topRowWinNumber) - 1).style.background = "";
    }

for (let k = 0; k < 230; k++) {
    var id_span = document.getElementById(k).getElementsByTagName("span");
    id_span[0].innerText = "";
}
this.updateWinsArray(0);    
this.setState({winCover:false})

},2500)
       

        // this.props.gamstateHandle(this.props.gameId)
      
        // this.rightButtonPanelHandler('clear');
      
        if(this.props.isPlayerBanned){
            this.gameCloseHandler("target");            
            this.props.player_banned();
        }

        console.log("gameUnderMaintanance "+this.props.gameUnderMaintanance);
        if(this.props.gameUnderMaintanance){
            this.gameCloseHandler("target");
            this.props.game_maintanance("Game under maintenance, reach to admin.");
        }

        console.log("deviceBlocked "+this.props.isdeviceBlocked);
        if(this.props.isdeviceBlocked){
            this.gameCloseHandler("target"); 
            this.props.device_blocked();
        }
    }
    updateWinsArray(num) {
        console.log(this.state.totalBet);       
        console.log(this.props.balance);
        if(num == 0){
            this.isReady = false; 
            console.log(this.state.betString.length);
            for(let k=0; k<this.state.betString.length; k++ ){
                let id_span_0 = document.getElementById(k).getElementsByTagName("span"); 
                id_span_0[0].innerText = "";   
                this.state.betString[k].amount = 0;
                this.state.totalBet = this.state.totalBet * num;                                     
            }
              this.setState({ infoText: this.props.language.Play_Njoy });
        }else{
            console.log(this.state.totalBet * num <= this.props.balance);
            if(this.state.totalBet * num >this.props.maxBetAmt){
                this.setState({infoText: this.props.language.MinBetAmt+" "+this.props.minBetLbl+" "+this.props.language.MaxBetAmt+" "+this.props.maxBetLbl});
            }else{
                if(this.state.totalBet * num <= this.props.balance){
                    console.log(this.state.totalBet)
                    this.isReady = true;
                    for(let b=0; b<this.state.betString.length; b++){      
                        let totBetVal = this.state.totalBet;              
                        if (this.state.betString[b].amount != 0) {
                            let id_span = document.getElementById(b).getElementsByTagName("span");                    
                            let betIndexVal = Number(this.state.betString[b].amount);                        
                            if(b>=10 && b<130){      
                                if(this.state.betString[b].amount*num <= Number(this.sp_limits[1])){   
                                    let totBetAmt = this.state.betString[b].amount+totBetVal;
                                    console.log(totBetAmt);
                                    console.log(betIndexVal);
                                    // totBetAmt = this.changeAmtlabl(totBetAmt);
                                    // id_span[0].innerText = betIndexVal*num;
                                    id_span[0].innerText = this.changeAmtlabl(Number( betIndexVal*num));
                                    this.state.betString[b].amount *= num;
                                    console.log(this.state.betString[b].amount);
                                    this.setState({totalBet: totBetAmt});
                                    this.state.totalBet = totBetAmt;
                                    console.log("Total Bet Amount "+this.state.totalBet);
                                }else{
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.sp_limits[1]))}); 
                                    console.log("Inner Limit exceeded.")
                                }
                            }
                            else if(b>129 && b<231){                            
                                if(b>=220 && b<=229){
                                    if(this.state.betString[b].amount*num <= Number(this.tr_limits[1])){
                                        let totBetAmt2 = this.state.betString[b].amount+totBetVal;
                                        console.log(totBetAmt2);
                                        console.log(betIndexVal);
                                        // totBetAmt2 = this.changeAmtlabl(totBetAmt2);
                                        // id_span[0].innerText = betIndexVal*num;
                                        id_span[0].innerText = this.changeAmtlabl(Number( betIndexVal*num));
                                        this.state.betString[b].amount *= num;
                                        console.log(this.state.betString[b].amount);
                                        this.setState({totalBet: totBetAmt2});
                                        this.state.totalBet = totBetAmt2;
                                    }else{
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.tr_limits[1]))});
                                        console.log("Inner Limit exceeded.")
                                    }
                                }else{
                                    if(this.state.betString[b].amount*num <= Number(this.dp_limits[1])){
                                        let totBetAmt3 = this.state.betString[b].amount+totBetVal;                
                                        console.log(totBetAmt3);
                                        console.log(betIndexVal);
                                        // totBetAmt3 = this.changeAmtlabl(totBetAmt3);

                                        // id_span[0].innerText = betIndexVal*num;
                                        id_span[0].innerText = this.changeAmtlabl(Number( betIndexVal*num));
                                        this.state.betString[b].amount *= num;
                                        console.log(this.state.betString[b].amount);
                                        this.setState({totalBet: totBetAmt3});
                                        this.state.totalBet = totBetAmt3;
                                    }else{
                                        // this.setState({ infoText: this.props.language.InerLmt_Max }); 
                                        this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.dp_limits[1]))});
                                        console.log("Inner Limit exceeded.")
                                    }
                                }                           
                            }else{
                                if(betIndexVal*num <= Number(this.ak_limits[1])){
                                    let totBetAmt4 = this.state.betString[b].amount + totBetVal;                
                                    console.log(totBetAmt4);
                                    console.log(betIndexVal);
                                   // totBetAmt4 = this.changeAmtlabl(totBetAmt4);

                                    id_span[0].innerText = this.changeAmtlabl(Number( betIndexVal*num));
                                    this.state.betString[b].amount *= num;
                                    console.log(this.state.betString[b].amount);
                                    this.setState({totalBet: totBetAmt4});
                                    this.state.totalBet = totBetAmt4;
                                }else{
                                    this.setState({ infoText: this.props.language.MaxInrBetAmt+ this.changeAmtlabl(Number(this.ak_limits[1]))});
                                    console.log("Inner Limit exceeded.")
                                } 
                            }
                        }
                    }
                }else{
                    this.setState({ infoText: this.props.language.Amount_Reduce });  
                }

            }          
        }
    }

    betHandler(amount) {
        console.log(amount);
        this.takeSound.play();
        this.setState({ betAmount: amount });
    }

    funMatkaGetwheelresp() {
        this.fmntWheelRef.current.updateResultNumber("");
        console.log(this.props.targetResult);
        if (this.props.targetResult) {
            console.log(this.props.targetResult.resultNum);
            //setTimeout(()=>{
                if(this.props.targetResult.resultNum < 1000){
                    this.fmntWheelRef.current.spinTheWheel(this.props.targetResult.resultNum);
                }else{
                    this.fmntWheelRef.current.spinTheWheel("000");
                }
            //},200)
            this.wheelSound.play();
            // if(document.getElementById('iospopup').style.display == "none"){
            // }
        }
    }

    removeActive(){
        for (let k = 0; k < 230; k++) {
            // console.log("--- "+document.getElementById(k));
            if(document.getElementById(k) && document.getElementById(k).classList.contains("activeIn")){
                document.getElementById(k).classList.remove('activeIn');
            }
        }
        if(this.takeAmount == 0){     
            document.getElementById('funMatkaCover').style.display = 'none';
        }
    }

    loadBg(){
        this.LoadImg = new window.Image()
        this.LoadImg.src = fmnt_Bg; 
        this.LoadImg.addEventListener('load', this.eurpRltLoded)
    }
    
    eurpRltLoded = () => { 
        if(!this.isLoad){
        this.isLoad = true;
        } else {
            this.setState({
                eurpRlt:false
            }) 
        }
    }

    updatePlayerInfo(){   
        if(this.props.targetResult.winAmount > 0){
            // this.setState({totalBet: 0})
            setTimeout(()=> {
                document.getElementById('winPop').style.display = 'none';
                if(this.updatePlayerBalnce == true){
                    this.setState({
                        // disBetBtn: 0,
                        btnActive : false,
                        betButtonText: this.props.language.Prev, 
                        betButtonValue:0,
                    });
                
                    document.getElementById('matkaCover').style.display = 'none';
                }
            },2000)
        }else{
            if(this.updatePlayerBalnce == true){
                setTimeout(() => {
                    this.setState({
                        btnActive : false,
                        betButtonText: this.props.language.Prev, 
                        betButtonValue:0,
                        totalBet: 0,
                        balance: this.props.balance,
                    });
                   
                    document.getElementById('matkaCover').style.display = 'none';  
                },1500);
              
            }else{
             
                if(this.props.balance > 0){
                    document.getElementById('matkaCover').style.display = 'none';
                    // this.setState({disBetBtn: 0})
                }

                if(this.props.gameState.history && this.props.gameState.history.length == 0){
                    this.setState({disBetBtn: 1});
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="gameBox" id="gameBox">
                    <div className="gamePage FGAfunMatka nonTimerMatka fd ">
                        <div className="fd p_30">
                            <div className="col_matka_left">
                                <MatkaLeft
                                    childRef = {ref =>(this.MatkaLeft = ref)}                       
                                    ref={this.MatkaLeft}
                                    gameState={this.props.gameState}
                                    playerId={this.props.playerId}
                                    action={this.betPanelHandler.bind(this)}
                                    balance={this.props.balance}
                                    totalBet={this.state.totalBet}
                                    winning={this.state.winBet}
                                />
                            </div>
                            <div className="col_33">
                                <Title></Title>
                                <Wheel 
                                    onFocus={this.props.onFocus} 
                                    infoText={this.state.infoText} 
                                    gameState={this.props.gameState} 
                                    eurpRltLoded={this.eurpRltLoded.bind(this)} 
                                    bet={this.betHandler.bind(this)} 
                                    ref={this.fmntWheelRef} 
                                    height={this.wheelDimension} 
                                    action={this.wheelHandler.bind(this)} 
                                    isMobile={this.props.isMobile}
                                    fm_chipValues={this.props.fm_chipValues}
                                ></Wheel>
                            </div>
                            <div className="col_matka_right">
                                {/* {this.props.gameEntry == "in" ? <Close action={this.gameCloseHandler.bind(this)}></Close> : null }  */}
                                {/* <Close action={this.gameCloseHandler.bind(this)}></Close> */}
                                <RightButtonPanel 
                                    childRef = {ref =>(this.RightButtonPanel = ref)}
                                    ref={this.RightButtonPanel} 
                                    btnActive={this.state.btnActive} 
                                    name={this.props.user} 
                                    stop={this.state.stop} 
                                    targetResult={this.props.targetResult}
                                    betBtnText={this.state.betButtonText} 
                                    betButtonValue = {this.state.betButtonValue}
                                    action={this.betPanelHandler.bind(this)} 
                                    btntext={this.rightButtonPanelHandler.bind(this)}
                                    disDoubleBtn ={this.state.disDoubleBtn} 
                                    disBetBtn={this.state.disBetBtn} 
                                    disClearBtn={this.state.disClearBtn}
                                ></RightButtonPanel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="winPopMatka" id="winPop"> 
                    <div className="popBorder">
                    {this.props.language.Win_Amt}&nbsp;<span>{this.winAmount}</span> 
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatesToProps = (state) =>{
    return{
       
        language : state.languageObjs.languageObj,
    }
}

export default connect(mapStatesToProps)(FunMatka)
