import React from "react";
import chips from "../../../chips";

import mt_wheelBg_img from "../../../../../../assets/games/funMatka/matkaWheelBg.svg";
import mt_wheelNum1 from "../../../../../../assets/games/funMatka/wheelNum1.png";
import mt_wheelNum2 from "../../../../../../assets/games/funMatka/wheelNum2.png";
import mt_wheelNum3 from "../../../../../../assets/games/funMatka/wheelNum3.png";
import mt_matkaRes from "../../../../../../assets/games/funMatka/matka_win.png";


export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // result: "",
      chipSelect: 1,
    };
    this.state.resultNumber = "";

    this.wheel_nos_1 = "";
    this.wheel_nos_2 = "";
    this.wheel_nos_3 = "";

    this.chipVal = [ 
      { label: "1k" , val: 1000 }, 
      { label: "5k" , val: 5000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "50k" , val: 50000 }, 
      { label: "100k" , val: 100000 }, 
      { label: "5M" , val: 5000000 }, 
      { label: "1B" , val: 100000000 },
      { label: "" , val: 0 }
    ];

  }
  componentStateHandler() {}
  componentDidMount() {
    this.wheel_nos_1 = document.getElementById("wheel_nos_1");
    this.wheel_nos_2 = document.getElementById("wheel_nos_2");
    this.wheel_nos_3 = document.getElementById("wheel_nos_3");

    let m1 = 0;
    let m2 = 0;
    let m3 = 0;

    if(this.props.gameState.response){
      if(this.props.gameState.response.resultNum == 1000){
        this.setState({resultNumber: "000"});
      }else{
        this.setState({resultNumber: this.props.gameState.response.resultNum});
      }
    }

    if (this.props.gameState.history && this.props.gameState.history.length > 0) {
      
      var nos = 0 ;
      
      if(this.props.gameState.history[this.props.gameState.history.length - 1].resultNo === 1000){
        nos = "000" 
      }else{
        nos = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo
       
      }
      

      m1 = Math.floor(nos / 100);
      m2 = Math.floor((nos - m1 * 100) / 10);
      m3 = nos - m1 * 100 - m2 * 10;

      console.log(m1, m2, m3)
    } else {

        m1 = 0;
        m2 = 0;
        m3 = 0;
      // sthis.setState({resultNumber: ""});
    }
    const w1_styles = {
      transition: 'all 0s',
      transform: `rotate(${( -m1 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-m2 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-m3 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);


    if(this.props.fm_chipValues){
      let newChips = this.props.fm_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt ="";
      for(let i =0; i<newChips.length; i++){                   
        if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
          lblTxt = "K"
        }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
          lblTxt = "M"                        
        }else if(Number(newChips[i]) > 999999999){            
          lblTxt = "B"
        }                     
        this.chipVal[i].val = newChips[i]         
        console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
      }
    }   
  }

  checkChipValue(chipValue, chpTxt, i) {       
    if(chipValue >= 1000){
      chipValue = chipValue / 1000;
      this.checkChipValue(chipValue, chpTxt, i)
    }else{
      console.log(chpTxt);
      this.showResultChipValue(chipValue, chpTxt, i);
      return chipValue
    }
  }

  showResultChipValue(chVal, chipTxt, index){
    console.log(chVal);
    console.log(chVal+""+chipTxt)
    this.chipVal[index].label = chVal+""+chipTxt;
    console.log(this.chipVal)
  }

  spinTheWheel = (s) => {
    console.log(s);
    
    this.resultSent = false;

    this.setState({resultNumber: " "});
  

    // var digits = ("" + s).split("");
    // console.log(digits);
    let s1 = Math.floor(s / 100);
    let s2 = Math.floor((s - s1 * 100) / 10);
    let s3 = s - s1 * 100 - s2 * 10;

    console.log("stop the wheels at: " + s1 + " " + s2 + " " + s3);

    const w1_styles = {
      transition: 'all 7s',
      transform: `rotate(${(1440 - (s1 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 8s',
      transform: `rotate(${-(1800 + (s2 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 9s',
      transform: `rotate(${(2160 - (s3 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);

    setTimeout(() => {
      this.afterRotate(s);
      
    }, 9500)
    
  };

  afterRotate(resNumVal) {
    console.log('hi');
    console.log(resNumVal);        

   let resNum;

    if(resNumVal === 1000){
      resNum = "000"
    }else{
      resNum = resNumVal;
    }
      let k1 = Math.floor(resNum / 100);
      let k2 = Math.floor((resNum - k1 * 100) / 10);
      let k3 = resNum - k1 * 100 - k2 * 10;
      console.log(k1, k2, k3);

    const w1_styles = {
      transition: 'all 0s',
      transform: `rotate(${( -k1 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-k2 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-k3 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);
    
    this.props.action(this.state.resultNumber);
    if(resNum == 1000){
      this.setState({resultNumber: "000"});        
    }else{
      this.setState({resultNumber: resNum});        
    }

  }

  updateResultNumber = (resNum) => {
    console.log("Wheel Result Numner: " + resNum);
    if(Number(resNum) == 1000){
      this.setState({resultNumber: "000"})
      this.state.resultNumber = "000";
    }else{
      this.setState({resultNumber: resNum})
      this.state.resultNumber = resNum;
    }    
    console.log("result: " + this.state.resultNumber);
  };

  stopWheelSound = () => {
   // this.matkaWheelOne.current.stopWheel();
  };


  chipMosueDwn(val){
    console.log(val);
    document.getElementById(val).style.transform = "scale(0.7)";
  }
  chipMosueUp(val){
      console.log(val);
      document.getElementById(val).style.transform = "scale(1)";
  }
  
  render() {
    return (
      <div className="fd">
        
        <div className="mt_wheelBg" id="mt_wheelBg">
            <img className="wheelBorder_1" src={mt_wheelBg_img} />    
            <img className="wheel_nos_1" id="wheel_nos_1" src={mt_wheelNum1} />    
            <img className="wheel_nos_2" id="wheel_nos_2" src={mt_wheelNum2} />    
            <img className="wheel_nos_3" id="wheel_nos_3" src={mt_wheelNum3} />    
            <img className="matkaResPos" src={mt_matkaRes} />
            <div className="pivotNum">{this.state.resultNumber}</div>
        </div> 
        
        <div className="fd p_5 mt_whellBottom">
          <div className="mt_matkaRes fd text-center ht_56">
          {this.props.gameState.history ? this.props.gameState.history.map((value, index) => {
               if(value.resultNo == 1000){
                return index >= 0 ? <span key={index}> &nbsp;  000</span> : "";
              }else{

                return index >= 0 ? <span key={index}> &nbsp;  {value.resultNo}</span> : "";
              }
              })
               : ' ' }
          </div>

          <div className="matkaRes2 fd text-center lh_info Maiandra_GD" id="mt_matkaRes"> {this.props.infoText} </div>
          <div className="fd">
            <div className="mt_matkaRes chipBox">
              <ul className="chips_matka fd">
                {chips.map((chip, i) => (
                  <li key={chip.src} id = {"chipImg_"+chip.val} onClick={(e) => {e.preventDefault(); document.getElementById("chipImg_"+chip.val).style.transform = "scale(0.7)"; setTimeout(() => {
                    document.getElementById("chipImg_"+chip.val).style.transform = "scale(1)";}, 200); this.props.bet(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`); this.setState({ chipSelect: chip.val });}}>
                    {/* <img
                      src={chip.src}
                      id = {"chipImg_"+chip.val}
                      className={this.state.chipSelect === chip.val ? "active" : "inactive"}
                      alt={chip.val}
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("chipImg_"+chip.val).style.transform = "scale(0.7)";
                        setTimeout(() => {
                            document.getElementById("chipImg_"+chip.val).style.transform = "scale(1)";
                        }, 200);
                        this.props.bet(chip.val);
                        this.setState({ chipSelect: chip.val });
                      }}
                    /> */}

                   
                    <img src={chip.src}  className={this.state.chipSelect === chip.val ? "active" : "inactive"} alt={chip.val} />
                    <div class="mt_overlay_text"> {this.chipVal[i].label}</div>

                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
