import React from 'react';
import betImg0 from "../../../../../../assets/games/funSoratTimer/pics_png/butterfly.png";
import betImg1 from "../../../../../../assets/games/funSoratTimer/pics_png/dog.png";
import betImg2 from "../../../../../../assets/games/funSoratTimer/pics_png/bug.png";
import betImg3 from "../../../../../../assets/games/funSoratTimer/pics_png/beer.png";
import betImg4 from "../../../../../../assets/games/funSoratTimer/pics_png/cat.png";
import betImg5 from "../../../../../../assets/games/funSoratTimer/pics_png/bird_1.png";
import betImg6 from "../../../../../../assets/games/funSoratTimer/pics_png/horse.png";
import betImg7 from "../../../../../../assets/games/funSoratTimer/pics_png/lion.png";
import betImg8 from "../../../../../../assets/games/funSoratTimer/pics_png/kangaro.png";
import betImg9 from "../../../../../../assets/games/funSoratTimer/pics_png/monkey.png";
import betImg10 from "../../../../../../assets/games/funSoratTimer/pics_png/snail.png";
import betImg11 from "../../../../../../assets/games/funSoratTimer/pics_png/snake.png";
import betImg12 from "../../../../../../assets/games/funSoratTimer/pics_png/zebra.png";

const tableData = [
    { src : betImg0, betCntr: 0, betBtm: null, betLeft : null, betCrnr : null, betRight: null, betTop: null, TR:'tr0'},
    { src : betImg1, betCntr: 1, betBtm: null, betLeft :30, betCrnr : null, betRight: null, betTop: null, TR:'tr3'},
    { src : betImg2, betCntr: 2, betBtm: 13, betLeft :31, betCrnr : 37, betRight: null, betTop: null, TR:'tr2'},
    { src : betImg3, betCntr: 3, betBtm: 14, betLeft :32, betCrnr : 38, betRight: null, betTop: 33, TR:'tr1'},
    { src : betImg4, betCntr: 4, betBtm: null, betLeft :21, betCrnr : null, betRight: null, betTop: null, TR:'tr3'},
    { src : betImg5, betCntr: 5, betBtm: 15, betLeft :22, betCrnr : 39, betRight: null, betTop: null, TR:'tr2'},
    { src : betImg6, betCntr: 6, betBtm: 16, betLeft :23, betCrnr : 40, betRight: null, betTop: 34, TR:'tr1'},
    { src : betImg7, betCntr: 7, betBtm: null, betLeft :24, betCrnr : null, betRight: null, betTop: null, TR:'tr3'},
    { src : betImg8, betCntr: 8, betBtm: 17, betLeft :25, betCrnr : 41, betRight: null, betTop: null, TR:'tr2'},
    { src : betImg9, betCntr: 9, betBtm: 18, betLeft :26, betCrnr : 42, betRight: null, betTop: 35, TR:'tr1'},
    { src : betImg10, betCntr: 10, betBtm: null, betLeft :27, betCrnr : null, betRight: 45, betTop: null, TR:'tr3'},
    { src : betImg11, betCntr: 11, betBtm: 19, betLeft :28, betCrnr : 43, betRight: 46, betTop: null, TR:'tr2'},
    { src : betImg12, betCntr: 12, betBtm: 20, betLeft :29, betCrnr : 44, betRight: 47, betTop: 36, TR:'tr1'},
] 
//48  52  53
export default tableData;