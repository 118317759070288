export const CHIP_DECREASE = 'CHIP_DECREASE';
export const CHIP_INCREASE = 'CHIP_INCREASE';
export const CHIP_TOTAL_PLUS = 'CHIP_TOTAL_PLUS';
export const CHIP_TOTAL_MINUS = 'CHIP_TOTAL_MINUS';
export const CHIP_TOTAL_CLEARE = 'CHIP_TOTAL_CLEARE'; 
export const GET_RESULT_CARDS = 'GET_RESULT_CARDS';
export const NEW_DRAGON_GAME = 'NEW_DRAGON_GAME';
export const DEAL_BUTTON_TRUE = 'DEAL_BUTTON_TRUE' ;
export const WIN_AMOUNT = 'WIN_AMOUNT';
export const HISTORY = 'HISTORY';
export const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';
export const INITIAL_CHIP ='INITIAL_CHIP';


export const initialChipVal = (val, lbl) => {
    console.log( val, lbl )
    return {
        type: INITIAL_CHIP,
        payload: val,
        lbl: lbl
    }
}
export const chipBtnPlus = (val, lbl) => {
    console.log( val, lbl )
    return {
        type: CHIP_INCREASE,
        payload: val,
        lbl: lbl
    }
}
export const chipBtnMinus = (val, lbl) => { 
    console.log( val, lbl ) 
    return {
        type: CHIP_DECREASE,
        payload: val,         
        lbl: lbl
    }
}

export const chipTotalMinus = (val, lbl) => {
    return {
        type: CHIP_TOTAL_MINUS,
        payload: val,
        lbl: lbl
    }
}
export const chipTotalPlus = (val, lbl) => {
    return {
        type: CHIP_TOTAL_PLUS,
        payload: val,
        lbl: lbl
    }
}

export const chipTotalCleare = (val) => {  
    return {
        type: CHIP_TOTAL_CLEARE,
        payload: val
    }
}


export const getResultCards = (res) => {
    console.log( res )
    return {
        type: GET_RESULT_CARDS,
        payload: res
    }
}

export const newDragonGame = () => { 
    return {
        type: NEW_DRAGON_GAME
    }
}

export const dealButtonTrue = () => {
    return {
        type : DEAL_BUTTON_TRUE
    }
}
export const winAmountAction = (val, lbl) => {
    return {
        type : WIN_AMOUNT,
        payload: val,
        lbl: lbl
    }
}


export const updateHistoryAction = (val) => {
    console.log("History Val: "+val);
    return {
        type : HISTORY,
        payload: val
    }
}
export const getUserBalance = (val, lbl) => {
    console.log("userBalance Val: "+val);
    return {
        type : UPDATE_USER_BALANCE,
        payload: val,
        lbl: lbl
    }
}
