import React from "react";
import "../../../../../css/games/funTarget/betPanel.css";

export default class BetPanel extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      wins : [
        { id: 1, amount: 0 },
        { id: 2, amount: 0 },
        { id: 3, amount: 0 },
        { id: 4, amount: 0 },
        { id: 5, amount: 0 },
        { id: 6, amount: 0 },
        { id: 7, amount: 0 },
        { id: 8, amount: 0 },
        { id: 9, amount: 0 },
        { id: 0, amount: 0 },      
      ]
    }    
  }

  updateBetAmt(id, value){
    let val = Number(value.toFixed(2))
     console.log(id+"     "+val);

    for(var i =0; i< 10; i++){
      if(this.state.wins[i].id == id){     
        
        if(val >= 1000 && val <=999999){
          val = Number((val / 1000).toFixed(2))+"K";
          console.log(val);
        }else if(val >= 1000000 && val <=999999999){
          val = Number((val / 1000).toFixed(2))+"M";
          console.log(val);
        }else if(val >999999999){
          val = Number((val / 1000).toFixed(2))+"B";
          console.log(val);
        }

        this.state.wins[i].amount = val;
      }
    }    
  }
  mouseDownSelectCard(data){ 
    console.log(data)   ;
    clearInterval(this.startInt );
    this.props.action(data) 
    this.startInt = setInterval( () => { 
      this.props.action(data)
    }, 150); 
  } 
  mouseUpStopCards(){    
    clearInterval(this.startInt);    
  }
  render(){

    return (
      <div className="btmPanel ">
        {this.state.wins.map((value, i) => (
          <div key={this.state.wins[i].id} 
            className= {"panelTabs buttonZero addCursorPointer" } 
            id={"boxSml_"+this.state.wins[i].id} 
            // onMouseOut={(e)=>{ e.preventDefault(); this.boxSmlUp('boxSml_'+this.state.wins[i].id); this.mouseUpStopCards(this.state.wins[i].id) }}
            // onMouseUp={(e)=>{ e.preventDefault(); this.boxSmlUp('boxSml_'+this.state.wins[i].id); this.mouseUpStopCards(this.state.wins[i].id) }}
            // onMouseDown={(e)=>{ e.preventDefault(); this.boxSmlDown('boxSml_'+this.state.wins[i].id); this.mouseDownSelectCard(this.state.wins[i].id) }} 

            onMouseUp={(e)=>{ e.preventDefault(); this.mouseUpStopCards() }}
            onMouseOut={(e)=>{ e.preventDefault();  this.mouseUpStopCards() }}
            onMouseDown={(e) => {e.preventDefault(); this.mouseDownSelectCard(this.state.wins[i].id) }}
          >        
            <div className="betCoverFTW"></div>   
            <div className={value.id === this.props.resultNo ? "buttonOneBg" : "buttonZeroBg"}>
              <div className= "pan"   >                
                <div className="pan1 v_align">
                  <div> {value.id} </div>
                </div>
                <div className="pan2 v_align">
                  <div>{value.amount == 0 ?  null : value.amount }</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
