import react from "react";
import c_1 from '../../../../../../assets/games/funCard/cards/c_1.svg';
import c_2 from '../../../../../../assets/games/funCard/cards/c_2.svg';
import c_3 from '../../../../../../assets/games/funCard/cards/c_3.svg';
import c_4 from '../../../../../../assets/games/funCard/cards/c_4.svg';
import c_5 from '../../../../../../assets/games/funCard/cards/c_5.svg';
import c_6 from '../../../../../../assets/games/funCard/cards/c_6.svg';
import c_7 from '../../../../../../assets/games/funCard/cards/c_7.svg';
import c_8 from '../../../../../../assets/games/funCard/cards/c_8.svg';
import c_9 from '../../../../../../assets/games/funCard/cards/c_9.svg';
import c_10 from '../../../../../../assets/games/funCard/cards/c_10.svg';
import c_j from '../../../../../../assets/games/funCard/cards/c_j.svg';
import c_q from '../../../../../../assets/games/funCard/cards/c_q.svg';
import c_k from '../../../../../../assets/games/funCard/cards/c_k.svg';
const card_items = [
    {src: c_1, c_val : '0' },
    {src: c_2, c_val : '1' },
    {src: c_3, c_val : '2' },
    {src: c_4, c_val : '3' },
    {src: c_5, c_val : '4' },
    {src: c_6, c_val : '5' },
    {src: c_7, c_val : '6' },
    {src: c_8, c_val : '7' },
    {src: c_9, c_val : '8' },
    {src: c_10, c_val : '9' },
    {src: c_j, c_val : '10' },
    {src: c_q, c_val : '11' },
    {src: c_k, c_val : '12' }
]

export default card_items;