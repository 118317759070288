import React from "react";
//import { winImgs } from './winImg';
import tickSound from "../../../../../../assets/sounds/tick.mp3";
import { connect } from "react-redux";
 class FunTargetTimerInSeconds extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            timeInSec: 60,
            getTime: 60,
            callsec:true,
            timerStop:true,
            disVal: 0,
            isBetSent: false,
            isDisableSent: false,
            isTimerSent:false,
        };
       // this.timerv = this.props.timesend;        
    }
    componentDidMount(){
       const {childRef} = this.props
        childRef(this)
        document.getElementById("timeSpan").style.display = "none";
        // document.getElementById("timeSpan").style.display = "block";

        this.timerclear = setInterval(this.myTimer, 1000);       
    }
    componentWillUnmount(){
        clearInterval(this.timerclear);
    }
    sendTimer(tt) { 
        document.getElementById('timeSpan').style.display = 'block';
        this.setState({ getTime: tt });
        this.myTimer();
    }
   
    myTimer = () => {    
        if (this.state.getTime != null && this.state.getTime != NaN && this.state.getTime >=0) {     
            let d = this.state.getTime;
            let timer = document.getElementById("timer");  
            let funTargetCover = document.getElementById("funTargetCover");
            
            let n = --d;
            let sec = parseInt(n % 60);
            let secS = sec.toString();
            if (String(sec).length < 2) {
                secS = "0" + sec.toString();
            }
            this.setState({ getTime: secS })
            this.setState({ timeInSec: secS }); 
            if (this.state.getTime != null) {   
                
                // if (n == 10 && !this.state.isTimerSent) {
                //     this.setState({isTimerSent: true});
                //     this.getTimer();  
                // }
                if (n <= 0) {
                    this.setState({ getTime: 60, isDisableSent: false  });   
                    this.setState({isTimerSent: false});
                                                     
                }
                
                if (n <= 5 && n > 0) {
                    funTargetCover.style.display = 'block';
                    funTargetCover.style.zIndex = "500";

                    if(!this.state.isBetSent){
                        this.setState({isBetSent: true});     
                        this.props.action("BET OK");                         
                    }    
                    if(!this.state.isDisableSent){
                        this.setState({isDisableSent: true});
                        this.state.isDisableSent = true;
                        this.getTimer();
                        this.props.action("disableBtns");                          
                    }                                        
                }else{
                    this.setState({isBetSent: false});   
                }                

                if(n > 5 && n < 52){
                    if(this.props.winCover == false ) { 
                        funTargetCover.style.display = 'none'; 
                    } else { 
                        funTargetCover.style.display = 'block';
                        this.props.action("enableBtns"); 
                    } 
                    this.setState({isBetSent: false});     
                }
                if (n <=10 && n >= 5) {
                    timer.classList.add("timerYelloBorder");
                    this.tickSound = new Audio(tickSound);
                    this.tickSound.play();
                    // this.props.action("PlayTickSound");
                    this.setState({isBetSent: false});  
                }else {
                    if(timer && timer.classList.contains('timerYelloBorder')){
                        timer.classList.remove("timerYelloBorder");
                        this.tickSound = {};
                        // this.props.action("StopTickSound"); 
                    }
                } 
            }
        }
    }

    getTimer() {
        this.setState({isTimerSent: true});
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_TIMER",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        this.props.network.sendof(body);
        this.body={};   
    } 

  changeAmtlabl(chngAmt){
    let newAmt = Number(Number(chngAmt).toFixed(2));
    if(newAmt >= 1000 && newAmt <=999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"K";      
    }else if(newAmt >= 1000000 && newAmt <=999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"M";    
    }else if(newAmt >999999999){
      newAmt = Number((newAmt / 1000).toFixed(2))+"B";      
    }
    return newAmt;
  }
    render() {
        let winAmount = this.props.winningAmount !==undefined ? (this.props.winningAmount):null
        return <div className="fd m_b_15">
            <div className="funTargetWinImg" id="funTargetWinImg"> 
                <div className="fd"> 
                        <span className="cong">{this.props.language.You_won}  </span> <br/>
                        {this.props.language.Win_Num} <span className="cong">  {this.props.resultNo} </span> <br/>
                        {this.props.language.Win_Amt} <span className="cong"> {winAmount} </span>
                </div>
            </div>
            <div id="funTargetCover" className="funTargetCover"></div>
            <div className="targetTimer_Sec" id="timer"> <span className="" id="timeSpan"> {this.state.timeInSec} </span> </div>
        </div>;
    }
}


const mapStatesToProps = (state)=>{
    return {
        language : state.languageObjs.languageObj
    }
}

export default connect(mapStatesToProps)(FunTargetTimerInSeconds)