import KenoTable from "./UI/kenoTable";
import "./UI/keno.css";
//import KenoD_up from '../KenoD_up/kenoD_up';
import takeSound from "../../../../../assets/sounds/take.mp3";
import betSound from "../../../../../assets/sounds/bet.mp3";
//import starsprinkle from "../../../../../assets/sounds/dupstarsprinkle.mp3";
import exitSound from "../../../../../assets/sounds/exit.mp3";
import kenoballsounds from "../../../../../assets/sounds/keno-loose_3.mp3";
import ballshuffling from "../../../../../assets/sounds/ballshuffling.mp3";
import kenoname from "../../../../../assets/games/keno/keno.gif";
import buttons from "../../../../../assets/games/keno/exit button.png";
import closeImage from "../../../../../assets/games/keno/keno_exit_btn.png";
import resultBalls from "./UI/kenoballs";
import React, { Component, Fragment } from "react";
import winningpanels from "../../../../../assets/games/keno/win.png";
//import dupglow from "../../../../../assets/games/keno/takewinning.png";
import tablef from "../../../../../assets/games/keno/tablef.png";
import user from "../.../../../../../../assets/games/keno/user.png";
// import i_symbol from "../../../../../assets/games/keno/i_symbol.png";
// import star1 from "../../../../../assets/games/d_up/star1.png";
// import str1 from "../../../../../assets/games/d_up/star.png";
// import str2 from "../../../../../assets/games/d_up/star.png";
// import str3 from "../../../../../assets/games/d_up/star.png";
// import str4 from "../../../../../assets/games/d_up/star.png";
// import str5 from "../../../../../assets/games/d_up/star.png";
// import str6 from "../../../../../assets/games/d_up/star.png";
// import str7 from "../../../../../assets/games/d_up/star.png";
// import str8 from "../../../../../assets/games/d_up/star.png";
// import str9 from "../../../../../assets/games/d_up/star.png";
// import str10 from "../../../../../assets/games/d_up/star.png";
// import str11 from "../../../../../assets/games/d_up/star.png";
// import str12 from "../../../../../assets/games/d_up/star.png";
// import str13 from "../../../../../assets/games/d_up/star.png";

import keno_winSnd from "../../../../../assets/sounds/keno_win.mp3";
import sparkanimation from "../../sparkanim";
import { connect } from "react-redux";
import imageObject from "./kenocage.js";

class Keno extends Component {
  constructor(props) {
    super(props);
    this.updatePlayerBalnce = false;
    this.kenoTable = React.createRef();

    this.state = {
      balls: [],
      resultNo: [],
      showAnotherComponent: false,
      strs: [],
      //showdupbutton: false,

      Animationballs: [],
      // betString: [],
      randBtn: false,
      showballs: false,
      takebtn: false,
      betButtonValue: 0,
      gameId: this.props.gameId,
      playerId: this.props.playerId,
      tableBts: false,
      winningbalance: 0,
      totalBet: 0,
      betAmount: 0,
      Total_Bet_Amount: 0,
      starcnt: this.props.starCount,
      // showDupComponent: false,
      infoText: this.props.language.Play_Njoy + " " + this.props.language.keno_bet_info,
      resultNo: this.props.resultNo,
      hidebtn: true,
      balance: this.props.balance,
      showAnimation: false,
      targetResult: props.targetResult,
      matchedNumbers: [],
      unmatchedNumbers: [],
      matchedNumberselect: [],
      unmatchedNumberselect: [],
      doubleUpGameState: {},
      doubleUPTakeAmt: 0,
      isGameActive: false,
      //isDupActive: false,
      isPayout_show: false,
      handId: 0,
    };
    this.is_kn_GameOn = true;
    this.betStringData = [];
    this.imageNum = 32;
    this.sparkle = 1;

    // this.stars = [
    //   { src: str1, val: 1 },
    //   { src: str2, val: 2 },
    //   { src: str3, val: 3 },
    //   { src: str4, val: 4 },
    //   { src: str5, val: 5 },
    //   { src: str6, val: 6 },
    //   { src: str7, val: 7 },
    //   { src: str8, val: 8 },
    //   { src: str9, val: 9 },
    //   { src: str10, val: 10 },
    //   { src: str11, val: 11 },
    //   { src: str12, val: 12 },
    //   { src: str13, val: 13 },
    // ];
  }

  componentDidMount() {
    //console.log(this.props.gameState.handId)
    //console.log(resultBalls)

    this.timeoutId1 = null;
    this.timeoutId2 = null;
    this.timeoutId3 = null;
    this.timeoutId4 = null;
    this.timeoutId5 = null;
    this.timeoutId6 = null;
    this.timeoutId7 = null;
    this.betCol = null;
    this.balAnimSnd = null;
    this.soundInterval = null;
    this.interval = null;
    this.intervalsparkle = null;

    this.takeSound = new Audio(takeSound);
    this.kenoballsounds = new Audio(kenoballsounds);
    this.exitSound = new Audio(exitSound);
    this.betSnd = new Audio(betSound);
    this.ballshuffling = new Audio(ballshuffling);
    this.keno_winSnd = new Audio(keno_winSnd);
    //this.ballsbouncing = new Audio(ballsbouncing);
    //this.starsprinkle = new Audio(starsprinkle);
    this.imageNum = 32;
    this.setState({
      hidebtn: true,
      takebtn: true,
      isGameActive: true,
      handId: this.props.gameState.handId
    });
    //document.getElementById("d_upBtn").classList.add("disableBtns");
    const { childRef } = this.props;
    childRef(this);
   
    this.preloadCageImages();
    this.preloadSparkAnim();
  }
  preloadCageImages=()=>{
    let loadedImages = 0;
    for (let i = 32; i <= 385; i++) {
      const img = new Image();
      const imageName = `kenojars${i}`;
      img.onload = img.onerror = () => {
        loadedImages++;
      
      };
      img.src = imageObject[imageName];
    }
  

  }
 

  // preloadCageImages() {
  
  //   let loadedImages = 0;
  //   for (let i = 32; i <= 385; i++) {
  //     let img = new Image();
  //     const imageName = `kenojars${i}`;
      // img.src = imageObject[imageName];
    // Preload each image
    // for (let i = 32; i <= 385; i++) {
    //   let img = new Image();
    //   if (loadedImages !== 385) {
    //     loadedImages++;
    //     img.onload = loadedImages;
    //     img.onerror = loadedImages; // Call imageLoaded even if there's an error loading the image
    //     const imageName = `kenojars${i}`;
    //     img.src = imageObject[imageName];
    //     //console.log(img.src);
    //   }
    // }
//   }
// }

  preloadSparkAnim() {
    let loadedSparkImages = 0;
    // Preload each image
    for (let n = 1; n <= 100; n++) {
      let spark_img = new Image();
      if (loadedSparkImages !== 385) {
        loadedSparkImages++;
        spark_img.onload = loadedSparkImages;
        spark_img.onerror = loadedSparkImages; // Call imageLoaded even if there's an error loading the image
        const sparkImageName =  `sparkanimation${n}`;
        spark_img.src = sparkanimation[sparkImageName];
        //console.log(spark_img.src);
      }
    }
  }

  takehandlerandombtn() {
    this.setState({ randBtn: true });
    document.getElementById("randBtn").classList.add("disableBtns");
  }

  showResult() {
    this.KenoTable.handleResult();
    this.setState({ doubleUPTakeAmt: this.props.targetResult.winAmount });
    if (this.props.targetResult.winAmount > 0) {
      if (document.getElementById("winPop")) {
        document.getElementById("winPop").style.display = "block";
      }

      // document.getElementById("sparkID").src = staranimation;
      this.intervalsparkle = setInterval(this.startsprinkleanimation, 35);

      this.timeoutId6 = setTimeout(() => {
        // document.getElementById("sparkID").src = "";
        if (document.getElementById("winPop")) {
          document.getElementById("winPop").style.display = "none";
        }

        // const body = {
        //   sessionId: sessionStorage.getItem("sessionId"),
        //   CN: "TAKE_AMOUNT",
        //   object: {
        //     playerId: Number(this.props.playerId),
        //     gameId: this.props.gameId,
        //   },
        // };
        // this.props.network.sendof(body);

        if (this.props.isPlayerBanned) {
          this.gameCloseHandler("target");
          this.props.player_banned();
        }

        console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
        if (this.props.gameUnderMaintanance) {
          this.gameCloseHandler("target");
          this.props.game_maintanance(
            "Game under maintenance, reach to admin."
          );
        }

        console.log("deviceBlocked " + this.props.isdeviceBlocked);
        if (this.props.isdeviceBlocked) {
          this.gameCloseHandler("target");
          this.props.device_blocked();
        }

        this.setState({ winAmount: 0 });
        if (document.getElementById("clearBtn")) {
          document.getElementById("clearBtn").classList.remove("disableBtns");
        }
        if (document.getElementById("betBtn")) {
          document.getElementById("betBtn").classList.remove("disableBtns");
        }
        if (document.getElementById("doubledBtn")) {
          document.getElementById("doubledBtn").classList.remove("disableBtns");
        }
      }, 3000);

      this.timeoutId7 = setTimeout(() => {
        const buttons = document.getElementsByClassName("kenobetpannel");
        // console.log(buttons)
        for (let i = 0; i < buttons.length; i++) {
          buttons[i].classList.remove("fill-color-mixedgran");
          buttons[i].classList.remove("fill-color-mixedgclick");
          buttons[i].classList.remove("fill-color-mixedr");
          const buttonNumber = parseInt(buttons[i].innerText);

          document.getElementById("bet_" + (i + 1)).disabled = false;
        }

        const getGameStateBody = {
          sessionId: sessionStorage.getItem("sessionId"),
          CN: "GET_GAME_STATE",
          object: {
            playerId: Number(this.props.playerId),
            gameId: this.props.gameId,
          },
        };
        this.props.network.sendof(getGameStateBody);

        // this.setState({
        //   showdupbutton:
        //     this.props.targetResult.winAmount > 5000 ? false : true,
        // });
        if (
          //document.getElementById("d_upBtn") &&
          this.props.targetResult.winAmount <= 5000
        ) {
          //document.getElementById("d_upBtn").classList.remove("disableBtns");
        }
      }, 3500);
    } else {
      const getGameStateBody = {
        sessionId: sessionStorage.getItem("sessionId"),
        CN: "GET_GAME_STATE",
        object: {
          playerId: Number(this.props.playerId),
          gameId: this.props.gameId,
        },
      };
      this.props.network.sendof(getGameStateBody);

  
      this.setState({ randBtn: true, showdupbutton: false});

      // if (document.getElementById("d_upBtn")) {
      //   document.getElementById("d_upBtn").classList.add("disableBtns");
      // }
      if (document.getElementById("doubledBtn")) {
        document.getElementById("doubledBtn").classList.remove("disableBtns");
      }
      if (document.getElementById("clearBtn")) {
        document.getElementById("clearBtn").classList.remove("disableBtns");
      }
      if (document.getElementById("betBtn")) {
        document.getElementById("betBtn").classList.remove("disableBtns");
      }
    }
  }

  randomTableClick() {
    this.betSnd.play();
    this.KenoTable.randomTableClick(this.props.resultNo);
  }

  // updateMessage(infoTxt) {
  //   // alert(infoTxt)
  //   if (this.state.isDupActive) {
  //     this.KenoD_up.updateMessage(infoTxt);
  //   } else {
  //     this.setState({ infoText: infoTxt });
  //   }
  // }

  updateDoubleupState(startCnt, bonusVal) {
    // console.log(startCnt)
    if (!this.state.showDupComponent) {
      this.updateStarImages(startCnt);
    }

    // this.setState({ starcnt: startCnt });

    // let stars = [];
    // {
    //   this.stars.map((displaystar, i) => {
    //     const starimage = `starimage${i + 1}`;
    //     displaystar.className = `starskeno${i + 1}`;
    //     const shouldReplace = i < this.state.starcnt;
    //     //console.log(this.state.starcnt)
    //     stars.push(<img
    //       id={starimage}
    //       className={displaystar.className}
    //       src={shouldReplace ? star1 : displaystar.src}
    //       key={`starskeno${i + 1}`}
    //     />)

    //   })

    //   for (let k = 1; k <= startCnt; k++) {
    //     if (document.getElementById("starimage" + k)) {
    //       document.getElementById("starimage" + k).classList.add("keno_starblink")
    //     }
    //   }
    // }

    // this.setState({ strs: stars });
    // if (this.KenoD_up && this.state.isDupActive) {
    //   this.KenoD_up.updateGameState(startCnt, bonusVal);
    // }
  }

  // updateDoubleupInfo(infoTxt) {
  //   if (this.KenoD_up && this.state.isDupActive) {
  //     this.KenoD_up.updateInfo(infoTxt);
  //   }
  // }

  // doubleUPResult(event) {
  //   //alert("//////////////////////?????????????////////////////"+event.starCount)
  //   console.log(event);
  //   if (this.KenoD_up && this.state.isDupActive) {
  //     this.KenoD_up.updateResult(event);
  //   }
  // }
  startsprinkleanimation = () => {
    this.sparkle++;
    if (this.sparkle < 101) {
      const sparkle = `sparkanimation${this.sparkle}`;
      //console.log(sparkle)
      const starssprinkle = sparkanimation[sparkle];
      //console.log(starssprinkle)
      if (document.getElementById("sparkID")) {
        document.getElementById("sparkID").src = starssprinkle;
      }
    } else {
      this.sparkle = 1;
      clearInterval(this.intervalsparkle);
    }
  };
  getPlayerInfo() {
    const { playerId, gameId, network } = this.props;
          const body2 = {
              CN: "GET_PLAYER_INFO",
              object: {
              gameId: Number(gameId),
              playerId: Number(playerId),
              },
              sessionId: sessionStorage.getItem('sessionId'),
          }
          network.sendof(body2);
          this.body2 ={};
      }
  kenoResults(event) {
  
    if (this.state.isGameActive) {
    if(event.winAmount==0){
      this.getPlayerInfo();
    }
      this.KenoTable.enableKeyboard(false);
     
        this.imageNum = 32;
        this.interval = setInterval(this.cageImgRot,50); 
      

      // this.interval = setInterval(this.cageImgRot, 35);

      this.ballshuffling = new Audio(ballshuffling);
      this.ballshuffling.currentTime = 0;

      this.ballshuffling.play();
      let resNum = 0;
      let animTime = 450;
      let randomNumbers = this.props.resultNo;
      let balls = [];
      this.timeoutId1 = setTimeout(() => {
        console.log(randomNumbers);

        randomNumbers.map((num, i) => {
          resNum = num;
          balls.push(
            <img
              className={`point${i + 1} pointMain`}
              src={resultBalls[num - 1].src}
              style={{ animationFillMode: "forwards" }}
              key={num}
              alt={`Ball ${num}`}
            />
          );
        });
        this.setState({ balls: balls });

        if (document.hidden) {
          resNum = 0;
          animTime = 250;
          balls = [];

          randomNumbers.map((num, i) => {
            resNum = num;
            if (resultBalls.bsc_val === num);
            balls.push(
              <img
                className={`points${i + 1} pointMain`}
                src={resultBalls[num - 1].src}
                style={{ animationFillMode: "forwards" }}
                key={num}
                alt={`Ball ${num}`}
              />
            );
          });

          this.setState({ balls: balls });
        }

        if (this.state.isGameActive) {
          this.kenoballsounds.play();

          const playSound = () => {
            this.kenoballsounds.play();
          };
          this.soundInterval = setInterval(playSound, 500);

          this.balAnimSnd = setTimeout(() => {
            this.timeoutId5 = setTimeout(() => {
              clearInterval(this.soundInterval);
            }, 9500);
          }, 400);
        }
      }, 2500);

      this.betCol = setTimeout(() => {
        console.log(resNum);
        for (let k = 0; k < 20; k++) {
          for (let m = 0; m < 10; m++) {
            // console.log(this.betStringData[m]);
            // console.log(this.props.resultNo[k]);
            if (this.props.resultNo[k] == this.betStringData[m]) {
              this.timeoutId3 = setTimeout(() => {
                if (
                  document.getElementById("bet_" + this.props.resultNo[k]) &&
                  this.state.isGameActive
                ) {
                  document
                    .getElementById("bet_" + this.props.resultNo[k])
                    .classList.add("fill-color-mixedgran");
                  document
                    .getElementById("bet_" + this.props.resultNo[k])
                    .classList.add("fill-color-mixedgclick");
                }

                if (this.state.isGameActive) {
                  this.keno_winSnd = new Audio(keno_winSnd);
                  this.keno_winSnd.play();
                }
              }, (k + 1) * animTime);
            } else {
              this.timeoutId4 = setTimeout(() => {
                if (
                  document.getElementById("bet_" + this.props.resultNo[k]) &&
                  this.state.isGameActive
                ) {
                  document
                    .getElementById("bet_" + this.props.resultNo[k])
                    .classList.add("fill-color-mixedr");
                }
              }, (k + 1) * animTime);
            }
          }
          animTime = 500;
        }
      }, 2000);
    }
  }


cageImgRot = () => {
 
  this.imageNum++;

 
  if (this.imageNum < 290) {
    const imageName = `kenojars${this.imageNum}`;
    const cageimagerotating = imageObject[imageName];

   
    const cageImgElement = document.getElementById("cageImg");
    if (cageImgElement && cageimagerotating) {
      cageImgElement.src = cageimagerotating;
    } else {
      console.error(`Image ${imageName} not found or element missing`);
    }
  } else {
  
    this.imageNum = 32;
    clearInterval(this.interval);

   
    const initialImage = imageObject["kenojars32"];
    const cageImgElement = document.getElementById("cageImg");
    if (cageImgElement && initialImage) {
      cageImgElement.src = initialImage;
    }
    this.ballshuffling.pause();
    this.showResult();
  }
};

  showballs() {
    this.setState({ showballs: true });
  }
  clearballs() {
    this.setState({ showballs: false });
  }

  disableRandomBtn() {
    this.setState({ randBtn: true });
    if (document.getElementById("randBtn")) {
      document.getElementById("randBtn").classList.add("disableBtns");
    }
  }

  closeBallanimation() {
    this.setState({ balls: [] });
  }

  // showDoubleUP() {
  //   if (this.starsprinkle instanceof Audio) {
  //     //this.starsprinkle.pause();
  //   }

  //   this.KenoTable.disabletake();
  //   let buttons = document.getElementsByClassName("kenobetpannel");
  //   for (let i = 0; i < buttons.length; i++) {
  //     buttons[i].classList.remove("fill-color-mixedgran");
  //     buttons[i].classList.remove("fill-color-mixedgclick");
  //     buttons[i].classList.remove("fill-color-mixedr");
  //   }

  //   this.setState({
  //     infoText: this.props.language.Play_Njoy,
  //     // isDupActive: true,
  //     showDupComponent: true,
  //     balls: [],
  //     randBtn: false,
  //     // showdupbutton: false,
  //   });

  //   if (document.getElementById("randBtn")) {
  //     document.getElementById("randBtn").classList.remove("disableBtns");
  //   }
  //   // if (document.getElementById("d_upBtn")) {
  //   //   document.getElementById("d_upBtn").classList.add("disableBtns");
  //   // }
  //   for (let m = 1; m <= 13; m++) {
  //     if (
  //       document.getElementById("starimage" + m) &&
  //       document
  //         .getElementById("starimage" + m)
  //         .classList.contains("keno_starblink")
  //     ) {
  //       document
  //         .getElementById("starimage" + m)
  //         .classList.remove("keno_starblink");
  //     }
  //   }

  //   this.state.strs = [];

  //   const getPlayerInfoBody = {
  //     sessionId: sessionStorage.getItem("sessionId"),
  //     CN: "GET_PLAYER_INFO",
  //     object: {
  //       playerId: Number(this.props.playerId),
  //       gameId: this.props.gameId,
  //     },
  //   };
  //   this.props.network.sendof(getPlayerInfoBody);
  // }

  // disabledupComponent = (starcountval) => {
  //   this.updateStarImages(starcountval);
  //   this.KenoTable.enablebetandclear();
  //   this.setState({ randBtn: true }, () => {
  //     // console.log(this.state.starcnt);
  //   });
  //   document.getElementById("randBtn").classList.add("disableBtns");
  //   document.getElementById("clearBtn").classList.remove("disableBtns");
  //   document.getElementById("betBtn").classList.remove("disableBtns");
  //   document.getElementById("doubledBtn").classList.remove("disableBtns");
  //   document.getElementById("kenoCover").style.display = "block";
  //   document.getElementById("bet_coverpannel").style.display = "none";
  //   // if (document.getElementById("d_upBtn")) {
  //   //   document.getElementById("d_upBtn").classList.add("disableBtns");
  //   // }
  //   let buttons = document.getElementsByClassName("kenobetpannel");

  //   for (let i = 0; i < buttons.length; i++) {
  //     buttons[i].classList.remove("fill-color-mixedgran");
  //     buttons[i].classList.remove("fill-color-mixedgclick");
  //     buttons[i].classList.remove("fill-color-mixedr");
  //   }
  //   this.setState({
  //     showballs: false,
  //     showDupComponent: false,
  //     showdupbutton: false,
  //     infoText: this.props.language.Play_Njoy,
  //     isDupActive: false,
  //   });

  //   // const getPlayerInfoBody = {
  //   //   sessionId: localStorage.getItem('sessionId'),
  //   //   CN: "GET_PLAYER_INFO",
  //   //   object: {
  //   //     playerId: Number(this.props.playerId),
  //   //   },
  //   // };
  //   // this.props.network.sendof(getPlayerInfoBody);

  //   // const getGameStateBody = {
  //   //   sessionId: localStorage.getItem('sessionId'),
  //   //   CN: "GET_GAME_STATE",
  //   //   object: {
  //   //     playerId: Number(this.props.playerId),
  //   //     gameId: this.props.gameId
  //   //   },
  //   // };
  //   // this.props.network.sendof(getGameStateBody);

  //   //this.showgameundermaintence();
  // };
 
  updateBetString(betData) {
    this.betStringData = betData;
    console.log( "------------------------  ******************   --------------------------" );
    console.log(this.betStringData);
    console.log("------------------------  ******************   --------------------------");
  }

  // disableD_UP() {
  //   this.setState({ showdupbutton: false });
  //   clearTimeout(this.timeoutId7);
  //   // document.getElementById("d_upBtn").classList.add("disableBtns");
  // }

  diableRandom() {
    this.setState({ randBtn: true });
    if (document.getElementById("randBtn")) {
      document.getElementById("randBtn").classList.add("disableBtns");
    }
  }

  randone = () => {
    this.setState({ randBtn: true });
    if (document.getElementById("randBtn")) {
      document.getElementById("randBtn").classList.add("disableBtns");
    }
  };

  clearenable() {
    this.setState({ randBtn: false });
    if (document.getElementById("randBtn")) {
      document.getElementById("randBtn").classList.remove("disableBtns");
    }
  }

  clearbetAmount() {
    this.setState({ betAmount: this.props.minBetAmt });
  }

  takeenable = () => {
    this.setState({ isvideodisplayed: true });
  };

  showgameundermaintence() {
    if (this.props.isPlayerBanned) {
      this.gameCloseHandler("target");
      this.props.player_banned();
    }

    console.log("gameUnderMaintanance " + this.props.gameUnderMaintanance);
    if (this.props.gameUnderMaintanance) {
      this.gameCloseHandler("target");
      this.props.game_maintanance("Game under maintenance, reach to admin.");
    }

    console.log("deviceBlocked " + this.props.isdeviceBlocked);
    if (this.props.isdeviceBlocked) {
      this.gameCloseHandler("target");
      this.props.device_blocked();
    }
  }

  gameSessionClosed() {
    this.setState({ isGameActive: false });
    this.state.isGameActive = false;
    if (this.KenoD_up) {
      this.KenoD_up.closeDoubleUP();
    }
    this.KenoTable.clearTimeout_fn();

    this.imageNum = 32;
    clearInterval(this.interval);
    clearInterval(this.intervalsparkle);
    clearTimeout(this.timeoutId1);
    clearTimeout(this.timeoutId2);
    clearTimeout(this.timeoutId3);
    clearTimeout(this.timeoutId4);
    clearTimeout(this.timeoutId5);
    clearTimeout(this.timeoutId6);
    clearTimeout(this.timeoutId7);
    clearTimeout(this.betCol);
    clearTimeout(this.balAnimSnd);
    clearTimeout(this.soundInterval);
    this.kenoballsounds.pause();
    this.keno_winSnd.pause();
    this.ballshuffling.pause();
    // this.KenoTable.clearBet();
    this.exitSound.play();

    this.is_kn_GameOn = false;
  }

  gameCloseHandler(game) {
    clearInterval(this.interval);
    this.gameSessionClosed();
    this.props.action(game);
  }

  addwinrationtble() {
    if (!this.state.isPayout_show) {
      if (document.getElementById("keno_winratiopop")) {
        document
          .getElementById("keno_winratiopop")
          .classList.add("keno_winratio");
        document.getElementById("keno_winratiopop").style.display = "block";
      }
      this.setState({ isPayout_show: true });
    } else {
      if (document.getElementById("keno_winratiopop")) {
        document
          .getElementById("keno_winratiopop")
          .classList.remove("keno_winratio");
        document.getElementById("keno_winratiopop").style.display = "none";
      }
      this.setState({ isPayout_show: false });
    }
  }

  hidewinrationtble() {
    if (this.state.isPayout_show) {
      if (document.getElementById("keno_winratiopop")) {
        document
          .getElementById("keno_winratiopop")
          .classList.remove("keno_winratio");
        document.getElementById("keno_winratiopop").style.display = "none";
      }
      this.setState({ isPayout_show: 
        false });
    }
  }

  updateGameState() {
    this.setState({ handId: this.props.gameState.handId })
  }
   topLable_fn(Value) {
    let labelValue = Math.abs(Number(Value))
    // Nine Zeroes for Billions
    return labelValue >= 1.0e+9
  
    ? Number((labelValue / 1.0e+9).toFixed(2)) + "B"
    // Six Zeroes for Millions 
    : labelValue >= 1.0e+6
  
    ? Number((labelValue / 1.0e+6).toFixed(2)) + "M"
    // Three Zeroes for Thousands
    : labelValue >= 1.0e+3
  
    ? Number((labelValue / 1.0e+3).toFixed(2)) + "K"
  
    : Number(labelValue.toFixed(2));
  }

 updatePlayerInfo=()=>{
  // alert("........12")
 this.KenoTable.kenoupdatePlayerInfo();
  
}
  render() {
  
    // console.log(this.props.handid)
    let updateWin = (this.props.targetResult.winAmount !==undefined ? (this.props.targetResult.winAmount) :"");
    return (
      <div className="kenobg">
        <div className="kenonam">
          <div className="keno_pid_val">
            {/* ID: {this.props.gameState.handId} <br />P ID:{" "} */}
            {/* {this.props.playerId} */}
            {this.props.language.Player_Id} {this.props.playerId} <br></br>
            {this.props.language.Hand_Id} {this.state.handId}
          </div>
          <img src={kenoname} />
        </div>
        <div className="usercontainer">
          <img className="userframe" src={user} />
          <span className="userid">{this.props.user}</span>
        </div>

        {/* <div className="win1">
            <div className="text_overlaywinner">
              <span>{this.props.language.Global_Bonus}</span>
            </div>
            <div>
              <img className='gBonusImg' src={winningpanels} />
              <span className="text_overlaybonusdup">{this.props.bonusAmt}</span>
            </div>
          </div> */}

        <div className="tbbg">
          <img src={tablef} style={{ width: "1300px", height: "860px" }} />
          <div id="kenoCover" className="kenoCover" />
        </div>

        {/* <div>
            <button
              id="d_upBtn"
              className={
                this.props.isMobile
                  ? "butdup emptyPointer"
                  : "butdup addCursorPointer"
              }
              disabled={!this.state.showdupbutton}
              onClick={(e) => {
                e.preventDefault();
                this.showDoubleUP();
                this.betSnd.play();
              }}
            >
           
              {this.state.showdupbutton ? (
                <img className="dupglow" src={dupglow} />
              ) : (
                <img
                  style={{ width: "250px", height: "100px" }}
                  src={buttons}
                />
              )}
            </button>
          </div> */}

        <div>
          <button
            id="randBtn"
            className={
              this.props.isMobile
                ? "butsele emptyPointer"
                : "butsele addCursorPointer"
            }
            disabled={this.state.randBtn}
            onClick={(e) => {
              e.preventDefault();
              this.randomTableClick();
            }}
          >
            <img src={buttons} style={{ width: "250px", height: "100px" }} />
            <div className="text_overlays">
              <span>{this.props.language.RANDOM}</span>
            </div>
          </button>
        </div>

        {/* <div>
            <button
              className={
                this.props.isMobile
                  ? "butext emptyPointer"
                  : "butext addCursorPointer"
              }
              id="butt"
              onClick={(e) => {
                e.preventDefault();
                this.gameCloseHandler("target");
              }}
            >
              <img
                className="clseimage"
                src={closeImage}
                style={{ width: "140px", height: "140px" }}
              />
              <div className="keno_exit_Txt">
                <span>{this.props.language.Exit}</span>
              </div>
            </button>
          </div> */}

        {/* {this.state.showDupComponent ? (
            <KenoD_up
              ref={this.KenoD_up}
              childRef={(ref) => this.KenoD_up = ref}
              infoText={this.props.infoText}
              isMobile={this.props.isMobile}
              user={this.props.user}
              action={this.props.action}
              network={this.props.network}
              gameId={this.props.gameId}
              playerId={this.props.playerId}
              winAmount={this.props.targetResult.winAmount}
              resultCards={this.props.resultCard}
              bonusAmount={this.props.bonusAmt}
              starCounts={this.props.starCount}
              dupbalanc={this.props.balance}
              displaynonedup={this.disabledupComponent}
              winAmountindup={this.state.doubleUPTakeAmt}
              takeamounts={this.props.gameState.takeAmount}
              doubleUpGameState={this.state.doubleUpGameState}
              handId={this.props.gameState.handId}
              gameCloseHandler={this.gameCloseHandler.bind(this)}
            />
          ) : (
            ""
          )} */}
        {/* <Staranimation/> */}
        <KenoTable
          // gameCloseHandler={this.gameCloseHandler.bind(this)}
          closeBallanimation={this.closeBallanimation.bind(this)}
          // showDoubleUP={this.showDoubleUP.bind(this)}
          ref={this.kenotable}
          childRef={(ref) => (this.KenoTable = ref)}
          isGameActive={this.state.isGameActive}
          winningbalance={this.state.winningbalance}
          disabled={this.state.hidebtn}
          tbleBtn={this.state.tableBts}
          clickedNumbers={this.state.clickedNumbers}
          matchedNumbers={this.state.matchedNumbers}
          unmatchedNumbers={this.state.unmatchedNumbers}
          randomNums={this.state.randomNums}
          infoText={this.props.infoText}
          minBetLbl={this.props.minBetLbl}
          maxBetLbl={this.props.maxBetLbl}
          balance={this.props.balance}
          gameId={this.props.gameId}
          playerId={this.props.playerId}
          network={this.props.network}
          resultNo={this.props.resultNo}
          isMobile={this.props.isMobile}
          kn_takeAmount={this.props.kn_takeAmount}
          takeAmount={this.props.takeAmount}
          kn_chipValues={this.props.kn_chipValues}
          TotalBetAmouont={this.state.Total_Bet_Amount}
          betp={this.state.betAmount}
          takeAmounts={this.props.takeAmount}
          onSendClickedNumbers={this.handleClickedNumbers}
          gameState={this.props.gameState}
          takebtn={this.state.takebtn}
          dis_randBtn={this.disableRandomBtn.bind(this)}
          oneclickdisable={this.randone}
          afterclearenable={this.clearenable.bind(this)}
          winAmount={this.props.targetResult.winAmount}
          sendResult={this.kenoTakeamount}
          clearbetAmount={this.clearbetAmount.bind(this)}
          showgameundermaintence={this.showgameundermaintence.bind(this)}
          showballstake={this.showballs.bind(this)}
          clearballsballstake={this.clearballs.bind(this)}
          ballstu={this.state.balls}
          takehandlerandombtn={this.takehandlerandombtn.bind(this)}
          takeenableballs={this.takeenable}
          // disableD_UP={this.disableD_UP.bind(this)}
          minBetAmt={this.props.minBetAmt}
          maxBetAmt={this.props.maxBetAmt}
          updateBetString={this.updateBetString.bind(this)}
        // showdupbutton={this.state.showDupComponent}
        />

        <div className="popup_backkeno" id="winPop">
          <div className="kn_winpop">
            <span className="kn_win_txt">
              {updateWin}
            </span>
          </div>

          <div id="sparkAnim">
            <img className="kn_sparkAnim" id="sparkID" src="" />
          </div>
        </div>

        {/* <div
          className={
            this.props.isMobile
              ? "keno_payTable emptyPointer"
              : "keno_payTable"
          }
          onClick={(e) => {
            e.preventDefault();
            this.addwinrationtble();
          }}
          onMouseLeave={(e) => {
            e.preventDefault();
            this.hidewinrationtble();
          }}
          onMouseOut={(e) => {
            e.preventDefault();
            this.hidewinrationtble();
          }}
        >
          <img
            className={
              this.props.isMobile ? "i_symbol emptyPointer" : "i_symbol"
            }
            src={i_symbol}
          />
        </div> */}

        <div className="kenoratio_table" id="keno_winratiopop">
          <table
            id="payTable"
            className={
              this.props.isMobile ? "payTable emptyPointer" : "payTable"
            }
          >
            <tbody>
              <tr id="tableHead">
                <th>{this.props.language.NUM_MAT}</th>
                <th>{this.props.language.PRIZE_AMT}</th>
              </tr>
            </tbody>
            <tbody>
              {this.props.kn_payouts.map((item, index) => (
                <tr key={index}>
                  <td>{item.numMatches}</td>
                  <td>Bet x {item.prize}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>{this.state.balls}</div>
        <div id="starPos">{this.state.strs}</div>
        <div id="cagecontainer" className="cageanimation">
          <img
            className="kenocage"
            id="cageImg"
            src={imageObject["kenojars32"]}
          />
        </div>
      </div>

    );
  }
}

const mapStatesToProps = (state) => {
  return {
    language: state.languageObjs.languageObj,
  };
};
export default connect(mapStatesToProps)(Keno);
