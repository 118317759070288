import React from "react";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import chips from '../../../chips';
import { connect } from "react-redux";
import closeImg from '../../../../../../assets/am_close.svg';


 class RightButtonPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chipSelect : 1,
        }
        this.gameHistroy = [];
        this.filterHistory = [];
        this.historyArray = [];
        setTimeout(() => {
            this.updateHistory();
        }, 100);
      }
      updateHistory() { 
        this.historyArray = []
          if (this.props.gameState.history !== undefined && this.props.gameState.history.length != 0) {
            this.gameHistroy = this.props.gameState.history;
            this.filterHistory = this.gameHistroy.slice(Math.max(this.gameHistroy.length - 5, 0));
            for (let k = 0; k < this.filterHistory.length; k++) {
                let idNo = Number( this.filterHistory[k].resultNo )+1
              let resClassList = document.getElementById("id_" + idNo).parentElement.classList;
              const hist = {
                numb: this.filterHistory[k].resultNo,
                color: resClassList[0],
              };
              this.historyArray.push(hist);
            }
          }
      }
      componentDidMount() {
        const { childRef } = this.props;
        childRef(this);
      }

    render() {
        return (
            <div className="fd">
                <div className="history history_AR fd box_clm">
                    <div id="rouletteCover" className="rouletteCover"></div>
                    <div className="fd clr_ff text-center m_b_5 font_30 histHead">{this.props.language.History}</div>
                    <div className="fd">
                        <table className="fd american_HistoryTbl" cellPadding="0" cellSpacing="0">
                            <tbody>
                            <tr>
                            {this.historyArray != 0 ? (
                              this.historyArray.map((value, index) => {
                                return (
                                  <td key={index}>
                                    {" "}
                                    <span style={{color : value.color }}> {value.numb == 37 ?  '00' : value.numb  } </span>{" "}
                                  </td>
                                );
                              })
                            ) : (
                              <td> &nbsp; </td>
                            )}
                          </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                 
                    <button
                        id="zoomBtn"
                        className="fd ar_bt_1" disabled={this.props.disZoomBtn == 1}
                        name={this.props.zoomButtonText} onClick={(e) => {
                        e.preventDefault();
                        console.log(e.target.name);
                        if (this.props.zoomButtonValue === 0) {
                            this.props.action("ZoomON");
                        }
                        if (this.props.zoomButtonValue ===1) {
                            this.props.action("ZoomOFF");
                        }
                    }}>{this.props.zoomButtonText}</button>
                
                    <button
                        id="bet_take"
                        className={this.props.btnActive ? "fd ar_bt_1 active " : "fd ar_bt_1 z_0"}
                        name={this.props.betBtnText}
                        disabled={this.props.disBetBtn == 1 && this.props.betBtnText !== "TAKE"}
                        onClick={(e) => {
                            e.preventDefault();
                            console.log(e.target.name);

                            // let betSound;
                            if (this.props.betButtonValue === 2) {
                                console.log("TAKE Button ");
                                this.props.action("TAKE");
                            } else if (this.props.betButtonValue === 0) {
                                console.log("Prev Button Name: " + e.target.name)
                                this.props.action("PREV");
                            } else if (this.props.betButtonValue === 1) {
                                console.log("Send Bet Request");
                                this.betSound = new Audio(betSound);
                                this.betSound.play();
                                this.props.action("BET OK");
                            }
                        }}>{this.props.betBtnText}
                    </button>
                 
                
                    <button className="fd ar_bt_1" onClick={(e) => {
                        e.preventDefault();
                        this.props.action("double")
                    }} disabled={this.props.disDoubleBtn == 1}>{this.props.language.Double}
                    </button> 
                 
                    <button className="fd ar_bt_1" onClick={(e) => {
                        e.preventDefault();
                        this.props.action("clear")
                    }} disabled={this.props.disClearBtn == 1}> {this.props.language.Clear}
                    </button> 
                
            </div>
        );
    }
    
}

  
const mapStatesToProps = (state)=>{
  return {
    language : state.languageObjs.languageObj
  }
}
export default connect(mapStatesToProps)(RightButtonPanel)
