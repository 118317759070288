import React from "react"; 
import ar_num_img from "../../../../../../assets/games/americanRoulette/art_num_bg.svg";
import ar_ball_img from "../../../../../../assets/games/americanRoulette/ar_rollBall.png"; 

import arWheelBg from "../../../../../../assets/games/americanRouletteTimer/art_wheel_bg.svg";
import wheel_poll from "../../../../../../assets/games/americanRouletteTimer/art_wheel_poll.svg";
import wheelborderBottom from "../../../../../../assets/games/americanRouletteTimer/art_wheel_border.svg";

export default class WheelInner extends React.Component {
  constructor(props) {
    super(props); 
    this.reward = [0, 28, 9, 26, 30, 11, 7, 20, 32, 17, 5, 22, 34, 15, 3, 24, 36, 13, 1, 37, 27, 10, 25, 29, 12, 8, 19, 31, 18, 6, 21, 33, 16, 4, 23, 35, 14, 2];
    this.rewards = { 0: 0, 28: 1, 9: 2, 26: 3, 30: 4, 11: 5, 7: 6, 20: 7, 32: 8, 17: 9, 5: 10, 22: 11, 34: 12, 15: 13, 3: 14, 24: 15, 36: 16, 13: 17, 1: 18, 37: 19, 27: 20, 10: 21, 25: 22, 29: 23, 12: 24, 8: 25, 19: 26, 31: 27, 18: 28, 6: 29, 21: 30, 33: 31, 16: 32, 4: 33, 23: 34, 35: 35, 14: 36, 2: 37 };
    // this.wheelSound = new Audio(wheelSound); 

    this.ar_ballBg = "";
    this.ar_wheelAnim = "";
  }
  componentDidMount() {
    this.ar_ballBg = document.getElementById("ar_ballBg");
    this.ar_wheelAnim = document.getElementById("ar_wheelAnim")
    this.ar_rollBallBg = document.getElementById("ar_rollBall"); 
  } 

  onLoadWheel(s){
      console.log(s)
      this.resultNumber = Number(s); 
      if (s == 37) {
        s = "00";
      }
      let index = this.reward.indexOf(this.resultNumber);
      console.log(index);

      console.log(this.rewards);
      console.log(this.reward[index]); 
      var stopValueFirst = this.reward[index];
      console.log(stopValueFirst);
        
      const ar_ball_style = {
          transition: 'all 0s',
          transform: `rotate(${((index) * 9.478)}deg)`,
      };
      Object.assign(this.ar_ballBg.style, ar_ball_style);

      const arf_styles = {
          transition: 'all 0s',
          transform: `rotate(0deg)`,

      };
      Object.assign(this.ar_wheelAnim.style, arf_styles);
     
  }
    
  handleLoad = () => { 
  }; 

  stopWheel = () => {
    // this.wheelSound.pause(); 
    // this.playAudio = false;
  };
   
  spinTheWheel = (s) => { 
    this.resultNumber = s;   

    if (s === 37) {
      s = "00";
    }
     
    console.log("AR Result Numeber: "+this.resultNumber)
    let index = this.reward.indexOf(this.resultNumber);
    console.log(index);
    const ar_ball_style = {
      transition: 'all 7s',
      transform: `rotate(${(2160 + (index * 9.478))}deg)`,
    };
    Object.assign(this.ar_ballBg.style, ar_ball_style);

    const ar_styles = {
      transition: 'all 7s',
      transform: `rotate(${-1080}deg)`,

    };
    Object.assign(this.ar_wheelAnim.style, ar_styles);

    setTimeout(() => {
      this.ar_rollBallBg.style.transform = 'translateY(-165px)'
    }, 5000)

    this.ar_rollBallBg.style.transform = 'translateY(-240px)'

    setTimeout(() => {
      this.afterRotate(index);
    }, 7200)

  };
  afterRotate(resNumVal) {
    console.log('hi');
    console.log(resNumVal);        
    console.log(resNumVal * 9.478);
    
    const ball_style = {
        transition: 'all 0s',
        transform: `rotate(${(resNumVal * 9.478)}deg)`,
    };
    Object.assign(this.ar_ballBg.style, ball_style);

    const styles = {
        transition: 'all 0s',
        transform: `rotate(0deg)`,
    };
    Object.assign(this.ar_wheelAnim.style, styles);
    this.announceWinner(this.resultNumber);
    // this.wheelSound = {};
}
 

  announceWinner(value) {
    console.log("Result Number : "+this.resultNumber);
    this.props.showGlow(); 
    this.props.action(value); 

    // this.props.showGlow();
    // this.animation.stop();
    // this.props.action(value);
  }

  render() {
    return (

      <div className="ar_WheelWraper">
        <div className="ar_wheelBorder"> <img src={arWheelBg} /> </div>
        <div className="ar_wheelBorder_out zin_9"> <img src={wheelborderBottom} /> </div>
        <div className="ar_wheelAnimOne">
            <div className="ar_wheelAnimParent">
                <div className="ar_wheelAnim" id="ar_wheelAnim">
                    <img src={ar_num_img} />
                    <div className="ar_bollWrap" id="ar_ballBg">
                        <img className="ar_rollBall" id="ar_rollBall" src={ar_ball_img} />
                    </div>
                </div>
            </div>
        </div>
        <div className="art_wheelPoll"> <img src={wheel_poll} />  </div>
      </div>
    );
  }
}
