import React from "react"; 
import ert_num_img from "../../../../../../assets/games/erupeanRouletteTimer/ert_num_bg.png";
import ert_ball_img from "../../../../../../assets/games/erupeanRouletteTimer/ert_rollBall.png";  
import ertWheelBg from "../../../../../../assets/games/erupeanRouletteTimer/ert_wheel_bg.svg";
import wheel_poll from "../../../../../../assets/games/erupeanRouletteTimer/wheel_poll.svg"; 

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props);
        this.firstload = true;
        this.reward = [0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26];
        this.rewards = { 0: 0, 32: 1, 15: 2, 19: 3, 4: 4, 21: 5, 2: 6, 25: 7, 17: 8, 34: 9, 6: 10, 27: 11, 13: 12, 36: 13, 11: 14, 30: 15, 8: 16, 23: 17, 10: 18, 5: 19, 24: 20, 16: 21, 33: 22, 1: 23, 20: 24, 14: 25, 31: 26, 9: 27, 22: 28, 18: 29, 29: 30, 7: 31, 28: 32, 12: 33, 35: 34, 3: 35, 26: 36 };
        // this.wheelSound = new Audio(wheelSound);
        this.ert_ballBg = "";
        this.ert_wheelAnim = "";
    }

    componentDidMount() {  
        this.ert_ballBg = document.getElementById("ert_ballBg");
        this.ert_wheelAnim = document.getElementById("ert_wheelAnim")
        this.ert_rollBallBg = document.getElementById("ert_rollBall");

        if(this.props.gameState.history && this.firstload && this.props.gameState.history.length > 0){
            this.firstload = false; 
            let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            this.spinTheWheelOnload(resNo)   
          } else {
            let defaultNo = 0
            this.spinTheWheelOnload(defaultNo)
        } 
    } 

    spinTheWheelOnload = (s) => {
        console.log(s)
        this.resultNumber = Number(s);
        let index = this.reward.indexOf(this.resultNumber);
        var stopValueFirst = this.reward[index];
        const ert_ball_style = {
            transition: 'all 0s',
            transform: `rotate(${((index) * 9.73)}deg)`,
        };
        Object.assign(this.ert_ballBg.style, ert_ball_style);

        const ert_styles = {
        transition: 'all 0s',
        transform: `rotate(0deg)`,
        };
        Object.assign(this.ert_wheelAnim.style, ert_styles);
    };

    spinTheWheel = (s) => {
        this.setState({stopAnim: false});
        this.resultNumber = s;
        // this.wheelSound = new Audio(wheelSound);
        // this.wheelSound.currentTime = 1;
        // this.wheelSound.play();
        console.log("ERT Result Numeber: "+this.resultNumber)
        let index = this.reward.indexOf(this.resultNumber);
        console.log(index);
        const ert_ball_style = {
          transition: 'all 7s',
          transform: `rotate(${(2160 + (index * 9.73))}deg)`,
        };
        Object.assign(this.ert_ballBg.style, ert_ball_style);
    
        const ert_styles = {
          transition: 'all 7s',
          transform: `rotate(${-1080}deg)`,
    
        };
        Object.assign(this.ert_wheelAnim.style, ert_styles);
    
        setTimeout(() => {
          this.ert_rollBallBg.style.transform = 'translateY(-165px)'
        }, 5000)
    
        this.ert_rollBallBg.style.transform = 'translateY(-240px)'
    
        setTimeout(() => {
          this.afterRotate(index);
        }, 7200)
    };

    afterRotate(resNumVal) {
        console.log('hi');
        console.log(resNumVal);        
        console.log(resNumVal * 9.73);
    
        const ball_style = {
            transition: 'all 0s',
            transform: `rotate(${(resNumVal * 9.73)}deg)`,
        };
        Object.assign(this.ert_ballBg.style, ball_style);
    
        const styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.ert_wheelAnim.style, styles);
        this.announceWinner(this.resultNumber);
        // setTimeout(() => {
        // }, 200)
        // this.wheelSound = {};
    }

    announceWinner(value) {      
        this.props.action(value);  
        this.setState({stopAnim: false});
    }

    render() {
        return (
            <div className="ert_WheelWraper">
                <div className="ert_wheelBorder"> <img src={ertWheelBg} /> </div> 
                <div class="ert_wheelAnimOne">
                    <div className="ert_wheelAnimParent">
                        <div class="ert_wheelAnim" id="ert_wheelAnim">
                            <img src={ert_num_img} />
                            <div class="ert_bollWrap" id="ert_ballBg">
                                <img class="ert_rollBall" id="ert_rollBall" src={ert_ball_img} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ert_wheel_poll"> <img src={wheel_poll} /> </div>
            </div>
        );
    }
}
