import React from "react";
import WheelInner from "./europeanRouletteTimerWheel";
// import WheelBorder from "./europeanRouletteWheelTimerBorder";

export default class Wheel extends React.Component{
    constructor(props){
        super(props)
        this.artWheelRef = React.createRef();
        this.inWid = window.innerWidth
        this.inHt = window.innerHeight 
        this.widthMain = 950;
        this.heightMain = 520;
        this.ScaleX = 1; 
        this.posX = 0.500;
        this.ScaleY = 0.47;
        this.posY = 0.95;
        this.fontSize = 35;
        this.ballSize = 13;
        this.textYpos = 0.13 
        this.ballPosAfterRotate = 1299
        this.ballPosOnload = -2
         
    }
    componentStateHandler(){

    }
    stopWheelSound =() =>{
       // this.artWheelRef.current.stopWheel();
    }
	spinTheWheel = (s) => {
        this.artWheelRef.current.spinTheWheel(s);
    }
    wheelHandler(position){
        //console.log(position);
        this.props.action(position);
    }
    stopWheelAnim(){
        console.log("Wheel --> Stop wheel animation.")       
       // this.artWheelRef.current.stopWheelAnim();       
    }
    render(){
        return(
            <React.Fragment>
            <div id="amRoutTimerWheel" 
            style={{
                width:this.widthMain,      
                height:this.heightMain,                 
                transform: `scale(${this.props.zoom})`,
                transformOrigin: 'top',
                zIndex:this.props.zindex,
                position:'relative',}} >
                <WheelInner ballPosOnload={this.ballPosOnload} ballPosAfterRotate={this.ballPosAfterRotate}
                textYpos={this.textYpos} ballSize={this.ballSize} fontSize={this.fontSize} 
                width={this.widthMain} height={this.heightMain} 
                ScaleX={this.ScaleX} ScaleY={this.ScaleY} posX={this.posX} posY={this.posY}
                 onFocus={this.props.onFocus} gameState={this.props.gameState} showGlow={this.props.showGlow} ref={this.artWheelRef} zindex={this.props.zindex} zoom={this.props.zoom} action={this.wheelHandler.bind(this)} isMobile={this.props.isMobile}></WheelInner>
                 
                </div>
                </React.Fragment>
        )
    }
}
