import React from "react";
import "../../../../../css/games/funSoratTimer/title.css"
import SoratTimer from "../../../../../../assets/titles/indie/soaratName.svg";
import user from "../../../../../../assets/login/user.svg";

export default function Title(props) {
    return <div className="fd text_center indSoratTitle">
                       <img className="title" src={SoratTimer} alt="Sorat"/>
       {/* <div className="fd box_clm soratBx ">
                    <div className="userLbl soratUser">
                       <img className="user" src={user} alt={user}/> {props.user}
                   </div> 
               </div>*/}
    </div>
}