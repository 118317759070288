import React, { useEffect, useState } from 'react';
import "./triplechanceStyle.css";
import chips from '../../chips';

const TripleChance = () => {
    const [mount, setMount] = useState(true);
    const [chipSelect, setChipSelect] = useState(1);
    var idsArray = [];
    var tipleLabelArray = [];
    let tripleIdsArray = [];
    let betString = [];
    
    useEffect(() => { 
        if(mount){
            countFun()
                for(let i=0; i<100; i++){
                    var dbl_Ids = document.getElementById(`dbl_0${i}`)
                    var tprl_Ids = document.getElementById(`tprl_0${i}`)
                    console.log("Triple Id's: "+tprl_Ids)
                    idsArray.push(dbl_Ids, tprl_Ids)
                }
                for(let i=0; i<10; i++){
                    var tprl_label_Ids = document.getElementById(`tprlLabel_${i}00`)
                    var single_Ids = document.getElementById(`single_${i}`)
                    idsArray.push(single_Ids);
                    tipleLabelArray.push(tprl_label_Ids);

                }

            setMount(false)

            idsArray.forEach(function(element){
                element.addEventListener("click", selectValue)
            })
            tipleLabelArray.forEach(function(element){
                element.addEventListener("click", getInnerNumb)
            })
        }
    });

    const selectValue = (elem) =>{
        betString.push(elem.path[0].innerText);
       /* console.log('Selected Value: ', elem.path[0].innerText);
        console.log("Bet String");*/
        console.log( 'chipSelect ');
        console.log( chipSelect );
        console.log(betString);
    }

    const getInnerNumb = (elem) => { 
        console.log('Check', elem.path[0].innerText);
        let triple_Ch = document.getElementById("triple_Ch");
        let selectedNum = Number(elem.path[0].innerText);
        document.getElementById("triple_Ch").innerHTML = "";
        tripleIdsArray = [];
        var tprl_Ids = 0;
        if(selectedNum == 0){
            for(let i=0; i<100; i++){

                let numb = String(i+Number(elem.path[0].innerText));
                if(numb.length == 1){
                    triple_Ch.innerHTML += `<div class="boxCh" id="tprl_0${i}"> 00${numb} </div>`
                }else{
                    triple_Ch.innerHTML += `<div class="boxCh" id="tprl_0${i}"> 0${numb} </div>`
                }

                var tprl_Ids = document.getElementById(`tprl_0${i}`);
                tripleIdsArray.push(tprl_Ids);
            }
        }else{
            for(let j=0; j<100; j++){
                let numb = Number(j+selectedNum);
                //console.log("Numb: "+numb);
                triple_Ch.innerHTML += `<div class="boxCh" id="tprl_${numb}"> ${numb} </div>`
                var tprl_Ids = document.getElementById(`tprl_${numb}`);
                tripleIdsArray.push(tprl_Ids)
            }
        }

        for(let k=0; k<tripleIdsArray.length; k++){
            document.getElementById(tripleIdsArray[k].id).addEventListener("click", selectValue);
        }
    }


    const countFun = () => {
        var double_Ch = document.getElementById('double_Ch');
        var triple_Ch = document.getElementById('triple_Ch');
        var triple_Label = document.getElementById('triple_Label');
        var single_Ch = document.getElementById('single_Ch');
        for(let i=0; i<100; i++){
            let numb = String(i); 
            console.log( numb.length )
            if(numb.length == 1){
                double_Ch.innerHTML += `<div class="boxCh" id="dbl_0${i}"> 0${i} </div>`
                triple_Ch.innerHTML += `<div class="boxCh" id="tprl_0${i}"> 00${i} </div>`
            } else {
                double_Ch.innerHTML += `<div class="boxCh" id="dbl_0${i}"> ${i} </div>`
                triple_Ch.innerHTML += `<div class="boxCh" id="tprl_0${i}"> 0${i} </div>`
            }
        } 
        for(let i=0; i<10; i++){
            triple_Label.innerHTML += `<div class="boxCh" id="tprlLabel_${i}00"> ${i}00 </div>`
            single_Ch.innerHTML += `<div class="boxCh" id="single_${i}"> ${i} </div>`
        }
         
    }
    const getChipVal = (val) => {
        console.log( 'chipSelect '+val )
       // chipSelect  =  val;
        console.log( chipSelect );
       setChipSelect(val);
        //console.log( chipSelect )
    }

  return (
    <div className="fd">
      <div className="fd">
        <div className="row">
          <div className="col-4">
            <div id="double_Ch" className='fd'></div>
          </div>
          <div className="col-4"></div>
          <div className="col-4">
            <div id="triple_Label" className='fd' ></div>
            <div id="triple_Ch" className='fd'></div>
          </div>
        </div>
      </div>
      <div className="fd">
        <div className="row">
        <div className="col-4"></div>
          <div className="col-4">
            <div id="single_Ch" className='fd'></div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
      <div className="fd">
        <div className="row">
            <div className="col-4"></div>
            <div className="col-4"> 
                <div className="fd">
                    <div className="row"> 
                        <div className='fd trplChips'>
                            {chips.map((data, i) => (
                            <div className="trplChipCard" key={data.src}>
                                <img src={data.src} alt={data.val}
                                className={chipSelect === data.val ? "active" : "inactive"}
                                onClick={(e)=> {e.preventDefault(); getChipVal(data.val);}}  />
                            </div>))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-4"></div>
        </div>

      </div>
    </div>
  );
};

export default TripleChance;
