import React from "react";
import tickSound from "../../../../../../assets/sounds/tick.mp3";
export default class MW_TimerInSeconds extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            timeInSec: 30,
            getTime: 30,
            callsec:true,
            timerStop:true,
            isBetSent: false,
            isDisableSent: false,
            isTimerSent:false,
            // isSpectateCheck: false,
        };      
    }
    componentDidMount(){
         document.getElementById("timeSpan").style.display = "none";
        this.timerclear = setInterval(this.myTimer, 1000);
    }
    componentWillUnmount(){
        clearInterval(this.timerclear);
    }
    sendTimer(tt) { 
        document.getElementById('timeSpan').style.display = 'block';
        this.setState({ getTime: tt });
        // if((tt >= 30 || tt <=5)){
        //     document.getElementById("mw_Cover").style.display = 'block';
        // }
        this.myTimer();
    }

    exitGame(){
        clearInterval(this.timerclear);
    }
   
    myTimer = () => {    
        if (this.state.getTime != null && this.state.getTime != NaN && this.state.getTime >=0) {     
            let d = this.state.getTime;
            let timer = document.getElementById("timer");  
            let mw_Cover = document.getElementById("mw_Cover");
        //    timer.classList.add("mw_timer_glow");  
            let n = --d;
            let sec = parseInt(n % 60);
            let secS = sec.toString();
            if (String(sec).length < 2) {
                secS = "0" + sec.toString();
            }
            this.setState({ getTime: secS })
            this.setState({ timeInSec: secS }); 
            if (this.state.getTime != null) {                   
                if (n <= 0) {
                    this.setState({ getTime: 30, isDisableSent: false });
                    this.state.getTime = 30;   
                    this.setState({isTimerSent: false});                                                     
                }
                                
                if (n <= 5 && n >= 0) {
                     mw_Cover.style.display = 'block';
                     mw_Cover.style.zIndex = "355";
                     document.getElementById("betButton").style.zIndex = "350";
                    // this.props.action("disableBtns");       
                    this.props.disableKeyBoard();
                    if(!this.state.isBetSent && this.props.takeAmount == 0 && !this.props.isBetSent){                        
                        this.setState({isBetSent: true});     
                        this.props.action("SPIN"); 
                    }                      
                    if(!this.state.isDisableSent){
                        this.setState({isDisableSent: true});
                        this.state.isDisableSent = true;                        
                        this.props.action("disableBtns");                          
                    } 
                }else{
                    this.setState({isBetSent: false});   
                    // mw_Cover.style.zIndex = "350";
                }  
                
                // if(n == 1){                   
                //     if(!this.state.isSpectateCheck){                        
                //       this.setState({isSpectateCheck: true});
                //       this.props.chek_Spec();  
                //     }
                // }
                if(n == 10){
                    if(!this.state.isTimerSent){
                        this.setState({isTimerSent: true});
                        this.getTimer();
                    }
                }
                if(n== 5){
                    this.props.clearBetAnim();
                }
                // if(n < 30 && n > 3){
                //     this.setState({isSpectateCheck: false});
                // }                
                if(n > 5 && n < 27){
                    if(this.props.winCover == false ) { 
                        //console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,5")
                        document.getElementById("mw_Cover").style.display = 'none'; 
                    } else { 
                        //console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,timr"+this.props.winCover)
                        document.getElementById("mw_Cover").style.display = 'block';
                       // this.props.action("enableBtns"); 
                    }                                    
                }
                if (n <= 10 && n >= 5) {
                    // this.tickSound = new Audio(tickSound);
                    // this.tickSound.play();   
                    this.props.action("PlayTickSound");                  
                    if(timer && !timer.classList.contains("mw_timer_glow")){
                        timer.classList.add("mw_timer_glow");                    
                    }
                }else {
                    if(timer && timer.classList.contains('mw_timer_glow')){
                        timer.classList.remove("mw_timer_glow");
                        // this.tickSound = {};
                        this.props.action("StopTickSound"); 
                    }
                }               
            }
        }
    }

    getTimer() {
        this.setState({isTimerSent: true}); 
            const body = {
                sessionId: sessionStorage.getItem('sessionId'),
                CN: "GET_GAME_TIMER",
                object: {
                    gameId: this.props.gameId,
                    playerId: Number(this.props.playerId),
                }
            }
        this.props.network.sendof(body);
        this.body={};           
    }
   
    render() {
        return <div>
            <div className="mw_timer_Sec" id="timer"> <span id="timeSpan"> {this.state.timeInSec} </span> </div>;
        </div>
    }
}
