
import kenojars32 from "../../../../../assets/games/keno/Ballroll/Ballroll_00031.png"
import kenojars33 from "../../../../../assets/games/keno/Ballroll/Ballroll_00032.png"
import kenojars34 from "../../../../../assets/games/keno/Ballroll/Ballroll_00033.png"
import kenojars35 from "../../../../../assets/games/keno/Ballroll/Ballroll_00034.png"
import kenojars36 from "../../../../../assets/games/keno/Ballroll/Ballroll_00035.png"
import kenojars37 from "../../../../../assets/games/keno/Ballroll/Ballroll_00036.png"
import kenojars38 from "../../../../../assets/games/keno/Ballroll/Ballroll_00037.png"
import kenojars39 from "../../../../../assets/games/keno/Ballroll/Ballroll_00038.png"
import kenojars40 from "../../../../../assets/games/keno/Ballroll/Ballroll_00039.png"
import kenojars41 from "../../../../../assets/games/keno/Ballroll/Ballroll_00040.png"
import kenojars42 from "../../../../../assets/games/keno/Ballroll/Ballroll_00041.png"
import kenojars43 from "../../../../../assets/games/keno/Ballroll/Ballroll_00042.png"
import kenojars44 from "../../../../../assets/games/keno/Ballroll/Ballroll_00043.png"
import kenojars45 from "../../../../../assets/games/keno/Ballroll/Ballroll_00044.png"
import kenojars46 from "../../../../../assets/games/keno/Ballroll/Ballroll_00045.png"
import kenojars47 from "../../../../../assets/games/keno/Ballroll/Ballroll_00046.png"
import kenojars48 from "../../../../../assets/games/keno/Ballroll/Ballroll_00047.png"
import kenojars49 from "../../../../../assets/games/keno/Ballroll/Ballroll_00048.png"
import kenojars50 from "../../../../../assets/games/keno/Ballroll/Ballroll_00049.png"
import kenojars51 from "../../../../../assets/games/keno/Ballroll/Ballroll_00050.png"
import kenojars52 from "../../../../../assets/games/keno/Ballroll/Ballroll_00051.png"
import kenojars53 from "../../../../../assets/games/keno/Ballroll/Ballroll_00052.png"
import kenojars54 from "../../../../../assets/games/keno/Ballroll/Ballroll_00053.png"
import kenojars55 from "../../../../../assets/games/keno/Ballroll/Ballroll_00054.png"
import kenojars56 from "../../../../../assets/games/keno/Ballroll/Ballroll_00055.png"
import kenojars57 from "../../../../../assets/games/keno/Ballroll/Ballroll_00056.png"
import kenojars58 from "../../../../../assets/games/keno/Ballroll/Ballroll_00057.png"
import kenojars59 from "../../../../../assets/games/keno/Ballroll/Ballroll_00058.png"
import kenojars60 from "../../../../../assets/games/keno/Ballroll/Ballroll_00059.png"
import kenojars61 from "../../../../../assets/games/keno/Ballroll/Ballroll_00060.png"
import kenojars62 from "../../../../../assets/games/keno/Ballroll/Ballroll_00061.png"
import kenojars63 from "../../../../../assets/games/keno/Ballroll/Ballroll_00062.png"
import kenojars64 from "../../../../../assets/games/keno/Ballroll/Ballroll_00063.png"
import kenojars65 from "../../../../../assets/games/keno/Ballroll/Ballroll_00064.png"
import kenojars66 from "../../../../../assets/games/keno/Ballroll/Ballroll_00065.png"
import kenojars67 from "../../../../../assets/games/keno/Ballroll/Ballroll_00066.png"
import kenojars68 from "../../../../../assets/games/keno/Ballroll/Ballroll_00067.png"
import kenojars69 from "../../../../../assets/games/keno/Ballroll/Ballroll_00068.png"
import kenojars70 from "../../../../../assets/games/keno/Ballroll/Ballroll_00069.png"

import kenojars71 from "../../../../../assets/games/keno/Ballroll/Ballroll_00070.png"
import kenojars72 from "../../../../../assets/games/keno/Ballroll/Ballroll_00071.png"
import kenojars73 from "../../../../../assets/games/keno/Ballroll/Ballroll_00072.png"
import kenojars74 from "../../../../../assets/games/keno/Ballroll/Ballroll_00073.png"
import kenojars75 from "../../../../../assets/games/keno/Ballroll/Ballroll_00074.png"
import kenojars76 from "../../../../../assets/games/keno/Ballroll/Ballroll_00075.png"
import kenojars77 from "../../../../../assets/games/keno/Ballroll/Ballroll_00076.png"
import kenojars78 from "../../../../../assets/games/keno/Ballroll/Ballroll_00077.png"
import kenojars79 from "../../../../../assets/games/keno/Ballroll/Ballroll_00078.png"
import kenojars80 from "../../../../../assets/games/keno/Ballroll/Ballroll_00079.png"
import kenojars81 from "../../../../../assets/games/keno/Ballroll/Ballroll_00080.png"
import kenojars82 from "../../../../../assets/games/keno/Ballroll/Ballroll_00081.png"
import kenojars83 from "../../../../../assets/games/keno/Ballroll/Ballroll_00082.png"
import kenojars84 from "../../../../../assets/games/keno/Ballroll/Ballroll_00083.png"
import kenojars85 from "../../../../../assets/games/keno/Ballroll/Ballroll_00084.png"
import kenojars86 from "../../../../../assets/games/keno/Ballroll/Ballroll_00085.png"
import kenojars87 from "../../../../../assets/games/keno/Ballroll/Ballroll_00086.png"
import kenojars88 from "../../../../../assets/games/keno/Ballroll/Ballroll_00087.png"
import kenojars89 from "../../../../../assets/games/keno/Ballroll/Ballroll_00088.png"
import kenojars90 from "../../../../../assets/games/keno/Ballroll/Ballroll_00089.png"
import kenojars91 from "../../../../../assets/games/keno/Ballroll/Ballroll_00090.png"
import kenojars92 from "../../../../../assets/games/keno/Ballroll/Ballroll_00091.png"
import kenojars93 from "../../../../../assets/games/keno/Ballroll/Ballroll_00092.png"
import kenojars94 from "../../../../../assets/games/keno/Ballroll/Ballroll_00093.png"
import kenojars95 from "../../../../../assets/games/keno/Ballroll/Ballroll_00094.png"
import kenojars96 from "../../../../../assets/games/keno/Ballroll/Ballroll_00095.png"
import kenojars97 from "../../../../../assets/games/keno/Ballroll/Ballroll_00096.png"
import kenojars98 from "../../../../../assets/games/keno/Ballroll/Ballroll_00097.png"
import kenojars99 from "../../../../../assets/games/keno/Ballroll/Ballroll_00098.png"
import kenojars100 from "../../../../../assets/games/keno/Ballroll/Ballroll_00099.png"
import kenojars101 from "../../../../../assets/games/keno/Ballroll/Ballroll_00100.png"
import kenojars102 from "../../../../../assets/games/keno/Ballroll/Ballroll_00101.png"
import kenojars103 from "../../../../../assets/games/keno/Ballroll/Ballroll_00102.png"
import kenojars104 from "../../../../../assets/games/keno/Ballroll/Ballroll_00103.png"
import kenojars105 from "../../../../../assets/games/keno/Ballroll/Ballroll_00104.png"
import kenojars106 from "../../../../../assets/games/keno/Ballroll/Ballroll_00105.png"
import kenojars107 from "../../../../../assets/games/keno/Ballroll/Ballroll_00106.png"
import kenojars108 from "../../../../../assets/games/keno/Ballroll/Ballroll_00107.png"
import kenojars109 from "../../../../../assets/games/keno/Ballroll/Ballroll_00108.png"
import kenojars110 from "../../../../../assets/games/keno/Ballroll/Ballroll_00109.png"
import kenojars111 from "../../../../../assets/games/keno/Ballroll/Ballroll_00110.png"
import kenojars112 from "../../../../../assets/games/keno/Ballroll/Ballroll_00111.png"
import kenojars113 from "../../../../../assets/games/keno/Ballroll/Ballroll_00112.png"
import kenojars114 from "../../../../../assets/games/keno/Ballroll/Ballroll_00113.png"
import kenojars115 from "../../../../../assets/games/keno/Ballroll/Ballroll_00114.png"
import kenojars116 from "../../../../../assets/games/keno/Ballroll/Ballroll_00115.png"
import kenojars117 from "../../../../../assets/games/keno/Ballroll/Ballroll_00116.png"
import kenojars118 from "../../../../../assets/games/keno/Ballroll/Ballroll_00117.png"
import kenojars119 from "../../../../../assets/games/keno/Ballroll/Ballroll_00118.png"
import kenojars120 from "../../../../../assets/games/keno/Ballroll/Ballroll_00119.png"
import kenojars121 from "../../../../../assets/games/keno/Ballroll/Ballroll_00120.png"
import kenojars122 from "../../../../../assets/games/keno/Ballroll/Ballroll_00121.png"
import kenojars123 from "../../../../../assets/games/keno/Ballroll/Ballroll_00123.png"
import kenojars124 from "../../../../../assets/games/keno/Ballroll/Ballroll_00124.png"
import kenojars125 from "../../../../../assets/games/keno/Ballroll/Ballroll_00125.png"
import kenojars126 from "../../../../../assets/games/keno/Ballroll/Ballroll_00126.png"
import kenojars127 from "../../../../../assets/games/keno/Ballroll/Ballroll_00127.png"
import kenojars128 from "../../../../../assets/games/keno/Ballroll/Ballroll_00128.png"
import kenojars129 from "../../../../../assets/games/keno/Ballroll/Ballroll_00129.png"
import kenojars130 from "../../../../../assets/games/keno/Ballroll/Ballroll_00130.png"
import kenojars131 from "../../../../../assets/games/keno/Ballroll/Ballroll_00131.png"
import kenojars132 from "../../../../../assets/games/keno/Ballroll/Ballroll_00132.png"
import kenojars133 from "../../../../../assets/games/keno/Ballroll/Ballroll_00133.png"
import kenojars134 from "../../../../../assets/games/keno/Ballroll/Ballroll_00134.png"
import kenojars135 from "../../../../../assets/games/keno/Ballroll/Ballroll_00135.png"
import kenojars136 from "../../../../../assets/games/keno/Ballroll/Ballroll_00136.png"
import kenojars137 from "../../../../../assets/games/keno/Ballroll/Ballroll_00137.png"
import kenojars138 from "../../../../../assets/games/keno/Ballroll/Ballroll_00138.png"
import kenojars139 from "../../../../../assets/games/keno/Ballroll/Ballroll_00139.png"
import kenojars140 from "../../../../../assets/games/keno/Ballroll/Ballroll_00140.png"



import kenojars141 from "../../../../../assets/games/keno/Ballroll/Ballroll_00141.png"
import kenojars142 from "../../../../../assets/games/keno/Ballroll/Ballroll_00142.png"
import kenojars143 from "../../../../../assets/games/keno/Ballroll/Ballroll_00143.png"
import kenojars144 from "../../../../../assets/games/keno/Ballroll/Ballroll_00144.png"
import kenojars145 from "../../../../../assets/games/keno/Ballroll/Ballroll_00145.png"
import kenojars146 from "../../../../../assets/games/keno/Ballroll/Ballroll_00146.png"
import kenojars147 from "../../../../../assets/games/keno/Ballroll/Ballroll_00147.png"
import kenojars148 from "../../../../../assets/games/keno/Ballroll/Ballroll_00148.png"
import kenojars149 from "../../../../../assets/games/keno/Ballroll/Ballroll_00149.png"
import kenojars150 from "../../../../../assets/games/keno/Ballroll/Ballroll_00150.png"
import kenojars151 from "../../../../../assets/games/keno/Ballroll/Ballroll_00151.png"
import kenojars152 from "../../../../../assets/games/keno/Ballroll/Ballroll_00152.png"
import kenojars153 from "../../../../../assets/games/keno/Ballroll/Ballroll_00153.png"
import kenojars154 from "../../../../../assets/games/keno/Ballroll/Ballroll_00154.png"
import kenojars155 from "../../../../../assets/games/keno/Ballroll/Ballroll_00155.png"
import kenojars156 from "../../../../../assets/games/keno/Ballroll/Ballroll_00156.png"
import kenojars157 from "../../../../../assets/games/keno/Ballroll/Ballroll_00157.png"
import kenojars158 from "../../../../../assets/games/keno/Ballroll/Ballroll_00158.png"
import kenojars159 from "../../../../../assets/games/keno/Ballroll/Ballroll_00160.png"
import kenojars160 from "../../../../../assets/games/keno/Ballroll/Ballroll_00161.png"
import kenojars161 from "../../../../../assets/games/keno/Ballroll/Ballroll_00162.png"
import kenojars162 from "../../../../../assets/games/keno/Ballroll/Ballroll_00163.png"
import kenojars163 from "../../../../../assets/games/keno/Ballroll/Ballroll_00164.png"
import kenojars164 from "../../../../../assets/games/keno/Ballroll/Ballroll_00165.png"
import kenojars165 from "../../../../../assets/games/keno/Ballroll/Ballroll_00166.png"
import kenojars166 from "../../../../../assets/games/keno/Ballroll/Ballroll_00167.png"
import kenojars167 from "../../../../../assets/games/keno/Ballroll/Ballroll_00168.png"
import kenojars168 from "../../../../../assets/games/keno/Ballroll/Ballroll_00169.png"
import kenojars169 from "../../../../../assets/games/keno/Ballroll/Ballroll_00170.png"
import kenojars170 from "../../../../../assets/games/keno/Ballroll/Ballroll_00171.png"
import kenojars171 from "../../../../../assets/games/keno/Ballroll/Ballroll_00172.png"
import kenojars172 from "../../../../../assets/games/keno/Ballroll/Ballroll_00173.png"
import kenojars173 from "../../../../../assets/games/keno/Ballroll/Ballroll_00174.png"
import kenojars174 from "../../../../../assets/games/keno/Ballroll/Ballroll_00175.png"
import kenojars175 from "../../../../../assets/games/keno/Ballroll/Ballroll_00176.png"
import kenojars176 from "../../../../../assets/games/keno/Ballroll/Ballroll_00177.png"
import kenojars177 from "../../../../../assets/games/keno/Ballroll/Ballroll_00178.png"
import kenojars178 from "../../../../../assets/games/keno/Ballroll/Ballroll_00179.png"
import kenojars179 from "../../../../../assets/games/keno/Ballroll/Ballroll_00180.png"
import kenojars180 from "../../../../../assets/games/keno/Ballroll/Ballroll_00181.png"
import kenojars181 from "../../../../../assets/games/keno/Ballroll/Ballroll_00182.png"
import kenojars182 from "../../../../../assets/games/keno/Ballroll/Ballroll_00183.png"
import kenojars183 from "../../../../../assets/games/keno/Ballroll/Ballroll_00184.png"
import kenojars184 from "../../../../../assets/games/keno/Ballroll/Ballroll_00185.png"
import kenojars185 from "../../../../../assets/games/keno/Ballroll/Ballroll_00186.png"
import kenojars186 from "../../../../../assets/games/keno/Ballroll/Ballroll_00187.png"
import kenojars187 from "../../../../../assets/games/keno/Ballroll/Ballroll_00188.png"
import kenojars188 from "../../../../../assets/games/keno/Ballroll/Ballroll_00190.png"
import kenojars189 from "../../../../../assets/games/keno/Ballroll/Ballroll_00202.png"
import kenojars190 from "../../../../../assets/games/keno/Ballroll/Ballroll_00191.png"
import kenojars191 from "../../../../../assets/games/keno/Ballroll/Ballroll_00192.png"
import kenojars192 from "../../../../../assets/games/keno/Ballroll/Ballroll_00193.png"
import kenojars193 from "../../../../../assets/games/keno/Ballroll/Ballroll_00194.png"
import kenojars194 from "../../../../../assets/games/keno/Ballroll/Ballroll_00195.png"
import kenojars195 from "../../../../../assets/games/keno/Ballroll/Ballroll_00196.png"
import kenojars196 from "../../../../../assets/games/keno/Ballroll/Ballroll_00197.png"
import kenojars197 from "../../../../../assets/games/keno/Ballroll/Ballroll_00198.png"
import kenojars198 from "../../../../../assets/games/keno/Ballroll/Ballroll_00199.png"
import kenojars199 from "../../../../../assets/games/keno/Ballroll/Ballroll_00200.png"
import kenojars200 from "../../../../../assets/games/keno/Ballroll/Ballroll_00201.png"
import kenojars201 from "../../../../../assets/games/keno/Ballroll/Ballroll_00202.png"
import kenojars202 from "../../../../../assets/games/keno/Ballroll/Ballroll_00203.png"
import kenojars203 from "../../../../../assets/games/keno/Ballroll/Ballroll_00204.png"
import kenojars204 from "../../../../../assets/games/keno/Ballroll/Ballroll_00205.png"
import kenojars205 from "../../../../../assets/games/keno/Ballroll/Ballroll_00206.png"
import kenojars206 from "../../../../../assets/games/keno/Ballroll/Ballroll_00207.png"
import kenojars207 from "../../../../../assets/games/keno/Ballroll/Ballroll_00208.png"
import kenojars208 from "../../../../../assets/games/keno/Ballroll/Ballroll_00209.png"
import kenojars209 from "../../../../../assets/games/keno/Ballroll/Ballroll_00210.png"
import kenojars210 from "../../../../../assets/games/keno/Ballroll/Ballroll_00211.png"
import kenojars211 from "../../../../../assets/games/keno/Ballroll/Ballroll_00212.png"



import kenojars212 from "../../../../../assets/games/keno/Ballroll/Ballroll_00213.png"
import kenojars213 from "../../../../../assets/games/keno/Ballroll/Ballroll_00214.png"
import kenojars214 from "../../../../../assets/games/keno/Ballroll/Ballroll_00215.png"
import kenojars215 from "../../../../../assets/games/keno/Ballroll/Ballroll_00216.png"
import kenojars216 from "../../../../../assets/games/keno/Ballroll/Ballroll_00217.png"
import kenojars217 from "../../../../../assets/games/keno/Ballroll/Ballroll_00218.png"
import kenojars218 from "../../../../../assets/games/keno/Ballroll/Ballroll_00219.png"
import kenojars219 from "../../../../../assets/games/keno/Ballroll/Ballroll_00220.png"
import kenojars220 from "../../../../../assets/games/keno/Ballroll/Ballroll_00221.png"
import kenojars221 from "../../../../../assets/games/keno/Ballroll/Ballroll_00222.png"
import kenojars222 from "../../../../../assets/games/keno/Ballroll/Ballroll_00223.png"
import kenojars223 from "../../../../../assets/games/keno/Ballroll/Ballroll_00224.png"
import kenojars224 from "../../../../../assets/games/keno/Ballroll/Ballroll_00225.png"
import kenojars225 from "../../../../../assets/games/keno/Ballroll/Ballroll_00226.png"
import kenojars226 from "../../../../../assets/games/keno/Ballroll/Ballroll_00227.png"
import kenojars227 from "../../../../../assets/games/keno/Ballroll/Ballroll_00228.png"
import kenojars228 from "../../../../../assets/games/keno/Ballroll/Ballroll_00229.png"
import kenojars229 from "../../../../../assets/games/keno/Ballroll/Ballroll_00230.png"
import kenojars230 from "../../../../../assets/games/keno/Ballroll/Ballroll_00231.png"
import kenojars231 from "../../../../../assets/games/keno/Ballroll/Ballroll_00232.png"
import kenojars232 from "../../../../../assets/games/keno/Ballroll/Ballroll_00233.png"
import kenojars233 from "../../../../../assets/games/keno/Ballroll/Ballroll_00234.png"
import kenojars234 from "../../../../../assets/games/keno/Ballroll/Ballroll_00235.png"
import kenojars235 from "../../../../../assets/games/keno/Ballroll/Ballroll_00236.png"
import kenojars236 from "../../../../../assets/games/keno/Ballroll/Ballroll_00237.png"
import kenojars237 from "../../../../../assets/games/keno/Ballroll/Ballroll_00238.png"
import kenojars238 from "../../../../../assets/games/keno/Ballroll/Ballroll_00239.png"
import kenojars239 from "../../../../../assets/games/keno/Ballroll/Ballroll_00240.png"
import kenojars240 from "../../../../../assets/games/keno/Ballroll/Ballroll_00241.png"
import kenojars241 from "../../../../../assets/games/keno/Ballroll/Ballroll_00242.png"
import kenojars242 from "../../../../../assets/games/keno/Ballroll/Ballroll_00243.png"
import kenojars243 from "../../../../../assets/games/keno/Ballroll/Ballroll_00244.png"
import kenojars244 from "../../../../../assets/games/keno/Ballroll/Ballroll_00245.png"
import kenojars245 from "../../../../../assets/games/keno/Ballroll/Ballroll_00246.png"
import kenojars246 from "../../../../../assets/games/keno/Ballroll/Ballroll_00247.png"
import kenojars247 from "../../../../../assets/games/keno/Ballroll/Ballroll_00248.png"
import kenojars248 from "../../../../../assets/games/keno/Ballroll/Ballroll_00249.png"
import kenojars249 from "../../../../../assets/games/keno/Ballroll/Ballroll_00250.png"
import kenojars250 from "../../../../../assets/games/keno/Ballroll/Ballroll_00251.png"
import kenojars251 from "../../../../../assets/games/keno/Ballroll/Ballroll_00252.png"
import kenojars252 from "../../../../../assets/games/keno/Ballroll/Ballroll_00253.png"
import kenojars253 from "../../../../../assets/games/keno/Ballroll/Ballroll_00254.png"
import kenojars254 from "../../../../../assets/games/keno/Ballroll/Ballroll_00255.png"
import kenojars255 from "../../../../../assets/games/keno/Ballroll/Ballroll_00256.png"
import kenojars256 from "../../../../../assets/games/keno/Ballroll/Ballroll_00257.png"
import kenojars257 from "../../../../../assets/games/keno/Ballroll/Ballroll_00258.png"
import kenojars258 from "../../../../../assets/games/keno/Ballroll/Ballroll_00259.png"
import kenojars259 from "../../../../../assets/games/keno/Ballroll/Ballroll_00260.png"
import kenojars260 from "../../../../../assets/games/keno/Ballroll/Ballroll_00261.png"
import kenojars261 from "../../../../../assets/games/keno/Ballroll/Ballroll_00262.png"
import kenojars262 from "../../../../../assets/games/keno/Ballroll/Ballroll_00263.png"
import kenojars263 from "../../../../../assets/games/keno/Ballroll/Ballroll_00264.png"
import kenojars264 from "../../../../../assets/games/keno/Ballroll/Ballroll_00265.png"
import kenojars265 from "../../../../../assets/games/keno/Ballroll/Ballroll_00266.png"
import kenojars266 from "../../../../../assets/games/keno/Ballroll/Ballroll_00267.png"
import kenojars267 from "../../../../../assets/games/keno/Ballroll/Ballroll_00268.png"
import kenojars268 from "../../../../../assets/games/keno/Ballroll/Ballroll_00269.png"
import kenojars269 from "../../../../../assets/games/keno/Ballroll/Ballroll_00270.png"
import kenojars270 from "../../../../../assets/games/keno/Ballroll/Ballroll_00271.png"
import kenojars271 from "../../../../../assets/games/keno/Ballroll/Ballroll_00272.png"
import kenojars272 from "../../../../../assets/games/keno/Ballroll/Ballroll_00273.png"
import kenojars273 from "../../../../../assets/games/keno/Ballroll/Ballroll_00274.png"
import kenojars274 from "../../../../../assets/games/keno/Ballroll/Ballroll_00275.png"
import kenojars275 from "../../../../../assets/games/keno/Ballroll/Ballroll_00276.png"
import kenojars276 from "../../../../../assets/games/keno/Ballroll/Ballroll_00277.png"
import kenojars277 from "../../../../../assets/games/keno/Ballroll/Ballroll_00278.png"
import kenojars278 from "../../../../../assets/games/keno/Ballroll/Ballroll_00279.png"
import kenojars279 from "../../../../../assets/games/keno/Ballroll/Ballroll_00280.png"
import kenojars280 from "../../../../../assets/games/keno/Ballroll/Ballroll_00281.png"
import kenojars281 from "../../../../../assets/games/keno/Ballroll/Ballroll_00282.png"



import kenojars282 from "../../../../../assets/games/keno/Ballroll/Ballroll_00283.png"
import kenojars283 from "../../../../../assets/games/keno/Ballroll/Ballroll_00284.png"
import kenojars284 from "../../../../../assets/games/keno/Ballroll/Ballroll_00285.png"
import kenojars285 from "../../../../../assets/games/keno/Ballroll/Ballroll_00286.png"
import kenojars286 from "../../../../../assets/games/keno/Ballroll/Ballroll_00287.png"
import kenojars287 from "../../../../../assets/games/keno/Ballroll/Ballroll_00288.png"
import kenojars288 from "../../../../../assets/games/keno/Ballroll/Ballroll_00289.png"
import kenojars289 from "../../../../../assets/games/keno/Ballroll/Ballroll_00290.png"
import kenojars290 from "../../../../../assets/games/keno/Ballroll/Ballroll_00291.png"
import kenojars291 from "../../../../../assets/games/keno/Ballroll/Ballroll_00292.png"
import kenojars292 from "../../../../../assets/games/keno/Ballroll/Ballroll_00293.png"
import kenojars293 from "../../../../../assets/games/keno/Ballroll/Ballroll_00294.png"
import kenojars294 from "../../../../../assets/games/keno/Ballroll/Ballroll_00295.png"
import kenojars295 from "../../../../../assets/games/keno/Ballroll/Ballroll_00296.png"
import kenojars296 from "../../../../../assets/games/keno/Ballroll/Ballroll_00297.png"
import kenojars297 from "../../../../../assets/games/keno/Ballroll/Ballroll_00298.png"
import kenojars298 from "../../../../../assets/games/keno/Ballroll/Ballroll_00299.png"
import kenojars299 from "../../../../../assets/games/keno/Ballroll/Ballroll_00300.png"
import kenojars300 from "../../../../../assets/games/keno/Ballroll/Ballroll_00301.png"
import kenojars301 from "../../../../../assets/games/keno/Ballroll/Ballroll_00302.png"
import kenojars302 from "../../../../../assets/games/keno/Ballroll/Ballroll_00303.png"
import kenojars303 from "../../../../../assets/games/keno/Ballroll/Ballroll_00304.png"
import kenojars304 from "../../../../../assets/games/keno/Ballroll/Ballroll_00305.png"
import kenojars305 from "../../../../../assets/games/keno/Ballroll/Ballroll_00306.png"
import kenojars306 from "../../../../../assets/games/keno/Ballroll/Ballroll_00307.png"
import kenojars307 from "../../../../../assets/games/keno/Ballroll/Ballroll_00308.png"
import kenojars308 from "../../../../../assets/games/keno/Ballroll/Ballroll_00309.png"
import kenojars309 from "../../../../../assets/games/keno/Ballroll/Ballroll_00310.png"
import kenojars310 from "../../../../../assets/games/keno/Ballroll/Ballroll_00311.png"
import kenojars311 from "../../../../../assets/games/keno/Ballroll/Ballroll_00312.png"
import kenojars312 from "../../../../../assets/games/keno/Ballroll/Ballroll_00313.png"
import kenojars313 from "../../../../../assets/games/keno/Ballroll/Ballroll_00314.png"
import kenojars314 from "../../../../../assets/games/keno/Ballroll/Ballroll_00315.png"
import kenojars315 from "../../../../../assets/games/keno/Ballroll/Ballroll_00316.png"
import kenojars316 from "../../../../../assets/games/keno/Ballroll/Ballroll_00317.png"
import kenojars317 from "../../../../../assets/games/keno/Ballroll/Ballroll_00318.png"
import kenojars318 from "../../../../../assets/games/keno/Ballroll/Ballroll_00319.png"
import kenojars319 from "../../../../../assets/games/keno/Ballroll/Ballroll_00320.png"
import kenojars320 from "../../../../../assets/games/keno/Ballroll/Ballroll_00321.png"
import kenojars321 from "../../../../../assets/games/keno/Ballroll/Ballroll_00322.png"
import kenojars322 from "../../../../../assets/games/keno/Ballroll/Ballroll_00323.png"
import kenojars323 from "../../../../../assets/games/keno/Ballroll/Ballroll_00324.png"
import kenojars324 from "../../../../../assets/games/keno/Ballroll/Ballroll_00325.png"
import kenojars325 from "../../../../../assets/games/keno/Ballroll/Ballroll_00326.png"
import kenojars326 from "../../../../../assets/games/keno/Ballroll/Ballroll_00327.png"
import kenojars327 from "../../../../../assets/games/keno/Ballroll/Ballroll_00328.png"
import kenojars328 from "../../../../../assets/games/keno/Ballroll/Ballroll_00329.png"
import kenojars329 from "../../../../../assets/games/keno/Ballroll/Ballroll_00330.png"
import kenojars330 from "../../../../../assets/games/keno/Ballroll/Ballroll_00331.png"
import kenojars331 from "../../../../../assets/games/keno/Ballroll/Ballroll_00332.png"
import kenojars332 from "../../../../../assets/games/keno/Ballroll/Ballroll_00333.png"
import kenojars333 from "../../../../../assets/games/keno/Ballroll/Ballroll_00334.png"
import kenojars334 from "../../../../../assets/games/keno/Ballroll/Ballroll_00335.png"
import kenojars335 from "../../../../../assets/games/keno/Ballroll/Ballroll_00336.png"
import kenojars336 from "../../../../../assets/games/keno/Ballroll/Ballroll_00337.png"
import kenojars337 from "../../../../../assets/games/keno/Ballroll/Ballroll_00338.png"
import kenojars338 from "../../../../../assets/games/keno/Ballroll/Ballroll_00339.png"
import kenojars339 from "../../../../../assets/games/keno/Ballroll/Ballroll_00340.png"
import kenojars340 from "../../../../../assets/games/keno/Ballroll/Ballroll_00341.png"
import kenojars341 from "../../../../../assets/games/keno/Ballroll/Ballroll_00342.png"
import kenojars342 from "../../../../../assets/games/keno/Ballroll/Ballroll_00343.png"
import kenojars343 from "../../../../../assets/games/keno/Ballroll/Ballroll_00344.png"
import kenojars344 from "../../../../../assets/games/keno/Ballroll/Ballroll_00345.png"
import kenojars345 from "../../../../../assets/games/keno/Ballroll/Ballroll_00346.png"
import kenojars346 from "../../../../../assets/games/keno/Ballroll/Ballroll_00347.png"
import kenojars347 from "../../../../../assets/games/keno/Ballroll/Ballroll_00348.png"
import kenojars348 from "../../../../../assets/games/keno/Ballroll/Ballroll_00349.png"
import kenojars349 from "../../../../../assets/games/keno/Ballroll/Ballroll_00350.png"
import kenojars350 from "../../../../../assets/games/keno/Ballroll/Ballroll_00351.png"
import kenojars351 from "../../../../../assets/games/keno/Ballroll/Ballroll_00352.png"



import kenojars352 from "../../../../../assets/games/keno/Ballroll/Ballroll_00353.png"
import kenojars353 from "../../../../../assets/games/keno/Ballroll/Ballroll_00354.png"
import kenojars354 from "../../../../../assets/games/keno/Ballroll/Ballroll_00355.png"
import kenojars355 from "../../../../../assets/games/keno/Ballroll/Ballroll_00356.png"
import kenojars356 from "../../../../../assets/games/keno/Ballroll/Ballroll_00357.png"
import kenojars357 from "../../../../../assets/games/keno/Ballroll/Ballroll_00358.png"
import kenojars358 from "../../../../../assets/games/keno/Ballroll/Ballroll_00359.png"
import kenojars359 from "../../../../../assets/games/keno/Ballroll/Ballroll_00360.png"
import kenojars360 from "../../../../../assets/games/keno/Ballroll/Ballroll_00361.png"
import kenojars361 from "../../../../../assets/games/keno/Ballroll/Ballroll_00362.png"
import kenojars362 from "../../../../../assets/games/keno/Ballroll/Ballroll_00363.png"
import kenojars363 from "../../../../../assets/games/keno/Ballroll/Ballroll_00364.png"
import kenojars364 from "../../../../../assets/games/keno/Ballroll/Ballroll_00365.png"
import kenojars365 from "../../../../../assets/games/keno/Ballroll/Ballroll_00366.png"
import kenojars366 from "../../../../../assets/games/keno/Ballroll/Ballroll_00367.png"
import kenojars367 from "../../../../../assets/games/keno/Ballroll/Ballroll_00368.png"
import kenojars368 from "../../../../../assets/games/keno/Ballroll/Ballroll_00369.png"
import kenojars369 from "../../../../../assets/games/keno/Ballroll/Ballroll_00370.png"
import kenojars370 from "../../../../../assets/games/keno/Ballroll/Ballroll_00371.png"
import kenojars371 from "../../../../../assets/games/keno/Ballroll/Ballroll_00372.png"
import kenojars372 from "../../../../../assets/games/keno/Ballroll/Ballroll_00373.png"
import kenojars373 from "../../../../../assets/games/keno/Ballroll/Ballroll_00374.png"
import kenojars374 from "../../../../../assets/games/keno/Ballroll/Ballroll_00375.png"
import kenojars375 from "../../../../../assets/games/keno/Ballroll/Ballroll_00376.png"
import kenojars376 from "../../../../../assets/games/keno/Ballroll/Ballroll_00377.png"
import kenojars377 from "../../../../../assets/games/keno/Ballroll/Ballroll_00378.png"
import kenojars378 from "../../../../../assets/games/keno/Ballroll/Ballroll_00379.png"
import kenojars379 from "../../../../../assets/games/keno/Ballroll/Ballroll_00380.png"
import kenojars380 from "../../../../../assets/games/keno/Ballroll/Ballroll_00381.png"
import kenojars381 from "../../../../../assets/games/keno/Ballroll/Ballroll_00382.png"
import kenojars382 from "../../../../../assets/games/keno/Ballroll/Ballroll_00383.png"
import kenojars383 from "../../../../../assets/games/keno/Ballroll/Ballroll_00384.png"
import kenojars384 from "../../../../../assets/games/keno/Ballroll/Ballroll_00385.png"
import kenojars385 from "../../../../../assets/games/keno/Ballroll/Ballroll_00386.png"


const imageObject = {    
    kenojars32,
    kenojars33,
    kenojars34,
    kenojars35,
    kenojars36,
    kenojars37,
    kenojars38,
    kenojars39,
    kenojars40,
    kenojars41,
    kenojars42,
    kenojars43,
    kenojars44,
    kenojars45,
    kenojars46,
    kenojars47,
    kenojars48,
    kenojars49,
    kenojars50,
    kenojars51,
    kenojars52,
    kenojars53,
    kenojars54,
    kenojars55,
    kenojars56,
    kenojars57,
    kenojars58,
    kenojars59,
    kenojars60,
    kenojars61,
    kenojars62,
    kenojars63,
    kenojars64,
    kenojars65,
    kenojars66,
    kenojars67,
    kenojars68,
    kenojars69,
    kenojars70,
    kenojars71,
    kenojars72,
    kenojars73,
    kenojars74,
    kenojars75,
    kenojars76,
    kenojars77,
    kenojars78,
    kenojars79,
    kenojars80,
    kenojars81,
    kenojars82,
    kenojars83,
    kenojars84,
    kenojars85,
    kenojars86,
    kenojars87,
    kenojars88,
    kenojars89,
    kenojars90,
    kenojars91,
    kenojars92,
    kenojars93,
    kenojars94,
    kenojars95,
    kenojars96,
    kenojars97,
    kenojars98,
    kenojars99,
    kenojars100,
    kenojars101,
    kenojars102,
    kenojars103,
    kenojars104,
    kenojars105,
    kenojars106,
    kenojars107,
    kenojars108,
    kenojars109,
    kenojars110,
    kenojars111,
    kenojars112,
    kenojars113,
    kenojars114,
    kenojars115,
    kenojars116,
    kenojars117,
    kenojars118,
    kenojars119,
    kenojars120,
    kenojars121,
    kenojars122,
    kenojars123,
    kenojars124,
    kenojars125,
    kenojars126,
    kenojars127,
    kenojars128,
    kenojars129,
    kenojars130,
    kenojars131,
    kenojars132,
    kenojars133,
    kenojars134,
    kenojars135,
    kenojars136,
    kenojars137,
    kenojars138,
    kenojars139,
    kenojars140,
    kenojars141,
    kenojars142,
    kenojars143,
    kenojars144,
    kenojars145,
    kenojars146,
    kenojars147,
    kenojars148,
    kenojars149,
    kenojars150,
    kenojars151,
    kenojars152,
    kenojars153,
    kenojars154,
    kenojars155,
    kenojars156,
    kenojars157,
    kenojars158,
    kenojars159,
    kenojars160,
    kenojars161,
    kenojars162,
    kenojars163,
    kenojars164,
    kenojars165,
    kenojars166,
    kenojars167,
    kenojars168,
    kenojars169,
    kenojars170,
    kenojars171,
    kenojars172,
    kenojars173,
    kenojars174,
    kenojars175,
    kenojars176,
    kenojars177,
    kenojars178,
    kenojars179,
    kenojars180,
    kenojars181,
    kenojars182,
    kenojars183,
    kenojars184,
    kenojars185,
    kenojars186,
    kenojars187,
    kenojars188,
    kenojars189,
    kenojars190,
    kenojars191,
    kenojars192,
    kenojars193,
    kenojars194,
    kenojars195,
    kenojars196,
    kenojars197,
    kenojars198,
    kenojars199,
    kenojars200,
    kenojars201,
    kenojars202,
    kenojars203,
    kenojars204,
    kenojars205,
    kenojars206,
    kenojars207,
    kenojars208,
    kenojars209,
    kenojars210,
    kenojars211,
    kenojars212,
    kenojars213,
    kenojars214,
    kenojars215,
    kenojars216,
    kenojars217,
    kenojars218,
    kenojars219,
    kenojars220,
    kenojars221,
    kenojars222,
    kenojars223,
    kenojars224,
    kenojars225,
    kenojars226,
    kenojars227,
    kenojars228,
    kenojars229,
    kenojars230,
    kenojars231,
    kenojars232,
    kenojars233,
    kenojars234,
    kenojars235,
    kenojars236,
    kenojars237,
    kenojars238,
    kenojars239,
    kenojars240,
    kenojars241,
    kenojars242,
    kenojars243,
    kenojars244,
    kenojars245,
    kenojars246,
    kenojars247,
    kenojars248,
    kenojars249,
    kenojars250,
    kenojars251,
    kenojars252,
    kenojars253,
    kenojars254,
    kenojars255,
    kenojars256,
    kenojars257,
    kenojars258,
    kenojars259,
    kenojars260,
    kenojars261,
    kenojars262,
    kenojars263,
    kenojars264,
    kenojars265,
    kenojars266,
    kenojars267,
    kenojars268,
    kenojars269,
    kenojars270,
    kenojars271,
    kenojars272,
    kenojars273,
    kenojars274,
    kenojars275,
    kenojars276,
    kenojars277,
    kenojars278,
    kenojars279,
    kenojars280,
    kenojars281,
    kenojars282,
    kenojars283,
    kenojars284,
    kenojars285,
    kenojars286,
    kenojars287,
    kenojars288,
    kenojars289,
    kenojars290,
    kenojars291,
    kenojars292,
    kenojars293,
    kenojars294,
    kenojars295,
    kenojars296,
    kenojars297,
    kenojars298,
    kenojars299,
    kenojars300,
    kenojars301,
    kenojars302,
    kenojars303,
    kenojars304,
    kenojars305,
    kenojars306,
    kenojars307,
    kenojars308,
    kenojars309,
    kenojars310,
    kenojars311,
    kenojars312,
    kenojars313,
    kenojars314,
    kenojars315,
    kenojars316,
    kenojars317,
    kenojars318,
    kenojars319,
    kenojars320,
    kenojars321,
    kenojars322,
    kenojars323,
    kenojars324,
    kenojars325,
    kenojars326,
    kenojars327,
    kenojars328,
    kenojars329,
    kenojars330,
    kenojars331,
    kenojars332,
    kenojars333,
    kenojars334,
    kenojars335,
    kenojars336,
    kenojars337,
    kenojars338,
    kenojars339,
    kenojars340,
    kenojars341,
    kenojars342,
    kenojars343,
    kenojars344,
    kenojars345,
    kenojars346,
    kenojars347,
    kenojars348,
    kenojars349,
    kenojars350,
    kenojars351,
    kenojars352,
    kenojars353,
    kenojars354,
    kenojars355,
    kenojars356,
    kenojars357,
    kenojars358,
    kenojars359,
    kenojars360,
    kenojars361,
    kenojars362,
    kenojars363,
    kenojars364,
    kenojars365,
    kenojars366,
    kenojars367,
    kenojars368,
    kenojars369,
    kenojars370,
    kenojars371,
    kenojars372,
    kenojars373,
    kenojars374,
    kenojars375,
    kenojars376,
    kenojars377,
    kenojars378,
    kenojars379,
    kenojars380,
    kenojars381,
    kenojars382,
    kenojars383,
    kenojars384,
    kenojars385,
  };
  export default imageObject;
  