import React from "react";
import "../../../../../css/games/funTarget/funTargetWheel.css";
import ft_num_img from "../../../../../../assets/games/funTarget/fun Target wheell.svg";
import ftWheelBg_1 from "../../../../../../assets/games/funTarget/wheel_1.svg"; 
import ftWheelBg_2 from "../../../../../../assets/games/funTarget/wheel_2.svg"; 

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeCountQ: 1,
            layerOne: true, 
        };
                
        this.finished = false;
        this.firstload = true;
        this.resNum = 0;
        this.reward = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
       

        this.rewards = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 7, 7: 8, 8: 9, 9: 0 };
       

        // this.wheelSound = new Audio(wheelSound);
        this.resultNumber = 0;
        this.ft_wheelAnim = "";
        this.imgChange = '' 
    }

    componentDidMount() {      
        this.ft_wheelAnim = document.getElementById("ft_wheelAnim")
        if(this.props.gameState.history && this.firstload && this.props.gameState.history.length > 0){ 
          this.firstload = false; 
          let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
       
          console.log("Last login result number "+resNo)
          this.spinTheWheelOnload(resNo)   
        } else { 
          let defaultNo = 0
          this.spinTheWheelOnload(defaultNo)
      }

        this.imgChange = setInterval(() => {
            this.setState({
                timeCountQ: this.state.timeCountQ + 1
            }) 
            if( this.state.timeCountQ % 2 == 0 ){
                this.setState({
                    layerOne: true
                })  
            } else {
                this.setState({
                    layerOne: false
                }) 
            } 
        }, 200);


    }

    spinTheWheelOnload = (s) => { 
        console.log(s)
         this.resNum = Number(s);        
         console.log(this.resNum)
        
           const styles = {
            transition: 'all 0s',
            transform: `rotate(${((10-this.resNum) * 36)}deg)`,
        };
        Object.assign(this.ft_wheelAnim.style, styles);

  
    };

    spinTheWheel = (s) => {       
        // this.wheelSound.play();
        console.log("Result Number: "+s);
        const styles = {
            transition: 'all 7s',
            transform: `rotate(${(1440 - (s * 36))}deg)`,
        };
        Object.assign(this.ft_wheelAnim.style, styles);

        setTimeout(() => {
            this.afterRotate(s);
        }, 7200)
    };

    afterRotate(resNumVal) {
        console.log('hi');
        console.log(resNumVal);        
        console.log(resNumVal * 36);
        const styles = {
            transition: 'all 0s',
             transform: `rotate(${((10-resNumVal) * 36)}deg)`,
        };
        Object.assign(this.ft_wheelAnim.style, styles);
        this.announceWinner(resNumVal);
       
    }

    announceWinner(value) {
        this.props.action(value);               
    }

    render() {
        return (
            <div id="ft_wheelWraper">
                <div className="ft_wheelAnimOne"> 
                    <div className="ft_wheelAnim" id="ft_wheelAnim">
                        <img src={ft_num_img} />    
                    </div> 
                </div>
                <div className="ft_wheelBorder"> 
                {this.state.layerOne ? 
                    <img src={ftWheelBg_1} /> :  
                    <img src={ftWheelBg_2} /> }
                </div>
                </div>
            
        );
    }
}
