import React from "react";
import winImg from "../../../../../../assets/games/funMatka/last_win.svg";
//import tickSound from "../../../../../../assets/sounds/tick.mp3";
export default class FunMatkaTimerInSeconds extends React.Component {
  constructor(props) {
    //console.log("Fun Matka Timer")
    super(props);
    this.state = {
      timeInSec: 120,
      getTime: 120,
      timeInMin: 0,
      resultNumber:0,
      disableBtns: false,
    }; 
  }
  componentDidMount(){
    this.timerclear = setInterval(this.myTimer, 1000);
    document.getElementById('timeSpan').style.display = 'none';
    document.getElementById('winImg').style.display = 'none';
    

  }
  sendTimer(tt) {
    //console.log("Fun Matka Current Time:" + tt);
        document.getElementById('timeSpan').style.display = 'block';
        this.setState({ getTime: tt });
        this.myTimer();
  }
  updateResNum(resNum){
    if(resNum == 1000){
      this.setState({resultNumber: "000"});
      this.state.resultNumber = "000";
    }else{
      this.setState({resultNumber: resNum});
      this.state.resultNumber = resNum;
    }
    document.getElementById("winImg").style.display = "block";
  }
  componentWillUnmount(){
    clearInterval(this.timerclear);
  } 
  myTimer = () => {
    if (this.state.getTime !== null && this.state.getTime != NaN && this.state.getTime >0) {
      var funMatkaCover = document.getElementById("funMatkaCover");
      var d = this.state.getTime;
      var n = --d;
      var min = parseInt(n / 60);
      var sec = parseInt(n % 60);

      var minS = min.toString();
      var secS = sec.toString();
      var time = "";
      if (String(min).length < 2) {
        minS = "0" + min.toString();
      }

      if (String(sec).length < 2) {
        secS = "0" + sec.toString();
      }

      if (min != 0) {
        time = String(minS) + " : " + String(secS);
      } else {
        time = String(secS);
      }

      var timer = document.getElementById("timer");
      this.setState({ timeInMin: time });

      //console.log("Current Time: "+n);
      this.setState({ getTime: n });
      this.setState({ timeInSec: n });
      
      if(this.state.getTime != null ){         

        if (n == 0) {        
          this.setState({ getTime: 120 });
        }
       
        if(n <= 5 && n >= 0){
          funMatkaCover.style.display = 'block'
          funMatkaCover.style.display = 'block';
          if(!this.state.disableBtns){
            this.state.disableBtns = true;
            this.setState({ disableBtns: true });
            this.props.action("disableBtns");
            this.props.action("betOk");
            this.getTimer();
            funMatkaCover.style.display = 'block'
          }
        }else{
          this.setState({disableBtns: false});      
          this.state.disableBtns = false;
        }      
                
        if (n <= 10 && n >=5 ) {
          timer.classList.add("timerYelloBorder");
          // this.tickSound = new Audio(tickSound);
          // this.tickSound.play();
          this.props.action("PlayTickSound");
        } else {
          if (timer && timer.classList.contains("timerYelloBorder")) {
            timer.classList.remove("timerYelloBorder");
            // this.tickSound = {};
            this.props.action("StopTickSound"); 
          }
        }
      }
    }
  };

  getTimer() {
    const body = {
      sessionId: sessionStorage.getItem('sessionId'),
      CN: "GET_GAME_TIMER",
      object: {
        gameId: this.props.gameId,
        playerId: Number(this.props.playerId),
      },
    };
    this.props.network.sendof(body);
  }
  render() {
    return (
      <div className="fd">
        <div id="funMatkaCover" className="funMatkaCover"></div>
        <div className="timer_Sec matka_timerSec" id="timer"> 
          <span className="" id="timeSpan"> {this.state.timeInMin} </span>
        </div>
        <div id='winImg' className="winImg">
            <img src={winImg} />
            <span> {this.state.resultNumber} </span>
            {/* <span> 452 </span> */}
        </div>
      </div>
    );
  }
}
