import React from "react";
import "../../../../../css/games/funSoratTimer/rightButtonPanel.css";
import tableData from "./table";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import tickSound from "../../../../../../assets/sounds/tick.mp3";
export default class RightButtonPanel extends React.Component {
   constructor(props) {
       super(props);
       this.state = {
           timeInSec: 60,
           getTime: 60,
           timerStop:true,
           disVal: 0,
           isBetSent: false,
           isDisableSent: false,
           historyFirstLoad : true,
       };
       this.loadFirsttime = true;
       this.timerv = this.props.timesend;
       console.log("Funsort Timer Current time: "+this.props.timesend);
       
   }

   componentDidMount(){
    const { childRef } = this.props;
        childRef(this);
       document.getElementById('timeSpan').style.display = 'none';
        this.timerclear = setInterval(() =>{
            this.myTimer() 
        }, 1000);
        this.historyData();
        this.timer = document.getElementById("timer");
    }
    componentWillUnmount(){
        clearInterval(this.timerclear);
    }

    clearTimer(){
        console.log("Stop Timer");
        this.setState({timerStop: false})
        clearInterval(this.clearTime );
    }

    sendSoratTimer(tt) {
        
        this.setState({ getTime: tt });
        this.state.getTime = tt;
        document.getElementById('timeSpan').style.display = 'block';
        this.myTimer();
    }
    myTimer = () => {
        var funSoratCover = document.getElementById('funSoratCover');
        if (this.state.getTime !== null && this.state.getTime != NaN && this.state.getTime >=0) {
            if (this.state.timerStop) {
                var d = this.state.getTime;
                var n = --d;
                var sec = parseInt(n % 60);
                var secS = sec.toString();
                this.setState({getTime: n});
                if(this.state.getTime !== null){                
                    if (String(sec).length < 2) {
                        secS = "0" + sec.toString();
                    }
                    this.setState({timeInSec: secS});
                    if (n <= 0) {
                        //this.props.action("ZoomON");                    
                        this.setState({getTime: 60, isDisableSent: false});
                        this.state.getTime = 60;
                    // 
                    }
                    if (n < 5 && n >= 0) {
                        funSoratCover.style.display = 'block';
                        funSoratCover.style.zIndex = "1050";

                        if(!this.state.isBetSent){
                            this.setState({isBetSent: true});     
                            this.props.action("BET OK"); 
                        } 
                        
                        if(!this.state.isDisableSent){
                            this.setState({disableBtns: true});
                            this.state.isDisableSent = true;
                            this.getTimer();
                            this.props.action("disableBtns");
                        }
                    }else{
                        this.setState({isBetSent: false});
                    }
                
                    if (n <= 10 && n >= 5) {
                        this.timer.classList.add("timerYelloBorder");
                        this.tickSound = new Audio(tickSound);
                        this.tickSound.play();
                        // this.props.action("PlayTickSound"); 
                        // this.setState({isBetSent: false});  
                    } else {
                        if(this.timer && this.timer.classList.contains('timerYelloBorder')){
                            this.timer.classList.remove("timerYelloBorder");
                            this.tickSound = {};
                            // this.props.action("StopTickSound"); 
                        }
                    }
                }
            }
        }
    }
    getTimer() {
        const body = {
            sessionId: sessionStorage.getItem('sessionId'),
            CN: "GET_GAME_TIMER",
            object: {
                gameId: this.props.gameId,
                playerId: Number(this.props.playerId),
            }
        }
        this.props.network.sendof(body);
    };
    historyData(){
        if(this.state.historyFirstLoad){
            if(this.props.gameState.history){
                this.histData = this.props.gameState.history
            } 
            this.setState({ historyFirstLoad : false })
        } else {
            setTimeout(()=>{
                if(this.props.gameState.history){
                    this.histData = this.props.gameState.history
                } 
            }, 100 )
        }
    }
   render() { 
       return (
           <div className="fd m_t_15 p_r_15">
               <div className="fd">
                   <div id="funSoratCover" className="funSoratCover"></div>
                   <div className="soratUser"> {this.props.user} </div>
                   <div className="SoratTimer_Sec" id="timer"> <span className="" id="timeSpan"> {this.state.timeInSec} </span> </div>
               </div>
               <div className="history soratHis fd box_clm soratBx flex_clmn m_t_15"> 
                   <ul className="FS_History">
                       {this.histData ? this.histData.map((value, index) => {
                            return index > 4 ?
                               <li key={index}>
                                   {value.extraInfo == 2 ? <span >2X</span> : <span > </span> }
                                   {value.extraInfo == 4 ? <span >4X</span> : <span > </span> }
                                   <img src={tableData[value.resultNo].src} alt={tableData[value.resultNo].src}/>
                               </li>
                            :
                            null
                       }):
                       ''}
                   </ul>
               </div>

               
               {/* <div className="fd box_clm soratBx">
                       <button  id="zoomBtn"
                           className="fd ar_bt_1" style={{zIndex: this.props.btnZindex}} disabled={this.props.disZoomBtn == 1}
                           name={this.props.zoomButtonText}
                           onClick={(e) => {
                               e.preventDefault();
                               console.log("FS: RB: Button Name: "+e.target.name);
                               switch (e.target.name){
                                   case "Wheel Zoom: ON":
                                       this.props.action("ZoomON");
                                       break;
                                   case "Wheel Zoom: OFF":
                                    this.props.action("ZoomOFF");
                                     break;
                               }
                           }}
                       >
                           {this.props.zoomButtonText}
                   </button>
               </div> */}
               {/* <div className="fd box_clm soratBx">
                       <button
                           id= "bet_take"
                           className="fd ar_bt_1 zin_360"
                           name={this.props.betBtnText}
                           disabled={this.props.disBetBtn == 1 && this.props.betBtnText !== "TAKE"}
                           onClick={(e) => {
                               console.log("Button name: "+e.target.name)
                               e.preventDefault();

                               if (e.target.name === "TAKE") {
                                   console.log("TAKE Button ");
                                   this.props.action("TAKE");
                               }else if(e.target.name === "PREV"){
                                   console.log("Prev Button Name: "+e.target.name)
                                   this.props.action("PREV");
                               }else if(e.target.name === "BET OK"){
                                   console.log("Send Bet Request");
                                   this.betSound = new Audio(betSound);
                                   this.betSound.play();
                                   this.props.action("BET OK");
                               } 
                           }} 
                       >
                           {this.props.betBtnText}
                       </button>
               </div>
               <div className="fd box_clm soratBx">
                       <button className="fd ar_bt_1" onClick={(e) => { e.preventDefault(); this.props.action("double") }} disabled={this.props.disDoubleBtn == 1}>DOUBLE</button>
               </div> 
                <div className="fd box_clm soratBx zin_1050">
                    <button className="fd ar_bt_1" onClick={(e) => { e.preventDefault(); this.props.action("exit") }}> EXIT</button>
                </div> */}
           </div>
       );
   }
}
