import React from "react";
import chips from "../../../chips";

import mtt_wheelBg_img from "../../../../../../assets/games/funMatka/matkaTimerWheelBg.svg";
import mtt_wheelNum1 from "../../../../../../assets/games/funMatka/wheelNum1.png";
import mtt_wheelNum2 from "../../../../../../assets/games/funMatka/wheelNum2.png";
import mtt_wheelNum3 from "../../../../../../assets/games/funMatka/wheelNum3.png";
import matka_t_Res from "../../../../../../assets/games/funMatka/matka_win.png";

export default class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {    
      chipSelect:1,
      winImgNum: 0,
    };
    
    this.state.resultNumber = "";
    this.wheel_nos_1 = "";
    this.wheel_nos_2 = "";
    this.wheel_nos_3 = "";

    this.chipVal = [ 
      { label: "1k" , val: 1000 }, 
      { label: "5k" , val: 5000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "10k" , val: 10000 }, 
      { label: "50k" , val: 50000 }, 
      { label: "100k" , val: 100000 }, 
      { label: "5M" , val: 5000000 }, 
      { label: "1B" , val: 100000000 },
      { label: "" , val: 0 }
    ];
  }
  componentStateHandler() { }
  componentDidMount() {
    this.wheel_nos_1 = document.getElementById("mtt_wheel_nos_1");
    this.wheel_nos_2 = document.getElementById("mtt_wheel_nos_2");
    this.wheel_nos_3 = document.getElementById("mtt_wheel_nos_3");

    let mt1 = 0;
    let mt2 = 0;
    let mt3 = 0;

    if(this.props.gameState.response){
      if(this.props.gameState.response.resultNum == 1000){
        this.setState({resultNumber: "000"});
      }else{
        this.setState({resultNumber: this.props.gameState.response.resultNum});
      }
    }

    if (this.props.gameState.history && this.props.gameState.history.length > 0) {
      var nos = 0 ;
      console.log(nos)
      if(this.props.gameState.history[this.props.gameState.history.length - 1].resultNo == 1000){
        nos = "000";        
      }else{
        nos = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;        
      }
      console.log("Result number: "+nos)

      mt1 = Math.floor(nos / 100);
      mt2 = Math.floor((nos - mt1 * 100) / 10);
      mt3 = nos - mt1 * 100 - mt2 * 10;
     
    } else {
      mt1 = 0;
      mt2 = 0;
      mt3 = 0;
    }

    const w1_styles = {
      transition: 'all 0s',
      transform: `rotate(${( -mt1 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-mt2 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-mt3 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);

    if(this.props.fmt_chipValues){
      let newChips = this.props.fmt_chipValues.split(",");
      console.log(newChips);
      console.log(newChips.length)
      let lblTxt ="";
      for(let i =0; i<newChips.length; i++){                   
        if(Number(newChips[i]) >= 1000 && Number(newChips[i]) <= 999999){
          lblTxt = "K"
        }else if(Number(newChips[i]) >= 1000000 && Number(newChips[i]) <= 999999999){
          lblTxt = "M"                        
        }else if(Number(newChips[i]) > 999999999){            
          lblTxt = "B"
        }                     
        this.chipVal[i].val = newChips[i]         
        console.log(this.chipVal[i].val, lblTxt);
        this.checkChipValue(Number(this.chipVal[i].val), lblTxt, i);            
      }
    }
  }

  checkChipValue(chipValue, chpTxt, i) {       
    if(chipValue >= 1000){
      chipValue = chipValue / 1000;
      this.checkChipValue(chipValue, chpTxt, i)
    }else{
      console.log(chpTxt);
      this.showResultChipValue(chipValue, chpTxt, i);
      return chipValue
    }
  }

  showResultChipValue(chVal, chipTxt, index){
    console.log(chVal);
    console.log(chVal+""+chipTxt)
    this.chipVal[index].label = chVal+""+chipTxt;
    console.log(this.chipVal)
  }

  spinTheWheel = (s) => {    
    console.log(s);
    this.resultSent = false;
    this.setState({resultNumber: " "});
    let s1 = Math.floor(s / 100);
    let s2 = Math.floor((s - s1 * 100) / 10);
    let s3 = s - s1 * 100 - s2 * 10;

    const w1_styles = {
      transition: 'all 7s',
      transform: `rotate(${(1440 - (s1 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 8s',
      transform: `rotate(${-(1800 + (s2 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 9s',
      transform: `rotate(${(2160 - (s3 * 36))}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);

    setTimeout(() => {
      this.afterRotate(s);
      
    }, 9500)
  };

  afterRotate(resNumVal) {
    console.log('hi');
    console.log(resNumVal);        

    let resNum;

    if(resNumVal === 1000){
      resNum = "000"
    }else{
      resNum = resNumVal;
    }
    let k1 = Math.floor(resNum / 100);
    let k2 = Math.floor((resNum - k1 * 100) / 10);
    let k3 = resNum - k1 * 100 - k2 * 10;
    console.log(k1, k2, k3);


    const w1_styles = {
      transition: 'all 0s',
      transform: `rotate(${( -k1 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_1.style, w1_styles);

    const w2_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-k2 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_2.style, w2_styles);

    const w3_styles = {
      transition: 'all 0s',
      transform: `rotate(${(-k3 * 36)}deg)`,
    };
    Object.assign(this.wheel_nos_3.style, w3_styles);
    
    this.props.action(this.state.resultNumber);
    if(resNum == 1000){
      this.setState({resultNumber: "000"});        
      if(this.props.winningAmt == 0){
        this.state.winImgNum = "000";
      }
    }else{
      this.setState({resultNumber: resNum});        
      if(this.props.winningAmt == 0){
        this.state.winImgNum = resNum;
      }
    }
  }

  updateResultNumber(resNum) {
    console.log("Wheel Result Numner: "+ Number(resNum));
    if(Number(resNum) == 1000){
      this.setState({resultNumber: "000"})
      this.state.resultNumber = "000";
      if(this.props.winningAmt == 0){
        this.state.winImgNum = "000";
      }
    }else{
      this.setState({resultNumber: resNum})
      this.state.resultNumber = resNum;
      if(this.props.winningAmt == 0){
        this.state.winImgNum = resNum;
      }
    } 
  }


  stopWheelSound =() =>{
      // this.matkaWheelOne.current.stopWheel();
      // this.matkaWheelTwo.current.stopWheel();
      // this.matkaWheelThree.current.stopWheel();
  }

  render() {
    return (
      <div className="fd">

        <div className="mtt_wheelBg" id="mtt_wheelBg">
            <img className="mtt_wheelBorder_1" src={mtt_wheelBg_img} />    
            <img className="mtt_wheel_nos_1" id="mtt_wheel_nos_1" src={mtt_wheelNum1} />    
            <img className="mtt_wheel_nos_2" id="mtt_wheel_nos_2" src={mtt_wheelNum2} />    
            <img className="mtt_wheel_nos_3" id="mtt_wheel_nos_3" src={mtt_wheelNum3} />    
            <img className="mtt_matkaResPos" src={matka_t_Res} />
            <div className="mtt_pivotNum">{this.state.resultNumber}</div>
        </div> 

        <div className="fd p_lr_5 m_t-70"> 
            {this.props.gameState.history ? 
              <div className="matkaRes fd text-center ht_56">
            {this.props.gameState.history.map((value, index) => {
              if(value.resultNo == 1000){
                return index >= 0 ? <span key={index}> &nbsp;  000</span> : "";
              }else{

                return index >= 0 ? <span key={index}> &nbsp;  {value.resultNo}</span> : "";
              }
            })}
            </div>
            : ''} 
            <div className="matkaTimerRes2 fd text-center lh_3vh"> {this.props.infoText} </div>
          <div className="fd">
            <div className="matkaTimerChips fmt_chipBox">
              <ul className="chips_matka_timer fd">
                {chips.map((chip, i) => (
                  <li key={chip.src} >
                      <div className="chipCls" id = {"chipImg_"+chip.val} onClick={(e) => {e.preventDefault(); document.getElementById("chipImg_"+chip.val).style.transform = "scale(0.7)"; setTimeout(() => {
                        document.getElementById("chipImg_"+chip.val).style.transform = "scale(1)";}, 200); this.props.bet(`${this.chipVal[i].val}`, `${this.chipVal[i].label}`); this.setState({ chipSelect: chip.val });}} >
                        <img src={chip.src} className={this.state.chipSelect === chip.val ? "active" : "inactive"} alt={chip.val} />
                        <div id={"chipText_"+chip.val} class="mtt_overlay_text"> {this.chipVal[i].label}</div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
