import { useSelector } from "react-redux";
import React from "react";
import { useState } from "react";
import scoreAsset from "../../../../../../assets/games/bingo/score_pannel.png"; 
import gameTitle from "../../../../../../assets/games/bingo/bng_title.gif";
import bngo_userpannel from "../../../../../../assets/games/bingo/bingo_userpannel.png";
import bingo_exitbtn from "../../../../../../assets/games/bingo/bngo_exit.png";
import bngowinpannelanim from "../../../../../../assets/games/bingo/bngowinpanel.png"

import closeImg from "../../../../../../assets/common_imgs/exit_btn.png"

const TopButtonPanel = (props) => {
  const language = useSelector((state) => state.languageObjs.languageObj);
  const [isPayout_showbngo, setpayoutval] = useState(false);   
  return (
    <React.Fragment>
    <div className="top-button-pannel row">
      <div className="col-2 score-container">
          <span className="score_panneltxt">{language.Score}</span>
        
          <span className="bgnoblnctxt">{props.updatebalnace}</span>
      </div>
      <div className="col-2 total_bet">
        <span id="totalbetbingo" className="total_bettext">{language.TotalBet}</span>
        <span className="ttlbet_val">{props.updateBetAmt}</span>
      </div>
      <div className="col-3 game-title">
      <img className="bingo_title_pos" src={gameTitle} />
      </div>
      <div className="col-2 winnerpanel ">
          <img className="winner-asset" src={scoreAsset} />
        <span className="winnerpanel_txt">{language.Winner}</span>
          <span className="bngo_winamt">{props.winAmount}</span>
          <img className={props.winGlowbngo ? "winGlowbngo " : "hidewinglowbngo"} src={props.winGlowbngo ? bngowinpannelanim : ""} />
      </div>
      <div className="col-2">
      <img className="user_pannel" src={bngo_userpannel} />
      <span className="user_id">{props.user}</span>
      </div>    
      {/* <div className={props.isMobile  ? "bngo_winamt ": "bingo_exitBtn addCursorPointer_bngo "} >
      <img className="bottom-container-exit-img" src={closeImg} id="exit-button"
          onMouseDown={(e) => {e.preventDefault(); props.action("EXIT");}} />
      </div> */}
    </div>
    </React.Fragment>
  );
};

export default TopButtonPanel;
