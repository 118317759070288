import { CHIP_INCREASE } from "./actions";
import { CHIP_DECREASE } from "./actions";
import { CHIP_TOTAL_PLUS } from "./actions";
import { CHIP_TOTAL_MINUS } from "./actions";
import { CHIP_TOTAL_CLEARE } from "./actions";
import { GET_RESULT_CARDS } from "./actions";
import { NEW_DRAGON_GAME } from "./actions"; 
import { DEAL_BUTTON_TRUE } from "./actions"; 
import { WIN_AMOUNT } from "./actions";
import { HISTORY } from "./actions";
import { UPDATE_USER_BALANCE } from "./actions";
import {INITIAL_CHIP} from "./actions"


const initialState = {
  chipAmount: 1000,
  chipLbl:"1k",
  betAmount: 0,
  count: 0,
  plusBtn: true,
  minusBtn: false,
  resultCards: [],
  dealBtn: true,
  winAmount: 0,
  history: [],
  userBalance:0,
};

const manageChip = (state = initialState, action) => {
    console.log("Action Type: "+action.type);
    switch (action.type) {
        case INITIAL_CHIP:
          console.log("Chip VAlue: "+ state.chipAmount+"  Count: "+state.count+"   chip label:  "+state.chipLbl);

          return { ...state, count: state.count, chipAmount: (state.chipAmount = action.payload), chipLbl: (state.chipLbl = action.lbl) };
        break;  
        case CHIP_INCREASE:
            console.log("Chip VAlue: "+ state.chipAmount);
            console.log("action: "+ action.payload);
          return { ...state, count: state.count + 1, chipAmount: (state.chipAmount = action.payload), chipLbl: (state.chipLbl = action.lbl) };
          break;
        case CHIP_DECREASE:
            console.log("Chip VAlue"+ state.chipAmount)
          return { ...state, count: state.count - 1, chipAmount: (state.chipAmount = action.payload), chipLbl: (state.chipLbl = action.lbl) };
          break;
        case CHIP_TOTAL_PLUS:
        console.log( "CHIP_TOTAL_PLUS " +  state.userBalance )
          return { ...state, betAmount: state.betAmount + action.payload, userBalance:  state.userBalance - action.payload };
          break;
        case CHIP_TOTAL_MINUS:
          console.log( "CHIP_TOTAL_MINUS " +  state.userBalance )
          return { ...state, betAmount: state.betAmount - action.payload, userBalance:  state.userBalance + action.payload };
          break;
        case CHIP_TOTAL_CLEARE:
          return { ...state, betAmount: (state.betAmount = 0) };
          break;
        case GET_RESULT_CARDS:
          return { ...state, dealBtn: false };
          break;
        case NEW_DRAGON_GAME:
          return { ...state, resultCards: '', dealBtn: true, betAmount: (state.betAmount = 0) };
          break;
        case DEAL_BUTTON_TRUE:
          return { ...state, dealBtn: true };
          break;
        case WIN_AMOUNT:
          return { ...state, winAmount: action.payload };
          break;
        case HISTORY:
            console.log("Update History: "+action.payload);
            return{...state, history: action.payload};
        break;
        case UPDATE_USER_BALANCE:
            console.log("Update userBalance: "+action.payload);
            return{...state, userBalance: action.payload};
        break;

        default:
          return state;
          break;
    }
};

export default manageChip;
