import React from "react";

const Matkanums = [
    
    {sectionOne : [ { betNum : 128, betId : 10 }, { betNum : 129, betId : 11 }, { betNum : 120, betId : 12 }, { betNum : 130, betId :13 }, { betNum : 140, betId :14 }, 
        { betNum : 137, betId : 20}, { betNum : 138, betId : 21}, { betNum : 139, betId :22 }, { betNum : 149, betId : 23}, { betNum : 159, betId : 24}, 
        { betNum : 146, betId : 30}, { betNum : 147, betId : 31}, { betNum : 148, betId : 32}, { betNum : 158, betId : 33}, { betNum : 168, betId : 34}, 
        { betNum : 236, betId : 40}, { betNum : 156, betId : 41}, { betNum : 157, betId : 42}, { betNum : 167, betId : 43}, { betNum : 230, betId : 44}, 
        { betNum : 245, betId : 50}, { betNum : 237, betId : 51}, { betNum : 238, betId : 52}, { betNum : 239, betId : 53}, { betNum : 249, betId : 54}, 
        { betNum : 290, betId : 60}, { betNum : 246, betId : 61}, { betNum : 247, betId : 62}, { betNum : 248, betId : 63}, { betNum : 258, betId : 64}, 
        { betNum : 380, betId : 70}, { betNum : 345, betId : 71}, { betNum : 256, betId : 72}, { betNum : 257, betId : 73}, { betNum : 267, betId : 74}, 
        { betNum : 470, betId : 80}, { betNum : 390, betId : 81}, { betNum : 346, betId : 82}, { betNum : 347, betId : 83}, { betNum : 348, betId : 84}, 
        { betNum : 489, betId : 90}, { betNum : 480, betId : 91}, { betNum : 490, betId : 92}, { betNum : 356, betId : 93}, { betNum : 357, betId : 94}, 
        { betNum : 560, betId : 100}, { betNum : 570, betId : 101}, { betNum : 580, betId : 102}, { betNum : 590, betId : 103}, { betNum : 456, betId : 104}, 
        { betNum : 579, betId : 110}, { betNum : 589, betId : 111}, { betNum : 670, betId : 112}, { betNum : 680, betId : 113}, { betNum : 690, betId : 114}, 
        { betNum : 678, betId : 120}, { betNum : 679, betId : 121}, { betNum : 689, betId : 122}, { betNum : 789, betId : 123}, { betNum : 780, betId : 124} ]},
    
    {sectionTwo : [ 
        { betNum : 100, betId : 130}, { betNum : 110, betId : 131}, { betNum : 166, betId : 132}, { betNum : 112, betId : 133}, { betNum : 113, betId : 134}, 
        { betNum : 119, betId : 140}, { betNum : 200, betId : 141}, { betNum : 229, betId : 142}, { betNum : 220, betId : 143}, { betNum : 122, betId : 144}, 
        { betNum : 155, betId : 150}, { betNum : 228, betId : 151}, { betNum : 300, betId : 152}, { betNum : 266, betId : 153}, { betNum : 177, betId : 154}, 
        { betNum : 227, betId : 160}, { betNum : 255, betId : 161}, { betNum : 337, betId : 162}, { betNum : 338, betId : 163}, { betNum : 339, betId : 164}, 
        { betNum : 335, betId : 170}, { betNum : 336, betId : 171}, { betNum : 355, betId : 172}, { betNum : 400, betId : 173}, { betNum : 366, betId : 174}, 
        { betNum : 344, betId : 180}, { betNum : 499, betId : 181}, { betNum : 445, betId : 182}, { betNum : 446, betId : 183}, { betNum : 447, betId : 184}, 
        { betNum : 399, betId : 190}, { betNum : 660, betId : 191}, { betNum : 599, betId : 192}, { betNum : 455, betId : 193}, { betNum : 500, betId : 194}, 
        { betNum : 588, betId : 200}, { betNum : 688, betId : 201}, { betNum : 779, betId : 202}, { betNum : 699, betId : 203}, { betNum : 799, betId : 204}, 
        { betNum : 669, betId : 210}, { betNum : 778, betId : 211}, { betNum : 788, betId : 212}, { betNum : 770, betId : 213}, { betNum : 889, betId: 214 }]},

    {sectionThree : [ 
        { betNum : 123, betId :15 }, { betNum : 124, betId : 16}, { betNum : 125, betId : 17}, { betNum : 126, betId :18 }, { betNum : 127, betId : 19}, 
        { betNum : 150, betId : 25}, { betNum : 160, betId : 26}, { betNum : 134, betId :27 }, { betNum : 135, betId :28 }, { betNum : 136, betId : 29}, 
        { betNum : 169, betId : 35}, { betNum : 179, betId : 36}, { betNum : 170, betId : 37}, { betNum : 180, betId : 38}, { betNum : 145, betId : 39}, 
        { betNum : 178, betId : 45}, { betNum : 250, betId : 46}, { betNum : 189, betId : 47}, { betNum : 234, betId : 48}, { betNum : 190, betId : 49}, 
        { betNum : 240, betId : 55}, { betNum : 269, betId : 56}, { betNum : 260, betId : 57}, { betNum : 270, betId : 58}, { betNum : 235, betId : 59}, 
        { betNum : 259, betId : 65}, { betNum : 278, betId : 66}, { betNum : 279, betId : 67}, { betNum : 289, betId : 68}, { betNum : 280, betId : 69}, 
        { betNum : 268, betId : 75}, { betNum : 340, betId : 76}, { betNum : 350, betId : 77}, { betNum : 360, betId : 78}, { betNum : 370, betId : 79}, 
        { betNum : 349, betId : 85}, { betNum : 359, betId : 86}, { betNum : 369, betId : 87}, { betNum : 379, betId : 88}, { betNum : 389, betId : 89}, 
        { betNum : 358, betId : 95}, { betNum : 368, betId : 96}, { betNum : 378, betId : 97}, { betNum : 450, betId : 98}, { betNum : 460, betId : 99}, 
        { betNum : 367, betId : 105}, { betNum : 458, betId : 106}, { betNum : 459, betId : 107}, { betNum : 469, betId : 108}, { betNum : 479, betId : 109}, 
        { betNum : 457, betId : 115}, { betNum : 467, betId : 116}, { betNum : 468, betId : 117}, { betNum : 478, betId : 118}, { betNum : 569, betId : 119}, 
        { betNum : 790, betId : 125}, { betNum : 890, betId : 126}, { betNum : 567, betId : 127}, { betNum : 568, betId : 128}, { betNum : 578, betId: 129 }]},
    
    {sectionFour : [ 
        { betNum : 114, betId : 135}, { betNum : 115, betId : 136}, { betNum : 116, betId : 137}, { betNum : 117, betId : 138}, { betNum : 118, betId : 139}, 
        { betNum : 277, betId : 145}, { betNum : 133, betId : 146}, { betNum : 224, betId : 147}, { betNum : 144, betId : 148}, { betNum : 226, betId : 149}, 
        { betNum : 330, betId : 155}, { betNum : 188, betId : 156}, { betNum : 233, betId : 157}, { betNum : 199, betId : 158}, { betNum : 244, betId : 159}, 
        { betNum : 448, betId : 165}, { betNum : 223, betId : 166}, { betNum : 288, betId : 167}, { betNum : 225, betId : 168}, { betNum : 299, betId : 169}, 
        { betNum : 466, betId : 175}, { betNum : 377, betId : 176}, { betNum : 440, betId : 177}, { betNum : 388, betId : 178}, { betNum : 334, betId : 179}, 
        { betNum : 556, betId : 185}, { betNum : 449, betId : 186}, { betNum : 477, betId : 187}, { betNum : 559, betId : 188}, { betNum : 488, betId : 189}, 
        { betNum : 600, betId : 195}, { betNum : 557, betId : 196}, { betNum : 558, betId : 197}, { betNum : 577, betId : 198}, { betNum : 550, betId : 199}, 
        { betNum : 880, betId : 205}, { betNum : 566, betId : 206}, { betNum : 800, betId : 207}, { betNum : 667, betId : 208}, { betNum : 668, betId : 209}, 
        { betNum : 899, betId : 215}, { betNum : 700, betId : 216}, { betNum : 990, betId : 217}, { betNum : 900, betId : 218}, { betNum : 677, betId: 219 }]},

        {top_bottom : [
            { betNum : 1, betId : 0, tpBtm: 'topLeft' },
            { betNum : 2, betId : 1, tpBtm: 'topLeft' },
            { betNum : 3, betId : 2, tpBtm: 'topLeft' },
            { betNum : 4, betId : 3, tpBtm: 'topLeft' },
            { betNum : 5, betId : 4, tpBtm: 'topLeft' },
            
            { betNum : 6, betId : 5, tpBtm: 'topRight' },
            { betNum : 7, betId : 6, tpBtm: 'topRight' },
            { betNum : 8, betId : 7, tpBtm: 'topRight' },
            { betNum : 9, betId : 8, tpBtm: 'topRight' },
            { betNum : 0, betId : 9, tpBtm: 'topRight' },

            { betNum : 777, betId : 220, tpBtm: 'btmLeft' },
            { betNum : 444, betId : 221, tpBtm: 'btmLeft' },
            { betNum : 111, betId : 222, tpBtm: 'btmLeft' },
            { betNum : 888, betId : 223, tpBtm: 'btmLeft' },
            { betNum : 555, betId : 224, tpBtm: 'btmLeft' },

            { betNum : 222, betId : 225, tpBtm: 'btmRight' },
            { betNum : 999, betId : 226, tpBtm: 'btmRight' },
            { betNum : 666, betId : 227, tpBtm: 'btmRight' },
            { betNum : 333, betId : 228, tpBtm: 'btmRight' },
            { betNum : '000', betId : 229, tpBtm: 'btmRight' },
        ]},
]

export default Matkanums;