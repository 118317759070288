import React from "react";
import WheelInner from "./funSoratWheel";
import Ximage from "./funSoratXimage";
import wheelSound from "../../../../../../assets/sounds/wheelStart.mp3";

export default class Wheel extends React.Component {
    constructor(props) {
        super(props);
        this.soratWheelRef = React.createRef();
       // this.ximgaes = React.createRef();
        this.state = {
            targetResult:props.targetResult
        } 
        this.inWid = window.innerWidth
        this.inHt = window.innerHeight 
        this.widthMain = 740;
        this.heightMain = 740; 
        this.posX = 0.5; 
        this.posY = 0.5;
        this.imageWidth = 110;  
        this.ballWidth = 12;
        this.imgPos = 0;
        this.X_top = 300;
        this.X_wid = 200; 
        this.bollOnWheel = 1050; 
        this.wheelSound = new Audio(wheelSound); 
    }
    componentStateHandler() { 
    }
    spinTheWheel = (s) => {
       
        this.soratWheelRef.current.spinTheWheel(s);
    };
    wheelHandler(position) {
        //console.log("Wheel Handler Position: "+position);
        this.props.action(position);
    }

    setJackpotNumber =(bonusNum) =>{
      //  this.ximgaes.current.setJackpotNumber(bonusNum);
    }
    stopWheelAnimation =(isFocus,s) =>{
        //  console.log( isFocus, s )      
       this.soratWheelRef.current.showResultOffFocus(isFocus,s);
    }

    stopWheelSound =() =>{
       // this.soratWheelRef.current.stopWheel();
    }
    sendimg=(img)=>{  
       // this.ximgaes.current.combine(img); 
    }
    render() { 
    //console.log('render from sorat')

        return (
            <div id="soratWheelContainer" style={{zIndex: `${this.props.zindex}`, width:`${this.widthMain}px`, height:`${this.heightMain}px`, position:'relative',   }}> 
            <div 
            style={{
                width: `${100}%`,
                height: `${100}%`,
                position: "absolute",
                transition: 'all 0.5s',
                zIndex: `${this.props.zIndex}`,
                transform: `scale(${this.props.zoom})`,
                transformOrigin: 'top',
                left: `${this.props.zIndex}%`
            }}
            >
            {/* <WheelBorder soratLoded={this.props.soratLoded} width={this.props.width} zoom={this.props.zoom} height={this.props.height} x={0} y={0} zIndex={this.props.zindex}></WheelBorder> */}
            <WheelInner bollOnWheel={this.bollOnWheel} onFocus={this.props.onFocus} ImageHandel={this.props.ImageHandel} imgPos={this.imgPos} posX={this.posX} posY={this.posY} imageWidth={this.imageWidth} ballWidth={this.ballWidth} gameState={this.props.gameState} ref={this.soratWheelRef} zoom={this.props.zoom} width={this.props.width} height={this.props.height} action={this.wheelHandler.bind(this)} isMobile={this.props.isMobile} isIphone = {this.props.isIphone} zIndex={this.props.zindex} lastResNum={this.props.lastResNum} ></WheelInner>
            {/* <Ximage bonusVal={this.props.bonusVal} X_ht={this.X_ht} zIndex={this.props.zindex} ref= {this.ximgaes} X_wid={this.X_wid} x_img={this.props.x_img} width={this.props.width} X_top={this.X_top} zoom={this.props.zoom} height={this.props.height} x={0} y={0}/> */}
            {/* <div   className="betXiMG_Marq"> 
            </div> */}
        <div  className="betXiMG"></div>
            </div>
          </div>
        );
    }
}
