import React from "react";
import { useState } from "react";
import "../../../../../css/games/funTargetTimer/rightButtonPanel.css";
import betSound from "../../../../../../assets/sounds/bet.mp3";
import { useSelector } from "react-redux";

export default function RightButtonPanel(props) {
    const language = useSelector((state) => state.languageObjs.languageObj)
    return (
        <div className="targetTimer_btnPanel">
            {/* disabled={this.props.disabulity} */}
            <div className="fl_1"> 
                    <button 
                        className={props.actBtn ? "fd active z_350" : "fd z_10"}
                        disabled={props.disBetBtn == 1}
                        name={props.betBtnText}
                        onClick={(e) => {
                        e.preventDefault();

                        console.log(e.target.name); 
                        // if (e.target.name == 'TAKE') {
                        if (props.betButtonValue == 2) {
                            console.log("TAKE Button ");
                            props.action("TAKE");
                        // }else if(e.target.name === "PREV"){
                        }else if(props.betButtonValue === 0){
                            console.log("Prev Button Name: "+e.target.name)
                            props.action("PREV");
                        // }else if(e.target.name === "BET OK"){
                        }else if(props.betButtonValue === 1){
                            console.log("Send Bet Request");
                            let betSounds;
                            betSounds = new Audio(betSound);
                            betSounds.play();
                            props.action("BET OK");
                        }

                    }}>{props.betBtnText}</button>
            </div>
            <div className="fl_1">
                    <button className="fd" onClick={(e) => { e.preventDefault(); props.action("clear") }} disabled={props.disClearBtn == 1}>{language.Clear}</button>
            </div>
            <div className="fl_1">
                    <button className="fd" onClick={(e) => { e.preventDefault(); props.action("double") }} disabled={props.disDoubleBtn == 1}> {language.Double} </button>
            </div>
        </div>
    );
}
