import React from "react";
// import { Stage, Layer, Group, Rect, Arc, Text } from "react-konva";
import "../../../../../css/games/funTargetTimer/funTargetTimerWheel.css";
// import wheelSound from "../../../../../../assets/sounds/spinandwheelreel.mp3";
import ftt_num_img from "../../../../../../assets/games/funTargetTimer/ftt_num_bg.svg";
import fttWheelBg_1 from "../../../../../../assets/games/funTargetTimer/wheel_1.svg";
import fttWheelBg_2 from "../../../../../../assets/games/funTargetTimer/wheel_2.svg";
import targetArrow from "../../../../../../assets/games/funTargetTimer/targetArrow.svg";

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props);
    
        this.firstload = true;
        this.r = 0;
        this.reward = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        this.rewards = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 7, 7: 8, 8: 9, 9: 0 };

        this.resultNumber = 0;
        this.ftt_wheelAnim = "";
        this.state = {
            timeCountQ: 1,
            layerOne: true, 
        };   
    }

    componentDidMount() { 
        this.setState({
            timeCountQ: 1
        })

        this.ftt_wheelAnim = document.getElementById("ftt_wheelAnim");

        // this.wedge.startRotation = this.wedge.rotation();
        // this.animation = new Konva.Animation(this.animate.bind(this), this.layer);
        if(this.props.gameState.history && this.firstload && this.props.gameState.history.length > 0){ 
            this.firstload = false; 
            let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            this.spinTheWheelOnload(resNo)   
        } else {
            let defaultNo = 0
            this.spinTheWheelOnload(defaultNo)
        }

        this.imgChange = setInterval(() => {
           
            this.setState({
                timeCountQ: this.state.timeCountQ + 1
            }) 
            // console.log(this.state.timeCountQ);
            if( this.state.timeCountQ % 2 == 0 ){
                this.setState({
                    layerOne: true
                })  
            } else {
                this.setState({
                    layerOne: false
                }) 
            } 
        }, 200);

        console.log(this.state.layerOne);
    }
    spinTheWheelOnload = (s) => {
        console.log(s)
        this.resNum = Number(s);

        const ftt_styles = {
            transition: 'all 0s',
            transform: `rotate(${(360 - (this.resNum * 36))}deg)`,
        };
        Object.assign(this.ftt_wheelAnim.style, ftt_styles);
    };
    
    spinTheWheel = (s) => {

        console.log("----------------  FTT Spin Wheel ----------------------");
        // this.ftt_wheelSound.play();
         console.log("Result Number: "+s);
         const styles = {
             transition: 'all 6.2s',            
             transform: `rotate(${(1440 + (360 - (s * 36)))}deg)`,
         };
         Object.assign(this.ftt_wheelAnim.style, styles);
 
         setTimeout(() => {
             this.afterRotate(s);
         }, 6500)
    };

    stopWheel=()=>{
        clearInterval(this.imgChange);
    }

    afterRotate(resNumVal) {        
        const styles = {
            transition: 'all 0s',           
            transform: `rotate(${(360 - (resNumVal * 36))}deg)`,
        };
        Object.assign(this.ftt_wheelAnim.style, styles);
        this.announceWinner(resNumVal);       
    }

    announceWinner(value) {
        setTimeout(() => {
            this.props.action(value);
        }, 100);
    }

    render() {
        return (            
            <div id="ftt_wheelWraper">       
             <img className="targetTimerArrow" src={targetArrow} />        
                <div class="ftt_wheelAnimOne">                       
                    <div class="ftt_wheelAnim" id="ftt_wheelAnim">
                        <img src={ftt_num_img} />    
                    </div>                       
                </div>               
                <div className="ftt_wheelBorder"> 
                    {this.state.layerOne ? 
                        <img src={fttWheelBg_1} /> :  
                        <img src={fttWheelBg_2} /> }
                </div>
            </div>
        );
    }
}
