import React from "react";
import wheelImg from "../../../../../../assets/games/funSoratTimer/funsorat_wheel.png";
import ball_img from "../../../../../../assets/games/funSoratTimer/rollBall.png";
import fstWheelBg_1 from "../../../../../../assets/games/funSoratTimer/wheel_1.svg";
import fstWheelBg_2 from "../../../../../../assets/games/funSoratTimer/wheel_2.svg";

export default class WheelInner extends React.Component {
    constructor(props) {
        super(props);
        this.firstload = true;
        this.rewards = { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 7, 7: 8, 8: 9, 9: 10, 10: 11, 11: 12, 12: 13 };
        this.arr = [0, 9, 12, 7, 8, 5, 4, 11, 2, 1, 10, 3, 6];
        this.resultNumber = 0;
        this.onLoadImg = false;
        this.isFocused = true;
        this.state = {
            image: null,
            timeCountQ: 1,
            layerOne: true, 
        };
        this.ballBg = "";
        this.wheelAnim = "";
    }

    componentDidMount() {
        this.setState({
            timeCountQ: 1
        })

        this.ballBg = document.getElementById("ballBg");
        this.wheelAnim = document.getElementById("wheelAnim")
        this.rollBallBg = document.getElementById("rollBall");

        if (this.props.gameState.history && this.firstload && this.props.gameState.history.length > 0) {
            let resNo = this.props.gameState.history[this.props.gameState.history.length - 1].resultNo;
            this.spinTheWheelOnload(resNo);
            this.firstload = false;
        } else {
            let defaultNo = 0
            this.spinTheWheelOnload(defaultNo);
        }

        this.imgChange = setInterval(() => {
           
            this.setState({
                timeCountQ: this.state.timeCountQ + 1
            }) 
            //console.log(this.state.timeCountQ);
            if( this.state.timeCountQ % 2 == 0 ){
                this.setState({
                    layerOne: true
                })  
            } else {
                this.setState({
                    layerOne: false
                }) 
            } 
        }, 200);
    }

    addImage(image) {  
        let img = new window.Image();
        img.src = image; 
        return img; 
    }

    spinTheWheelOnload = (s) => {
        console.log("--------------->  "+s);
        var stopValueFirst = this.arr.indexOf(Number(s))
        console.log("****************   "+stopValueFirst);
        const ball_style = {
            transition: 'all 0s',
            transform: `rotate(${(stopValueFirst * 27.69)}deg)`,
        };

        Object.assign(this.ballBg.style, ball_style);

        const styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,

        };
        Object.assign(this.wheelAnim.style, styles);
    };

    showResultOffFocus=(isFocus, s)=>{
        // console.log("--------------   Set Result ---------------");
        // console.log(isFocus);
        // console.log(this.finished);
       
        if(!isFocus && !this.finished){
            this.isFocused = false;
        
        }else{
            if(!this.isFocused){
                this.isFocused = true;
            }
        }       
    }

    stopAnimation() {
        this.animateFirst();
    }

    animateFirst(){ 
        var diff = 10 / 2000; 
        if(this.wheel){
            this.wheel.rotate(-diff);
        }
        if(this.pointerGroup && this.pointerGroup.rotate){
            this.pointerGroup.rotate(-diff); 
        }
        if( this.lastRotation && this.wheel){
            this.lastRotation = this.wheel.rotation();
        }
    }

    spinTheWheel = (resNum) => {
        let stopValue = this.arr.indexOf(Number(resNum))
        const ball_style = {
            transition: 'all 7.5s',
            transform: `rotate(${(2520 + (stopValue * 27.69))}deg)`,
        };
        Object.assign(this.ballBg.style, ball_style);

        const styles = {
            transition: 'all 7.5s',
            transform: `rotate(${-1080}deg)`,

        };
        Object.assign(this.wheelAnim.style, styles);        
        
        let minVal = Math.ceil(5000);
        let maxVal = Math.floor(7000);
        let randStopVal = Math.floor(Math.random() * (maxVal - minVal + 1) + minVal);
        
        console.log(randStopVal);

        setTimeout(() => {
            this.afterRotate(stopValue);
        }, 7100)
    };


    afterRotate(resNumVal) {
        console.log('hi');
        console.log(resNumVal);        
        console.log(resNumVal * 27.69);

        const ball_style = {
            transition: 'all 0s',
            transform: `rotate(${(resNumVal * 27.69)}deg)`,
        };
        Object.assign(this.ballBg.style, ball_style);

        const styles = {
            transition: 'all 0s',
            transform: `rotate(0deg)`,
        };
        Object.assign(this.wheelAnim.style, styles);

        this.props.ImageHandel()
        this.announceWinner(this.resultNumber);
    }

    announceWinner(value) {
        console.log("Stop Ball: " + value);        
        this.props.action(value);     
    }

    render() {
        return (

            <div className="funSoratWheelContainer" id="funSoratWheelContainer">

                <div className="wheelAnimOne">
                    <div className="wheelAnimParent">
                        <div className="wheelAnim" id="wheelAnim">
                            <img src={wheelImg} />                            
                        </div>
                        <div className="bollWrap" id="ballBg">
                            <img className="rollBall" id="rollBall" src={ball_img} />
                        </div>
                        <div className="fst_wheelBorder"> 
                        {this.state.layerOne ? 
                        <img src={fstWheelBg_1} /> :  
                        <img src={fstWheelBg_2} /> }
                    </div>
                    </div>
                    
                </div>

            </div>
        );
    }
}
